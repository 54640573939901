import { BaseColors } from '../../types';
import * as variables from '../../../constants/styles';

export const getBaseColor = (color?: BaseColors): string => {
  if (color === 'pink') return variables.colorPink;
  if (color === 'blue') return variables.colorBlue;
  if (color === 'black') return variables.textBlack;
  if (color === 'grey') return variables.textGrey;
  if (color === 'secondary') return variables.colorBtnSecondary;
  if (color === 'white') return 'white';
  return variables.textBlack;
};
