export const creatorRootUrl = '/creator';

export const creatorSignInUrl = `${creatorRootUrl}/signin`;

export const creatorSignUpUrl = `${creatorRootUrl}/signup`;
export const signUpInitialUrl = `${creatorSignUpUrl}`;
export const signUpConfirmWasSentUrl = `${signUpInitialUrl}/thanks`;
export const signUpConfirmUrl = `${creatorSignUpUrl}/confirm`;

export const resetUrl = `${creatorRootUrl}/reset`;
export const resetSuccessUrl = `${resetUrl}/success`;
export const resetConfirmUrl = `${creatorRootUrl}/resetpw/confirm`;
export const resetConfirmSuccessUrl = `${creatorRootUrl}/resetpw/confirm/success`;

export const accountUrl = `${creatorRootUrl}`;

export const changePlanUrl = '/changeplan';
export const paymentMethodUrl = '/payment';
export const subscriptionSuccess = '/subscription/success';
export const creatorPaymentSuccessful = '/res/payment/success';

export const newGalleryUrl = `${accountUrl}/new-gallery`;
export const salesUrl = `${accountUrl}/sales`;
export const activeGalleriesUrl = `${accountUrl}/active-gallery`;
export const draftGalleriesUrl = `${accountUrl}/draft-gallery`;
export const settingsUrl = `${accountUrl}/settings`;
export const supportUrl = `${accountUrl}/support`;
export const notificationsUrl = `${accountUrl}/notifications`;

export const confirmedEmail = '/confirmed-email';
