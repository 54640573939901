import React from 'react';
import { useTranslation } from 'react-i18next';
import { MailIcon } from '../../../../shared/icons/Landing';
import { MediumBlackText, MediumGreyText, MediumPinkText } from '../../../../shared/styles';
import { TermsAndPrivacyContactInfoWrapper, TermsAndPrivacyContactUsWrapper, TermsAndPrivacyContactEmail } from './styled';

export const TermsAndPrivacyContactInfo: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyContactInfoWrapper>
      <TermsAndPrivacyContactUsWrapper>
        <MediumBlackText>
          {t('contactUs')}
        </MediumBlackText>
        <TermsAndPrivacyContactEmail>
          <MailIcon />
          <MediumPinkText fontWeight={600}>
            info@delivrable.io
          </MediumPinkText>
        </TermsAndPrivacyContactEmail>
      </TermsAndPrivacyContactUsWrapper>
      <div>
        <MediumBlackText>
          {t('address')}
        </MediumBlackText>
      </div>
      <div>
        <MediumGreyText>
          {t('leealVision')}
        </MediumGreyText>
      </div>
    </TermsAndPrivacyContactInfoWrapper>
  );
};
