import React, { CSSProperties, ReactElement } from 'react';
import { DetailsContentWrapper } from './styled';

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
  noOverflowY?: boolean;
  style?: CSSProperties;
};

export const TabPanel = (props: TabPanelProps): ReactElement => {
  const {
    children, value, index, noOverflowY, style, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <DetailsContentWrapper style={style} noOverflowY={noOverflowY}>
          {children}
        </DetailsContentWrapper>
      )}
    </div>
  );
};
