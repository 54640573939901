import styled from '@emotion/styled';
import { TabPanelWrapper } from '../styled';

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & svg {
    margin-right: 8px;
  }
`;

export const DevicesAndBrowsersTable = styled.div`
  margin-top: 20px;
`;

export const CancelPanelWrapper = styled.div`
  & > div,
  & > span, 
  & > button {
    margin-top: 15px;
  }
`;

export const InvisibleButton = styled.button<{ isDisplay: boolean }>`
  padding: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: ${(props) => (props.isDisplay ? 'flex' : 'none')};
`;

export const DevicesAndBrowsersWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabSettingsWrapper = styled(TabPanelWrapper)`
  & > div {
    min-height: 0px;
  }
`;

export const SocialLinksTitle = styled.div`
  margin-top: 20px;
  margin-bottom: 16px;
  display: flex;
`;

export const SiteAddressWrapper = styled.div`
  margin-bottom: 40px;
`;

export const SettingsResendLinkButton = styled.button`
  &: disabled {
    cursor: auto;
  }
`;

export const ConfirmedEmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 0px;
  box-sizing: border-box;
  min-height: 100vh;
  height: fit-content;
  padding: 0px 20px;
`;
