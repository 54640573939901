import { AxiosResponse } from 'axios';
import { FavouritesCartReq } from '../../shared/types/client';
import { clientInstance, instance } from '../base/instance';
import { interactionsUrl } from '../../constants/urls';

export const favourites = {
  getFavourites: (galleryId: string): Promise<AxiosResponse<any>> => clientInstance.get(`${interactionsUrl}/favourites/${galleryId}`),
  addToFavourites: (data: FavouritesCartReq): Promise<AxiosResponse<any>> => clientInstance.put(`${interactionsUrl}/favourites`, data),
  removeFavourites: (data: FavouritesCartReq): Promise<AxiosResponse<string[]>> => clientInstance.put(`${interactionsUrl}/favourites/delete`, data),
};

export const creatorFavourites = {
  getFavourites: (galleryId: string, token: string): Promise<AxiosResponse<any>> => instance.get(`${interactionsUrl}/creators/favourites/${galleryId}`, { headers: { crossDomain: true, Authorization: `${token}` } }),
};
