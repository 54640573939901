import styled from '@emotion/styled';
import { PrimaryWhiteButton } from '../../../../../../shared/styles';

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  width: fit-content;

  & > span {
    &:first-child {
      margin-bottom: 36px;
    }
  }
`;

export const VideoColumnWrapper = styled(ColumnWrapper)`
  margin-bottom: 30px;
`;

export const WhiteButtonFitContent = styled(PrimaryWhiteButton)`
  width: fit-content;
`;

export const VideoFromYouTubeWrapper = styled.div`
  width: 100%;
  height: fit-content;

  & iframe {
    width: 100%;
  }
`;
