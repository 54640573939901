/* eslint-disable */
import { Grid } from '@material-ui/core';
import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useUser } from '@clerk/clerk-react';
import { useFormikContext } from 'formik';
import { httpUrl, dilivrableGalleryURL } from '../../../../../../../constants/urls';
import { CommonCopyInput } from '../../../../../../../shared/components/CommonCopyInput/CommonCopyInput';
import { CommonInput } from '../../../../../../../shared/components/FormElements/CommonInput/CommonInput';
import { CreatorAppDispatch, useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';
import {
  FacebookIcon,
  InstagramIcon,
  YouTubeIcon,
  VimeoIcon,
  LinkedInIcon,
  PinterestIcon,
} from '../../../../../../../shared/icons';
import { PrimaryWhiteButton, SimpleBlackText } from '../../../../../../../shared/styles';
import { CreatorProfileGet, CreatorForm } from '../../../../../../../shared/types';
import { RowsWrapper } from '../styled';
import { CancelPanel } from './CancelPanel';
import { DevicesAndBrowsers } from './DevicesAndBrowsers';
import { ProfilePicture } from './ProfilePicture/ProfilePicture';
import { LabelWrapper, SiteAddressWrapper, SocialLinksTitle, TabSettingsWrapper } from './styled';
import { ChangePassword } from './ChangePassword/ChangePassword';
import { dateFormats, getTimezonesList } from '../../../../../../../constants/selectors';
import { useGetSelectionItems } from '../../../../../../../shared/hooks/useGetSelectionItems';
import { CommonSelect } from '../../../../../../../shared/components/FormElements/CommonSelect/CommonSelect';
import { timezonesArr } from '../../../../../../../constants/selectors/timezones';
import { ChangeEmail } from './ChangeEmail/UpdateEmail';
import { fetchRedirectToOnboarding } from '../../../../../redux/userCreator/signUp/signUpCreatorThunk';
import { ErrorMessage } from '../../../../../../../shared/components/styled';
import { PinkLoader } from '../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { HelpTip } from '../../../../../../../shared/components/HelpTip/HelpTip';

export const ProfileTab: React.FC = () => {
  const { t } = useTranslation('settings');
  const getSelectionItems = useGetSelectionItems();
  const dispatch = useCreatorAppDispatch();
  const { getToken } = useAuth();

  const handleClick = () => {
    dispatch(fetchRedirectToOnboarding(getToken));
  };

  const redirectError = useTypedSelectorCreator(
    (state) => state.signUpCreator.redirectError,
  );
  const redirectLoading = useTypedSelectorCreator(
    (state) => state.signUpCreator.redirectLoading,
  );
  const currentUser = useTypedSelectorCreator(
    (state) => state.creator.currentUser,
  );
  const isLoadingUser = useTypedSelectorCreator(
    (state) => state.creator.loading,
  );

  const selectPlaceholder = useMemo(
    () => timezonesArr.find((item) => item.value.includes('Los Angeles')),
    [],
  );

  const timezones = useMemo(() => getTimezonesList(), []);
  const menuItemsTimezones = useMemo(() => getSelectionItems(timezones), []);

  const menuItemsDateFormats = useMemo(() => getSelectionItems(dateFormats), []);

  const {
    values, errors, handleChange, handleBlur, touched, setFieldValue,
  } = useFormikContext<CreatorForm>();

  const address = `${currentUser?.businessNameUrl}.${dilivrableGalleryURL}` || '';

  // if (isLoadingUser) {
  //   return (
  //     <TabSettingsWrapper>
  //       <RowsWrapper>
  //         <PinkLoader />
  //       </RowsWrapper>
  //     </TabSettingsWrapper>
  //   );
  // }

  return (
    <TabSettingsWrapper>
      <RowsWrapper>
        <Grid container>
          <Grid container item lg={6} md={12}>
            <ProfilePicture />
            <Grid style={{ marginTop: '20px' }} item sm={9}>
              <CommonInput
                label={t('yourFirstName')}
                value={values.firstName}
                name="firstName"
                handleChange={handleChange}
                handleBlur={handleBlur}
                direction="column"
                error={errors?.firstName}
                touched={touched?.firstName}
                type="text"
              />
            </Grid>
            <Grid item sm={9}>
              <CommonInput
                label={t('yourSecondName')}
                value={values.lastName}
                name="lastName"
                handleChange={handleChange}
                handleBlur={handleBlur}
                direction="column"
                error={errors?.lastName}
                touched={touched?.lastName}
                type="text"
              />
            </Grid>
            <Grid item sm={9}>
              <CommonInput
                label={t('businessName')}
                value={values.businessName}
                name="businessName"
                handleChange={handleChange}
                handleBlur={handleBlur}
                direction="column"
                error={errors?.businessName}
                touched={touched?.businessName}
                type="text"
              />
            </Grid>
            <Grid item sm={9}>
              <SiteAddressWrapper>
                <CommonCopyInput isLinkFull label={t('address')} value={address} name="address" readonly />
              </SiteAddressWrapper>
            </Grid>
            {!currentUser?.onboarded && (
            <Grid item sm={9}>
              <SiteAddressWrapper>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <PrimaryWhiteButton onClick={handleClick} type="button" disabled={!!redirectLoading}>
                    Finish Stripe registration
                  </PrimaryWhiteButton>
                  {redirectLoading && <div style={{ marginLeft: '20px' }}><PinkLoader /></div>}
                </div>
                {redirectError && <ErrorMessage>{redirectError}</ErrorMessage>}
              </SiteAddressWrapper>
            </Grid>
            )}
            <ChangeEmail />
            <ChangePassword />
          </Grid>
          <Grid style={{ height: 'fit-content' }} container item lg={6} md={12}>
            <Grid item sm={10}>
              <CommonSelect
                error={errors.timezone}
                value={values.timezone}
                name="timezone"
                placeholder={selectPlaceholder?.value || ''}
                handleChange={handleChange}
                touched={touched.timezone}
                label={t('timezone')}
                menuItems={menuItemsTimezones}
                maxWidth={314}
                valuesArr={timezones}
              />
            </Grid>
            <Grid item sm={10}>
              <CommonSelect
                error={errors.dateFormat}
                value={values.dateFormat}
                name="dateFormat"
                placeholder="mm/dd/yyyy"
                handleChange={handleChange}
                touched={touched.dateFormat}
                label={t('dateFormat')}
                menuItems={menuItemsDateFormats}
                valuesArr={dateFormats}
              />
            </Grid>
            <Grid item sm={10}>
              <SocialLinksTitle>
                <SimpleBlackText>{t('socialLinks')}</SimpleBlackText>
                <HelpTip position="right" text={t('socialLinksHelp')} withMgLeft />
              </SocialLinksTitle>
            </Grid>
            <Grid item sm={10}>
              <CommonInput
                label={
                  <LabelWrapper>
                    <FacebookIcon />
                    {t('socialMedias.facebook')}
                  </LabelWrapper>
                }
                value={values.facebook}
                name="facebook"
                handleChange={handleChange}
                handleBlur={handleBlur}
                direction="column"
                error={errors?.facebook}
                touched={touched?.facebook}
                placeholder={t('placeholders.link')}
                type="text"
                withoutMargins
              />
            </Grid>
            <Grid item sm={10}>
              <CommonInput
                label={
                  <LabelWrapper>
                    <InstagramIcon />
                    {t('socialMedias.instagram')}
                  </LabelWrapper>
                }
                value={values.instagram}
                name="instagram"
                handleChange={handleChange}
                handleBlur={handleBlur}
                direction="column"
                error={errors?.instagram}
                touched={touched?.instagram}
                placeholder={t('placeholders.link')}
                type="text"
                withoutMargins
              />
            </Grid>
            <Grid item sm={10}>
              <CommonInput
                label={
                  <LabelWrapper>
                    <YouTubeIcon />
                    {t('socialMedias.youtube')}
                  </LabelWrapper>
                }
                value={values.youtube}
                name="youtube"
                handleChange={handleChange}
                handleBlur={handleBlur}
                direction="column"
                error={errors?.youtube}
                touched={touched?.youtube}
                placeholder={t('placeholders.link')}
                type="text"
                withoutMargins
              />
            </Grid>
            <Grid item sm={10}>
              <CommonInput
                label={
                  <LabelWrapper>
                    <VimeoIcon />
                    {t('socialMedias.vimeo')}
                  </LabelWrapper>
                }
                value={values.vimeo}
                name="vimeo"
                handleChange={handleChange}
                handleBlur={handleBlur}
                direction="column"
                error={errors?.vimeo}
                touched={touched?.vimeo}
                placeholder={t('placeholders.link')}
                type="text"
                withoutMargins
              />
            </Grid>
            <Grid item sm={10}>
              <CommonInput
                label={
                  <LabelWrapper>
                    <LinkedInIcon />
                    {t('socialMedias.linkedin')}
                  </LabelWrapper>
                }
                value={values.linkedIn}
                name="linkedIn"
                handleChange={handleChange}
                handleBlur={handleBlur}
                direction="column"
                error={errors?.linkedIn}
                touched={touched?.linkedIn}
                placeholder={t('placeholders.link')}
                type="text"
                withoutMargins
              />
            </Grid>
            <Grid item sm={10}>
              <CommonInput
                label={
                  <LabelWrapper>
                    <PinterestIcon />
                    {t('socialMedias.pintrest')}
                  </LabelWrapper>
                }
                value={values.pinterest}
                name="pinterest"
                handleChange={handleChange}
                handleBlur={handleBlur}
                direction="column"
                error={errors?.pinterest}
                touched={touched?.pinterest}
                placeholder={t('placeholders.link')}
                type="text"
                withoutMargins
              />
            </Grid>
          </Grid>
        </Grid>
      </RowsWrapper>
      {/* //! hide for now */}
      {/* <DevicesAndBrowsers /> */}
      {/* //! hide for now */}
      {/* <CancelPanel /> */}
    </TabSettingsWrapper>
  );
};
