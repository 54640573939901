import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from '@mui/material';
import { ClerkProvider } from '@clerk/clerk-react';
import { GlobalContainer } from '../../../shared/components/GlobalContainer/GlobalContainer';
import * as urls from '../../../constants/urls';
import { CreatorContainer } from './CreatorContainer';
import { PasswordProtectedPage } from '../../PasswordProtectedPage/containers/PasswordProtectedPage';
import { BetaPaymentSuccessful } from '../../PasswordProtectedPage/containers/BetaPaymentSuccessful';
import { ConfirmedEmail } from './CreatorAccount/Tabs/Settings/ProfileTab/ChangeEmail/ConfirmedEmail';
import { LandingPrivacy } from '../../Landing/containers/LandingTermsAndPrivicyPolicy/LandingPrivacy';
import { LandingTerms } from '../../Landing/containers/LandingTermsAndPrivicyPolicy/LandingTerms';
import { TranslateContainer } from '../../../shared/components/GlobalContainer/TranslateContainer';
import { CookiesConsent } from '../../../shared/components/CookiesConsent/CookiesConsent';

const PUBLISHABLE_KEY = process.env.REACT_APP_VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key');
}

export const CreatorAndLandingContainer: React.FC = () => (
  <ClerkProvider
    publishableKey={PUBLISHABLE_KEY}
  >
    <Router basename="/">
      <Routes>
        <Route
          path={`${urls.creatorRootUrl}/*`}
          element={(
            <GlobalContainer>
              <Container maxWidth="xl">
                <CreatorContainer />
              </Container>
            </GlobalContainer>
          )}
        />
        <Route
          path={`${urls.paymentMethodUrl}${urls.subscriptionSuccess}`}
          element={(
            <GlobalContainer>
              <Container disableGutters maxWidth={false}>
                <BetaPaymentSuccessful />
              </Container>
            </GlobalContainer>
          )}
        />
        <Route
          path={urls.rootLanding}
          element={(
            <GlobalContainer>
              {/* <Container maxWidth="xl"> */}
              <PasswordProtectedPage />
              {/* <LandingContainer /> */}
              {/* </Container> */}
            </GlobalContainer>
          )}
        />
        <Route
          path="email-confirm"
          element={(
            <GlobalContainer>
              <ConfirmedEmail />
            </GlobalContainer>
          )}
        />
        <Route
          path={urls.termsUrl}
          element={<GlobalContainer><LandingTerms /></GlobalContainer>}
        />
        <Route
          path={urls.privacyUrl}
          element={<GlobalContainer><LandingPrivacy /></GlobalContainer>}
        />
      </Routes>
    </Router>
  </ClerkProvider>
);
