import React, { ReactNode } from 'react';
import { TabPanel } from '../../ActiveGalleries/Dashboard/GalleryTabsDetails/TabPanel';

export const SettingsTabPanel: React.FC<{
    children: ReactNode;
    value: number;
    index: number;
  }> = ({ children, value, index }) => (
    <TabPanel style={{ paddingTop: 0 }} value={value} index={index} noOverflowY>
      {children}
    </TabPanel>
  );
