import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TermsAndPrivacyListTitle, TermsAndPrivacyText } from '../styled';

export const CookiesPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <>
      <TermsAndPrivacyListTitle>
        {t('privacy.cookies')}
      </TermsAndPrivacyListTitle>
      <Trans t={t} i18nKey="privacy.cookiesPoints">
        <TermsAndPrivacyText hasMarginBottom>
          We may use cookies on our website from time to time. Cookies are text
          files placed in your computer&apos;s browser to store your preferences.
          Cookies, by themselves, do not tell us your email address or other
          personally identifiable information. If and when you choose to provide
          our online website with personal information, this information may be
          linked to the data stored in the cookie.
        </TermsAndPrivacyText>
        <TermsAndPrivacyText hasMarginBottom>
          You can block cookies by activating the setting on your browser that
          allows you to refuse the setting of all or some cookies. However, if
          you use your browser settings to block all cookies (including
          essential cookies) you may not be able to access all or parts of our
          website.
        </TermsAndPrivacyText>
      </Trans>
    </>
  );
};
