import { Grid } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TickIcon } from '../../../../shared/icons';
import {
  SignInUpCardCenter,
  TextWithIcon,
  TitleSimple,
  ErrorWrapper,
} from '../../../../shared/styles';
import { TextInstructions, ResendButtonWrapper } from './styled';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../shared/hooks/useTypedSelector';
import { ErrorMessage } from '../../../../shared/components/styled';
import { fetchSignUpInitialResendCreator } from '../../redux/userCreator/signUp/signUpCreatorThunk';
import { useTimer } from '../../../../shared/hooks/useTimer';
import { setTimeToResendData } from '../../utils/helpers';
import * as urls from '../../../../constants/urls';
import { ResendButton } from '../../../../shared/components/ResendButton/ResendButton';

export const EmailWasConfirmedCard: React.FC = () => {
  const { t } = useTranslation(['signUp', 'basicComponents']);
  const dispatch = useCreatorAppDispatch();
  const navigate = useNavigate();
  const wasSent = useTypedSelectorCreator((state) => state.signUpCreator.wasSent);
  const isLoading = useTypedSelectorCreator((state) => state.signUpCreator.loading);
  const signUpErrors = useTypedSelectorCreator((state) => state.signUpCreator.error);
  const { msCounter, startNewTimer } = useTimer();

  useEffect(() => {
    if (wasSent) {
      startNewTimer();
    }
  }, [wasSent]);

  const handleResendEmail = () => {
    const email = window.localStorage.getItem('email');
    if (email) {
      setTimeToResendData();
      dispatch(fetchSignUpInitialResendCreator(email));
    } else {
      navigate(urls.resetUrl);
    }
  };

  const emailError = useMemo(() => {
    if (signUpErrors?.errorType === 'other' && signUpErrors.errors.length > 0) {
      return signUpErrors.errors[0].message;
    }
    return null;
  }, [signUpErrors]);

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xl={6} lg={7} md={9} xs={11}>
        <SignInUpCardCenter theme="thanks">
          <TitleSimple>{t('basicComponents:signUp')}</TitleSimple>
          <TextWithIcon>
            <TickIcon />
            <span>{t('thanks')}</span>
          </TextWithIcon>
          <TextInstructions>
            {t('sentEmailConfirmation')}
          </TextInstructions>
          <ErrorWrapper>
            {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
          </ErrorWrapper>
          <ResendButtonWrapper>
            <ResendButton
              buttonText={t('signUp:resendEmail')}
              msCounter={msCounter}
              isLoading={isLoading}
              handleResend={handleResendEmail}
            />
          </ResendButtonWrapper>
        </SignInUpCardCenter>
      </Grid>
    </Grid>
  );
};
