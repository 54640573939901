import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomAvatar = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '30px',
    height: '30px',
    fontSize: '1rem',
  },
}));
