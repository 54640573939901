import React from 'react';
import { useTranslation } from 'react-i18next';
import { MediumBlackText } from '../../../../../../../../shared/styles';
import {
  ReminderEmailFormQuestions,
  ReminderEmailFormRow, ReminderEmailFormWrapper,
} from './styled';
import { HelpTip } from '../../../../../../../../shared/components/HelpTip/HelpTip';
import { ReminderEmailCheckboxesGroup } from './ReminderEmailCheckboxesGroup';
import { ReminderEmailRegisteredEmails } from './ReminderEmailRegisteredEmails';
import { ReminderEmailWhitelistedEmails } from './ReminderEmailWhitelistedEmails';

export const ReminderEmailForm: React.FC = () => {
  const { t } = useTranslation('createNewGallery');

  return (
    <ReminderEmailFormWrapper>
      <ReminderEmailFormQuestions>
        <MediumBlackText>
          {t('fourthStep.reminder.when.question')}
        </MediumBlackText>
        <HelpTip withMgLeft text={t('fourthStep.helpTexts.reminderWhenQuestion')} />
      </ReminderEmailFormQuestions>
      <ReminderEmailCheckboxesGroup />
      <ReminderEmailFormQuestions>
        <MediumBlackText>
          {t('fourthStep.reminder.who.question')}
        </MediumBlackText>
      </ReminderEmailFormQuestions>
      <ReminderEmailFormRow>
        <ReminderEmailRegisteredEmails />
        <ReminderEmailWhitelistedEmails />
      </ReminderEmailFormRow>
    </ReminderEmailFormWrapper>
  );
};
