import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyInsideListWrapper, TermsAndPrivacyListItem } from '../styled';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const IntellectualPropertyPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={11}
      listTitle={t('terms.intellectualProperty')}
      pointsNameForTrans="intellectualPropertyPoints"
    >
      <LandingNumberedLi>
        All intellectual property (including copyright) developed, adapted,
        modified or created by us or our personnel (including in connection
        with the Terms, any content on the Platform, and the products) (
        <span className="bold">Our Intellectual Property</span>
        ) will at all
        times vest, or remain vested, in us.
      </LandingNumberedLi>
      <LandingNumberedLi>
        We authorise you to use Our Intellectual Property solely for the
        purposes for which it was intended to be used.
      </LandingNumberedLi>
      <LandingNumberedLi>
        You must not, without our prior written consent:
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            copy, in whole or in part, any of Our Intellectual Property;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            reproduce, retransmit, distribute, disseminate, sell, publish,
            broadcast or circulate any of Our Intellectual Property to any
            third party; or
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            breach any intellectual property rights connected with the
            Platform, including (without limitation) altering or modifying
            any of Our Intellectual Property; causing any of Our
            Intellectual Property to be framed or embedded in another
            website; or creating derivative works from any of Our
            Intellectual Property.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            This clause will survive the termination or expiry of these
            Terms.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
