import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@clerk/clerk-react';
import { TitleStartWhiteText } from '../../../../../../shared/components/PremiumPlanCard/styled';
import {
  ColorfulCard,
  GradientColorText,
} from '../../../../../../shared/styles';
import { fetchRedirectToOnboarding } from '../../../../redux/userCreator/signUp/signUpCreatorThunk';
import { WhiteButtonFitContent } from '../Support/styled';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../shared/hooks/useTypedSelector';
import { PinkLoader } from '../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { ErrorMessage } from '../../../../../../shared/components/styled';

export const StripeRegistractionBanner: React.FC = () => {
  const { t } = useTranslation('basicComponents');
  const dispatch = useCreatorAppDispatch();
  const { getToken } = useAuth();
  const redirectLoading = useTypedSelectorCreator(
    (state) => state.signUpCreator.redirectLoading,
  );
  const redirectError = useTypedSelectorCreator(
    (state) => state.signUpCreator.redirectError,
  );

  const handleRedirectToOnboarding = () => {
    dispatch(fetchRedirectToOnboarding(getToken));
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <ColorfulCard
          style={{
            maxWidth: 'none',
            width: '100%',
            marginTop: '0px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '20px 15px',
            boxSizing: 'border-box',
          }}
        >
          <TitleStartWhiteText style={{ fontSize: '22px', fontWeight: 600 }}>
            {t('stripeRegister')}
          </TitleStartWhiteText>
          {redirectError && <ErrorMessage>{redirectError}</ErrorMessage>}
          <WhiteButtonFitContent
            type="button"
            onClick={handleRedirectToOnboarding}
            style={{ margin: '0px', boxShadow: 'none', fontSize: '18px' }}
          >
            {redirectLoading ? (
              <PinkLoader size="xs" />
            ) : (
              <GradientColorText fontWeight={600}>
                {t('buttons.register')}
              </GradientColorText>
            )}
          </WhiteButtonFitContent>
        </ColorfulCard>
      </Grid>
    </Grid>
  );
};
