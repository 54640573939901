import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TermsAndPrivacyListTitle, TermsAndPrivacyText } from '../styled';

export const YourRightsPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <>
      <TermsAndPrivacyListTitle>
        {t('privacy.yourRights')}
      </TermsAndPrivacyListTitle>
      <Trans t={t} i18nKey="privacy.yourRightsPoints">
        <TermsAndPrivacyText hasMarginBottom>
          <span className="bold">Your choice: </span>
          Please read this Privacy Policy carefully. If you provide
          personal information to us, you understand we will collect, hold, use
          and disclose your personal information in accordance with this Privacy
          Policy. You do not have to provide personal information to us,
          however, if you do not, may affect our ability to provide our Services
          to you and your use of our Services.
        </TermsAndPrivacyText>
        <TermsAndPrivacyText hasMarginBottom>
          <span className="bold">Information from third parties: </span>
          If we receive personal information
          about you from a third party, we will protect it as set out in this
          Privacy Policy. If you are a third party providing personal
          information about somebody else, you represent and warrant that you
          have such person&apos;s consent to provide the personal information to us.
        </TermsAndPrivacyText>
        <TermsAndPrivacyText hasMarginBottom>
          <span className="bold">Restrict and unsubscribe: </span>
          To object to processing for direct
          marketing/unsubscribe from our email database or opt-out of
          communications (including marketing communications), please contact us
          using the details below or opt-out using the opt-out facilities
          provided in the communication.
        </TermsAndPrivacyText>
        <TermsAndPrivacyText hasMarginBottom>
          <span className="bold">Access: </span>
          You may request access to the personal information that we
          hold about you. An administrative fee may be payable for the provision
          of such information. Please note, in some situations, we may be
          legally permitted to withhold access to your personal information. If
          we cannot provide access to your information, we will advise you as
          soon as reasonably possible and provide you with the reasons for our
          refusal and any mechanism available to complain about the refusal. If
          we ca n provide access to your information in another form that still
          meets your needs, then we will take reasonable steps to give you such
          access.
        </TermsAndPrivacyText>
        <TermsAndPrivacyText hasMarginBottom>
          <span className="bold">Correction: </span>
          If you believe that any information we hold about you is
          inaccurate, out of date, incomplete, irrelevant or misleading, please
          contact us using the details below. We will take reasonable steps to
          promptly correct any information found to be inaccurate, out of date,
          incomplete, irrelevant or misleading. Please note, in some situations,
          we may be legally permitted to not correct your personal information.
          If we cannot correct your information, we will advise you as soon as
          reasonably possible and provide you with the reasons for our refusal
          and any mechanism available to complain about the refusal.
        </TermsAndPrivacyText>
        <TermsAndPrivacyText hasMarginBottom>
          <span className="bold">Complaints: </span>
          If you wish to make a complaint, please contact us using
          the details below and provide us with full details of the complaint.
          We will promptly investigate your complaint and respond to you, in
          writing, setting out the outcome of our investigation and the steps we
          will take in response to your complaint. If you are not satisfied with
          our response, you also have the right to contact the Office of the
          Australian Information Commissioner.
        </TermsAndPrivacyText>
      </Trans>
    </>
  );
};
