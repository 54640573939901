import React, { useContext, useRef } from 'react';
import { FileForMasonry } from '../../../../../../../../shared/components/FileForMasonry/FileForMasonry';
import { useTypedSelectorClient } from '../../../../../../../../shared/hooks/useTypedSelector';
import { GalleryFile, GalleryIdea } from '../../../../../../../../shared/types';
import { isIdea } from '../../../../../../../../shared/utils';
import { ClientOnboardingContext } from '../../../../../../../Client/context/CreatorOnboardingContext';
import { FileAndElementsWrapper } from '../../../../styled';
import { ElementsOverFile } from './ElementsOverFile';
import { FileMasonryContainer, ImageOrVideoCardWrapper } from './styled';

type ImageCardProps = {
  fileOrIdea: GalleryFile | GalleryIdea;
  sectionId: string;
  fileIndex: number;
};

export const ImageOrVideoCard: React.FC<ImageCardProps> = ({
  fileOrIdea,
  sectionId,
  fileIndex,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { isStudy: isStudyClient } = useContext(ClientOnboardingContext);
  const stepIndex = useTypedSelectorClient(
    (state) => state?.onboarding?.stepIndex,
  );
  // TODO Fix Idea cover obj. On the backend it is string (just url)
  let currentFile = null;
  if (isIdea(fileOrIdea)) {
    currentFile = {
      name: '',
      type: 'image',
      url: (fileOrIdea as GalleryIdea).cover as unknown as string,
      id: (fileOrIdea as GalleryIdea).cover as unknown as string,
    };
  } else {
    currentFile = fileOrIdea as GalleryFile;
  }
  const areButtonsVisible = fileIndex === 0 && isStudyClient && stepIndex > 5 && stepIndex < 13;
  const {
    fullWidth,
    allowCommenting,
    instantlyDownloadable,
    name,
    id,
    isLiked,
    isInCart,
    comments,
  } = (fileOrIdea as GalleryFile) || (fileOrIdea as GalleryIdea);

  const fileUrl = currentFile.watermark
    ? currentFile?.urlWatermarked || currentFile?.url
    : currentFile?.url;

  return (
    <ImageOrVideoCardWrapper
      fullWidth={!!fullWidth}
    >
      <FileAndElementsWrapper
        fullWidth={!!fullWidth}
        areButtonsVisible={areButtonsVisible}
      >
        <ElementsOverFile
          fileUrl={fileUrl || ''}
          comments={comments || []}
          file={fileOrIdea}
          sectionId={sectionId}
          id={id}
          fileType={'cover' in fileOrIdea ? 'ideas' : 'files'}
          fullWidth={!!fullWidth}
          instantlyDownloadable={!!instantlyDownloadable}
          allowCommenting={!!allowCommenting}
          isFileLiked={!!isLiked}
          isFileInCart={!!isInCart}
          videoRef={videoRef}
        >
          <FileMasonryContainer
            id="client-click-item-step"
          >
            {currentFile && (
              <FileForMasonry
                videoRef={videoRef}
                currentFile={currentFile}
                currentFileUrl={fileUrl || ''}
                fileId={id}
                fileName={name}
              />
            )}
          </FileMasonryContainer>
        </ElementsOverFile>
      </FileAndElementsWrapper>
    </ImageOrVideoCardWrapper>
  );
};
