import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { creator } from '../../../../../api/creator/creator';
import { ErrorType } from '../../../../../shared/types';

export const fetchCheckPassword = createAsyncThunk(
  'creatorCheckPassword/fetchCheckPassword',
  async (password: string, thunkApi) => {
    try {
      const response = await creator.checkPassword(password);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  },
);

type initialState = {
  loading: boolean;
  error: ErrorType;
  isSuccess: boolean;
};

export const initialState: initialState = {
  loading: false,
  error: null,
  isSuccess: false,
};

const creatorCheckPasswordSlice = createSlice({
  name: 'creatorCheckPassword',
  initialState,
  reducers: {
    unsetSuccess(state) {
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCheckPassword.fulfilled, (state) => {
      state.error = null;
      state.isSuccess = true;
      state.loading = false;
    });
    builder.addCase(
      fetchCheckPassword.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
        state.isSuccess = false;
      },
    );
    builder.addCase(fetchCheckPassword.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.isSuccess = false;
    });
  },
});

export const { unsetSuccess } = creatorCheckPasswordSlice.actions;

export default creatorCheckPasswordSlice.reducer;
