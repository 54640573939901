import styled from '@emotion/styled';

export const MainLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 300px;
    height: auto;
  }
`;
