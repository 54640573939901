import { useEffect, useRef, useState } from 'react';

type ReturnObj = {
  msCounter: number;
  startNewTimer: () => void;
};

export const useTimer = (): ReturnObj => {
  const resendInMs = Number(window.localStorage.getItem('resendInMs'));
  const nowMs = new Date().getTime();

  const id = useRef<any>(null);
  const [msCounter, setMsCounter] = useState(
    Math.ceil((resendInMs - nowMs) / 1000),
  );

  const clear = () => {
    window.clearInterval(id?.current);
  };
  const setTimeout = () => {
    id.current = window.setInterval(() => {
      setMsCounter((prev) => prev - 1);
    }, 1000);
  };
  const startNewTimer = () => {
    const nowSeconds = new Date().getTime();
    const resendTime = Number(window.localStorage.getItem('resendInMs'));

    if (msCounter <= 0) {
      setMsCounter(Math.ceil((resendTime - nowSeconds) / 1000));
      setTimeout();
    }
  };

  useEffect(() => {
    setTimeout();
    return () => clear();
  }, []);
  useEffect(() => {
    if (msCounter < 1) {
      clear();
    }
  }, [msCounter]);

  return {
    msCounter,
    startNewTimer,
  };
};
