import styled from '@emotion/styled';
import * as variables from '../../../constants/styles';

export const NotificationWrapper = styled.div`
  background: ${variables.colorPink};
  display: flex;
  flex-direction: row;
  justify-content: space-round;
  align-items: center;
  padding: 16px 21px;
  border-radius: 4px;

  & svg {
    color: ${variables.colorGreyLight} !important;
    margin-right: 10px;
  }
`;
