import styled from '@emotion/styled';
import * as variables from '../../../constants/styles';
import waves from '../../../assets/image/beta-waves.png';
import { theme } from '../../../shared/components/GlobalContainer/styled';

export const ProtectedPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-height: 100%;
  min-height: 100vh;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 100%;
    margin-left: -32px;
    width: calc(100% + 64px);
    padding: 67px 0px 0px;
    box-sizing: border-box;
    height: calc(100vh - 117px);
  }
`;

export const ProtectedPageHeaderWrapper = styled.div`
  width: 100%;
  padding: 0px 42px;
  box-sizing: border-box;
`;

export const ProtectedPageFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  height: 100%;
  margin-bottom: 45px;
`;

export const ProtectedPageFooterLinkWrp = styled.div`
  height: fit-content;  

  & a {
    text-decoration: underline;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    & span {
      font-size: 14px;
      line-height: 150%;  
    }
  }
`;

export const ProtectedPageFooterTextsWrp = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & #slash {
    margin: 0px 8px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    align-items: center;
    margin-top: 80px;
  }
`;

export const ProtectedPageFooterRights = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const ProtectedPageModalWindow = styled.div`
  box-sizing: border-box;
  width: 670px;
  padding: 60px 50px 30px;
  background: ${variables.colorGreyLight};
  position: relative;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    width: 100%;
  }
`;

export const ProtectedPageCloseBtn = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

export const ProtectedPageCard = styled.div`
  border-radius: 10px;
  box-shadow: 10px 10px 17px 0px #E7E7E7, -2px -2px 3px 0px #FFF;
  background: ${variables.colorGreyLight};
  display: flex;
  box-sizing: border-box;
  height: fit-content;
`;

export const BetaCheckoutWindowContainer = styled.div`
  display: flex;
  box-sizing: border-box;
`;

export const BetaDescriptionConteiner = styled.div`
  border-radius: 0px 10px 10px 0px;
  background: linear-gradient(292deg, #E6934F -27.61%, #E186B0 55.12%, #78C0EA 137.79%);
  width: 46%;
  position: relative;
  margin-right: 4px;
  padding: 60px 30px;
  box-sizing: border-box;
  height: 100%;
`;

export const BetaFormConteiner = styled.div`
  border-radius: 0px 10px 10px 0px;
  padding: 60px 48px 140px;
  box-sizing: border-box;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BetaCheckoutFormConteiner = styled.div`
  border-radius: 0px 10px 10px 0px;
  padding: 60px 48px 60px;
  box-sizing: border-box;
  width: 54%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: ${variables.colorGreyLight};
  height: 100%;

  & #button-text {
    color: ${variables.colorGreyLight};
  }
`;

export const BetaFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;

  & > div {
    margin-top: 36px;
  } 
`;

export const BetaFormEmailWasSent = styled.div`
  position: absolute;
  left: -10px;
  height: 110%;
  width: calc(100% + 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  z-index: 10;
  background: ${variables.colorGreyLight};
`;

export const BetaFormTickText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & svg {
    margin-right: 8px;
    width: 22px;
    height: 22px;
  }
`;

export const BetaFormApplyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  width: fit-content;
`;

export const BetaFormBtn = styled.button`
  padding: 0;
  margin: 0px 5px;
`;

export const ProtectedPageImage = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url(${waves});
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 100%;
`;

export const ProtectedPageContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ProtectedPageDescriptionTitle = styled.div`
  margin-bottom: 12px;
  color: ${variables.colorGreyLight};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ProtectedPageDescriptionText = styled.div`
  color: ${variables.colorGreyLight};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const BetaCheckoutWindowWrp = styled(ProtectedPageContentWrapper)`
  align-items: flex-start;
`;

export const PaymentSuccessText = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const SubscriptionListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 10px;

  & > svg {
    margin-right: 10px;
    margin-top: 6px;
  }
`;

export const SubscriptionTickWrapper = styled.div`
  display: flex;

  & > svg {
    margin-right: 10px;
    margin-top: 6px;
    min-width: 11px;
    min-height: 9px;
    width: 13px;
    height: 11px;
    color: ${variables.colorGreyLight};
  }
`;

export const ListSubItem = styled.li`
  font-size: 14px;
  line-height: 150%;
  font-weight: normal;
  color: ${variables.colorGreyLight};
`;

export const BetaCheckoutHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const BetaCheckoutLogoWrp = styled.div`
  position: relative;
  display: flex;
  width: fit-content;
  margin-bottom: 16px;

  & svg {
    width: 165px;
    height: 26.57px;
  }
`;

export const BetaCheckoutBetaLogo = styled.div`
  background: ${variables.textBlack};
  right: -20px;
  top: -15px;
  width: 40px;
  height: 15px;
  font-size: 11px;
  content: 'beta';
  color: ${variables.colorGreyLight};
  position: absolute;
  border-radius: 137px;
  box-sizing: border-box;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

export const BetaSummatyWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;

export const BetaSubtotalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    padding-right: 0px;
    margin-top: 28px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-top: 40px;
  }
`;

export const BetaCheckboxesWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 16px;

  & #text-label {
    margin: 0px;
  }
`;

export const BetaAgreementLink = styled.a`
  text-decoration: underline;
  font-weight: 600;
  cursor: point;
`;

export const BetaSpotsLeftWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
