import React from 'react';
import { SidebarWrapper } from './styled';
import { SidebarMenu } from './SidebarMenu';
import { UserCard } from './UserCard';
import { HeaderWithLogo } from '../../../../../shared/components/HeaderWithLogo/HeaderWithLogo';

export const Sidebar: React.FC = () => (
  <SidebarWrapper>
    <HeaderWithLogo isCreator />
    <UserCard />
    <SidebarMenu />
  </SidebarWrapper>
);
