import React, { ChangeEventHandler } from 'react';
import {
  BaseInputWrapper, ErrorMessage,
} from '../../styled';
import { InputAndErrorWrapper, InputAndLabelWrapper } from '../styled';
import { TextLabel } from '../TextLabel';
import { MaxLengthText } from './MaxLengthText';
import { StyledTextarea } from './styled';

type CommonInputProps = {
  error: string | any;
  value: string | any;
  name: string;
  handleChange: ChangeEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  touched?: boolean | any;
  label?: string;
  isFullHeight?: boolean;
  maxTextLength?: number;
  disabled?: boolean;
  isFullWidth?: boolean;
};

export const CommonTextarea: React.FC<CommonInputProps> = ({
  error,
  value,
  name,
  handleChange,
  placeholder,
  touched,
  label,
  isFullHeight,
  maxTextLength,
  disabled,
  isFullWidth,
}) => (
  <InputAndLabelWrapper direction="column" isFullHeight isFullWidth={isFullWidth}>
    <TextLabel name={name} label={label} />
    <InputAndErrorWrapper isFullHeight={isFullHeight}>
      <BaseInputWrapper isSuccess={!error}>
        <StyledTextarea
          disabled={disabled}
          id={name}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={handleChange}
          rows={3}
        />
        <MaxLengthText isSuccess={!error} maxLength={maxTextLength} currentLength={value?.length} />
      </BaseInputWrapper>
      <ErrorMessage>{error || null}</ErrorMessage>
    </InputAndErrorWrapper>
  </InputAndLabelWrapper>
);
