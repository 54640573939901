import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ErrorType } from '../../../../../shared/types';
import { fetchGetGallerySales } from './salesThunk';
import { SalesGet } from '../../../types/sales';

type InitialState = {
  loading: boolean;
  error: ErrorType;
  sales: SalesGet;
};

export const initialState: InitialState = {
  sales: { amount: 0, amountPerWeek: 0 },
  loading: true,
  error: null,
};

const gallerySalesSlice = createSlice({
  name: 'gallerySales',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetGallerySales.fulfilled,
      (state, action: PayloadAction<SalesGet>) => {
        state.error = null;
        state.loading = false;
        state.sales = action.payload;
      },
    );
    builder.addCase(
      fetchGetGallerySales.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchGetGallerySales.pending, (state) => {
      state.loading = true;
    });
  },
});

export default gallerySalesSlice.reducer;
