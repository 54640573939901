import { createAsyncThunk } from '@reduxjs/toolkit';
import { CustomFile } from '../../../../../shared/types';

export const fetchTryAgainFiles = createAsyncThunk(
  'gallery/fetchTryAgainFiles',
  async (files: CustomFile[]) => {
    try {
      return files.map((item) => item.id);
    } catch (err: any) {
      return err.response.data;
    }
  },
);
