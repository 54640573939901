import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import * as urls from '../../../../../../../../constants/urls';
import {
  PaymentSuccessfulHeader,
  PaymentSuccessfulTitle,
  PaymentSuccessfulWrapper,
  TextWithCheckmark,
} from '../../../../../../../../shared/components/PaymentPages/styled';
import {
  MainLogoNoWhiteSpace,
  TickIcon,
} from '../../../../../../../../shared/icons';
import { TextBlackTwenty } from '../../../../../../../../shared/styles';
import { ContentWrapper } from '../../../../../../../Client/containers/styled';
import { LandingPaymentSuccessButtons } from '../../../../../../../Landing/containers/LandingPricing/LandingPlanPayment/styled';
import { PaymentSuccessfulButton } from '../../../../../../../Client/containers/Cart/CartSummary/ClientPayment/styled';
import { PinkLoader } from '../../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { ErrorMessage } from '../../../../../../../../shared/components/styled';
import { ConfirmedEmailWrapper } from '../styled';

type ReturnObj = {
  setEmailPrimary: () => void;
  isLoading: boolean;
  error: string;
  isSuccess: boolean;
}

export const useSetEmailPrimary = (): ReturnObj => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const { user } = useUser();

  const setEmailPrimary = async () => {
    if (user) {
      setError('');
      setIsSuccess(false);
      setIsLoading(true);
      try {
        const newEmail = user?.emailAddresses.find(
          (item) => item.emailAddress !== user?.primaryEmailAddress?.id,
        );
        await user?.update({ primaryEmailAddressId: newEmail?.id });
        user?.emailAddresses?.forEach(async (item) => {
          if (item.emailAddress !== newEmail?.emailAddress) {
            await item.destroy();
          }
        });
        setIsSuccess(true);
      } catch (e: any) {
        if (e?.errors?.length) {
          setError(e?.errors[0]?.message);
        } else {
          setError('Something went wrong');
        }
      }
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isSuccess,
    error,
    setEmailPrimary,
  };
};

export const ConfirmedEmail: React.FC = () => {
  const { t } = useTranslation(['landing', 'signIn']);
  const navigate = useNavigate();
  const { isLoaded } = useUser();
  const {
    isLoading, error, isSuccess, setEmailPrimary,
  } = useSetEmailPrimary();

  useEffect(() => {
    if (isLoaded) {
      setEmailPrimary();
    }
  }, [isLoaded]);

  return (
    <ConfirmedEmailWrapper>
      <PaymentSuccessfulHeader>
        <NavLink to={urls.creatorRootUrl}>
          <MainLogoNoWhiteSpace />
        </NavLink>
      </PaymentSuccessfulHeader>
      <ContentWrapper style={{ marginBottom: 0 }}>
        <PaymentSuccessfulWrapper
          style={{ width: '100%', height: 'calc(100vh - 210px)' }}
          hasBackground
        >
          <PaymentSuccessfulTitle>Email confirmation</PaymentSuccessfulTitle>
          {isLoading && <PinkLoader />}
          {error && (
            <ErrorMessage style={{ fontSize: '18px' }}>{error}</ErrorMessage>
          )}
          {isSuccess && (
            <>
              <TextWithCheckmark>
                <TickIcon isGradient={false} />
                <TextBlackTwenty>Your email was confirmed</TextBlackTwenty>
              </TextWithCheckmark>
              <LandingPaymentSuccessButtons>
                <PaymentSuccessfulButton
                  type="button"
                  onClick={() => navigate(urls.creatorRootUrl)}
                >
                  Back to account
                </PaymentSuccessfulButton>
              </LandingPaymentSuccessButtons>
            </>
          )}
        </PaymentSuccessfulWrapper>
      </ContentWrapper>
    </ConfirmedEmailWrapper>
  );
};
