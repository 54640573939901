import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';
import { NewGalleryCreator } from './NewGalleryCreator';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../shared/hooks/useTypedSelector';
import { fetchGetCreatorGallery } from '../../../../redux/gallery/galleryCreatorThunk';
import { PinkLoader } from '../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { CreatorOnboardingContext } from '../../../../context/CreatorOnboardingContext';

export const NewGalleryCreatorContainer: React.FC = () => {
  const { id: galleryId } = useParams<{ id: string }>();
  const dispatch = useCreatorAppDispatch();
  const { isStudy } = useContext(CreatorOnboardingContext);
  const { getToken } = useAuth();

  const gallery = useTypedSelectorCreator((state) => state.gallery.gallery);
  const isLoading = useTypedSelectorCreator((state) => state.gallery.loadingGet);
  const error = useTypedSelectorCreator((state) => state.gallery.error);

  useEffect(() => {
    if (!isStudy && galleryId) {
      dispatch(fetchGetCreatorGallery({ id: galleryId, getToken }));
    }
  }, [dispatch, galleryId, isStudy]);

  if (isLoading) {
    return <PinkLoader />;
  }

  if (error) {
    return <div>The gallery wasn&apos;t found</div>;
  }

  return (
    <NewGalleryCreator galleryId={galleryId || ''} currentGallery={gallery} />
  );
};
