import { Container } from '@mui/material';
import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { useCheckWindowSize } from '../../hooks/useCheckWindowSize';
import { theme } from './styled';

const CustomContainer = styled(Container)`
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    padding: 0px 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const ContainerWrapper: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const { screenSize } = useCheckWindowSize();

  if (screenSize === 'mobile') {
    return children;
  }

  return (
    <CustomContainer
      maxWidth="xl"
      style={{ minHeight: '100vh' }}
    >
      {children}
    </CustomContainer>
  );
};
