import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TermsAndPrivacyListTitle, TermsAndPrivacyText } from '../styled';

export const SocialMediaPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <>
      <TermsAndPrivacyListTitle>
        {t('privacy.infoToSocMedia')}
      </TermsAndPrivacyListTitle>
      <Trans t={t} i18nKey="privacy.infoToSocMediaPoints">
        <TermsAndPrivacyText hasMarginBottom>
          Our website platform allows you (a Customer User or Content Editor
          User) to connect your account with us to a social network account,
          such as lnstagram, and to post content created using our Services via
          your social media account.
        </TermsAndPrivacyText>
        <TermsAndPrivacyText hasMarginBottom>
          If you use this option, we may collect your personal information from
          the social network. We will do this in accordance with the privacy
          settings you have chosen on that social network. The personal
          information that we may receive includes your name, ID, user name,
          handle, profile picture, gender, age, language, list of friends or
          follows and any other personal information you choose to share.
        </TermsAndPrivacyText>
        <TermsAndPrivacyText hasMarginBottom>
          We use the personal information we receive from the social network to
          market our Services.
        </TermsAndPrivacyText>
        <TermsAndPrivacyText hasMarginBottom>
          If you agree, we may also use your personal information to give you
          updates on the social network which might interest you. We will not
          post to your social network without your permission.
        </TermsAndPrivacyText>
        <TermsAndPrivacyText hasMarginBottom>
          Where we have accessed your personal information through your Facebook
          account, you have the right to request the deletion of personal
          information that we have been provided by Facebook. To submit a
          request for the deletion of personal information we acquired from
          Facebook, please send us an email at the address at the end of this
          Privacy Policy and specify in your request which personal information
          you would like deleted. If we deny your request for the deletion of
          personal information, we will explain why.
        </TermsAndPrivacyText>
      </Trans>
    </>
  );
};
