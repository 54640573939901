import styled from '@emotion/styled';

export const NoFileWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: #dddddd;
  border-radius: inherit;
`;
