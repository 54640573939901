import React from 'react';
import { videoId } from '../../../../../../constants';
import { VideoFromYouTubeWrapper } from './styled';
import * as urls from '../../../../../../constants/urls';

export const VideoFromYouTube: React.FC = () => (
  <VideoFromYouTubeWrapper>
    <iframe
      height="480"
      src={`${urls.youTubeEmbed}${videoId}`}
      frameBorder="0"
      allow="accelerometer; autoplay;
      clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </VideoFromYouTubeWrapper>
);
