/* eslint-disable implicit-arrow-linebreak */
import countries from 'i18n-iso-countries/langs/en.json';
import { CurrencyTypes, SelectorMenuItem } from '../../shared/types';
import { timezonesArr } from './timezones';

export const dateFormats: SelectorMenuItem<string>[] = [
  { id: 'MM/dd/yyyy', value: 'mm/dd/yyyy' },
  { id: 'yyyy/dd/MM', value: 'yyyy/dd/mm' },
  { id: 'yyyy/MM/dd', value: 'yyyy/mm/dd' },
  { id: 'dd/MM/yyyy', value: 'dd/mm/yyyy' },
];

export const getTimezonesList = (): SelectorMenuItem<string>[] => timezonesArr.map((item) => ({
  id: item.id,
  value: item.value,
}));

export const paymentCurrencyData: SelectorMenuItem<string>[] = [
  // {
  //   id: 'Australian / New Zeland dollar',
  //   value: 'Australian / New Zeland dollar',
  // },
  { id: CurrencyTypes.USD, value: 'US Dollar ($USD)' },
  // { id: 'Euro', value: 'Euro' },
];

export const storeCurrencyData: SelectorMenuItem<string>[] = [
  { id: CurrencyTypes.USD, value: 'US Dollar ($USD)' },
  // { id: 'EURO', value: 'EURO' },
];

export const collectedByData: SelectorMenuItem<string>[] = [
  { id: 'Delivrable', value: 'Delivrable' },
  // { id: 'Me', value: 'Me (Photographer)' },
];

export const createCountriesList = (): SelectorMenuItem<string>[] =>
  Object.entries(countries.countries).map((item) => {
    const id = item[0];
    const value = typeof item[1] === 'string' ? item[1] : item[1][0];
    return ({
      id, value,
    });
  });
