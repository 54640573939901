export const colorBleak = '#B4BBC0';

export const textBlack = '#051B2C';
export const textGrey = '#828D95';

export const colorGreyLight = '#F8F8F8';

export const colorBlue = '#54C2EF';
export const colorPink = '#F180B2';
export const colorOrange = '#F78F2A';
export const colorBlack = '#051B2C';
export const colorGradient = 'linear-gradient(270deg, #E6934F 0%, #E186B0 49.82%, #78C0EA 100%)';
export const colorGradientDiagonal = 'linear-gradient(297.92deg, #E6934F -25.37%, #E186B0 52.4%, #78C0EA 130.11%)';
export const colorGradientButton = 'linear-gradient(274.36deg, #E6934F -13.34%, #E186B0 48.12%, #78C0EA 114.29%)';
export const colorError = '#ED0131';
export const colorPinkLight = '#F7E4ED';
export const colorBlueLight = '#D1ECF6';
export const colorBtnSecondary = '#444547';
