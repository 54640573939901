import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GalleryComment, ErrorType } from '../../../../shared/types';
import { fetchAddComment, fetchDeleteComment } from './commentsThunk';

type InitialState = {
  loading: boolean;
  error: ErrorType;
  comments: GalleryComment[];
};

export const initialState: InitialState = {
  comments: [],
  loading: true,
  error: null,
};

const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAddComment.fulfilled, (state, action) => {
      state.comments = [...state.comments, action.payload];
      state.error = null;
      state.loading = false;
    });
    builder.addCase(
      fetchAddComment.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload.message;
        state.loading = false;
      },
    );
    builder.addCase(fetchAddComment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDeleteComment.fulfilled, (state, action) => {
      state.comments = [...state.comments.filter((item) => item.id !== action.payload)];
      state.error = null;
      state.loading = false;
    });
    builder.addCase(
      fetchDeleteComment.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload.message;
        state.loading = false;
      },
    );
    builder.addCase(fetchDeleteComment.pending, (state) => {
      state.loading = true;
    });
  },
});

export default commentsSlice.reducer;
