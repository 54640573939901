import { Occupation, SignUpInProcess } from '../../shared/types';

export const countryValue = 'Country';
export const doValue = 'What best describes your role?';

export const signUpInitialValues: SignUpInProcess = {
  firstName: '',
  lastName: '',
  businessName: '',
  password: '',
  confirmPassword: '',
  country: '',
  dob: '',
  occupation: '' as Occupation,
  promoCode: '',
  subscribed: false,
  isAgreed: false,
  marketingSubscribed: false,
};
