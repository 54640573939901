import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const CommunicationPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={7}
      listTitle={t('terms.communication')}
      pointsNameForTrans="communicationPoints"
    >
      <LandingNumberedLi>
        We may contact you via the Platform using in-Account notifications,
        or via off-Platform communication channels, such as text message or
        email.
      </LandingNumberedLi>
      <LandingNumberedLi>
        Clients and Member can communicate privately using our private
        messaging service or offline using the listed contact details.
      </LandingNumberedLi>
      <LandingNumberedLi>
        Clients and Members must not use the contact details to organise the
        provision of the goods off the Platform, or otherwise to attempt to
        circumvent the payment of Deferred Payment Service Fees to us.
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
