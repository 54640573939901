import React, { useMemo, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { today } from '../../../constants';
import {
  ImageSizes,
  PreviewImageSizes,
} from '../../../constants/galleryPreviewsAndCovers';
import { getFormattedDateOrDateName } from '../../../shared/utils/dateOperations';
import { getFileInContainer } from '../../../shared/utils/createGallery';
import {
  GalleryCover,
  ImageSize,
  NumberOfGallery,
} from '../../../shared/types';
import { GalleryFileCropper } from '../components/GalleryFileCropper';
import { useTypedSelectorGallery } from '../../../shared/hooks/useTypedSelector';

type ReturnObj = {
  file: ReactNode | null;
  name: ReactNode;
  date: ReactNode;
  userName: ReactNode;
  message: ReactNode;
  extraFile: ReactNode | null;
};

type GalleryCoverTypeWithSize = GalleryCover & {
  extraFile?: ReactNode;
  size?: ImageSize;
  galleryNumber?: NumberOfGallery;
};

const getFileInContainerOrCropper = (
  data: GalleryCoverTypeWithSize,
): ReactNode => (data.withCropper ? (
  <GalleryFileCropper
    height={
        data.isPreview
          ? PreviewImageSizes[data.galleryNumber || 'first'][data.size || 'desktop'].height
          : ImageSizes[data.galleryNumber || 'first'][data.size || 'desktop'].height
      }
    width={
        data.isPreview
          ? PreviewImageSizes[data.galleryNumber || 'first'][data.size || 'desktop'].width
          : ImageSizes[data.galleryNumber || 'first'][data.size || 'desktop'].width
      }
    size={data.size || 'desktop'}
  />
) : (
  getFileInContainer({ file: data?.file })
));

export const useGalleryContent = (
  data: GalleryCoverTypeWithSize,
): ReturnObj => {
  const { t } = useTranslation('createNewGallery');
  const { userName } = data;
  const gallery = useTypedSelectorGallery((state) => state.gallery.gallery);

  const dateFormat = gallery?.dateFormat || 'mm/dd/yyyy';

  const formattedDate = data.date
    ? getFormattedDateOrDateName(`${data.date}`, dateFormat)
    : getFormattedDateOrDateName(`${today}`, dateFormat);

  const fileContent = useMemo(
    () => (data.file ? getFileInContainerOrCropper(data) : null),
    [data],
  );

  const extraFileContent = useMemo(
    () => (data.file ? getFileInContainerOrCropper(data) : null),
    [data],
  );
  return {
    extraFile: extraFileContent,
    file: fileContent,
    name: <h3>{data.name || 'Gallery name'}</h3>,
    date: <span id="client-date-step">{formattedDate}</span>,
    userName: (
      <p>
        <Trans t={t} i18nKey="by">
          by
          {' '}
          {{ userName }}
        </Trans>
      </p>
    ),
    message: <p id={data.message ? 'client-description-step' : ''}>{data.message || ''}</p>,
  };
};
