import styled from '@emotion/styled';
import { BaseColors, OptimisedStyles } from '../../../../../../../shared/types';
import { getBaseColor } from '../../../../../../../shared/utils/style/getBaseColor';
import { theme } from '../../../../../../../shared/components/GlobalContainer/styled';

export const IconsTopRightWrapper = styled.div<{
  color: BaseColors;
  wrapperStyle?: OptimisedStyles;
  iconStyle?: OptimisedStyles;
}>`
  position: absolute;
  right: 12px;
  top: 10px;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  z-index: 3;

  & > * {
    padding: 0;
    margin-left: 5px;
  }

  & > svg {
    color: ${(props) => getBaseColor(props.color)};
  }

  & svg {
    width: 30px;
    height: 30px;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    ${({ wrapperStyle }) => wrapperStyle?.tablet}

    & > svg {
      ${({ iconStyle }) => iconStyle?.tablet}
      width: 20px;
      height: 20px;
    }

    & svg {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    ${({ wrapperStyle }) => wrapperStyle?.mobile}

    & > svg {
      ${({ iconStyle }) => iconStyle?.mobile}
      width: 30px;
      height: 30px;
    }
    & svg {
      ${({ iconStyle }) => iconStyle?.mobile}
      width: 30px;
      height: 30px;
    }
  }
`;

export const IconsBottomRightWrapper = styled(IconsTopRightWrapper)`
  top: calc(100% - 34px);
`;
