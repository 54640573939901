import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import { useFormikContext } from 'formik';
import { CommonInput } from '../../../../../../../shared/components/FormElements/CommonInput/CommonInput';
import { GalleryData } from '../../../../../../../shared/types';
import { FormGalleryWrapper, GridWrapper } from '../styled';
import { CustomDatePicker } from '../../../../../../../shared/components/FormElements/CustomDatePicker/CustomDatePicker';
import { CommonTextarea } from '../../../../../../../shared/components/FormElements/CommonTextarea/CommonTextarea';
import { PreviewSwiper } from './PreviewGalleryCovers/PreviewSwiper/PreviewSwiper';
import { getFullName } from '../../../../../../../shared/utils/getFullName';
import { CommonCopyInput } from '../../../../../../../shared/components/CommonCopyInput/CommonCopyInput';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';
import { dilivrableGalleryURL } from '../../../../../../../constants/urls';
import { FirstStepCoverUpload } from './FirstStepCoverUpload';
import { resetUploadingFiles, resetErrorFiles, unsetError } from '../../../../../redux/gallery/uploadFiles/uploadFilesSliceV2';

export const NewGalleryCreatorFirstStep: React.FC = () => {
  const { t } = useTranslation('createNewGallery');
  const dispatch = useCreatorAppDispatch();
  const {
    values, errors, handleChange, handleBlur, touched, setFieldValue,
  } = useFormikContext<GalleryData>();

  const user = useTypedSelectorCreator((state) => state.creator?.currentUser);
  const returnUseEffect = () => {
    setFieldValue(
      'galleryUrl',
      `${user?.businessNameUrl}.${dilivrableGalleryURL}${values.name.toLowerCase().replace(/\W/g, '')}`,
    );
  };

  useEffect(
    () => returnUseEffect(),
    [values.name],
  );

  useEffect(() => () => {
    dispatch(resetUploadingFiles());
    dispatch(resetErrorFiles());
    dispatch(unsetError());
  }, []);

  return (
    <FormGalleryWrapper>
      <GridWrapper container direction="row" justifyContent="space-between">
        <Grid container item xl={4} lg={4} md={5}>
          <div id="galleryNameDateFirstStep" style={{ height: 'fit-content' }}>
            <Grid item sm={12}>
              <CommonInput
                label={t('firstStep.galleryName')}
                error={errors.name}
                value={values.name}
                name="name"
                handleChange={handleChange}
                handleBlur={handleBlur}
                type="text"
                touched={touched.name}
              />
            </Grid>
            <Grid item xl={8} lg={8} md={12}>
              <CustomDatePicker
                limits={false}
                name="date"
                data={values.date}
                setFieldValue={setFieldValue}
                touched={touched.date}
                label={t('firstStep.projectDate')}
                error={errors.date}
                dateFormat={user?.dateFormat}
                style={{
                  marginBottom: '-15px',
                }}
              />
            </Grid>
          </div>
        </Grid>
        <FirstStepCoverUpload />
      </GridWrapper>
      <GridWrapper container justifyContent="space-between">
        <Grid item sm={5}>
          <CommonTextarea
            name="message"
            value={values.message}
            handleChange={handleChange}
            touched={touched.message}
            label={t('firstStep.message')}
            error={errors.message}
            placeholder="Message"
          />
        </Grid>
        <Grid item sm={6}>
          <CommonCopyInput
            label={t('firstStep.galleryUrl')}
            value={values.galleryUrl}
            name="galleryUrl"
            readonly
            isLinkFull
          />
        </Grid>
      </GridWrapper>
      <PreviewSwiper
        file={
            values?.coverFile?.desktop
              ? values?.coverFile?.desktop
              : values?.coverFile?.original
          }
        setFieldValue={setFieldValue}
        value={values.galleryDesign}
        date={values.date}
        name={values.name}
        userName={getFullName(user?.firstName, user?.lastName)}
        message={values.message}
      />
    </FormGalleryWrapper>
  );
};
