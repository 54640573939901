import { Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TabletVersionWrapper } from './styled';

type TabletWrapperProps = { children: ReactElement; }

export const TabletWrapper: React.FC<TabletWrapperProps> = ({
  children,
}) => {
  const { t } = useTranslation('createNewGallery');
  return (
    <Grid container item sm={7}>
      <TabletVersionWrapper>
        <span>{t('tablet')}</span>
        <div>
          {children}
        </div>
      </TabletVersionWrapper>
    </Grid>
  );
};
