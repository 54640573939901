import styled from '@emotion/styled';
import Slider from 'react-slick';
import { ErrorMessage } from '../../../../../../../../../shared/components/styled';
import * as variables from '../../../../../../../../../constants/styles';

export const TitleAndErrorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
`;

export const SwiperArrowsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const CustomSlider = styled(Slider)`
  & > div {
    & > div {
      & > div {
        & > div {
          display: flex;
        }
      }
    }
  }

  & .slick-slide {
    width: 320px !important;
  }

  & .slick-track {
    width: 960px !important;
  }
`;

export const PreviewSwiperContainer = styled.div`
  margin-top: 10px;
`;
export const PreviewSwiperWrapper = styled.div`
  margin-top: 15px;
  position: relative;

  & button {
    padding: 0;
  }
`;
export const ElementWrapper = styled.div`
  margin-right: 30px;
`;
export const FrontWrapper = styled.div`
  position: absolute;
  height: 200px;
  right: 0;
  width: 20%;
  z-index: 100;
  background: -moz-linear-gradient(90deg, rgba(0,0,0,0.006613991477272707) 0%, rgba(248,248,248,0) 0%, rgba(248,248,248,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(0,0,0,0.006613991477272707) 0%, rgba(248,248,248,0) 0%, rgba(248,248,248,1) 100%);
  background: linear-gradient(90deg, rgba(0,0,0,0.006613991477272707) 0%, rgba(248,248,248,0) 0%, rgba(248,248,248,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#f8f8f8",GradientType=1);
`;

export const SwiperHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & div {
    position: relative;
  }
`;

export const ErrorMessageCustom = styled(ErrorMessage)`
  top: 28%;
  left: 110%;
`;

export const ChoosePreviewWrapper = styled.div`
  position: relative;

  & #preview,
  > span {
    font-size: 14px;
    line-height: 150%;
    color: ${variables.textGrey};
    visibility: hidden;
    width: 307px;
    text-align: center;
  }

  &:hover {
    & #preview,
    > span {
      visibility: visible;
    }
  }
`;

export const ChoosePreviewButton = styled.button`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% - 40px);
  z-index: 1;
`;
