import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { GalleryFile, GalleryIdea } from '../../../../shared/types';

export type SwiperFiles = {
  files: (GalleryFile | GalleryIdea)[];
  currentFile: GalleryFile | GalleryIdea | null;
}

type SwiperState = {
  isOpen: boolean;
  currentFile: GalleryFile | GalleryIdea | null;
} & SwiperFiles;

const initialState: SwiperState = { files: [], isOpen: false, currentFile: null };

const swiperSlice = createSlice({
  name: 'swiper',
  initialState,
  reducers: {
    openSwiper(state, action: PayloadAction<SwiperFiles>) {
      state.files = action.payload.files;
      state.isOpen = true;
      state.currentFile = action.payload.currentFile;
    },
    closeSwiper(state) {
      state.isOpen = false;
      state.currentFile = null;
    },
  },
});

export const { openSwiper, closeSwiper } = swiperSlice.actions;
export default swiperSlice.reducer;
