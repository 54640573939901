import styled from '@emotion/styled';
import { theme } from '../../../../shared/components/GlobalContainer/styled';

export const CardWrapper = styled.div`
  margin-top: 40px;
`;

export const CartContentWrapper = styled.div`
  max-width: 580px;
`;

export const CartContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;

  & img {
    max-width: none !important;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex-direction: column-reverse;
  }
`;

export const CartItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    width: 52%;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 100%;
  }
`;
