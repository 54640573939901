import styled from '@emotion/styled';
import * as variables from '../../../../constants/styles';
import { theme } from '../../../../shared/components/GlobalContainer/styled';
import { MediumBlackText } from '../../../../shared/styles';

export const CommentContainerWrapper = styled.div`
  z-index: 100;
  background: transparent;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 140px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50%;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    height: 100%;
    margin-bottom: 0;
    justify-content: flex-end;
    padding-bottom: 35%;
    margin-top: 0;
  }
  
  @media (max-width: ${theme.breakpoints.values.md}px) {
    height: 100%;
    max-height: calc(100vh - 145px);
    justify-content: space-between;
    margin-bottom: 80px;
    margin-top: 65px;
  }
`;

export const CommentAreaWrapper = styled.div`
  width: 70%;
  max-height: 312px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 0px;
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0px;
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0px;
    display: none;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    width: 100%;
    box-sizing: border-box;
    max-height: 393px;
    padding: 0px 20px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 0px 16px;
    max-height: 100%;
  }
`;

export const CloseButtonWrapper = styled.div`
  width: 70%;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 20px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 0px 16px;
  }
`;

export const CloseCommentsButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: ${variables.colorGreyLight};
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 150%;
  padding: 0;

  & > svg {
    color: ${variables.colorGreyLight};
    margin-right: 10px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    color: ${variables.textBlack} !important;

    & > svg {
      color: ${variables.textBlack};
      margin-right: 10px;
    }  
  }
`;

export const CommentItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 28px;
  background: ${variables.colorGreyLight};
  border: 0.5px solid ${variables.colorBleak};
  box-sizing: border-box;
  border-radius: 12.4051px;
  width: 100%;
  margin-bottom: 16px;
  position: relative;
  transition: visibility 0.2s ease-in-out;

  &:hover {
    & > button {
      transition: visibility 0.2s ease-in-out;
      visibility: visible;
    }
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 14px; 
  }
`;

export const CommentItemContainerInput = styled(CommentItemContainer)`
  background: #262626cf;
  border: none;
`;

export const CommentMessageWrapper = styled(MediumBlackText)`
  display: flex;
  display: -moz-box;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  margin: 0;
`;

export const CommentTextareaElement = styled.div`
  width: 100%;
  margin-left: 20px;
  margin-right: 10px;

  & textarea {
    width: 100%;
    min-height: 20px;
    box-sizing: border-box;
    align-items: flex-start;
    resize: none;
    border: none;
    color: ${variables.colorGreyLight};
    font-size: 14px;
    line-height: 150%;
    background: transparent;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${variables.colorBleak};
      border-radius: 100px !important;
    }

    &::placeholder {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: ${variables.colorBleak};
      line-height: 140%;
    }
  }
  
  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-left: 10px;
    margin-right: 14px;
  }  
`;

export const RoundSendButton = styled.button`
  background: ${variables.colorGreyLight};
  width: 31px;
  height: 31px;
  position: relative;
  margin-bottom: -5px;
  border-radius: 50%;

  & > svg {
    position: absolute;
    top: 7px;
    left: 9px;
  }
`;

export const CommentForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  align-items: flex-end;

  & #button-send {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }
`;

export const FileAndCommentsWindowWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const FileContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%; 
  object-fit: cover;
  overflow: hidden;
  display: flex;
  justify-content: center;  
  
  & img {
    position: absolute;
    height: 100%;
    width: auto;
    object-fit: cover;
  }
`;

export const GalleryCommentsArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background: #141E2473;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
`;

export const OpenWindowButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: transparent;
`;

export const CommentDateAndTimeWrapper = styled.div`
  display: flex;
  flex-direction; row;
  align-items: center;
  margin-top: 8px;
`;

export const CommentMessageAndDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-left: 10px;
  } 
`;

export const CommentDeleteButton = styled.button`
  position: absolute;
  bottom: 10px;
  right: 14px;
  padding: 0;
  display: flex;
  visibility: hidden;
  transition: visibility 0.2s ease-in-out;

  &:hover {
    & svg {
      transition: all 0.1s ease-in-out;
      color: ${variables.textBlack};
    }
  }
`;
