import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetToken } from '@clerk/types';
import { commentsClient, commentsCreator } from '../../../../../api/comments/comments';
import { CommentPutRes, CommentDeleteReq } from '../../../../../shared/types/client';

export const fetchGetCreatorComments = createAsyncThunk(
  'commentsCreator/fetchGetCreatorComments',
  async ({ galleryId, getToken }: { galleryId: string, getToken: GetToken }, thunkApi) => {
    try {
      const token = (await getToken()) || '';
      const response = await commentsCreator.getComments(galleryId, token);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchCreatorAddComment = createAsyncThunk(
  'commentsCreator/fetchAddComment',
  async (data: CommentPutRes & { getToken: GetToken }, thunkApi) => {
    try {
      const token = (await data.getToken()) || '';
      const response = await commentsCreator.addComment(
        {
          galleryId: data.galleryId,
          sectionId: data.sectionId,
          fileId: data.fileId,
          messageId: data.messageId,
          text: data.text,
          date: data.date,
        },
        token,
      );
      return response.data;
    } catch (err: any) {
      return thunkApi.rejectWithValue(err.message);
    }
  },
);

export const fetchCreatorDeleteComment = createAsyncThunk(
  'commentsCreator/fetchDeleteComment',
  async (data: CommentDeleteReq) => {
    try {
      const response = await commentsClient.deleteComment(data);

      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  },
);
