import React, { ReactNode, useState } from 'react';
import { clientGallery } from '../../../../api/client/clientGallery';
import { CustomAlert } from '../../../../shared/components/CustomAlert/CustomAlert';

type ReturnObj = {
  handleDownloadAllFreeFiles: (galleryId: string) => void;
  handleDownload: (fileId: string, fileName: string, galleryId: string) => void;
  downloadErrorAlert: ReactNode;
  isLoading: boolean;
  isLoadingFile: string[];
  downloadLink: string;
  handleDownloadZip: () => void;
};

export const useFreeFilesDownload = (): ReturnObj => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFile, setIsLoadingFile] = useState<string[]>([]);
  const [downloadLink, setDownloadLink] = useState('');

  const handleDownloadZip = () => {
    const link = document.createElement('a');
    link.href = downloadLink;
    link.setAttribute('download', 'delivrable.zip');
    document.body.appendChild(link);
    link.click();
  };

  const handleDownload = async (
    fileId: string,
    fileName: string,
    galleryId: string,
  ) => {
    setIsLoadingFile((prev) => [...prev, fileId]);
    clientGallery.downloadFile(galleryId, fileId)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(() => {
        setError('Download process failed. Please, try again later');
      })
      .finally(() => setIsLoadingFile((prev) => prev.filter((item) => item !== fileId)));
  };

  const handleDownloadAllFreeFiles = async (
    galleryId: string,
  ) => {
    setIsLoading(true);
    clientGallery.downloadFreeFile(galleryId)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        setDownloadLink(url);
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', 'delivrable-files.zip');
        // link.download = 'delivrable-files.zip';
        // document.body.appendChild(link);
        // link.click();
        // link.remove();
      })
      .catch(() => {
        setError('Download process failed. Please, try again later');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    handleDownloadZip,
    handleDownloadAllFreeFiles,
    handleDownload,
    downloadLink,
    isLoading,
    isLoadingFile,
    downloadErrorAlert: (
      <CustomAlert
        isOpenWindow={!!error}
        message={error || ''}
        type="error"
        title="Oops..."
        onClose={() => setError('')}
        onClick={() => setError('')}
      />
    ),
  };
};
