import React, { ReactElement } from 'react';
import {
  CustomSelect,
  MenuItemText,
  PlaceholderText,
  SelectValueText,
  SelectWithErrorMessage,
} from './styled';
import { getStyle } from './getStyleForSelect';
import { ErrorMessage } from '../../styled';
import { SetFieldValue } from '../../../types';
import { TextLabel } from '../TextLabel';

type CommonSelectProps = {
  error?: string;
  value: string | string[];
  name: string;
  handleChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => void;
  touched?: boolean;
  label?: string;
  placeholder?: string;
  menuItems: ReactElement[];
  multiple?: boolean;
  setFieldValue?: SetFieldValue;
  disabled?: boolean;
  maxWidth?: number;
  valuesArr?: { id: string; value: string }[];
};

function renderValue(
  options: any,
  value: string | string[],
  placeholder?: string,
  valuesArr?: { id: string; value: string }[],
) {
  if (options.length === 0) {
    return <PlaceholderText><SelectValueText>{placeholder}</SelectValueText></PlaceholderText>;
  }

  if (Array.isArray(options)) {
    const result = options.length > 1 ? `${options[0]} + ${options.length - 1}` : options[0];
    return <SelectValueText>{result}</SelectValueText>;
  }
  return (
    <MenuItemText style={{ maxWidth: 'calc(100% - 20px)' }}>
      {valuesArr?.find((item) => item.id === value)?.value || value || ''}
    </MenuItemText>
  );
}

export const CommonSelect: React.FC<CommonSelectProps> = ({
  error,
  value,
  name,
  handleChange,
  touched,
  label,
  placeholder,
  menuItems,
  multiple,
  setFieldValue,
  disabled,
  maxWidth,
  valuesArr,
}) => {
  const onClick = (newValue: typeof value) => {
    if (setFieldValue) {
      setFieldValue(name, newValue);
    }
  };

  const styles = getStyle(maxWidth);

  return (
    <SelectWithErrorMessage>
      <TextLabel name={name} label={label} />
      <CustomSelect
        id={name}
        name={name}
        value={value}
        onChange={
          !multiple
            ? (e: any, child) => handleChange(e, child)
            : (e) => onClick(e.target.value as string[])
        }
        error={Boolean(error && touched)}
        MenuProps={{ ...styles, disableScrollLock: false }}
        multiple={multiple}
        variant="standard"
        disableUnderline
        placeholder={placeholder}
        displayEmpty
        disabled={disabled}
        renderValue={(options: any) => renderValue(options, value, placeholder, valuesArr)}
        labelId="demo-simple-select-label"
      >
        {menuItems}
      </CustomSelect>
      <ErrorMessage>{error && touched ? error : null}</ErrorMessage>
    </SelectWithErrorMessage>
  );
};
