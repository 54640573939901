import styled from '@emotion/styled';
import * as variables from '../../../../constants/styles';
import { theme } from '../../../../shared/components/GlobalContainer/styled';

export const FileModalWindowWrap = styled.div`
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  padding: 20px 70px;
  max-height: 100%;
  position: relative;
  height: 100vh;

  & div {
    display: flex;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    padding: 70px 0px 20px;
    margin-bottom: 20px;
    
    & img {
      width: 100%;
      object-fit: contain;
    }
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 110px 0px 0px;
    height: auto;
    margin-bottom: 20px;
    display: flex;
  }
`;

export const GallerySwiperContainerWrp = styled.div`
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  padding: 0px 40px 0px;
  max-height: 100%;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    padding: 20px 20px;
    height: auto;
    margin-bottom: 20px;
    display: flex;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 110px 0px 0px;
    height: auto;
    margin-bottom: 20px;
    display: flex;
  }
`;

export const ContentActionsButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & svg {
    width: 28px;
    height: 28px;
  }

  & > * {
    margin-right: 24px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;

export const GallerySwiperCloseButton = styled.button`
  position: absolute;
  top: 0;
  left: 40px;
  top: 20px;
  z-index: 5;

  & svg {
    transition: all 0.1s ease-in-out;
    color: ${variables.textGrey};
  }

  @media (min-width: ${theme.breakpoints.values.lg}px) {
    &:hover {
      & svg {
        transition: all 0.1s ease-in-out;
        color: ${variables.textBlack};
      }
    }
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    padding: 0;
    left: 20px;
    top: 20px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    left: 16px;
    top: 50px;
  }
`;

export const SwiperDocumentCardWrapper = styled.div`
  & > div {
    padding: 40px;
    width: 316px;
    height: 304px;
    border-radius: 16px;
    margin: 0;
  }

  & svg {
    width: 52px;
    height: 60px;
  }

  & * {
    font-size: 16px;
  }
`;

export const SwiperImageCard = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  justify-content: center;

  & img {
    height: 100%;
    width: auto;
    object-fit: contain;
  }
`;

export const GallerySwiperWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    display: block;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 100%;
  }
`;
