import styled from '@emotion/styled';
import { theme } from '../../../../../shared/components/GlobalContainer/styled';
import { SimpleBlackText, TextTotalBlackLarge } from '../../../../../shared/styles';

export const GallerySwiperButtonsAndDescriptionWrapper = styled.div`
  padding: 20px 153px;
  box-sizing: border-box;
  width: 100%;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    padding: 0px;
    margin: 0px 20px 60px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin: 60px 16px 60px;
  }
`;

export const ContentDescriptionContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DescriptionTitle = styled.p`
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  max-width: 200px;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #000;
`;

export const DescriptionText = styled(SimpleBlackText)`
  margin-bottom: 30px;
  display: block;
`;

export const SwiperIdeaFileSlide = styled.div`
  width: 320px;
  height: 320px;
  min-width: 320px;
  min-height: 320px;
  border-radius: 10px;

  & img {
    max-width: none;
  }

  @media (max-width: 352px) {
    width: 250px;
    height: 250px;
    min-width: 250px;
    min-height: 250px;  
  }
`;

export const GallerySwiperIdeaFilesWrp = styled.div`
  margin: 32px 0px 40px;
`;

export const ContentDescriptionPriceDesktop = styled.div`
  display: flex;
  margin-top: 32px;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    display: none;
  }
`;

export const ContentDescriptionPriceTablet = styled.div`
  display: none;
  margin-top: 24px;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    display: flex;
  }
`;

export const TextTotalBlackLargeOptimized = styled(TextTotalBlackLarge)`
  & p {
    padding: 0;
    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    max-width: 220px;
    text-align: left;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    & p {
      max-width: 150px;
    }

    font-size: 16px;
    line-height: 150%;
  }
`;

export const GallerySwiperDescriptionContainer = styled.div`
  max-width: 350px;
  width: 100%;
  min-width: 350px;
  box-sizing: border-box;
  padding: 160px 0px 0px 40px;
  height: 100%;

  @media (max-height: 570px) {
    padding: 40px 0px 0px 40px;
  }
`;

export const GallerySwiperDescName = styled(TextTotalBlackLarge)`
  & p {
    padding: 0;
    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    max-width: 220px;
    text-align: left;
  }
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 500;
`;

export const GallerySwiperDescText = styled(SimpleBlackText)`
  & p {
    padding: 0;
    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    text-align: left;
  }

  margin-bottom: 25px;
  display: block;
`;

export const GallerySwiperDescPrice = styled(SimpleBlackText)`
  & p {
    padding: 0;
    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    max-width: 220px;
    text-align: left;
  }

  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
`;

export const GallerySwiperButtonCart = styled.div`
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
`;
