import React from 'react';
import { RoundAvatar } from '../../../../shared/components/RoundAvatar/RoundAvatar';
import { GalleryComment } from '../../../../shared/types';
import {
  CommentDateAndTimeWrapper,
  CommentDeleteButton,
  CommentItemContainer,
  CommentMessageAndDateWrapper,
  CommentMessageWrapper,
} from './styled';
import {
  getFormattedDateOrDateName,
} from '../../../../shared/utils';
import { LittleGreyText } from '../../../../shared/styles';
import { DeleteIcon } from '../../../../shared/icons';

type CommentItemProps = {
  comment: GalleryComment;
  dateFormat: string;
  deleteComment: (commentId: string) => void;
  currentUserEmail: string;
  timezone?: string;
};

export const CommentItem: React.FC<CommentItemProps> = ({
  comment,
  dateFormat,
  deleteComment,
  currentUserEmail,
  timezone,
}) => {
  const formattedDate = getFormattedDateOrDateName(
    comment.date,
    dateFormat,
    timezone,
    true,
  );
  // const formattedTime = getFormattedTime(comment.date);

  return (
    <CommentItemContainer>
      <RoundAvatar
        userName={comment?.userName || comment?.email || 'Anonym'}
        urlPhoto={comment?.photoUrl || ''}
        size="medium"
      />
      <CommentMessageAndDateWrapper>
        <CommentMessageWrapper>{comment.text}</CommentMessageWrapper>
        <CommentDateAndTimeWrapper>
          {/* <LittleGreyText style={{ marginRight: '8px' }}>
            {formattedTime}
          </LittleGreyText> */}
          <LittleGreyText>{formattedDate}</LittleGreyText>
        </CommentDateAndTimeWrapper>
      </CommentMessageAndDateWrapper>
      {currentUserEmail === comment?.email
      && (
      <CommentDeleteButton type="button" onClick={() => deleteComment(comment.id)}>
        <DeleteIcon />
      </CommentDeleteButton>
      )}
    </CommentItemContainer>
  );
};
