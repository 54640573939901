import React, { useEffect } from 'react';
import { CommentAreaWrapper } from './styled';
import { CommentItem } from './CommentItem';
import { CommentItemWithTextarea } from './CommentItemWithTextarea';
import { GalleryComment } from '../../../../shared/types';
import {
  useTypedSelectorClient,
  useTypedSelectorCreator,
} from '../../../../shared/hooks/useTypedSelector';
import { getFullName } from '../../../../shared/utils';

type CommentAreaProps = {
  comments: GalleryComment[];
  dateFormat: string;
  addComment: (comment: string) => void;
  deleteComment: (commentId: string) => void;
  userProfileUrl?: string
};

export const CommentArea: React.FC<CommentAreaProps> = ({
  comments,
  dateFormat,
  addComment,
  deleteComment,
  userProfileUrl,
}) => {
  const clientEmail = useTypedSelectorClient(
    (state) => state?.signIn?.clientEmail,
  );
  const creator = useTypedSelectorCreator(
    (state) => state?.creator?.currentUser,
  );
  const email = creator?.email || clientEmail;
  const userName = creator ? getFullName(creator?.firstName, creator?.lastName) : email;

  useEffect(() => {
    const modalWindow = document.getElementsByClassName('MuiPaper-root');
    modalWindow[0]?.scrollTo({
      top: modalWindow[0].scrollHeight,
      behavior: 'smooth',
    });
  }, []);

  return (
    <CommentAreaWrapper id="scrolled-wrap">
      {comments?.map((item) => (
        <CommentItem
          currentUserEmail={email}
          deleteComment={deleteComment}
          key={item.id}
          comment={item}
          dateFormat={dateFormat}
          timezone={creator?.timezone}
        />
      ))}
      <CommentItemWithTextarea
        currentUserName={userName}
        addComment={addComment}
        urlUserAvatar={userProfileUrl || ''}
      />
    </CommentAreaWrapper>
  );
};
