import styled from '@emotion/styled';

export const OpenButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
