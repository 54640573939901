import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ErrorType, FavouritesCartResItem,
} from '../../../../shared/types';
import { Favourites } from '../../../../shared/types/client';
import {
  fetchAddToFavourites,
  fetchGetClientFavourites,
  fetchRemoveFromFavourites,
} from './favouritesClientThunk';

type initialState = {
  count: number;
  files: FavouritesCartResItem[];
  loadingGet: boolean;
  loadingDelete: boolean;
  error: ErrorType;
};

export const initialState: initialState = {
  count: 0,
  files: [],
  loadingGet: false,
  loadingDelete: false,
  error: null,
};

const favouritesClientSlice = createSlice({
  name: 'favouritesClientSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetClientFavourites.fulfilled,
      (state, action: PayloadAction<Favourites>) => {
        state.count = action.payload?.count;
        state.error = null;
        state.loadingGet = false;
        state.files = action.payload.files;
      },
    );
    builder.addCase(
      fetchGetClientFavourites.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingGet = false;
      },
    );
    builder.addCase(fetchGetClientFavourites.pending, (state) => {
      state.loadingGet = true;
    });
    builder.addCase(
      fetchAddToFavourites.fulfilled,
      (state, action: PayloadAction<FavouritesCartResItem[]>) => {
        state.count = (action.payload?.length || 0) + state.files.length;
        state.error = null;
        state.loadingGet = false;
        state.files = [...state.files, ...action.payload];
      },
    );
    builder.addCase(
      fetchAddToFavourites.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingGet = false;
      },
    );
    builder.addCase(fetchAddToFavourites.pending, (state) => {
      state.loadingGet = true;
    });
    builder.addCase(
      fetchRemoveFromFavourites.fulfilled,
      (state, action: PayloadAction<string[]>) => {
        state.count -= action.payload?.length || 0;
        state.error = null;
        state.loadingDelete = false;
        state.files = state.files.filter((item) => !action.payload.includes(item.id));
      },
    );
    builder.addCase(
      fetchRemoveFromFavourites.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingDelete = false;
      },
    );
    builder.addCase(fetchRemoveFromFavourites.pending, (state) => {
      state.loadingDelete = true;
    });
  },
});

export default favouritesClientSlice.reducer;
