import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ErrorType,
  NotificationsData,
} from '../../../../shared/types';
import {
  fetchGetNotifications,
  fetchGetNotReadNotifications,
  fetchSetNotificationsRead,
  setEmptyArrNotifications,
} from './notificationsThunk';

type InitialState = {
  loading: boolean;
  error: ErrorType;
} & NotificationsData;

export const initialState: InitialState = {
  notifications: [],
  totalNotifications: 0,
  numberNotRead: 0,
  loading: true,
  error: null,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGetNotifications.fulfilled, (state, action) => {
      state.notifications = [
        ...state.notifications,
        ...action.payload.notifications,
      ];
      state.numberNotRead = action.payload.numberNotRead;
      state.totalNotifications = action.payload.totalNotifications;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(
      fetchGetNotifications.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload.message;
        state.loading = false;
      },
    );
    builder.addCase(fetchSetNotificationsRead.fulfilled, (state, action) => {
      const notifications = state.notifications.filter((item) => action.payload.includes(item.id));
      notifications.forEach((item) => (item.isRead = true));
      state.numberNotRead = 0;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(fetchSetNotificationsRead.rejected, (state) => {
      state.error = 'Error';
      state.loading = false;
    });
    builder.addCase(fetchSetNotificationsRead.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchGetNotReadNotifications.fulfilled, (state, action) => {
      state.numberNotRead = action.payload;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(fetchGetNotReadNotifications.rejected, (state) => {
      state.error = 'Error';
      state.loading = false;
    });
    builder.addCase(fetchGetNotReadNotifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setEmptyArrNotifications, (state) => {
      state.error = null;
      state.loading = false;
      state.notifications = [];
    });
  },
});

export default notificationsSlice.reducer;
