import React from 'react';
import { CloseIcon } from '../../icons';
import { BaseColors } from '../../types';
import { CloseWindowButton } from './styled';

type CloseModalWindowButtonProps = {
  handleClick: () => void;
  color: BaseColors;
};

export const CloseModalWindowButton: React.FC<CloseModalWindowButtonProps> = ({
  handleClick,
  color,
}) => (
  <CloseWindowButton color={color} type="button" onClick={handleClick}>
    <CloseIcon />
  </CloseWindowButton>
);
