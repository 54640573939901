import React, { ChangeEvent, useMemo } from 'react';
import { MiniFileCardWrapper, MiniFileCardPositionedIcon } from './styled';
import { CommonCheckbox } from '../FormElements/CommonCheckbox/CommonCheckbox';
import { CustomPositionCheckbox } from '../styled';
import { getFileInContainer } from '../../utils/gallery/getFileInContainer';
import { CustomFile } from '../../types';
import { BulbIcon } from '../../icons';

type MiniFileCardProps = {
  file: CustomFile | null;
  handleCheck: (e: ChangeEvent<HTMLInputElement>, file: CustomFile) => void;
  isChecked: boolean;
  large?: boolean;
  id?: string;
  isSettingsApplied?: boolean;
  mobileStyle?: string;
  margins?: boolean;
  isIdea?: boolean;
  isLoading?: boolean;
  watermarked?: boolean;
  tryingVideoThumbnailUrl?: string;
};

export const MiniFileCard: React.FC<MiniFileCardProps> = ({
  file,
  handleCheck,
  isChecked,
  large,
  id,
  isSettingsApplied,
  mobileStyle,
  margins = true,
  isIdea,
  isLoading,
  watermarked,
  tryingVideoThumbnailUrl,
}) => {
  const content = useMemo(
    () => getFileInContainer({
      file,
      isLoading,
      watermarked,
      tryingVideoThumbnailUrl,
    }),
    [file, isLoading, watermarked, tryingVideoThumbnailUrl],
  );

  return (
    <MiniFileCardWrapper
      margins={!!margins}
      hasBorders={isSettingsApplied}
      large={large}
      mobileStyle={mobileStyle}
    >
      {file && (
        <>
          <CustomPositionCheckbox>
            <CommonCheckbox
              id={id}
              isChecked={isChecked}
              handleChange={(e) => handleCheck(e, file || file)}
              simple
              name=""
              positioned
            />
          </CustomPositionCheckbox>
          {isIdea && (
            <MiniFileCardPositionedIcon>
              <BulbIcon />
            </MiniFileCardPositionedIcon>
          )}
          {content}
        </>
      )}
    </MiniFileCardWrapper>
  );
};
