/* eslint-disable max-len */
import React from 'react';

export const CommentIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2417 8C10.7925 8 10.4444 8.4475 10.4444 9C10.4444 9.5525 10.7925 10 11.2417 10C11.6519 10 12 9.5525 12 9C12 8.4475 11.6519 8 11.2417 8ZM5.79722 8C5.34806 8 5 8.4475 5 9C5 9.5525 5.34806 10 5.79722 10C6.2075 10 6.55556 9.5525 6.55556 9C6.55556 8.4475 6.2075 8 5.79722 8Z"
      fill="currentColor"
    />
    <path
      d="M17.6728 6.88196C16.7066 5.60358 15.3567 4.74939 13.8762 4.36393V4.36587C13.5327 3.99785 13.145 3.65889 12.7111 3.35673C9.42265 1.05177 4.80642 1.75488 2.4059 4.92565C0.471416 7.50178 0.553777 10.967 2.52642 13.4288L2.54249 15.9972C2.54249 16.0592 2.55254 16.1212 2.57263 16.1793C2.67909 16.5066 3.04068 16.6868 3.37816 16.5841L5.92131 15.8113C6.59425 16.0418 7.2893 16.1735 7.98033 16.2103L7.97029 16.218C9.76013 17.4751 12.1064 17.8528 14.2578 17.1671L16.811 17.969C16.8753 17.9884 16.9416 18 17.0099 18C17.3655 18 17.6527 17.723 17.6527 17.3802V14.7847C19.4225 12.4681 19.4687 9.26633 17.6728 6.88196ZM6.20254 14.436L5.96148 14.3392L3.97276 14.9396L3.95268 12.9252L3.79197 12.7509C2.09252 10.752 1.98003 7.87173 3.571 5.75853C5.50749 3.1979 9.21976 2.63231 11.8674 4.48015C14.523 6.34155 15.1116 9.91521 13.1932 12.4603C11.5841 14.589 8.7035 15.3754 6.20254 14.436ZM16.347 14.1067L16.1863 14.3004L16.2064 16.3149L14.2378 15.6757L13.9967 15.7725C12.8718 16.1754 11.6725 16.2083 10.5616 15.9081L10.5576 15.9062C12.0421 15.4665 13.388 14.5755 14.3583 13.2932C15.893 11.2536 16.1421 8.69106 15.2502 6.50619L15.2622 6.51394C15.7243 6.83353 16.1481 7.23254 16.5077 7.71484C17.9661 9.64404 17.8837 12.2705 16.347 14.1067Z"
      fill="currentColor"
    />
    <path
      d="M9.025 8C8.4475 8 8 8.4475 8 9C8 9.5525 8.4475 10 9.025 10C9.5525 10 10 9.5525 10 9C10 8.4475 9.5525 8 9.025 8Z"
      fill="currentColor"
    />
  </svg>
);

export const ShareIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6667 12.4082C14.1125 12.4082 13.6011 12.5995 13.2006 12.919L9.17167 10.051C9.23913 9.68667 9.23913 9.31333 9.17167 8.94898L13.2006 6.08099C13.6011 6.40051 14.1125 6.59184 14.6667 6.59184C15.9539 6.59184 17 5.5625 17 4.29592C17 3.02934 15.9539 2 14.6667 2C13.3794 2 12.3333 3.02934 12.3333 4.29592C12.3333 4.51786 12.3644 4.73023 12.4247 4.93304L8.59806 7.65944C8.03028 6.919 7.12806 6.43878 6.11111 6.43878C4.39222 6.43878 3 7.80867 3 9.5C3 11.1913 4.39222 12.5612 6.11111 12.5612C7.12806 12.5612 8.03028 12.081 8.59806 11.3406L12.4247 14.067C12.3644 14.2698 12.3333 14.4841 12.3333 14.7041C12.3333 15.9707 13.3794 17 14.6667 17C15.9539 17 17 15.9707 17 14.7041C17 13.4375 15.9539 12.4082 14.6667 12.4082ZM14.6667 3.30102C15.2247 3.30102 15.6778 3.74681 15.6778 4.29592C15.6778 4.84503 15.2247 5.29082 14.6667 5.29082C14.1086 5.29082 13.6556 4.84503 13.6556 4.29592C13.6556 3.74681 14.1086 3.30102 14.6667 3.30102ZM6.11111 11.1837C5.16806 11.1837 4.4 10.4279 4.4 9.5C4.4 8.57207 5.16806 7.81633 6.11111 7.81633C7.05417 7.81633 7.82222 8.57207 7.82222 9.5C7.82222 10.4279 7.05417 11.1837 6.11111 11.1837ZM14.6667 15.699C14.1086 15.699 13.6556 15.2532 13.6556 14.7041C13.6556 14.155 14.1086 13.7092 14.6667 13.7092C15.2247 13.7092 15.6778 14.155 15.6778 14.7041C15.6778 15.2532 15.2247 15.699 14.6667 15.699Z"
      fill="currentColor"
    />
  </svg>
);
export const CartIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.6297 16.4508H7.67266L8.37344 15.0234L20.0172 15.0023C20.4109 15.0023 20.7484 14.7211 20.8187 14.332L22.4312 5.30625C22.4734 5.06953 22.4102 4.82578 22.2555 4.64062C22.179 4.5495 22.0837 4.4761 21.976 4.42551C21.8683 4.37492 21.751 4.34835 21.632 4.34766L6.81953 4.29844L6.69297 3.70312C6.61328 3.32344 6.27109 3.04688 5.88203 3.04688H2.26094C2.04151 3.04688 1.83107 3.13404 1.67592 3.2892C1.52076 3.44436 1.43359 3.65479 1.43359 3.87422C1.43359 4.09364 1.52076 4.30408 1.67592 4.45924C1.83107 4.6144 2.04151 4.70156 2.26094 4.70156H5.21172L5.76484 7.33125L7.12656 13.9242L5.37344 16.7859C5.28239 16.9088 5.22756 17.0547 5.21513 17.2071C5.20271 17.3596 5.23319 17.5124 5.30312 17.6484C5.44375 17.9273 5.72734 18.1031 6.04141 18.1031H7.51328C7.1995 18.5199 7.03001 19.0275 7.03047 19.5492C7.03047 20.8758 8.10859 21.9539 9.43516 21.9539C10.7617 21.9539 11.8398 20.8758 11.8398 19.5492C11.8398 19.0266 11.6664 18.518 11.357 18.1031H15.1328C14.819 18.5199 14.6495 19.0275 14.65 19.5492C14.65 20.8758 15.7281 21.9539 17.0547 21.9539C18.3812 21.9539 19.4594 20.8758 19.4594 19.5492C19.4594 19.0266 19.2859 18.518 18.9766 18.1031H21.632C22.0867 18.1031 22.4594 17.7328 22.4594 17.2758C22.458 17.0566 22.37 16.8468 22.2146 16.6923C22.0591 16.5377 21.8489 16.4509 21.6297 16.4508ZM7.16406 5.92969L20.6594 5.97422L19.3375 13.3758L8.73672 13.3945L7.16406 5.92969ZM9.43516 20.2898C9.02734 20.2898 8.69453 19.957 8.69453 19.5492C8.69453 19.1414 9.02734 18.8086 9.43516 18.8086C9.84297 18.8086 10.1758 19.1414 10.1758 19.5492C10.1758 19.7456 10.0978 19.934 9.95886 20.0729C9.81996 20.2118 9.63158 20.2898 9.43516 20.2898ZM17.0547 20.2898C16.6469 20.2898 16.3141 19.957 16.3141 19.5492C16.3141 19.1414 16.6469 18.8086 17.0547 18.8086C17.4625 18.8086 17.7953 19.1414 17.7953 19.5492C17.7953 19.7456 17.7173 19.934 17.5784 20.0729C17.4395 20.2118 17.2511 20.2898 17.0547 20.2898Z"
      fill="currentColor"
    />
    <path
      d="M13.7308 12.7952C13.7052 12.7827 13.6828 12.7646 13.6652 12.7422L11.0402 9.41875C10.9441 9.29688 11.0308 9.11641 11.1879 9.11641H12.9199V7H14.7548V9.11641L16.4379 9.11875C16.5949 9.11875 16.6816 9.29922 16.5855 9.42109L13.9605 12.7422C13.943 12.7646 13.9206 12.7827 13.895 12.7952C13.8694 12.8077 13.8413 12.8141 13.8129 12.8141C13.7844 12.8141 13.7563 12.8077 13.7308 12.7952Z"
      fill="currentColor"
    />
  </svg>
);

export const HeartIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0273 5.53908C17.7655 4.9329 17.3881 4.38359 16.916 3.92189C16.4436 3.45881 15.8866 3.09082 15.2754 2.8379C14.6416 2.57461 13.9617 2.43984 13.2754 2.44142C12.3125 2.44142 11.373 2.70509 10.5566 3.20314C10.3613 3.32228 10.1758 3.45314 10 3.59572C9.82422 3.45314 9.63867 3.32228 9.44336 3.20314C8.62695 2.70509 7.6875 2.44142 6.72461 2.44142C6.03125 2.44142 5.35938 2.57423 4.72461 2.8379C4.11133 3.09181 3.55859 3.45704 3.08398 3.92189C2.61132 4.38306 2.23375 4.93251 1.97266 5.53908C1.70117 6.16993 1.5625 6.83986 1.5625 7.52931C1.5625 8.1797 1.69531 8.85743 1.95898 9.54689C2.17969 10.1231 2.49609 10.7207 2.90039 11.3242C3.54102 12.2793 4.42188 13.2754 5.51562 14.2852C7.32812 15.959 9.12305 17.1152 9.19922 17.1621L9.66211 17.459C9.86719 17.5899 10.1309 17.5899 10.3359 17.459L10.7988 17.1621C10.875 17.1133 12.668 15.959 14.4824 14.2852C15.5762 13.2754 16.457 12.2793 17.0977 11.3242C17.502 10.7207 17.8203 10.1231 18.0391 9.54689C18.3027 8.85743 18.4355 8.1797 18.4355 7.52931C18.4375 6.83986 18.2988 6.16993 18.0273 5.53908ZM10 15.9141C10 15.9141 3.04688 11.459 3.04688 7.52931C3.04688 5.53908 4.69336 3.92579 6.72461 3.92579C8.15234 3.92579 9.39062 4.72267 10 5.88673C10.6094 4.72267 11.8477 3.92579 13.2754 3.92579C15.3066 3.92579 16.9531 5.53908 16.9531 7.52931C16.9531 11.459 10 15.9141 10 15.9141Z"
      fill="currentColor"
    />
  </svg>
);

export const HeartFilledIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <path fill="currentColor" d="M 18.023438 5.542969 C 17.765625 4.933594 17.390625 4.382812 16.917969 3.925781 C 16.441406 3.457031 15.882812 3.089844 15.273438 2.839844 C 14.640625 2.574219 13.957031 2.441406 13.273438 2.441406 C 12.316406 2.441406 11.375 2.707031 10.558594 3.199219 C 10.359375 3.324219 10.175781 3.449219 10 3.589844 C 9.824219 3.449219 9.640625 3.324219 9.441406 3.199219 C 8.625 2.707031 7.683594 2.441406 6.726562 2.441406 C 6.035156 2.441406 5.359375 2.574219 4.726562 2.839844 C 4.109375 3.089844 3.558594 3.457031 3.082031 3.925781 C 2.609375 4.382812 2.234375 4.933594 1.976562 5.542969 C 1.699219 6.167969 1.566406 6.839844 1.566406 7.535156 C 1.566406 8.183594 1.691406 8.859375 1.957031 9.550781 C 2.183594 10.125 2.5 10.714844 2.898438 11.324219 C 3.542969 12.285156 4.425781 13.273438 5.515625 14.285156 C 7.324219 15.957031 9.125 17.117188 9.199219 17.160156 L 9.660156 17.457031 C 9.867188 17.589844 10.132812 17.589844 10.332031 17.457031 L 10.800781 17.160156 C 10.875 17.117188 12.667969 15.957031 14.484375 14.285156 C 15.574219 13.273438 16.457031 12.285156 17.101562 11.324219 C 17.5 10.714844 17.816406 10.125 18.042969 9.550781 C 18.300781 8.859375 18.433594 8.183594 18.433594 7.535156 C 18.441406 6.839844 18.300781 6.167969 18.023438 5.542969 Z M 18.023438 5.542969 " />
  </svg>
);

export const InstantDownload: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.01354 14.3511C8.02645 14.3676 8.04295 14.3809 8.06179 14.3901C8.08063 14.3993 8.10131 14.404 8.12226 14.404C8.14321 14.404 8.16389 14.3993 8.18273 14.3901C8.20157 14.3809 8.21807 14.3676 8.23098 14.3511L10.1638 11.9057C10.2346 11.8159 10.1707 11.6831 10.0551 11.6831H8.77632V5.84314C8.77632 5.7672 8.71419 5.70508 8.63826 5.70508H7.60281C7.52688 5.70508 7.46475 5.7672 7.46475 5.84314V11.6813H6.18942C6.0738 11.6813 6.00995 11.8142 6.0807 11.904L8.01354 14.3511ZM14.4385 13.7471H13.403C13.3271 13.7471 13.265 13.8092 13.265 13.8851V16.5428H2.97954V13.8851C2.97954 13.8092 2.91741 13.7471 2.84148 13.7471H1.80603C1.7301 13.7471 1.66797 13.8092 1.66797 13.8851V17.3021C1.66797 17.6075 1.91475 17.8543 2.22021 17.8543H14.0243C14.3298 17.8543 14.5765 17.6075 14.5765 17.3021V13.8851C14.5765 13.8092 14.5144 13.7471 14.4385 13.7471Z"
      fill="currentColor"
    />
    <path
      d="M18.561 4.22269H16.4225L18.3455 1.79294C18.3853 1.74149 18.3494 1.66675 18.2844 1.66675H14.5616C14.5344 1.66675 14.5082 1.68131 14.4946 1.70558L11.9794 6.0496C11.9494 6.10105 11.9862 6.16609 12.0464 6.16609H13.7394L12.8716 9.63743C12.8531 9.71315 12.9444 9.76654 13.0007 9.71218L18.6144 4.35568C18.6649 4.30811 18.6309 4.22269 18.561 4.22269Z"
      fill="currentColor"
    />
  </svg>
);
