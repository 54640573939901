import styled from '@emotion/styled';
import * as variables from '../../../../../../../../../../constants/styles';
import { PreviewCard } from '../../styled';

export const PreviewCardThird = styled(PreviewCard)`
  padding: 13px;
  position: relative;
`;
export const ImageContainerThird = styled.div`
  position: absolute;
  top: 13;
  left: 13;
  width: 278px;
  height: 170px;
  overflow: hidden;
  object-fit: cover;
  background: ${variables.colorBleak};
  z-index: 1;
`;
export const TextContainerThird = styled.div`
  height: 100%;
  z-index: 10;
  width: 96px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2.87169px);
  -webkit-backdrop-filter: blur(2.87169px);
  padding: 28px 11px 28px 9px;
  text-align: start;
  font-weight: 300;
  font-size: 4.02037px;
  line-height: 145%;

  & div:last-child {
    margin-top: 5px;
    text-align: end;
  }

  & h3 {
    font-weight: 400;
    font-size: 9.4868px;
    line-height: 14px;
    margin-bottom: 0;
  }
`;
