import styled from '@emotion/styled';

export const CloseMenuIconWrapper = styled.button`
  position: absolute;
  top: 47px;
  right: 16px;
  padding: 0;

  & svg {
    width: 24px;
    height: 24px;
  }
`;

export const ClientMenuListWrapper = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: space-between !important;
  align-items: center;
  height: 100%;
  padding-top: 160px;
  position: relative;
`;

export const ClientMenuLinksAndButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  & .menu-item {
    width: fit-content;
    text-align: center;
    margin: auto;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    font-weight: 600;
    margin-bottom: 50px;
  }
`;
