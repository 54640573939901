export const aboveSectionTitle = 100;

export const scrollToElement = (
  idElement: string,
  scrolledElementId?: string,
  topMargin = 0,
): void => {
  const sectionItem = document.getElementById(idElement);
  const mainElement = document.querySelector(`#${scrolledElementId}` || '');
  const sectionElY = (sectionItem?.offsetTop || aboveSectionTitle)
    - (mainElement ? aboveSectionTitle : -50);
  (mainElement || window)?.scrollTo({
    top: sectionElY - topMargin,
    behavior: 'smooth',
  });
};
