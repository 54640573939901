import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../shared/hooks/useTypedSelector';
import { SimpleBlackText } from '../../../../../../shared/styles';
import { PlusCssIcon } from '../../../../../../shared/styles/Elements';
import { Notification } from '../../../../../../shared/types';
import { fetchGetNotifications, fetchSetNotificationsRead } from '../../../../redux/notifications/notificationsThunk';
import { NotificationsCard } from './NotificationCard';
import { LoadMoreButton, NotificationListWrapper } from './styled';

export const NotificationsList: React.FC = () => {
  const { t } = useTranslation('notifications');
  const [page, setPage] = useState(1);

  const dispatch = useCreatorAppDispatch();

  const notifications: Notification[] = useTypedSelectorCreator(
    (state) => state.notifications.notifications,
  );
  const totalNotifications = useTypedSelectorCreator(
    (state) => state.notifications.totalNotifications,
  );

  const handleLoadMore = () => {
    dispatch(fetchGetNotifications({ page: page + 1 }));
    setPage((prev) => prev += 1);
  };

  useEffect(() => {
    const notReadNotifications = notifications?.filter((item) => item.isRead === false) || [];
    if (notReadNotifications?.length) {
      setTimeout(() => {
        const ids = notReadNotifications.map((item) => item.id);
        dispatch(fetchSetNotificationsRead(ids));
      }, 2000);
    }
  }, [notifications]);

  return (
    <NotificationListWrapper>
      {notifications?.map((item) => (
        <NotificationsCard
          key={item.id}
          id={item.id}
          from={item.from}
          message={item.message}
          title={item.title}
          isRead={item.isRead}
          date={item.date}
          item={item.item}
        />
      ))}
      {notifications.length < totalNotifications
      && (
      <LoadMoreButton type="button" onClick={handleLoadMore}>
        <PlusCssIcon />
        <SimpleBlackText>{t('loadMore')}</SimpleBlackText>
      </LoadMoreButton>
      )}
    </NotificationListWrapper>
  );
};
