import { Drawer } from '@mui/material';
import React, { ReactElement, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { BurgerIcon } from '../../icons';
import * as variables from '../../../constants/styles';
import { CloseIcon } from '../../icons/CommonIcons';
import {
  ClientMenuLinksAndButtonsWrapper,
  ClientMenuListWrapper,
  CloseMenuIconWrapper,
} from './styled';

const StyledDrawer = styled(Drawer)(() => ({
  '& .MuiDrawer-paper': {
    width: '100vw',
    minWidth: '100vw',
    boxSizing: 'border-box',
    flexShrink: '0',
    background: variables.colorGreyLight,
  },
}));

type BurgerMenuProps = {
  footer: ReactElement | null;
  links: ReactElement;
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}

export const BurgerMenu: React.FC<BurgerMenuProps> = ({
  footer,
  links,
  isOpen,
  handleOpen,
  handleClose,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'scroll';
    }
  }, [isOpen]);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button style={{ padding: 0, display: 'flex' }} type="button" onClick={handleOpen}><BurgerIcon /></button>
      <StyledDrawer
        anchor="right"
        open={isOpen}
        onClose={handleClose}
        transitionDuration={0}
      >
        <ClientMenuListWrapper>
          <CloseMenuIconWrapper type="button" onClick={handleClose}>
            <CloseIcon color="black" />
          </CloseMenuIconWrapper>
          <ClientMenuLinksAndButtonsWrapper>
            {links}
          </ClientMenuLinksAndButtonsWrapper>
          {footer}
        </ClientMenuListWrapper>
      </StyledDrawer>
    </>
  );
};
