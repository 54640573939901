import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const PaymentsToMembersPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={9}
      listTitle={t('terms.paymentsToMembers')}
      pointsNameForTrans="paymentsToMembersPoints"
    >
      <LandingNumberedLi>
        This clause applies to you, a Member.
      </LandingNumberedLi>
      <LandingNumberedLi>
        In consideration for providing the SaaS Services and the Platform,
        we charge you, a Member, service fees (including any third-party
        payment processing fees) based on your Membership type as set out on
        the Platform and in the Membership Terms and Conditions.
      </LandingNumberedLi>
      <LandingNumberedLi>
        If you (a Member) have a Deferred Payment plan, your Membership Fee
        will be on hold and only payable if you use the Platform to
        successfully sell Output Content. This means that each time you sell
        Output Data via the Platform, your Membership Fee (being a
        percentage of the Project Fee) is payable to us from the Project Fee
        amount by our third party payment processor before any payment to
        you of the Project Fees as set out in this clause (
        <span className="bold">Deferred Payment Service Fee</span>
        ). For the avoidance of doubt, if you (a Member) fail to sell
        content via the Platform, you will not have to pay a Membership Fee.
      </LandingNumberedLi>
      <LandingNumberedLi>
        If payment for Output Content is due, the Project Fees, minus the
        Deferred Payment Service Fee (if applicable), will be deposited in
        your merchant account as linked in your Account upon payment by the
        Client on the timing of the terms of our third party payment
        processor
        {' '}
        <a target="_blink" href="https://stripe.com/en-gb-us">
          www.stripe.com
        </a>
        .
      </LandingNumberedLi>
      <LandingNumberedLi>
        You agree that we may set-off or deduct from any monies payable to
        you under these Terms, any amounts which are payable by you to us
        (whether under these Terms or otherwise).
      </LandingNumberedLi>
      <LandingNumberedLi>
        We do not store any credit card details, and all payment information
        is collected and stored through our third-party payment processor.
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
