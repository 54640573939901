import { Grid } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DownloadIcon } from '../../../../../../../shared/icons';
import { MediumBlackText } from '../../../../../../../shared/styles';
import { BillingHistory } from '../../../../../../../shared/types';
import { TextAndIconWrapper } from './styled';
import { getDateFromSec, getFormattedDateOrDateName } from '../../../../../../../shared/utils';
import { useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';

type RecentBillingHistoryRowProps = {
  row: BillingHistory;
};

export const RecentBillingHistoryRow: React.FC<RecentBillingHistoryRowProps> = ({
  row,
}) => {
  const { t } = useTranslation('sales');
  const currentUser = useTypedSelectorCreator(
    (state) => state.creator.currentUser,
  );
  const date = getFormattedDateOrDateName(getDateFromSec(row.created), currentUser?.dateFormat || '', currentUser?.timezone || '');

  return (
    <Grid container item sm={12} alignItems="center">
      <Grid item sm={3}>
        <MediumBlackText>
          {date}
        </MediumBlackText>
      </Grid>
      <Grid item sm={3}>
        <MediumBlackText>
          <Trans t={t} i18nKey="salesSum">
            $
            {{ sales: Number(row.total || 0) / 100 }}
          </Trans>
        </MediumBlackText>
      </Grid>
      <Grid item sm={3}>
        <MediumBlackText style={{ textTransform: 'capitalize' }} fontWeight={500}>{row.status}</MediumBlackText>
      </Grid>
      <Grid item sm={1}>
        <MediumBlackText>
          <TextAndIconWrapper>
            {row.invoiceUrl ? (<a href={row.invoiceUrl} target="_blank" rel="noreferrer"><DownloadIcon /></a>) : '&mdash'}
          </TextAndIconWrapper>
        </MediumBlackText>
      </Grid>
    </Grid>
  );
};
