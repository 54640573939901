import { Fade } from '@mui/material';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useTypedSelectorClient } from '../../../../../../../shared/hooks/useTypedSelector';
import { ArrowSimpleIcon } from '../../../../../../../shared/icons';
import { ClientOnboardingContext } from '../../../../../../Client/context/CreatorOnboardingContext';
import {
  DropDownContainer,
  DropDownHeader,
  DropDownListContainer,
  DropDownList,
  ListItem,
} from './styled';

type MenuType = { id: string; value: string };

type NavigationSelectProps = {
  menuItems: MenuType[];
  action: (id: string) => void;
  currentMenuItem: MenuType;
};

export const NavigationSelect: React.FC<NavigationSelectProps> = ({
  menuItems,
  action,
  currentMenuItem,
}) => {
  const { isStudy } = useContext(ClientOnboardingContext);
  const refSelect = useRef<HTMLDivElement>(null);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const stepIndex = useTypedSelectorClient(
    (state) => state?.onboarding?.stepIndex,
  );

  const handleClickHeader = () => {
    setIsSelectOpen((prev) => !prev);
  };

  const handleClickListItem = (data: MenuType) => () => {
    action(data.id);
    setTimeout(() => {
      handleClickHeader();
    }, 400);
  };

  const handleClickOutside = (event: any) => {
    if (refSelect.current && !refSelect.current.contains(event.target) && !isStudy) {
      setIsSelectOpen(false);
    }
  };

  useEffect(() => {
    const arrIndex = menuItems.findIndex((item) => item.id === currentMenuItem.id);
    if (isStudy && stepIndex === 3 && arrIndex === 0) {
      setIsSelectOpen(true);
    } else {
      setIsSelectOpen(false);
    }
  }, [isStudy, stepIndex, menuItems, currentMenuItem.id]);

  useEffect(() => {
    if (!isStudy) {
      window.addEventListener('scroll', () => setIsSelectOpen(false));
    }
    return () => window.removeEventListener('scroll', () => setIsSelectOpen(false));
  }, [isStudy]);

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => window.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <DropDownContainer ref={refSelect}>
      <DropDownHeader onClick={handleClickHeader}>
        <p>{currentMenuItem.value}</p>
        {' '}
        <ArrowSimpleIcon
          direction={isSelectOpen ? 'up' : 'down'}
          color="black"
        />
      </DropDownHeader>
      <Fade in={isSelectOpen}>
        <DropDownListContainer isSelectOpen id="client-navigation-step">
          <DropDownList>
            {menuItems.map((item) => (
              <ListItem
                isActive={currentMenuItem.value === item.value}
                onClick={handleClickListItem(item)}
                key={item.id}
              >
                <p>{item.value}</p>
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      </Fade>
    </DropDownContainer>
  );
};
