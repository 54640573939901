import React from 'react';
import { useTranslation } from 'react-i18next';
import * as urls from '../../../../../constants/urls/landingUrls';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const IntroductionPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={2}
      listTitle={t('terms.introduction')}
      pointsNameForTrans="introductionPoints"
    >
      <LandingNumberedLi>
        These terms and conditions
        {' '}
        <span className="bold">(Terms)</span>
        {' '}
        are
        entered into between Delivrable Pty Ltd ABN ABN 84 656 603 100 (
        <span className="bold">we</span>
        ,
        {' '}
        <span className="bold">us</span>
        {' '}
        or
        {' '}
        <span className="bold">our</span>
        ) and you, together the
        {' '}
        <span className="bold">Parties</span>
        {' '}
        and each a
        {' '}
        <span className="bold">Party</span>
        .
      </LandingNumberedLi>
      <LandingNumberedLi>
        We provide a cloud-based, software as a service (the SaaS Solution)
        which Members can use to combine video, photo, project files, known
        in the Platform as
        {' '}
        <span className="bold">&quot;Ideas&quot;</span>
        {' '}
        (
        <span className="bold">Output Data</span>
        ) and offer a Platform
        where Members can connect and transact with Clients to sell Output
        Data and offer other sales opportunities to Clients (
        <span className="bold">Platform</span>
        ).
      </LandingNumberedLi>
      <LandingNumberedLi>
        In these Terms,
        {' '}
        <span className="bold">you</span>
        {' '}
        means (as
        applicable) the person or entity registered with us as either a
        Client or Member or the individual accessing or using the Platform.
      </LandingNumberedLi>
      <LandingNumberedLi>
        If you are using the Platform as a Member, you also agree to our
        Membership Terms and Conditions available at
        <a href={urls.termsUrl}>www.delivrable.io/terms</a>
        , and to the
        extent of any inconsistency, the Membership Terms and Conditions
        take priority.
      </LandingNumberedLi>
      <LandingNumberedLi>
        If you are using the Platform on behalf of your employer or a
        business entity, you, in your individual capacity, represent and
        warrant that you are authorised to act on behalf of your employer or
        the business entity and to bind the entity and the entity’s
        personnel to these Terms.
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
