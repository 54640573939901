import styled from '@emotion/styled';
import * as variables from '../../constants/styles';
import { BaseColors } from '../types';
import { getBaseColor } from '../utils/style/getBaseColor';

export const BleakText = styled.span`
  width: 336px;
  font-size: 12px;
  line-height: 18px;
  margin-top: 18px;
  color: ${variables.colorBleak};
`;

export const ThirteenText = styled.div<{fontWeight?: number, color?: BaseColors, textUppercase?: boolean}>`
  font-size: 13px;
  line-height: 100%;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
  color: ${({ color }) => (getBaseColor(color))};
  text-transform: ${({ textUppercase }) => (textUppercase ? 'uppercase' : 'none')};
`;

export const MediumBlackText = styled.div<{fontWeight?: number, textUppercase?: boolean}>`
  font-size: 14px;
  line-height: 150%;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
  color: ${variables.textBlack};
  text-transform: ${({ textUppercase }) => (textUppercase ? 'uppercase' : 'none')};
`;

export const MediumPinkText = styled(MediumBlackText)`
  color: ${variables.colorPink};
`;

export const MediumWhiteText = styled(MediumBlackText)`
  color: ${variables.colorGreyLight};
`;

export const MediumGreyText = styled(MediumBlackText)`
  color: ${variables.textGrey};
`;

export const MediumBlackTextAndLink = styled(MediumBlackText)`
  text-align: center;
  margin-bottom: 20px;

  & > a {
    margin-left: 5px;
    margin-right: 5px;
    color: ${variables.colorPink};
    border-bottom: 1px solid ${variables.colorPink};
  }
`;

export const GreyLink = styled.div`
margin-top: 20px;

& > a {
  text-align: center;
  font-size: 14px;
  line-height: 150%;
  color: ${variables.textGrey};
}
`;

export const TitleSimple = styled.h1`
  font-weight: 500;
  font-size: 30px;
  line-height: 110%;
  margin-top: 0;
  text-transform: uppercase;
  color: ${variables.textBlack};
`;

export const TitleBlack = styled.span`
  font-size: 24px;
  line-height: 36px;
  color: ${variables.textBlack};
`;

export const TitlePink = styled(TitleBlack)`
  color: ${variables.colorPink};
`;
export const TitlePinkBold = styled(TitlePink)`
  font-weight: 600;
`;

export const TextBlackLarge = styled.div<{fontWeight?: number}>`
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: ${variables.textBlack};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
`;

export const TextBlackTwenty = styled.div<{fontWeight?: number}>`
  font-size: 20px;
  line-height: 150%;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
  color: ${variables.textBlack};
`;

export const TextGreyTwenty = styled(TextBlackTwenty)`
  color: ${variables.textGrey};
`;

export const TextTotalBlackLarge = styled(TextBlackLarge)`
  color: #000;
`;

export const TextPinkLarge = styled(TextBlackLarge)<{fontWeight?: number}>`
  color: ${variables.colorPink};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
`;

export const TextGreyLarge = styled(TextBlackLarge)`
  color: ${variables.textGrey};
`;

export const TextWhiteLarge = styled(TextBlackLarge)`
  color: ${variables.colorGreyLight};
`;

export const TextWithIcon = styled(TextBlackLarge)`
  margin-bottom: 10px;
  margin-top: 20px;

  & svg {
    margin-right: 17px;
  }
`;

export const SimpleGreyText = styled.span<{fontWeight?: number, textUppercase?: boolean}>`
  font-size: 16px;
  line-height: 24px;
  color: ${variables.textGrey};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
  text-transform: ${({ textUppercase }) => (textUppercase ? 'uppercase' : 'none')};
`;

export const SimpleBlackText = styled(SimpleGreyText)`
  color: ${variables.textBlack};
`;

export const SimplePinkText = styled(SimpleGreyText)`
  color: ${variables.colorPink};
`;

export const SimpleWhiteText = styled(SimpleGreyText)`
  color: ${variables.colorGreyLight};
`;

export const PinkLink = styled.a<{underline?: boolean, fontWeight?: number}>`
  color: ${variables.colorPink};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
`;

export const LittleBlackText = styled.span<{fontWeight?: number}>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: ${variables.textBlack};
`;

export const LittlePinkText = styled(LittleBlackText)`
  color: ${variables.colorPink};
`;

export const LittleGreyText = styled(LittleBlackText)`
  color: ${variables.textGrey};
`;

export const LittleWhiteText = styled(LittleBlackText)`
  color: ${variables.colorGreyLight};
`;

export const LittleErrorText = styled(LittleBlackText)`
  color: ${variables.colorError};
`;

export const TitleText = styled.span<{fontWeight?: number}>`
  font-size: 22px;
  line-height: 33px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
`;

export const TitleGreyLightText = styled(TitleText)`
  color: ${variables.colorGreyLight};
`;

export const ButtonBlackTextPinkHover = styled.button`
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: ${variables.textBlack};
  width: fit-content;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid ${variables.colorPink};
    color: ${variables.colorPink};
  }
`;

export const TitleTextPink = styled(TitleText)`
  color: ${variables.colorPink};
`;

export const GradientColorText = styled.span<{fontWeight?: number}>`
  background: linear-gradient(270deg, #E6934F 0%, #E186B0 49.82%, #78C0EA 100%);
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const GradientLink = styled.a<{fontWeight?: number}>`
  background: linear-gradient(270deg, #E6934F 0%, #E186B0 49.82%, #78C0EA 100%);
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
`;

export const TinyText = styled.div<{fontWeight?: number, color?: BaseColors, textUppercase?: boolean}>`
  font-size: 10px;
  line-height: 120%;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
  color: ${({ color }) => (getBaseColor(color))};
  text-transform: ${({ textUppercase }) => (textUppercase ? 'uppercase' : 'none')};
`;

export const TextBlockLimitedWidth = styled.p<{ maxWidth: number }>`
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
  align-items: inherit;
`;
