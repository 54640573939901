import { Tabs } from '@mui/material';
import React, { ChangeEvent, SetStateAction, Dispatch } from 'react';
import { TabType } from '../../../types';
import { StyledTab } from './StyledTab';

type BaseTabsProps = {
  tabs: TabType[];
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
};

export const BaseTabs: React.FC<BaseTabsProps> = ({ tabs, value, setValue }) => {
  const handleChange = (event: ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="basic tabs example"
      TabIndicatorProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
    >
      {tabs.map((item) => (
        <StyledTab
          tabsnumber={tabs.length}
          key={item.id}
          label={item.label}
          id={item.id}
        />
      ))}
    </Tabs>
  );
};
