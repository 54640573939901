import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterButton } from '../../../../../../shared/components/FilterButton/FilterButton';
import { FilterButtonsWrapper } from './styled';

type FilterButtonsProps = {
  isAnyPhotos: boolean;
  isAnyVideos: boolean;
  isAnyIdeas: boolean;
  isAnyOtherFiles: boolean;
  handleChoose: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const FilterButtons: React.FC<FilterButtonsProps> = ({
  isAnyPhotos,
  isAnyVideos,
  isAnyIdeas,
  isAnyOtherFiles,
  handleChoose,
}) => {
  const { t } = useTranslation('gallery');

  return (
    <FilterButtonsWrapper id="client-filters-step">
      {isAnyPhotos && (
        <FilterButton
          handleChoose={handleChoose}
          text={t('filters.photo')}
          id="photos"
        />
      )}
      {isAnyVideos && (
        <FilterButton
          handleChoose={handleChoose}
          text={t('filters.video')}
          id="videos"
        />
      )}
      {isAnyIdeas && (
        <FilterButton
          handleChoose={handleChoose}
          text={t('filters.ideas')}
          id="ideas"
        />
      )}
      {isAnyOtherFiles && (
        <FilterButton
          handleChoose={handleChoose}
          text={t('filters.otherFiles')}
          id="other"
        />
      )}
    </FilterButtonsWrapper>
  );
};
