import styled from '@emotion/styled';
import * as variables from '../../../../constants/styles';
import { theme } from '../../../../shared/components/GlobalContainer/styled';

export const TermsAndPrivacyTopScreenWrapper = styled.div`
  margin-top: 60px;
  margin-bottom: 143px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    flex-direction: column;
    margin-bottom: 19px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex-direction: column-reverse;
    margin-top: 16px;
    margin-bottom: 40px;
  }
`;

export const TermsAndPrivacyTopScreenHeader = styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin-top: 0px;
    margin-bottom: 38px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

export const TermsAndPrivacyTopScreenTitle = styled.div`
  font-weight: 700;
  font-size: 40px;
  line-height: 110%;
  color: ${variables.textBlack};
  margin-bottom: 24px;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    font-size: 30px;
    line-height: 130%;
    margin-bottom: 16px;
  }
`;

export const TermsAndPrivacyLastUpdate = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: ${variables.textGrey};

  @media (max-width: ${theme.breakpoints.values.md}px) {
    font-size: 14px;
  }
`;

export const TermsAndPrivacyTopScreenImage = styled.div`
  display: flex;
  min-height: 560px;

  & img {
    object-fit: contain;
    width: 100%;
    height: auto;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    min-height: 0px;
    margin-bottom: 48px;
  }
`;

export const TermsAndPrivacyListItem = styled.li<{
  listMarker?: 'none' | 'disc';
  hasCounter?: boolean;
  noPaddingLeft?: boolean;
  isTitle?: boolean;
}>`
  font-size: ${({ isTitle }) => (isTitle ? '22px' : '16px')};
  line-height: ${({ isTitle }) => (isTitle ? '33px' : '140%')};
  list-style-type: ${({ listMarker }) => listMarker || 'inherit'};
  ${({ noPaddingLeft }) => (!noPaddingLeft ? 'padding-left: 0px;' : '')}
  color: ${variables.textBlack};
  margin: ${({ isTitle }) => (isTitle ? '40px 0px 32px 0px' : '0px 0px 16px 0px')};

  & a {
    font-weight: 600;
    color: ${variables.colorPink};
  }

  & .bold {
    font-weight: 600;
  }

  ${({ hasCounter, isTitle }) => (hasCounter
    ? `
    list-style-type: none;
    margin-bottom: 0px;

    & > #number {
      height: fit-content;
      width: fit-content;
      margin-right: 12px;
      float: left;

      &:before {
        content: counters(list-item, ".") "${(isTitle ? '.' : '')} ";
        height: fit-content;
        width: fit-content;
      }
    }
  `
    : '')}

  @media (max-width: ${theme.breakpoints.values.md}px) {
    font-size: ${({ isTitle }) => (isTitle ? '18px' : '16px')};
    line-height: ${({ isTitle }) => (isTitle ? '27px' : '140%')};
  }
`;

export const LandingTermsAndPrivacyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TermsAndPrivacyText = styled.div<{ hasMarginBottom?: boolean }>`
  display: block;
  font-size: 16px;
  line-height: 140%;
  ${({ hasMarginBottom }) => (hasMarginBottom ? 'margin-bottom: 16px;' : '')}
  color: ${variables.textBlack};

  & a {
    font-weight: 600;
    color: ${variables.colorPink};
  }

  & .bold {
    font-weight: 600;
  }
`;

export const TermsAndPrivacyListTitle = styled.div<{ fontWeight?: number }>`
  display: flex;
  margin-bottom: 32px;
  margin-top: 40px;
  font-size: 22px;
  line-height: 33px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
  color: ${variables.textBlack};

  @media (max-width: ${theme.breakpoints.values.md}px) {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const TermsAndPrivacyListWrapper = styled.ul<{hasBigBottomMargin?: boolean}>`
  margin-bottom: ${({ hasBigBottomMargin }) => (hasBigBottomMargin ? '24px' : '8px')};
  margin-top: 16px;
`;

export const TermsAndPrivacyListNoMarkers = styled.ul<{hasBigBottomMargin?: boolean}>`
  padding-left: 20px;
`;

export const TermsAndPrivacyNumberedListWrapper = styled.ol<{noPaddingLeft?: boolean;}>`
  margin-bottom: 0px;
  ${({ noPaddingLeft }) => (noPaddingLeft ? 'padding-left: 0px;' : '')}

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const LandingNumberedLiWrapper = styled.div<{isTitle?: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: ${({ isTitle }) => (isTitle ? '0px' : '16px')};

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const TermsAndPrivacyInsideListWrapper = styled.ul`
  margin-top: 16px;
  padding-left: 20px;
`;

export const TermsAndPrivacyContactInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 40px;

  & > div {
    margin-bottom: 24px;
  }
`;

export const TermsAndPrivacyContactUsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    margin-right: 8px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex-direction: column;
    align-items: flex-start;

    & > * {
      margin-right: 0px;
    }
  }
`;

export const TermsAndPrivacyContactEmail = styled.div`
  display: flex;
  flex-direction: row;

  & > * {
    margin-right: 8px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-top: 8px;
  }
`;
