import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetToken } from '@clerk/types';
import { creator } from '../../../../../api/creator/creator';
import { CreatorProfilePut } from '../../../../../shared/types';

export const fetchCurrentUserCreator = createAsyncThunk(
  'creator/fetchCurrentUserCreator',
  async (getToken: GetToken, thunkApi) => {
    try {
      const token = await getToken();
      const response = await creator.getCurrentCreator(token || '');
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  },
);

export const fetchUpdateProfile = createAsyncThunk(
  'creator/fetchUpdateProfile',
  async (data: { profile: CreatorProfilePut, getToken: GetToken }, thunkApi) => {
    try {
      const token = (await data.getToken()) || '';
      const res = await creator.updateProfile(data.profile, token);
      return data.profile;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  },
);
