import { FormikHelpers, useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HighlightOff } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CancelRenewalSchema } from '../../../../../../../../shared/validation/creatorSettings/settingsSchemas';
import {
  BaseSecondaryButton,
  TextBlackLarge,
  PrimaryWhiteButton,
  FlexStartWrapper,
  MediumBlackText,
} from '../../../../../../../../shared/styles';
import {
  ProgressBar,
} from '../../../../../SignUp/styled';
import { BaseModalWindow } from '../../../../../../../../shared/components/BaseModalWindow/BaseModalWindow';
import {
  CancelRenewalButton,
  CancelRenewalCheckbox,
  CancelRenewalListItem,
  CancelRenewalTooltip,
  CancelRenewalWindowContainer,
  CancelTooltipTitle,
} from './styled';
import {
  FormikCustomProps,
  SetFieldValue,
} from '../../../../../../../../shared/types';
import { PinkLoader } from '../../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { PositionedLoader } from '../../../../../../../../shared/components/Loaders/PinkLoader/styled';
import { useTypedSelectorCreator } from '../../../../../../../../shared/hooks/useTypedSelector';
import { getFullName } from '../../../../../../../../shared/utils';
import { GroupRadioButtons } from '../../../../../../../../shared/components/FormElements/CommonRadioButton/CommonRadioButton';
import { CommonTextarea } from '../../../../../../../../shared/components/FormElements/CommonTextarea/CommonTextarea';
import { CommonCheckbox } from '../../../../../../../../shared/components/FormElements/CommonCheckbox/CommonCheckbox';
import { CancelRenewalEnum } from '../../../../../../types/types';
import { ErrorMessage } from '../../../../../../../../shared/components/styled';

type CancelRenewalData = {
  cause: string;
  causeText: string;
  isAgreed: boolean;
};

type FirstStepProps = {
  setFieldValue: SetFieldValue;
  onClose: () => void;
} & FormikCustomProps<CancelRenewalData>;
type SecondStepProps = {
  onClose: () => void;
  setFieldValue: SetFieldValue;
} & FormikCustomProps<CancelRenewalData>;

export const CancelRenewalFirstStep: React.FC<FirstStepProps> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  setFieldValue,
  onClose,
}) => {
  const { t } = useTranslation('settings');
  const user = useTypedSelectorCreator((state) => state.creator.currentUser);
  const fullName = getFullName(user?.firstName, user?.lastName);

  return (
    <>
      <TextBlackLarge
        fontWeight={600}
        style={{ marginBottom: '20px', textAlign: 'center' }}
      >
        <p>
          <Trans i18nKey="wantToCancelSub" t={t}>
            {{ fullName }}
            , we`re sorry to see you go.
            <br />
            Why do you want to cancel?
          </Trans>
        </p>
      </TextBlackLarge>
      {errors.cause && <ErrorMessage>{errors.cause}</ErrorMessage>}
      <div>
        <GroupRadioButtons
          setFieldValue={setFieldValue}
          radioButtons={[
            {
              value: CancelRenewalEnum.noProjects,
              label: "I don't have any upcoming projects",
            },
            {
              value: CancelRenewalEnum.notUseEnough,
              label: "I don't use enough",
            },
            {
              value: CancelRenewalEnum.difPlatform,
              label: "I'm subscribed to a different platform",
            },
            {
              value: CancelRenewalEnum.uncomfortable,
              label: 'I find delivrable uncomfortable to use',
            },
            {
              value: CancelRenewalEnum.cannotAfford,
              label: "I can't afford it",
            },
            {
              value: CancelRenewalEnum.other,
              label: 'Other (please specify)',
            },
          ]}
          name="cause"
          value={values.cause}
          handleChange={handleChange}
        />
      </div>
      <div style={{ marginTop: '10px', width: '100%' }}>
        <CommonTextarea
          isFullWidth
          error={errors.causeText}
          value={values.causeText}
          touched={touched.causeText}
          name="causeText"
          handleChange={handleChange}
          disabled={values.cause !== CancelRenewalEnum.other}
          placeholder={t('cancelRenewalPlacenolder')}
        />
      </div>
      <FlexStartWrapper>
        <PrimaryWhiteButton color="pink" onClick={onClose}>
          {t('backToAccount')}
        </PrimaryWhiteButton>
        <CancelRenewalButton
          type="submit"
          color="grey"
        >
          {t('nextButton')}
        </CancelRenewalButton>
      </FlexStartWrapper>
    </>
  );
};

export const CancelRenewalSecondStep: React.FC<SecondStepProps> = ({
  onClose,
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  setFieldValue,
}) => {
  const { t } = useTranslation('settings');
  const user = useTypedSelectorCreator((state) => state.creator.currentUser);
  const fullName = getFullName(user?.firstName, user?.lastName);

  const isLoading = false;

  return (
    <>
      <TextBlackLarge
        fontWeight={600}
        style={{
          marginBottom: '20px',
          textAlign: 'center',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <p>
          <Trans i18nKey="wantToCancelSubSecond" t={t}>
            {{ fullName }}
            , are you sure you
            <br />
            want to cancel?
          </Trans>
        </p>
      </TextBlackLarge>
      <CancelRenewalTooltip>
        <CancelTooltipTitle fontWeight={600}>
          <ErrorOutlineIcon />
          {t('warningTitle')}
        </CancelTooltipTitle>
        {t('warningText')}
      </CancelRenewalTooltip>
      <div>
        <MediumBlackText fontWeight={600} style={{ marginBottom: '8px' }}>
          {t('ifCancel')}
        </MediumBlackText>
        <div>
          {[
            'You will lose your delivrable founding members discount of 20% of ALL future annual subscription',
            'Any clients that have access to galleries will no longer be able to access them',
            'You will be downgraded to a free account on your next billing date',
          ].map((item) => (
            <CancelRenewalListItem key={item}>
              <HighlightOff />
              <MediumBlackText>{item}</MediumBlackText>
            </CancelRenewalListItem>
          ))}
        </div>
        <CancelRenewalCheckbox>
          {errors.isAgreed && <ErrorMessage>{errors.isAgreed}</ErrorMessage>}
          <CommonCheckbox
            label={(
              <MediumBlackText>
                {t('agreement')}
              </MediumBlackText>
            )}
            isChecked={values.isAgreed}
            name="isAgreed"
            handleChange={handleChange}
          />
        </CancelRenewalCheckbox>
      </div>
      <FlexStartWrapper>
        <PrimaryWhiteButton
          onClick={onClose}
          disabled={isLoading}
          style={{ color: '#F180B2' }}
        >
          {t('keepSubscription')}
        </PrimaryWhiteButton>
        <CancelRenewalButton
          type="submit"
          disabled={isLoading}
          style={{ position: 'relative' }}
        >
          {t('yesCancel')}
          {isLoading && (
            <PositionedLoader>
              <PinkLoader />
            </PositionedLoader>
          )}
        </CancelRenewalButton>
      </FlexStartWrapper>
    </>
  );
};

export const CancelRenewalWindow: React.FC = () => {
  const [step, setStep] = useState(1);
  const { t } = useTranslation('settings');
  const [isOpen, setIsOpen] = useState(false);
  const schema = useMemo(() => CancelRenewalSchema(step), [step]);

  const onSubmit = (
    data: CancelRenewalData,
    bag: FormikHelpers<CancelRenewalData>,
  ) => {
    bag.setSubmitting(false);

    if (step === 2) {
      bag.resetForm();
      setIsOpen(false);
    } else {
      setStep((prev) => prev + 1);
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    resetForm,
  } = useFormik<CancelRenewalData>({
    initialValues: {
      cause: '',
      causeText: '',
      isAgreed: false,
    },
    validationSchema: schema,
    onSubmit: (data, bag) => onSubmit(data, bag),
  });

  const onClose = () => {
    setIsOpen(false);
    resetForm();
    setStep(1);
  };

  return (
    <>
      <BaseSecondaryButton
        fontWeight={600}
        type="button"
        onClick={() => setIsOpen(true)}
        color="black"
      >
        {t('cancelRenewal')}
      </BaseSecondaryButton>
      <BaseModalWindow
        isOpen={isOpen}
        handleClose={onClose}
        fullWidth
        maxWidth="sm"
        transparentBackground
      >
        <CancelRenewalWindowContainer>
          <ProgressBar theme={50 * step}>
            <div />
          </ProgressBar>
          <form
            onSubmit={handleSubmit}
            noValidate
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {step === 1 ? (
              <CancelRenewalFirstStep
                errors={errors}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                setFieldValue={setFieldValue}
                onClose={onClose}
              />
            ) : (
              <CancelRenewalSecondStep
                onClose={onClose}
                setFieldValue={setFieldValue}
                errors={errors}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
              />
            )}
          </form>
        </CancelRenewalWindowContainer>
      </BaseModalWindow>
    </>
  );
};
