import { createAsyncThunk } from '@reduxjs/toolkit';
import { creatorSignIn } from '../../../../../api/creator/creatorAuth';
import { ResetPassword } from '../../../../../shared/types';
import { getSignUpErrors } from '../../../../../shared/utils/errors/errorHandlers';

export const fetchResetPasswordInitialCreator = createAsyncThunk(
  'signInCreator/fetchResetPasswordInitialCreator',
  async (email: string, thunkApi) => {
    try {
      const response = await creatorSignIn.resetPasswordInitial(email);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  },
);

export const fetchResetPasswordCreator = createAsyncThunk(
  'signInCreator/fetchResetPasswordCreator',
  async (data: ResetPassword, thunkApi) => {
    try {
      const response = await creatorSignIn.resetPassword(data);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(getSignUpErrors(error));
    }
  },
);
