import styled from '@emotion/styled';
import * as variables from '../../../constants/styles';

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 140%;
  white-space: pre;

  & svg {
    color: ${variables.colorPink};
    margin-right: 8px;
  }
`;
