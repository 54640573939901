import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BaseSecondaryButton, MediumBlackText, MediumGreyText, SimpleBlackText,
} from '../../../../../../../shared/styles';
import { MarginTopWrapper, TitleMgB } from '../styled';
import { RecentBillingHistoryRow } from './RecentBillingHistoryRow';
import { ButtonMgT } from './styled';
import { useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';
import { ErrorMessage } from '../../../../../../../shared/components/styled';
import { PinkLoader } from '../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';

export const RecentBillingHistory: React.FC = () => {
  const { t } = useTranslation('settings');
  const billingHistory = useTypedSelectorCreator(
    (state) => state.billingInfo.billingInfo.billingHistory,
  );
  const isLoading = useTypedSelectorCreator((state) => state.billingInfo.loading);
  const error = useTypedSelectorCreator((state) => state.billingInfo.error);

  return (
    <MarginTopWrapper>
      <TitleMgB><SimpleBlackText>{t('recentBillingHistory')}</SimpleBlackText></TitleMgB>
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
      <Grid container spacing={2}>
        <Grid container item sm={12}>
          <Grid item sm={3}>
            <MediumBlackText fontWeight={500}>{t('date')}</MediumBlackText>
          </Grid>
          <Grid item sm={3}>
            <MediumBlackText fontWeight={500}>{t('sum')}</MediumBlackText>
          </Grid>
          <Grid item sm={3}>
            <MediumBlackText fontWeight={500}>{t('status')}</MediumBlackText>
          </Grid>
          <Grid item sm={3}>
            <MediumBlackText fontWeight={500}>
              {t('document')}
            </MediumBlackText>
          </Grid>
        </Grid>
        {isLoading ? <PinkLoader />
          : billingHistory.map((item) => (
            <RecentBillingHistoryRow key={item.id} row={item} />
          ))}
      </Grid>
      {!billingHistory.length && !error && !isLoading
        ? <MediumGreyText fontWeight={400} style={{ marginTop: '16px' }}>No recent payments</MediumGreyText>
        : null}
      {billingHistory.length > 5 && (
        <ButtonMgT>
          <BaseSecondaryButton color="secondary" type="button" onClick={() => console.log('show more')}>
            {t('showMore')}
          </BaseSecondaryButton>
        </ButtonMgT>
      )}
    </MarginTopWrapper>
  );
};
