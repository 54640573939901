import styled from '@emotion/styled';

export const WatermarkWrapper = styled.div`
  margin-bottom: 60px;
`;

export const SettingsWrapper = styled.div`
  margin-top: 60px;
`;

export const RowsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const MarginTopWrapper = styled.div`
  margin-top: 60px;
`;

export const TitleMgB = styled.div`
  margin-bottom: 30px;
`;

export const TextMgB = styled.div`
  margin-bottom: 16px;
`;

export const TabPanelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 213px;
`;

export const TabPanelColumnWrapper = styled(TabPanelWrapper)`
  flex-direction: column;
`;
