import { useAuth } from '@clerk/clerk-react';
import { ErrorType } from '../../../../../../../shared/types';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';
import { fetchDeleteCreatorGallery } from '../../../../../redux/gallery/galleryCreatorThunk';

type ReturnObj = {
  deleteGallery: (id: string) => void;
  isLoading: boolean;
  isSuccess: boolean;
  error: ErrorType;
};

export const useDeleteGallery = (): ReturnObj => {
  const dispatch = useCreatorAppDispatch();
  const isSuccess = useTypedSelectorCreator((state) => state.gallery.isSuccessDelete);
  const isLoading = useTypedSelectorCreator((state) => state.gallery.loadingDelete);
  const error = useTypedSelectorCreator((state) => state.gallery.error);
  const { getToken } = useAuth();

  const deleteGallery = async (id: string) => {
    dispatch(fetchDeleteCreatorGallery({ id, getToken }));
  };

  return ({
    deleteGallery,
    isLoading,
    isSuccess,
    error,
  });
};
