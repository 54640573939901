import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type OnboardingState = {
  isStudy: boolean;
  stepIndex: number;
  stepTarget: string;
}

const initialState: OnboardingState = { stepIndex: 0, stepTarget: '', isStudy: false };

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setStudyStep(state, action: PayloadAction<{stepIndex: number, stepTarget: string}>) {
      state.stepIndex = action.payload.stepIndex;
      state.stepTarget = action.payload.stepTarget;
    },
    startStudy(state, action: PayloadAction<{isStudy: boolean}>) {
      state.isStudy = action.payload.isStudy;
    },
  },
});

export const { setStudyStep, startStudy } = onboardingSlice.actions;
export default onboardingSlice.reducer;
