import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@clerk/clerk-react';
import { PinkLoader } from '../../../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../../../../shared/hooks/useTypedSelector';
import { SimpleBlackText } from '../../../../../../../../../shared/styles';
import { fetchGetCreatorComments } from '../../../../../../../redux/gallery/comments/commentsCreatorThunk';
import { CommentsItem } from './DashboardCommentItem';
import { CommentsOrIdeasListWrapper } from './styled';

type CommentsTab = {
  galleryId: string;
}

export const CommentsTab: React.FC<CommentsTab> = ({
  galleryId,
}) => {
  const { t } = useTranslation('gallery');
  const dispatch = useCreatorAppDispatch();
  const comments = useTypedSelectorCreator((state) => state.comments.comments);
  const isLoading = useTypedSelectorCreator((state) => state.comments.loadingGet);
  const { getToken } = useAuth();

  useEffect(() => {
    dispatch(fetchGetCreatorComments({ galleryId, getToken }));
  }, []);

  if (isLoading) {
    return (
      <CommentsOrIdeasListWrapper style={{
        width: 'calc(100% - 10px)', display: 'flex', alignItems: 'center', justifyContent: 'center',
      }}
      >
        <PinkLoader />
      </CommentsOrIdeasListWrapper>
    );
  }

  return (
    <CommentsOrIdeasListWrapper>
      {comments?.length ? (
        comments.map((item) => (
          <CommentsItem
            galleryId={galleryId}
            key={item.id}
            comment={item}
          />
        ))
      ) : (
        <SimpleBlackText>{t('noComments')}</SimpleBlackText>
      )}
    </CommentsOrIdeasListWrapper>
  );
};
