import styled from '@emotion/styled';
import * as variables from '../../../../../../../../constants/styles';

export const DashboardHeaderWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;

  & svg {
    color: ${variables.colorBlack};
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: -25px;
  top: -35px;
`;

export const DashboardHeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DashboardHeaderButton = styled.button`
  display: flex;
  position: relative;
  padding: 0px;
  margin-left: 10px;
  transition: 0s opacity;
`;

export const DashboardHeaderBtnWrapper = styled.div`
  position: relative;

  &:hover {
    & > #tooltip {
      margin-left: 10px;

      transition-property: opacity;
      transition-delay: 0.5s;
      opacity: 1;
    }
  }
`;
