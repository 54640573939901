import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetSelectionItems } from '../../../../../shared/hooks/useGetSelectionItems';
import { Occupation } from '../../../../../shared/types';

type SelectOccupation = {
  id: Occupation;
  value: string;
};

type ReturnObj = {
  items: SelectOccupation[],
  menuItems: ReactElement[];
}

export const useGetWhatDoYouDoMenuItems = (): ReturnObj => {
  const { t } = useTranslation('signUp');
  const getSelectionItems = useGetSelectionItems();

  const items: SelectOccupation[] = [
    { id: 'photographer', value: t('whatDoYouDo.photographer') },
    { id: 'videographer', value: t('whatDoYouDo.videographer') },
    { id: 'filmmaker', value: t('whatDoYouDo.filmmaker') },
    { id: 'UGCCreator', value: t('whatDoYouDo.UGCCreator') },
    { id: 'agencyOwner', value: t('whatDoYouDo.agencyOwner') },
  ];

  return ({
    items,
    menuItems: getSelectionItems(items),
  });
};
