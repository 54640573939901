import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetToken } from '@clerk/types';
import { sales } from '../../../../../api/sales/sales';

export const fetchGetGallerySales = createAsyncThunk(
  'gallerySales/fetchGetGallerySales',
  async ({ galleryId, getToken }: {galleryId: string, getToken: GetToken}, thunkApi) => {
    try {
      const token = (await getToken() || '');
      const response = await sales.getSales(galleryId, token);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
