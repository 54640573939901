import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { fakeNotificationsRequests } from '../../../../api/notifications/fakeNotificationsRequests';
import { pageSizeNotifications } from '../../../../constants/pagination';
import { Pagination } from '../../../../shared/types';

export const fetchGetNotifications = createAsyncThunk(
  'notifications/fetchGetNotifications',
  async (pagination: Pagination) => {
    try {
      pagination.pageSize = pagination.pageSize ? pagination.pageSize : pageSizeNotifications;
      const response = await fakeNotificationsRequests.getNotifications(pagination);
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  },
);

export const fetchSetNotificationsRead = createAsyncThunk(
  'notifications/fetchSetNotificationsRead',
  async (notReadIds: string[]) => {
    try {
      const response = await fakeNotificationsRequests.setNotificationsRead(notReadIds);
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  },
);

export const fetchGetNotReadNotifications = createAsyncThunk(
  'notifications/fetchGetNotReadNotifications',
  async () => {
    try {
      const response = await fakeNotificationsRequests.getNotReadNotifications();
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  },
);

export const setEmptyArrNotifications = createAction('setEmptyArrNotifications');
