import { GalleryFile, GalleryIdea } from '../../../../../../../shared/types';
import { isArrayIncludeValue, isVideo } from '../../../../../../../shared/utils';

export const isItemWithAppliedSettings = (
  item: GalleryFile | GalleryIdea,
): boolean => Object.keys(item).includes('watermark' || 'allowCommenting');

export const onlyVideosWereSelected = (files: GalleryFile[], selected: string[]): boolean => {
  const selectedFiles = files.filter((item) => isArrayIncludeValue(selected, item.id));
  return !selectedFiles.find((item) => !isVideo(item));
};
