import { CreatorProfileGet, CreatorForm, PaymentMethod } from '../../shared/types';
import { timezonesArr } from '../selectors/timezones';

const getTimezoneValue = (timezone?: string): string => {
  if (!timezone) {
    return timezonesArr.find((item) => item.value.includes('Los Angeles'))?.id || '';
  }
  const tz = timezonesArr.find((item) => item.id === timezone);
  return tz?.id || '';
};

export const creatorInitialValues = (data: CreatorProfileGet | null): CreatorForm => ({
  firstName: data?.firstName || '',
  lastName: data?.lastName || '',
  businessName: data?.businessName || '',
  timezone: getTimezoneValue(data?.timezone),
  dateFormat: data?.dateFormat || 'mm/dd/yyyy',
  facebook: data?.settings?.facebook || '',
  instagram: data?.settings?.instagram || '',
  youtube: data?.settings?.youtube || '',
  vimeo: data?.settings?.vimeo || '',
  linkedIn: data?.settings?.linkedIn || '',
  pinterest: data?.settings?.pinterest || '',
  // plan: data?.plan || '',
  branding: data?.branding || false,
  nameOnInvoice: data?.settings?.nameOnInvoice || '',
  taxNumber: data?.settings?.taxNumber || '',
  watermark: data?.settings?.watermark || '',
  paymentCurrency: data?.settings?.paymentCurrency || '',
  downloadsCart: data?.settings?.downloadsCart || false,
  downloadsItem: data?.settings?.downloadsItem || false,
  createsFavourites: data?.settings?.createsFavourites || false,
  leavesComment: data?.settings?.leavesComment || false,
  purchasesItem: data?.settings?.purchasesItem || false,
  purchasesIdea: data?.settings?.purchasesIdea || false,
  solvedIdeaReminder: data?.settings?.solvedIdeaReminder || false,
  inviteFailed: data?.settings?.inviteFailed || false,
  storageFull: data?.settings?.storageFull || false,
  activeGalleryAboutExpiration: data?.settings?.activeGalleryAboutExpiration || false,
  newFeatures: data?.settings?.newFeatures || false,
  salesAndActivityReport: data?.settings?.salesAndActivityReport || false,
  receivesReward: data?.settings?.receivesReward || false,
  profileImage: data?.profileImage || '',
  storeCurrency: data?.settings?.storeCurrency || '',
  paymentsCollector: data?.settings?.paymentsCollector || '',
  paypalEmail: data?.settings?.paypalEmail || '',
});

export const emailNotificationsAndBrandingValues = {
  downloadCart: false,
  downloadSingleDelivrable: false,
  createNewFavouritesList: false,
  leaveComment: false,
  makePurchase: false,
  purchaseIdea: false,
  reminderIdea: false,
  emailInvite: false,
  accountStorageIsFull: false,
  expireAlert: false,
  newFeature: false,
  monthlySales: false,
  receiveReferralReward: false,
  removeBranding: false,
};

export const updatePaymentMethodValues = (data: PaymentMethod | null): PaymentMethod => ({
  cardHolderName: data?.cardHolderName || '',
  cardNumber: data?.cardNumber || '',
  expiration: data?.expiration || '',
  cvv: data?.cvv || '',
});
