import styled from '@emotion/styled';
import * as variables from '../../../../../constants/styles';
import { theme } from '../../../../../shared/components/GlobalContainer/styled';

export const GalleryFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  padding-top: 40px;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 0px 16px;
  }
`;

export const GalleryFooterV2Wrapper = styled.div`
  box-sizing: border-box;
  padding: 40px 152px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid rgba(235, 235, 235, 0.8);
  margin-top: 40px;
  filter: drop-shadow(0px -5px 15px #fff);
  box-shadow: 0px -5px 15px #fff;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    padding: 64px 70px 24px;
  }

  @media (max-width: 650px) {
    padding: 64px 30px 24px 40px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 53px 16px 36px 16px;
    max-height: fit-content;
  }
`;

export const GalleryFooterPoweredBy = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 21px;
  color: ${variables.textGrey};

  & > svg {
    width: 120px;
    margin-left: 8px;
  }
`;

export const GalleryFooterMediaWrp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  & * {
    margin-left: 5px;
    color: ${variables.textGrey};

    &:first-of-type {
      margin-right: 2px;
    }
  }

  & svg {
    width: 30px;
    height: 30px;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    & svg {
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    justify-content: center;
    margin-top: 80px;

    & svg {
      width: 32px;
      height: 32px;
    }
  }
`;
