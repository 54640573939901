import React from 'react';
import { useTranslation } from 'react-i18next';
import { TermsAndPrivacyListTitle, TermsAndPrivacyText } from '../styled';

export const AmendmentsPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <>
      <TermsAndPrivacyListTitle>
        {t('privacy.amendments')}
      </TermsAndPrivacyListTitle>
      <TermsAndPrivacyText>
        {t('privacy.amendmentsPoints')}
      </TermsAndPrivacyText>
    </>
  );
};
