import styled from '@emotion/styled';
import {
  MenuItemProps, Select, MenuItem, SelectProps, styled as MUIStyled,
} from '@mui/material';
import * as variables from '../../../../constants/styles';

export const StyledSelect = MUIStyled(Select)<SelectProps>(() => ({
  '& .MuiSelect-root': {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    color: variables.textGrey,
    fontFamily: variables.mainFont,
    margin: '0px',
    fontSize: '15px',
    width: '100%',
    padding: '0px',
    border: '0.5px solid #e0e0e0',
    height: '44px',
    boxSizing: 'border-box',
    boxShadow: 'inset -5px -5px 10px #fff, inset 2px 2px 5px #e7e7e7 !important',
    borderRadius: '163px',
    borderColor: '#e0e0e0',
  },
  '& .MuiInputBase-root': {
    '&.MuiSelect-select:focus': {
      backgroundColor: 'transparent',
      boxShadow: '2px 2px 5px 0px #E7E7E7 inset, -5px -5px 10px 0px #FFFFFF inset',
      borderRadius: '163px',
    },
    color: variables.textGrey,
    fontFamily: variables.mainFont,
    margin: '0px',
    fontSize: '15px',
    width: '100%',
    padding: '0px',
    border: '0.5px solid #e0e0e0',
    height: '44px',
    boxSizing: 'border-box',
    boxShadow: 'inset -5px -5px 10px #fff, inset 2px 2px 5px #e7e7e7',
    borderRadius: '163px',
    borderColor: '#e0e0e0',
  },
  '& .MuiSelect-standard': {
    '&.MuiSelect-select:focus': {
      backgroundColor: 'transparent',
      boxShadow: '2px 2px 5px 0px #E7E7E7 inset, -5px -5px 10px 0px #FFFFFF inset',
      borderRadius: '163px',
    },
    color: variables.textGrey,
    fontFamily: variables.mainFont,
    margin: '0px',
    fontSize: '15px',
    width: '100%',
    border: '0.5px solid #e0e0e0',
    height: '44px',
    boxSizing: 'border-box',
    boxShadow: 'inset -5px -5px 10px #fff, inset 2px 2px 5px #e7e7e7',
    borderRadius: '163px',
    borderColor: '#e0e0e0',
    padding: '12px 23px 12px 20px',
  },
}));

export const CustomSelect = styled(StyledSelect)`
& div {
  background: transparent !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
& svg {
  right: 14px;
}
& input {
  border: none;
  padding: 0px;
}
& select {
  & li {
    &:hover {
      width: calc(100% + 10px);
    }
  }
}
`;

export const SelectWithErrorMessage = styled.div`
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column; 

  & > span {
    padding-left: 24px;
  }
`;

export const SelectValueText = styled.p`
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  max-width: calc(100% - 13px);
`;

export const MenuItemText = styled.p`
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  max-width: 100%;
`;

export const CustomMenuItem = styled(MenuItem)<MenuItemProps>(() => ({
  '&.MuiMenuItem-root': {
    fontFamily: variables.mainFont,
    color: variables.textGrey,
    fontSize: '15px',
    lineHeight: '140%',
    borderRadius: '8px',
    padding: '6px 10px',
    transition: 'none',
    width: '100%',
  },
  '&.MuiButtonBase-root': {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  '&.Mui-selected': {
    backgroundColor: variables.colorBlueLight,
    color: variables.textBlack,
    transition: 'none',
    '&:hover': {
      backgroundColor: variables.colorBlueLight,
      color: variables.textBlack,
      transition: 'none',
    },
  },
  '&:hover': {
    backgroundColor: variables.colorBlueLight,
    color: variables.textBlack,
    transition: 'none',
  },
}));

export const PlaceholderWrapper = styled(MenuItem)`
  font-family: ${variables.mainFont} !important;
  color: ${variables.colorBleak} !important;
  display: none;
`;

export const PlaceholderText = styled.span`
  font-family: ${variables.mainFont};
  color: ${variables.colorBleak} !important;
  padding-right: 43px;
  max-width: 100%;
  width: 100%;
`;
