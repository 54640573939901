import styled from '@emotion/styled';
import * as variables from '../../../../constants/styles';
import { theme } from '../../../../shared/components/GlobalContainer/styled';

export const NavigationWrapper = styled.div`
  position: sticky;
  top: 100px;
  box-sizing: border-box;
  margin-right: 40px;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    display: none;
  }
`;

export const NavigationTitle = styled.div`
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${variables.colorBlack};
  margin-bottom: 3px;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    display: none;
  }
`;

export const SectionNavigationList = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;

  & > a,
  & > div,
  & > button {
    margin-top: 13px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    display: none;
  }
`;

export const SectionListWrapper = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 120px;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    &:not(:last-child) {
      margin-bottom: 80px;
    }  
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    &:not(:last-child) {
      margin-bottom: 0px;
    }

    &:not(:first-of-type) {
      margin-top: 50px;
    }
  }
`;

export const DocumentsListWrapper = styled.div`
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(81px, max-content));

  & div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;  
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    column-gap: 8px;
    row-gap: 8px;
  }
`;

export const FileAndElementsWrapper = styled.div<{fullWidth: boolean, areButtonsVisible: boolean}>`
  position: relative;
  transition: all 0.2s ease-in-out;
  display: flex;

  & > div > button {
    display: none;
    transition: 0.2s;
  }

  ${({ areButtonsVisible }) => (areButtonsVisible ? `
    & #cover-gradient {
      opacity: 1;
    }
  `
    : '')}

  @media (min-width: ${theme.breakpoints.values.lg}px) {
    &:hover {
      & > div > button {
        display: flex;
        transition: 0.2s;
      }
      & > #cover-gradient {
        opacity: 1;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  & video {
    width: 100%;
    height: auto;
  }
`;

export const SectionsListWrapper = styled.div`
  margin-bottom: 108px;
  box-sizing: border-box;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin-top: 130px;
    padding-right: 0px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-top: 0px;
    padding-right: 0px;
    margin-bottom: 80px;
  }
`;

export const GalleryContentWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-top: 64px;
  }
`;
