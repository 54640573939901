import styled from '@emotion/styled';
import { DialogActions, DialogContentText, DialogTitle } from '@material-ui/core';
import * as variables from '../../../constants/styles';
import { RowWrapper } from '../../styles';
import { AlertTypes } from '../../types';
import { theme } from '../GlobalContainer/styled';

export const getAlertStyles = (type: AlertTypes) => {
  const borderStyle = '0.5px solid';
  switch (type) {
    case 'error':
      return ({ border: `${borderStyle} ${variables.colorError}` });
    case 'warning':
      return ({ border: `${borderStyle} ${variables.colorOrange}` });
    default:
      return ({ border: `${borderStyle} #eoeoeo` });
  }
};

export const CustomTitleDialog = styled(DialogTitle)<{confirmation: boolean}>`
  & h2 {
    font-weight: normal;
    font-family: ${variables.mainFont} !important;
    font-style: normal;
    font-size: 22px;
    line-height: 33px;
    color: ${(props) => (props.confirmation ? variables.textBlack : variables.colorOrange)};
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 16px 24px 6px;

    & h2 {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;

export const CustomDialogContentText = styled(DialogContentText)`
  font-weight: normal;
  font-family: ${variables.mainFont} !important;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: ${variables.textBlack} !important;
`;
export const CustomDialogActions = styled(DialogActions)`
  justify-content: flex-start !important;
`;
export const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;

  & svg {
    color: ${variables.textBlack};
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    & svg {
      width: 22px;
      height: 22px;
    }
  }
`;
export const ButtonsWrapper = styled(RowWrapper)`
  padding: 0 16px;
  display: flex;
  flex-direction: row;

  & * {
    margin-right: 40px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 0px 0px 0px 16px;

    & * {
      margin-right: 30px;

      &:last-child {
        margin: 0;
      }
    }
  }
`;
