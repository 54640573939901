import styled from '@emotion/styled';
import { theme } from '../../../shared/components/GlobalContainer/styled';

export const ContentWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 60px;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin-top: 0px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    box-sizing: border-box;
    padding: 0px 16px;
    margin-bottom: 72px;
  }
`;

export const ClientWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`;
