import React from 'react';

export const PointingRightIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.79369 10.2972C6.65556 8.49345 6.84494 8.28908 8.27556 7.78283C9.65181 7.2972 12.2974 5.69596 13.0812 5.24596C13.7412 4.86721 14.2699 4.75471 14.8812 5.03596C15.2824 5.21971 15.7887 5.66596 15.8018 6.36346C15.8112 6.81908 15.7231 7.45846 14.4687 8.35471C14.3299 8.45408 14.3806 8.67158 14.5474 8.69783C14.6093 8.70721 14.6824 8.71658 14.7724 8.72221C15.4306 8.76908 20.5062 8.72221 21.7756 8.86283C23.0449 9.00346 23.2493 9.22658 23.5099 9.68033C23.6993 10.0103 23.6824 10.8466 23.2587 11.2703C22.8124 11.7166 21.3031 11.7335 15.7156 11.8235C14.9168 11.8235 14.7368 12.0072 14.9956 12.3953C15.1793 12.671 15.2356 13.3553 15.0106 13.751C14.8099 14.1016 14.6093 14.3303 14.6881 14.6622C14.7987 15.1197 14.8662 15.9335 14.4406 16.3966C14.1912 16.6685 14.0974 16.9947 14.1068 17.4653C14.1162 17.936 13.8762 19.0141 12.4212 19.016C10.6662 19.0178 8.81556 19.2747 7.32119 18.8416C6.47931 18.5978 5.84556 18.3766 4.96056 18.3766C4.23119 18.3766 3.48681 18.4347 2.64119 18.4347C1.45056 18.4347 0.721188 18.2641 0.533688 16.901C0.346188 15.5378 0.299313 12.6241 0.533688 11.591C0.768063 10.5578 1.47681 10.6197 2.08056 10.6197C3.32744 10.6197 4.33994 10.7378 4.79369 10.2972Z"
      fill="#FAC036"
    />
  </svg>
);

export const QuoteIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 21C5.93913 21 4.92172 20.5786 4.17157 19.8284C3.42143 19.0783 3 18.0609 3 17C3 15.527 4.333 10.86 7 3H9L7 13C8.06087 13 9.07828 13.4214 9.82843 14.1716C10.5786 14.9217 11 15.9391 11 17C11 18.0609 10.5786 19.0783 9.82843 19.8284C9.07828 20.5786 8.06087 21 7 21ZM17 21C15.9391 21 14.9217 20.5786 14.1716 19.8284C13.4214 19.0783 13 18.0609 13 17C13 15.527 14.333 10.86 17 3H19L17 13C18.0609 13 19.0783 13.4214 19.8284 14.1716C20.5786 14.9217 21 15.9391 21 17C21 18.0609 20.5786 19.0783 19.8284 19.8284C19.0783 20.5786 18.0609 21 17 21Z"
      fill="black"
    />
  </svg>
);

export const TextHeartIcon: React.FC = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0838 2.17285H12.0588C11.9976 2.17285 11.9351 2.17285 11.8738 2.17535C11.2263 2.1866 10.5976 2.33035 10.0038 2.5791C9.36758 2.84785 8.93133 3.24785 8.36508 3.60785C8.25883 3.67535 8.12883 3.7091 8.00008 3.7091C7.87008 3.7091 7.74008 3.67535 7.63383 3.60785C7.06883 3.24785 6.63133 2.84785 5.99508 2.5791C5.40133 2.33035 4.77258 2.1866 4.12508 2.17535C4.06383 2.17285 4.00258 2.17285 3.94133 2.17285H3.91633C2.27633 2.17285 1.08008 3.11535 0.378827 4.7541C0.0788275 5.4516 0.0738275 6.5016 0.196328 7.2341C0.318828 7.9641 0.622577 8.62285 1.03633 9.23785C2.41508 11.2916 4.87508 12.2441 6.64883 13.8991C6.83758 14.0754 7.02508 14.2666 7.21758 14.4429C7.42258 14.6316 7.67008 15.0666 8.00008 15.0866C8.32883 15.0666 8.57758 14.6329 8.78258 14.4429C8.97508 14.2654 9.16133 14.0754 9.35133 13.8991C11.1251 12.2441 13.5838 11.2916 14.9626 9.2391C15.3763 8.6241 15.6801 7.96535 15.8026 7.23535C15.9251 6.50285 15.9201 5.4516 15.6201 4.75535C14.9188 3.11535 13.7238 2.17285 12.0838 2.17285Z"
      fill="#B4BBC0"
    />
  </svg>
);

export const MailIcon: React.FC = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.125 3.625H1.875C1.5293 3.625 1.25 3.9043 1.25 4.25V16.75C1.25 17.0957 1.5293 17.375 1.875 17.375H18.125C18.4707 17.375 18.75 17.0957 18.75 16.75V4.25C18.75 3.9043 18.4707 3.625 18.125 3.625ZM17.3438 5.78906V15.9687H2.65625V5.78906L2.11719 5.36914L2.88477 4.38281L3.7207 5.0332H16.2813L17.1172 4.38281L17.8848 5.36914L17.3438 5.78906ZM16.2813 5.03125L10 9.91406L3.71875 5.03125L2.88281 4.38086L2.11523 5.36719L2.6543 5.78711L9.32617 10.9746C9.51805 11.1237 9.7541 11.2046 9.99707 11.2046C10.24 11.2046 10.4761 11.1237 10.668 10.9746L17.3438 5.78906L17.8828 5.36914L17.1152 4.38281L16.2813 5.03125Z"
      fill="#F180B2"
    />
  </svg>
);
