import styled from '@emotion/styled';
import { theme } from '../../../../shared/components/GlobalContainer/styled';
import { FormBase, MediumGreyText, WhiteSignCard } from '../../../../shared/styles';

export const SignInClientWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 40px 114px;
  background: transparent;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    padding: 60px 40px 60px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 0;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 13px;
  width: 100%;
  height: 100%;
`;

export const TextsWrapper = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    & h1 {
      margin-bottom: 10px;
    }
  }
`;

export const MediumGreyTextMgB = styled(MediumGreyText)`
  margin-bottom: 10px;
  margin-top: -8px;
`;

export const FormBaseClient = styled(FormBase)`
  margin-top: 33px;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-top: 25px;
  }
`;

export const WhiteSignCardWithMedia = styled(WhiteSignCard)`
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin-top: 50px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    box-shadow: none;
    margin-top: 50px;
    padding: 0px 16px;
  }
`;
