import styled from '@emotion/styled';
import * as variables from '../../../../../../../../constants/styles';

const getTextColor = (type: 'error' | 'text') => {
  switch (type) {
    case 'error':
      return variables.colorError;
    case 'text':
      return variables.textGrey;
    default:
      return variables.textGrey;
  }
};

const getFontSize = (size: 'sm' | 'lg') => {
  switch (size) {
    case 'sm':
      return '8px';
    case 'lg':
      return '12px';
    default:
      return '12px';
  }
};

const getPadding = (size: 'sm' | 'lg') => {
  switch (size) {
    case 'sm':
      return '10px';
    case 'lg':
      return '20px';
    default:
      return '20px';
  }
};

export const ErrorTryAgainContainer = styled.div<{ size: 'sm' | 'lg' }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: ${(props) => getPadding(props.size)};
  background: ${({ size }) => (size === 'sm' ? 'transparent' : '#F0F1F2')};
  z-index: 15;
  border: 1px solid ${variables.colorError};
`;

export const ErrorTryAgainText = styled.div<{ type: 'error' | 'text', size: 'sm' | 'lg' }>`
  font-size: ${(props) => getFontSize(props.size)};
  color: ${(props) => getTextColor(props.type)};
  word-break: break-all;
  text-align: center;

  display: flex;
  display: -moz-box;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  margin: 0;
`;

export const TryAgainButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8px;

  & svg {
    margin-top: 7px;
  }
`;

export const DeleteButton = styled.button<{ size: 'sm' | 'lg' }>`
  & svg {
    ${({ size }) => (size === 'sm' ? `color: ${variables.colorGreyLight}` : '')};
  }
`;
