import { BetaPlan, Plan, PlanEnum } from '../shared/types';

export const plans: Plan[] = [
  {
    storage: 53687091200,
    planType: PlanEnum.Beta,
    current: true,
    priceMonthly: 0,
    priceAnnually: 119,
    description: 'Everything you need to try out Delivrable and earn more from your client projects.',
    details: [
      '50GB storage',
      '3 gallery designs',
      '0% commission on your sales',
      '20% off all future annual subscription',
    ],
  },
  {
    storage: 2147483648,
    planType: PlanEnum.Free,
    current: false,
    priceMonthly: 0,
    priceAnnually: 0,
    description: 'Everything you need to try out Delivrable and earn more from your client projects.',
    details: [
      '3 gallery designs',
      '2GB storage',
      '10% commission on your sales',
      '$0 for 14 days',
    ],
  },
  {
    storage: 2147483648,
    planType: PlanEnum.Basic,
    current: true,
    priceMonthly: 0,
    priceAnnually: 0,
    description: 'Everything you need to try out Delivrable and earn more from your client projects.',
    details: [
      '3 gallery designs',
      '2GB storage',
      '10% commission on your sales',
      '$0 for 14 days',
    ],
  },
  {
    storage: 21474836480,
    planType: PlanEnum.Pro,
    current: false,
    priceMonthly: 60,
    priceAnnually: 290,
    description: 'The full Delivrable experience - 10x more storage, extremely low commissions, and premium features.',
    details: [
      '5 gallery designs',
      '20GB storage',
      '1% commission on your sales',
      'Testimonial call-to-action at gallery footer',
      '2 user seats for an extra team member',
      'Remove Delivrable branding',
      'Auto-alert emails to clients',
    ],
  },
  {
    storage: 42949672960,
    planType: PlanEnum.ProPlus,
    current: false,
    priceMonthly: 39,
    priceAnnually: 390,
    description: 'Everything in Premium + an extra 20GB of storage and no commissions.',
    details: [
      '7 gallery designs',
      '40GB storage',
      'Testimonial call-to-action at gallery footer',
      'Up to 5 user seats for an extra team member',
      'Remove Delivrable branding',
      'Auto-alert emails to clients',
      'Premium + support',
    ],
  },
];

export const betaPlans: BetaPlan[] = [
  {
    planType: PlanEnum.Beta,
    price: 119,
    discount: 0,
    code: 'FIRST100',
    storage: 53687091200,
    details: [
      { main: '50GB storage' },
      { main: '3 gallery designs' },
      { main: '0% commission on your sales' },
      { main: '20% off all future annual subscription' },
    ],
  },
  {
    planType: PlanEnum.Basic,
    price: 297,
    discount: 100,
    code: 'FIRST100',
    storage: 107374182400,
    details: [
      { main: '100GB storage' },
      { main: 'Unlimited galleries' },
      { main: 'Sell digital downloads' },
      { main: '0% commission on gallery sales' },
      { main: 'Set automated gallery expiry reminder email' },
    ],
  },
  {
    planType: PlanEnum.Pro,
    price: 397,
    discount: 100,
    code: 'FIRST100',
    storage: 1073741824000,
    details: [
      { main: '1000GB storage' },
      { main: 'Unlimited galleries' },
      { main: 'Sell digital downloads' },
      { main: '0% commission on gallery sales' },
      { main: 'Set automated gallery expiry reminder email' },
      { main: 'Content licensing' },
    ],
  },
  {
    planType: PlanEnum.ProPlus,
    price: 497,
    discount: 100,
    code: 'FIRST100',
    storage: 10995116277760,
    details: [
      { main: '10TB storage' },
      { main: 'Unlimited galleries' },
      { main: 'Sell digital downloads' },
      { main: '0% commission on gallery sales' },
      { main: 'Set automated gallery expiry reminder email + more' },
      { main: 'Content licensing' },
      { main: 'Multiple brands', extra: '2 user seats + $17/mo for ea. additional brand' },
    ],
  },
];
