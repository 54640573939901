import styled from '@emotion/styled';
import * as variables from '../../../constants/styles';

export const ListItemWithIconWrp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 16px;

  & > svg {
    margin-right: 10px;
    margin-top: 6px;
  }
`;

export const TickWrapper = styled.div`
  display: flex;

  & > svg {
    margin-right: 10px;
    margin-top: 6px;
    min-width: 11px;
    min-height: 9px;
    width: 11px;
    height: 9px;
    color: ${variables.colorPink};
  }
`;
