import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetToken } from '@clerk/types';
import { creatorFavourites } from '../../../../../api/favourites/favourites';

export const fetchGetCreatorFavourites = createAsyncThunk(
  'favouritesCreator/fetchGetCreatorFavourites',
  async ({ galleryId, getToken }: { galleryId: string, getToken: GetToken }, thunkApi) => {
    try {
      const token = (await getToken()) || '';
      const response = await creatorFavourites.getFavourites(galleryId, token);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
