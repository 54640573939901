import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const AustralianLawPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={15}
      listTitle={t('terms.australianLaw')}
      pointsNameForTrans="australianLawPoints"
    >
      <LandingNumberedLi>
        Certain legislation, including the Australian Consumer Law (
        <span className="bold">ACL</span>
        ) in the Competition and Consumer
        Act 2010 (Cth), and similar consumer protection laws and
        regulations, may confer you with rights, warranties, guarantees and
        remedies relating to the provision of the Platform by us to you
        which cannot be excluded, restricted or modified (
        <span className="bold">Consumer Law Rights</span>
        ).
      </LandingNumberedLi>
      <LandingNumberedLi>
        If the ACL applies to you as a consumer, nothing in these Terms
        excludes your Consumer Law Rights as a consumer under the ACL. You
        agree that our Liability for the Platform provided to an entity
        defined as a consumer under the ACL is governed solely by the ACL
        and these Terms.
      </LandingNumberedLi>
      <LandingNumberedLi>
        Subject to your Consumer Law Rights, we exclude all express and
        implied warranties, and all material, work and services (including
        the Platform) are provided to you without warranties of any kind,
        either express or implied, whether in statute, at law or on any
        other basis.
      </LandingNumberedLi>
      <LandingNumberedLi>
        As a Client, the goods provided by a Member may also confer on you
        certain rights under the ACL.
      </LandingNumberedLi>
      <LandingNumberedLi>
        This clause will survive the termination or expiry of these Terms.
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
