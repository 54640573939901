import { Collapse, Grid } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAuth } from '@clerk/clerk-react';
import {
  MediumBlackText,
  SimpleBlackText,
} from '../../../../../../../shared/styles';
import { SettingsGalleryCard } from './SettingsGalleryCard';
import { RowCards, StorageLoaderWrapper } from './styled';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';
import { PinkLoader } from '../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { convertFileSize } from '../../../../../utils/helpers';
import { betaPlans } from '../../../../../../../constants';
import { ArrowSimpleIcon } from '../../../../../../../shared/icons';
import { ViewMoreButton } from '../../NewGalleryCreator/NewGalleryCreatorSecondStep/styled';
import { PlanEnum } from '../../../../../../../shared/types';
import { fetchGetCreatorGalleries } from '../../../../../redux/gallery/galleryCreatorThunk';

export const Storage: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation('settings');
  const dispatch = useCreatorAppDispatch();
  const currentUser = useTypedSelectorCreator((state) => state.creator.currentUser);
  const { getToken } = useAuth();

  const isLoadingData = useTypedSelectorCreator((state) => state.gallery.loadingGet);
  const activeGalleries = useTypedSelectorCreator((state) => state.gallery.activeGalleries);
  const draftGalleries = useTypedSelectorCreator((state) => state.gallery.draftGalleries);

  const usedStorage = currentUser?.totalStorageUsed || 0;
  const totalStorage = useMemo(() => {
    if (currentUser?.plan === PlanEnum.Free) {
      return betaPlans?.find((item) => item.planType === PlanEnum.Basic)?.storage;
    }
    return betaPlans?.find((item) => item.planType === currentUser?.plan)?.storage;
  }, [currentUser]);

  useEffect(() => {
    dispatch(fetchGetCreatorGalleries(getToken));
  }, []);

  return (
    <>
      <SimpleBlackText>{t('storage')}</SimpleBlackText>
      <Grid
        container
        item
        sm={12}
        style={{ margin: '30px 0px', height: 'fit-content', minHeight: '0' }}
      >
        <Grid
          container
          item
          xl={5}
          lg={6}
          md={9}
          direction="row"
          style={{ height: 'fit-content' }}
        >
          <MediumBlackText>{t('availablePlan')}</MediumBlackText>
          <MediumBlackText fontWeight={500} style={{ marginLeft: '10px' }}>
            <Trans t={t} i18nKey="storageGB">
              {{ storageGB: convertFileSize(totalStorage, 0) }}
            </Trans>
          </MediumBlackText>
        </Grid>
        <Grid
          container
          item
          lg={5}
          md={9}
          sm={5}
          direction="row"
          style={{ height: 'fit-content' }}
        >
          <MediumBlackText>{t('storageUsed')}</MediumBlackText>
          <MediumBlackText fontWeight={500} style={{ marginLeft: '10px' }}>
            <Trans t={t} i18nKey="storageMB">
              {{ storageMB: convertFileSize(usedStorage) }}
            </Trans>
          </MediumBlackText>
        </Grid>
      </Grid>
      {isLoadingData && (
      <StorageLoaderWrapper>
        <PinkLoader />
      </StorageLoaderWrapper>
      )}
      <Collapse in={isExpanded} collapsedSize={180}>
        <RowCards>
          {[...activeGalleries, ...draftGalleries]?.map((item) => (
            <SettingsGalleryCard
              galleryId={item.id}
              key={item.id}
              file={item?.coverFile?.desktop || item?.coverFile?.original}
              name={item.name}
              size={convertFileSize(item?.totalSize)}
            />
          ))}
        </RowCards>
      </Collapse>
      <div style={{ maxWidth: '650px' }}>
        {[...activeGalleries, ...draftGalleries]?.length > 3 && (
          <ViewMoreButton type="button" onClick={() => setIsExpanded((prev: boolean) => !prev)}>
            <span>View more</span>
            <ArrowSimpleIcon direction={isExpanded ? 'up' : 'down'} />
          </ViewMoreButton>
        )}
      </div>
    </>
  );
};
