import { useEffect, useState } from 'react';

export const useVisibleOnScroll = (scrollY: number): boolean => {
  const [visible, setVisible] = useState(false);

  const onScroll = () => {
    if (Math.round(window.scrollY) > scrollY) {
      setVisible(true);
    }
    if (Math.round(window.scrollY) < scrollY) {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return visible;
};
