import { useMemo } from 'react';
import {
  useTypedSelectorClient,
  useTypedSelectorGallery,
} from '../../../shared/hooks/useTypedSelector';

type ReturnObj = {
  isFree: boolean;
  remainFreeFiles: number;
};

export const useFreeFilesInSection = (sectionId: string): ReturnObj => {
  const sections = useTypedSelectorGallery(
    (state) => state.gallery?.gallery?.sections,
  );
  const boughtFreeFilesSections = useTypedSelectorClient(
    (state) => state.boughtFreeFilesClient?.sections,
  );

  const section = useMemo(
    () => sections?.find((item) => item?.id === sectionId),
    [sections, sectionId],
  );
  const cartSections = useTypedSelectorClient((state) => state.cart?.sections);
  const cartSection = useMemo(
    () => cartSections?.find((item) => item?.sectionId === sectionId),
    [cartSections, sectionId],
  );
  const boughtFreeFilesSection = boughtFreeFilesSections?.find(
    (item) => item?.sectionId === section?.id,
  );

  const remainFreeFiles = useMemo(() => {
    const num = (section?.numberFreeFiles || 0)
      - (cartSection?.freeFiles || 0)
      - (boughtFreeFilesSection?.freeFiles || 0);
    return num < 0 ? 0 : num;
  }, [section?.numberFreeFiles, cartSection?.freeFiles, boughtFreeFilesSection?.freeFiles]);

  return {
    isFree: (section?.numberFreeFiles || 0) > (cartSection?.freeFiles || 0),
    remainFreeFiles,
  };
};
