import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import { theme } from '../../../shared/components/GlobalContainer/styled';
import * as urls from '../../../constants/urls';
import { GradientColorText, PrimaryWhiteButton, TextBlackTwenty } from '../../../shared/styles';
import { PaymentSuccessful } from '../../../shared/components/PaymentPages/PaymentSuccessful';
import { PaymentSuccessText } from './styled';

const styles = {
  paymentWrapper: {
    desktop: {
      width: '100%',
    },
    tablet: {
      paddingTop: '0px',
      width: '100%',
    },
    mobile: {
      paddingTop: '0px',
      width: '100%',
    },
  },
  header: {
    desktop: {
      paddingLeft: '20px',
    },
    tablet: {
      paddingLeft: '20px',
    },
    mobile: {
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  },
};

export const BetaPaymentSuccessful: React.FC = () => {
  const { t } = useTranslation('basicComponents');
  const { search } = useLocation();

  if (!search?.includes('redirect_status=succeeded')) {
    return <Navigate to={urls.clientRootUrl} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <PaymentSuccessful hasBackground styles={styles} rootUrl={urls.creatorRootUrl}>
        <PaymentSuccessText>
          <TextBlackTwenty fontWeight={600}>{t('welcomeToDelivrable')}</TextBlackTwenty>
        </PaymentSuccessText>
        <a href={`${window.location.origin}/creator`}>
          <PrimaryWhiteButton type="button">
            <GradientColorText fontWeight={600}>
              {t('backToAccount')}
            </GradientColorText>
          </PrimaryWhiteButton>
        </a>
      </PaymentSuccessful>
    </ThemeProvider>
  );
};
