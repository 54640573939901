import { configureStore } from '@reduxjs/toolkit';
import { galleryApi } from './gallery/galleryAPI';
import { rootReducer } from './rootReducer';

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    galleryApi.middleware,
  ),
//   ...options,
});

export type RootState = ReturnType<typeof rootReducer>;
