import React, { useContext, useEffect, MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../shared/hooks/useTypedSelector';
import {
  TabWrapper,
  TitleText,
  TabContentWrapper,
  SimpleBlackText,
  SimplePinkText,
  GradientColorText,
  GradientLink,
} from '../../../../../../shared/styles';
import { FAQAccordion } from './FAQAccordion/FAQAccordion';
import { ColumnWrapper, VideoColumnWrapper, WhiteButtonFitContent } from './styled';
import { VideoFromYouTube } from './VideoFromYouTube';
import { CreatorOnboardingContext } from '../../../../context/CreatorOnboardingContext';
import * as urls from '../../../../../../constants/urls';
import { CreatorOnboardingStepIndexContext } from '../../../../context/CreatorOnboardingStepIndexContext';
import { fetchGetSupport } from '../../../../redux/support/supportThunk';
import { BetaLogo } from '../../../../../../shared/components/BetaLogo/BetaLogo';

export const Support: React.FC = () => {
  const { t } = useTranslation('faq');
  const navigate = useNavigate();
  const { setIsStudy } = useContext(CreatorOnboardingContext);
  const { setStepIndex, stepIndex } = useContext(CreatorOnboardingStepIndexContext);
  const dispatch = useCreatorAppDispatch();

  const questions = useTypedSelectorCreator((state) => state.support.questions);
  const isLoading = useTypedSelectorCreator((state) => state.support.loading);

  useEffect(() => {
    dispatch(fetchGetSupport());
  }, []);

  const handleStartTour = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (stepIndex > 4) {
      setStepIndex(0);
    }
    if (stepIndex < 4) {
      setStepIndex(0);
    }
    setIsStudy(true);
    navigate(urls.creatorRootUrl);
  };

  return (
    <TabWrapper>
      {/* <TitleText fontWeight={600}>{t('title')}</TitleText> */}
      <TabContentWrapper>
        {/* {isLoading ? (
          <span>Loading...</span>
        ) : (
          <FAQAccordion questions={questions} />
        )} */}
        <ColumnWrapper id="training-course" style={{ marginTop: '50px' }}>
          <TitleText fontWeight={500} style={{ display: 'flex', flexDirection: 'row', margin: '0px 0px 20px 0px' }}>
            Join the
            <BetaLogo wrapperStyle={{ margin: '0px 30px 0px 10px' }} />
            {' '}
            facebook community
          </TitleText>
          <SimpleBlackText style={{ marginBottom: '20px' }}>
            A place to find answers, learn best practices,
            request new features, get updates and share your
            experiences with our team and other users.
          </SimpleBlackText>
          <a href="https://www.facebook.com/groups/377314941663218" target="_blank" rel="noreferrer">
            <WhiteButtonFitContent type="button">
              <GradientColorText fontWeight={600}>
                Join community
              </GradientColorText>
            </WhiteButtonFitContent>
          </a>
          <TitleText fontWeight={500} style={{ margin: '60px 0px 20px 0px' }}>Tutorial</TitleText>
          <SimpleBlackText style={{ marginBottom: '20px' }}>
            Need help navigating delivrable? Go through the tutorial again.
          </SimpleBlackText>
          <WhiteButtonFitContent type="button" onClick={handleStartTour}>
            <GradientColorText fontWeight={600}>
              {t('viewTraining')}
            </GradientColorText>
          </WhiteButtonFitContent>
        </ColumnWrapper>
        <VideoColumnWrapper>
          <TitleText fontWeight={500} style={{ margin: '0px 0px 20px 0px' }}>
            Checkout our
            {' '}
            <GradientLink
              target="_blank"
              href={urls.youtubeChannel}
              fontWeight={500}
            >
              YouTube channel
            </GradientLink>
          </TitleText>
          <SimpleBlackText>
            Watch tutorial videos showing you how to use delivrable
            which are packed with useful information.
          </SimpleBlackText>
        </VideoColumnWrapper>
        <Grid container item sm={12}>
          <VideoFromYouTube />
        </Grid>
        <ColumnWrapper>
          <TitleText fontWeight={500} style={{ margin: '60px 0px 20px 0px' }}>
            Still haven’t found what you’re looking for?
          </TitleText>
          <SimpleBlackText style={{ marginBottom: '20px' }}>
            Send us an email - support@delivrable.io.
          </SimpleBlackText>
        </ColumnWrapper>
      </TabContentWrapper>
    </TabWrapper>
  );
};
