import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomAlert } from '../../../../../../shared/components/CustomAlert/CustomAlert';
import { SuccessNotification } from '../../../../../../shared/components/SuccessNotification/SuccessNotification';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../shared/hooks/useTypedSelector';
import { unsetError, unsetSuccess } from '../../../../redux/userCreator/creator/creatorSlice';

export const SettingsAlerts: React.FC = () => {
  const { t } = useTranslation(['settings', 'basicComponents']);
  const [isNotificationOpened, setIsNotificationOpened] = useState(false);
  const dispatch = useCreatorAppDispatch();

  const isSuccess = useTypedSelectorCreator(
    (state) => state.creator.isSuccess,
  );
  const error = useTypedSelectorCreator(
    (state) => state.creator.error,
  );

  useEffect(() => {
    if (isSuccess) {
      setIsNotificationOpened(true);
    }
  }, [isSuccess]);

  return (
    <>
      <CustomAlert
        isOpenWindow={!!error}
        message={error || ''}
        type="error"
        title="Error"
        onClose={() => dispatch(unsetError())}
        onClick={() => dispatch(unsetError())}
      />
      <SuccessNotification
        unsetSuccess={() => dispatch(unsetSuccess())}
        isOpen={isNotificationOpened}
        text={t('basicComponents:changesSaved')}
        setIsOpen={setIsNotificationOpened}
        longTime
      />
    </>
  );
};
