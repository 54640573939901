/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
import styled from '@emotion/styled';
import * as variables from '../../../constants/styles';
import { theme } from '../GlobalContainer/styled';

export const GradientCover = styled.div<{
  isStudy: boolean;
  isGradientVisible: boolean;
}>`
  position: absolute;
  width: 120px;
  height: 100%;
  right: 0;
  background: linear-gradient(270deg, #000 0%, #000 10%, rgba(0, 0, 0, 0) 100%);
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  opacity: ${({ isGradientVisible }) => (isGradientVisible ? '1' : '0')};
  transition: all 0.2s ease-in-out;
  padding-left: 50px;
  margin-bottom: 0px !important;

  & svg {
    color: white;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    display: none;
  }
`;

export const IconsWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  margin-bottom: 0px !important;

  & button {
    padding: 0;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;

    &:first-of-type {
      margin-top: 0px;
    }

    &:last-of-type {
      margin-bottom: 0px;
    }

    &:hover {
      transition: all 0.1s ease-in-out;

      & svg {
        transition: all 0.1s ease-in-out;
        color: ${variables.colorPink} !important;
      }
    }
  }

  & svg {
    transition: all 0.1s ease-in-out;
    width: 30px;
    height: 30px;
  }
`;

export const ChangeColorButton = styled.button<{
  isClicked: boolean;
  baseColor?: string;
}>`
  & svg {
    ${({ isClicked, baseColor }) => (isClicked
    ? `color: ${variables.colorPink} !important;`
    : (baseColor ? `color: ${baseColor};` : ''))}
  }
`;
