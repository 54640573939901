import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyInsideListWrapper, TermsAndPrivacyListItem } from '../styled';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const LicensePoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={3}
      listTitle={t('terms.license')}
      pointsNameForTrans="licensePoints"
    >
      <LandingNumberedLi>
        You accept these Terms by checking the box, clicking “I accept”,
        registering on the Platform, or using the Platform or the Delivrable
        Services.
      </LandingNumberedLi>
      <LandingNumberedLi>
        You must be at least 16 years old to use the Platform.
      </LandingNumberedLi>
      <LandingNumberedLi>
        We may amend or terminate these Terms at any time, by providing
        written notice to you. By clicking “I accept” or continuing to use
        the Platform after the notice or 30 days after notification
        (whichever date is earlier), you agree to the amended Terms. If you
        do not agree to the amendment, you may terminate these Terms in
        accordance with the clause 18.
      </LandingNumberedLi>
      <LandingNumberedLi>
        If you access or download our mobile application from (1) the Apple
        App Store, you agree to any Usage Rules set forth in the App Store
        Terms of Service or (2) the Google Play Store, you agree to the
        Android, Google Inc. Terms and Conditions including the Google Apps
        Terms of Service.
      </LandingNumberedLi>
      <LandingNumberedLi>
        Subject to your compliance with these Terms, we grant you a
        personal, non-exclusive, royalty-free, revocable, worldwide,
        non-transferable licence to access and use our Platform in
        accordance with these Terms. All other uses are prohibited without
        our prior written consent.
      </LandingNumberedLi>
      <LandingNumberedLi>
        When using the Platform, you must not do or attempt to do anything
        that is unlawful or inappropriate, including:
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            anything that would constitute a breach of an individual’s
            privacy (including uploading private or personal information
            without an individual&apos;s consent) or any other legal rights;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            using the Platform to defame, harass, threaten, menace or offend
            any person;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            using the Platform for unlawful purposes;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            interfering with any user of the Platform;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            tampering with or modifying the Platform (including by
            transmitting viruses and using trojan horses);
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            using the Platform to send unsolicited electronic messages;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            using data mining, robots, screen scraping or similar data
            gathering and extraction tools on the Platform;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            facilitating or assisting a third party to do any of the above
            acts.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
