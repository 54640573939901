import styled from '@emotion/styled';

export const DownloadLoaderWrapper = styled.div<{flexDirection: 'row' | 'column'}>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: center;
  align-items: center;

  & svg {
    margin: 0px 10px;
  }
`;
