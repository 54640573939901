import React from 'react';
import { ArrowSimpleIcon } from '../../icons';
import { ArrowWrapper, ViewAllNavLink } from './styled';

type ViewAllLinkProps = {
  label: string;
  to: string;
};

export const ViewAllLink: React.FC<ViewAllLinkProps> = ({ label, to }) => (
  <ViewAllNavLink to={to}>
    <div>{label}</div>
    <ArrowWrapper>
      <ArrowSimpleIcon direction="right" />
    </ArrowWrapper>
  </ViewAllNavLink>
);
