import React, { CSSProperties } from 'react';
import { NumericFormat } from 'react-number-format';
import { SetFieldValue } from '../../../types';
import { ErrorMessage } from '../../styled';
import { InputAndErrorWrapper, InputAndLabelWrapper } from '../styled';
import { TextLabel } from '../TextLabel';
import { PriceInputErrorWrapper } from './styled';

type CommonPriceInputProps = {
  error?: string | any;
  value: string | any;
  name: string;
  touched?: boolean | any;
  label?: string;
  direction?: 'row' | 'column';
  disabled?: boolean;
  helpText?: string;
  id?: string;
  withoutMargins?: boolean;
  setFieldValue: SetFieldValue;
  inputWidth?: number;
  justifyContent?: 'space-between';
  inputAndErrorWrapperStyle?: CSSProperties;
};

export const CommonPriceInput: React.FC<CommonPriceInputProps> = ({
  error,
  value,
  name,
  touched,
  direction = 'row',
  disabled,
  label,
  helpText,
  id,
  withoutMargins,
  setFieldValue,
  inputWidth,
  justifyContent,
  inputAndErrorWrapperStyle,
}) => (
  <InputAndLabelWrapper
    justifyContent={justifyContent}
    direction={direction}
    id={id || name}
    withoutMargins={withoutMargins}
  >
    <TextLabel
      name={name}
      label={label}
      helpText={helpText}
      disabled={disabled}
    />
    <InputAndErrorWrapper style={inputAndErrorWrapperStyle}>
      <PriceInputErrorWrapper
        inputWidth={inputWidth}
        hasErrors={error && touched}
      >
        <NumericFormat
          prefix="$"
          placeholder="$0.00"
          thousandSeparator=" "
          decimalScale={2}
          fixedDecimalScale
          allowNegative={false}
          autoComplete="off"
          value={!value ? '' : String(value / 100)}
          name={name}
          disabled={disabled}
          // eslint-disable-next-line max-len
          onValueChange={({ floatValue }: { floatValue: any }) => setFieldValue(name, (floatValue || 0) * 100)}
        />
      </PriceInputErrorWrapper>
      <ErrorMessage>{error && touched ? error : null}</ErrorMessage>
    </InputAndErrorWrapper>
  </InputAndLabelWrapper>
);
