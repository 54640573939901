import {
  fieldTaken,
  smthGoesWrongErrorObj,
  wrongOrOldTokenErrorObj,
} from '../../../constants/validationValues';
import { SignUpFields } from '../../types';
import {
  ErrorFieldAndMessage,
  HttpError,
  ResponseErrors,
} from '../../types/httpError';
import { createObjectForErrors } from './createObjectForErrors';
import { getSignUpFieldFullName } from '../signUp/getSignUpFieldFullName';
import { smthGoesWrongError } from './errorsMessages';

export const getSignUpErrors = (
  error: HttpError<SignUpFields>,
): ResponseErrors => {
  const message = error.response?.data?.message;

  if (error.response?.status === 409) {
    const errors = error.response.data.field.map((item: SignUpFields) => ({
      field: item,
      message: message || fieldTaken(getSignUpFieldFullName(item)),
    }));
    return createObjectForErrors('wrongData', errors);
  }
  if (error.response.status === 401) {
    const errorObj = message ? { field: '', message } : wrongOrOldTokenErrorObj;
    return createObjectForErrors('other', [errorObj]);
  }
  if (error.response.status === 400) {
    const errorObj = message ? { field: '', message } : smthGoesWrongErrorObj;
    return createObjectForErrors('other', [errorObj]);
  }
  return smthGoesWrongError;
};

export const getSignUpClerkErrors = (
  error: HttpError<SignUpFields> | any,
): ErrorFieldAndMessage[] => {
  const message = error.response?.data?.message;

  if (error.response?.status === 409) {
    const errors = error.response.data.field.map((item: SignUpFields) => ({
      field: item,
      message: message || fieldTaken(getSignUpFieldFullName(item)),
    }));
    return errors;
  }
  if (error?.response?.data) {
    const errorObj = message ? { field: '', message } : smthGoesWrongErrorObj;
    return [errorObj];
  }
  if (error?.errors?.length > 0) {
    const errors = error.errors.map((item: { message: string }) => ({
      field: '',
      message: item.message,
    }));
    return errors;
  }

  return [smthGoesWrongErrorObj];
};

export const getInitialSignUpErrors = (
  error: HttpError<string>,
): ResponseErrors => {
  if (error.response.status === 409) {
    return createObjectForErrors('other', [smthGoesWrongErrorObj]);
  }
  return smthGoesWrongError;
};
