import styled from '@emotion/styled';
import * as variables from '../../../../../../../../constants/styles';
import { theme } from '../../../../../../../../shared/components/GlobalContainer/styled';

export const ConfirmCurrentPasswordWindowContainer = styled.div`
  padding: 42px 40px 1px;
  min-width: 380px;
  background: ${variables.colorGreyLight};
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;
`;

export const ChangePasswordWrapper = styled.div`
  margin-top: 40px;
  position: relative;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin-bottom: 40px;
  }
`;
