import React, { useState, MouseEvent } from 'react';
import { Filters } from '../../types';
import { FilterButtonElement } from './styled';

type FilterButtonProps = {
  text: string;
  id: Filters;
  handleChoose: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const FilterButton: React.FC<FilterButtonProps> = ({
  text,
  id,
  handleChoose,
}) => {
  const [chosen, setChosen] = useState(false);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    setChosen((prev) => !prev);
    handleChoose(e);
  };

  return (
    <FilterButtonElement
      id={id}
      type="button"
      chosen={chosen}
      onClick={handleClick}
    >
      {text}
    </FilterButtonElement>
  );
};
