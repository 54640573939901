import React from 'react';
import { PinkLoader } from '../Loaders/PinkLoader/PinkLoader';
import { ResendButtonElement } from './styled';

type ResendButtonProps = {
  msCounter: number;
  isLoading: boolean;
  handleResend: () => void;
  buttonText: string;
};

export const ResendButton: React.FC<ResendButtonProps> = ({
  msCounter,
  isLoading,
  handleResend,
  buttonText,
}) => (
  <ResendButtonElement disabled={msCounter > 0 || isLoading} type="button" width="213" onClick={handleResend}>
    {isLoading ? <PinkLoader size="xs" /> : buttonText}
    {msCounter > 0 && !isLoading ? ` (${msCounter})` : null}
  </ResendButtonElement>
);
