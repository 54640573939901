import React from 'react';
import { ViewsLikesDownloadsShares } from '../../../../../../../shared/components/ViewsLikesDownloadsShares/ViewsLikesDownloadsShares';
import {
  DownloadIcon, EyeIcon, HeartIcon, ShareIcon,
} from '../../../../../../../shared/icons';
import { GalleryStatus } from '../../../../../../../shared/types';
import { CopyPassword } from './CopyPassword/CopyPassword';
import { PasswordAndDateWrapper } from './styled';

type GalleryInfoProps = {
  likes: number;
  shares: number;
  downloads: number;
  views: number;
  password: string;
  expireDate: string;
  galleryId: string;
  galleryStatus: GalleryStatus;
};

export const GalleryInfo: React.FC<GalleryInfoProps> = ({
  likes,
  downloads,
  views,
  shares,
  password,
  expireDate,
  galleryId,
  galleryStatus,
}) => (
  <>
    <div>
      <ViewsLikesDownloadsShares icon={<EyeIcon />} numberOfData={views} type="views" />
      <ViewsLikesDownloadsShares icon={<HeartIcon />} numberOfData={likes} type="likes" />
      <ViewsLikesDownloadsShares icon={<DownloadIcon />} numberOfData={downloads} type="downloads" />
      <ViewsLikesDownloadsShares icon={<ShareIcon />} numberOfData={shares} type="shares" />
    </div>
    <PasswordAndDateWrapper>
      <CopyPassword password={password} />
      {/* hide for now */}
      {/* <StatusAndDate
        galleryStatus={galleryStatus}
        galleryId={galleryId}
        expireDate={expireDate}
      /> */}
    </PasswordAndDateWrapper>
  </>
);
