import React, {
  ChangeEvent, useEffect, useMemo, useState,
} from 'react';
import CreatableSelect from 'react-select/creatable';
import { OnChangeValue } from 'react-select';
import { getInputChipsStyles, InputChipsWrapper } from './styled';
import { MultiValueRemove } from './MultiValueRemove';
import { SetFieldValue, Option } from '../../../types';
import { InputAndErrorWrapper, InputAndLabelWrapper } from '../styled';
import { BaseInputWrapper, ErrorMessage } from '../../styled';

type InputChipsProps = {
  error?: string | any;
  values: string[];
  name: string;
  placeholder?: string;
  touched?: boolean | any;
  setFieldValue: SetFieldValue;
  handleBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  withoutMargins?: boolean;
};

const createOption = (label: string) => ({
  label,
  value: label,
});

export const InputChips: React.FC<InputChipsProps> = ({
  values,
  name,
  placeholder,
  error,
  setFieldValue,
  touched,
  handleBlur,
  disabled,
  withoutMargins,
}) => {
  const [value, setValue] = useState<{label: string, value: string}[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const handleChange = (newValue: OnChangeValue<Option, true>) => {
    setValue(newValue as any);
    const valuesArray = newValue.map((item) => item.value) || [];
    setFieldValue(name, valuesArray);
  };

  const handleInputChange = (newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const handleKeyDown = (event: any) => {
    if (!inputValue) return;
    if (
      event.key === ' '
      || event.key === 'Enter'
      || event.key === 'Tab'
      || event.key === ','
    ) {
      setInputValue('');
      setValue([...value, createOption(inputValue)]);
      setFieldValue(name, [...values, createOption(inputValue).value]);
      event.preventDefault();
    }
  };
  const styles = useMemo(
    () => getInputChipsStyles(error),
    [error, touched],
  );

  useEffect(() => {
    const options = values.map((item: any) => ({ label: item, value: item }));
    setValue(options);
  }, []);

  return (
    <InputAndLabelWrapper direction="column" withoutMargins={withoutMargins}>
      <InputAndErrorWrapper>
        <BaseInputWrapper isSuccess={!(error && touched)}>
          <InputChipsWrapper>
            <CreatableSelect
              id={name}
              styles={styles}
              inputValue={inputValue}
              isClearable
              isMulti
              components={{ MultiValueRemove, DropdownIndicator: null }}
              menuIsOpen={false}
              onChange={handleChange}
              onInputChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder={placeholder}
              value={value}
              name={name}
              onBlur={handleBlur}
              isDisabled={disabled}
            />
          </InputChipsWrapper>
        </BaseInputWrapper>
        {error && touched ? (
          <ErrorMessage>
            {typeof error === 'string' ? error : error[0]}
          </ErrorMessage>
        ) : null}
      </InputAndErrorWrapper>
    </InputAndLabelWrapper>
  );
};
