import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Cropper, { Area } from 'react-easy-crop';
import { getObjectFit } from '../../utils/createGallery/getObjectFit';
import { InitialCroppedArea } from '../../types';

type CropperFix = React.Component;

const Cropped = Cropper as any as {
  new (): CropperFix;
};
const style = {
  mediaStyle: {
    maxWidth: 'none',
  },
};

type BaseCropperProps = {
  size: string;
  videoOrImage: any;
  width: number;
  height: number;
  initialCroppedAreaPixels?: InitialCroppedArea;
  cropShape?: 'round';
  zoom?: number;
};

export const BaseCropper: React.FC<BaseCropperProps> = ({
  size,
  videoOrImage,
  width,
  height,
  initialCroppedAreaPixels,
  cropShape,
  zoom: outerZoom,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaData: Area) => {
    window.localStorage.setItem(size, JSON.stringify(croppedAreaData));
  }, [size]);

  const handleChange = ({ x, y }: { x: number; y: number }) => {
    if (crop.x !== x || crop.y !== y) {
      setCrop({ x, y });
    }
  };

  const objectFit = useMemo(() => {
    const fileUrl = videoOrImage.image || videoOrImage.video || '';
    return getObjectFit(fileUrl, width, height);
  }, [videoOrImage.image, videoOrImage.video, width, height]);

  useEffect(() => {
    if (outerZoom) {
      setZoom(outerZoom);
    }
  }, [outerZoom]);

  const props = {
    crop,
    zoom,
    onZoomChange: setZoom,
    onCropChange: handleChange,
    cropShape: cropShape || 'rect',
    style,
    objectFit,
    cropSize: {
      width,
      height,
    },
    onCropComplete,
    mediaProps: {
      autoPlay: false,
    },
    initialCroppedAreaPixels: initialCroppedAreaPixels || undefined,
  };

  return <Cropped {...props} {...videoOrImage} />;
};
