import React from 'react';
import { Tab, styled } from '@material-ui/core';
import * as variables from '../../../../constants/styles';
import { TabWrapper } from './styled';

export const StyledTab = styled((props: any) => (
  <TabWrapper tabsnumber={props.tabsNumber}>
    <Tab disableRipple {...props} />
  </TabWrapper>
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 400,
  fontFamily: variables.mainFont,
  marginRight: theme.spacing(0),
  width: '100%',
  height: '100%',
  minWidth: '80px',
  minHeight: '24px',
  fontSize: '16px',
  lineHeight: '140%',
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
  color: variables.textGrey,
  background: variables.colorGreyLight,
  boxShadow: '-2px -2px 3px #FFFFFF, 2px 2px 3px #E7E7E7',
  borderRadius: '7px',
  '&.Mui-selected': {
    color: variables.colorPink,
    boxShadow: 'inset -5px -5px 10px #FFFFFF, inset 2px 2px 5px #E7E7E7',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
  '.MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  indicator: {
    backgroundColor: 'white',
  },
}));
