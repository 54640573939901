import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import * as variables from '../../../../../constants/styles';

export const UserCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const UserPhoto = styled.div`
  margin-right: 30px;
`;
export const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserName = styled.span`
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 18px;
`;

export const SidebarWrapper = styled.div`
  background: ${variables.colorGreyLight};
  min-height: 100vh;
  height: 100%;
  padding-right: 20px;
  min-width: 230px; 
  box-sizing: border-box;
  box-shadow: 5px 5px 11px #f1f0f0;
`;

export const SidebarMenuWrapper = styled.div`
  margin: 50px 0 43px;
`;

export const SidebarMenuItem = styled.div<{isActive?: boolean, disabled?: boolean}>`
  position: relative;
  ${({ disabled }) => (disabled ? `
    & a,
    & button {
      cursor: auto;
      color: ${variables.colorBleak}
    }
  ` : `
    & a.active,
    & button.active {
      color: ${variables.colorPink};
      padding: 0;

      & div {
        box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #e7e7e7;
      }

      & svg {
        color: ${variables.colorPink};
      }
    }
  `)}
  & button {
    ${(props) => (props.isActive && !props.disabled ? `
    color: ${variables.colorPink};
    padding: 0;

    & div {
      box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #e7e7e7;
    }

    & svg {
      color: ${variables.colorPink};
    }
    ` : '')}
    
  }

  & a,
  & button {
    padding: 0;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    & span {
      font-size: 16px;
      line-height: 24px;
    }

    ${({ disabled }) => (disabled ? '' : `
    &:hover {
      color: ${variables.colorPink};

      & svg {
        color: ${variables.colorPink};
      }
    }
    
    &:active {
      color: ${variables.colorPink};

      & div {
        box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #e7e7e7;
      }

      & svg {
        color: ${variables.colorPink};
      }
    }
    `)}
  }
`;
export const SidebarLinkIcon = styled.div`
  height: 31px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${variables.colorGreyLight};
  box-shadow: -5px -5px 10px #fff, 2px 2px 4px #e8e8e8;
  border-radius: 4px;
  margin-right: 12px;
`;
export const LogoutButton = styled.button`
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;

  & svg {
    margin-right: 12px;
    color: ${variables.textBlack};
  }

  &:hover {
    color: ${variables.colorPink};

    & svg {
      color: ${variables.colorPink};
    }
  }
`;

export const AlignCenterNavLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

export const PinkButton = styled.button`
  padding: 0px;
  color: ${variables.colorPink};
  text-decoration: underline;
  font-weight: normal;
`;
