import styled from '@emotion/styled';
import * as variables from '../../../../../../../../constants/styles';

export const ActiveGalleryFileWrapper = styled.div`
  width: 103px;
  height: 104px;
  min-width: 103px;
  min-height: 104px;
  border-radius: 4px;
  position: relative;
  object-fit: cover;
  overflow: hidden;
  background: #c4c4c4;

  & img {
    width: auto;
    height: 100%;

  }
`;

export const DetailsContentWrapper = styled.div<{noOverflowY?: boolean}>`
  padding-top: 28px;
  overflow-y: ${(props) => (props.noOverflowY ? 'none' : 'auto')};
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${variables.textGrey};
    border-radius: 163px;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: #dbdbdb;
    border-radius: 163px;
  }
`;

export const GalleryDetailsTabs = styled.div`
  margin-top: 60px;
  height: 100%;
`;

export const GalleryDetailsWrapper = styled.div`
  margin-top: 10px;
`;

export const TabsWrapper = styled.div`
  width: 520px;
`;

export const GalleryDetailsTabContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 190px;
  overflow-x: clip;
`;
