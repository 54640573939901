import { AxiosResponse } from 'axios';
import { ClientGalleryReq } from '../../shared/types/client';
import { clientInstance } from '../base/instance';
import { ClientGallery, GalleryCreatorSocialMedia } from '../../shared/types';

export const clientGallery = {
  getGallery: (data: ClientGalleryReq): Promise<AxiosResponse<{ gallery: ClientGallery, email: string, wasWatched: boolean, socialMedia: GalleryCreatorSocialMedia }>> => clientInstance.get(`client/gallery/${data.businessName}/${data.galleryName}`),
  downloadFile: (galleryId: string, fileId: string): Promise<AxiosResponse<any>> => clientInstance.get(`client/download/${galleryId}/${fileId}`, { responseType: 'blob' }),
  downloadFreeFile: (galleryId: string): Promise<AxiosResponse<any>> => clientInstance.get(`client/download/${galleryId}`, { responseType: 'blob' }),
  checkGalleryLogin: (data: ClientGalleryReq): Promise<AxiosResponse<{ password: boolean }>> => clientInstance.get(`client/gallery/check/${data.businessName}/${data.galleryName}`),
};
