import styled from '@emotion/styled';
import { MediumBlackText } from '../../../../../../../../shared/styles';
import * as variables from '../../../../../../../../constants/styles';

export const ReminderEmailFormWrapper = styled.div`
  background: #f5f5f5;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 32px 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ReminderEmailFormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    &:first-of-type {
      margin-right: 40px;
      width: 45%;
    }
    &:last-child {
      margin-right: 23px;
      width: 55%;
    }
  }
`;

export const ReminderEmailFormQuestions = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 13.5px;
  align-items: center;
`;

export const MediumBlackTextMg = styled(MediumBlackText)`
  margin-top: 3px;
  margin-bottom: 13px;
`;

export const ReminderEmailFormCheckboxes = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  & > div {
    margin-right: 34px;
    color: rgba(5, 27, 44, 0.5);
  }
`;

export const ReminderEmailFormChipsWrapper = styled.div`
  margin-top: 18px;
  margin-bottom: 24px;
  width: 100%;


`;

export const ReminderEmailOpenPreviewBtn = styled.button`
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  & > svg {
    transition: 0.2s ease-in-out;
    margin-right: 8px;
    color: ${variables.textGrey};
  }

  & div {
    transition: 0.2s ease-in-out;
  }

  &:hover {
    & > svg {
      color: ${variables.textBlack};
      transition: 0.2s ease-in-out;
    }
    & div {
      color: ${variables.textBlack};
      transition: 0.2s ease-in-out;
    }
  }
`;
