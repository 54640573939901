import styled from '@emotion/styled';
import * as variables from '../../../../constants/styles';

export const CommonInputElement = styled.input<{hasErrors: boolean}>`
  font-size: 15px;
  width: 100%;
  height: 44px;
  padding: 12px 24px;
  background: ${variables.colorGreyLight};
  border: 0.5px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #e7e7e7;
  border-radius: 163px;
  display: flex;
  margin-right: 0;
  align-items: flex-start;

  &:read-only {
    color: ${variables.colorBleak};
  }

  &::placeholder {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: ${variables.colorBleak};
    line-height: 140%;
  }

  &:hover,
  &:active {
    box-shadow: inset -5px -5px 10px #fff, inset 3px 3px 6px #e7e7e7;
    cursor: auto;
  }
  ${({ hasErrors }) => (hasErrors
    ? `
  box-shadow: none;
  border: 0.5px solid ${variables.colorError};
  `
    : '')}

  &:disabled {
    border: 0.5px solid #e2e2e2;
    box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #ededed;

    &::placeholder {
      color: #d0d6db;
    }

    &:hover,
    &:active {
      box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #ededed;
    }
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
`;
