import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { Appearance, loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { ArrowSimpleIcon } from '../../../../../../../shared/icons';
import { TextBlackLarge } from '../../../../../../../shared/styles';
import { ChangePlanButton } from '../ChangePlanPage/styled';
import { SettingsWrapper } from '../styled';
import * as urls from '../../../../../../../constants/urls';
import { SettingsContainer } from '../SettingsContainer';
import { useSubscription } from '../../../../../hooks/useSubscription';
import { PinkLoader } from '../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { PaymentPage } from '../../../../../../../shared/components/PaymentPages/PaymentPage';
import { ErrorMessage } from '../../../../../../../shared/components/styled';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

const appearance: Appearance = {
  theme: 'stripe',
};

export const PaymentDetails: React.FC = () => {
  const { t } = useTranslation('settings');
  const navigate = useNavigate();

  const {
    isLoading,
    error,
    secretData,
    getSecretData,
  } = useSubscription();

  const handleClickToBack = () => {
    navigate(`${urls.settingsUrl}${urls.changePlanUrl}`);
  };

  useEffect(() => {
    getSecretData('price_1KsOiECL5WYl6siZYOY89oHQ');
  }, []);

  const options: StripeElementsOptions = {
    clientSecret: secretData?.clientSecret,
    appearance,
  };

  return (
    <SettingsContainer>
      <SettingsWrapper>
        <ChangePlanButton type="button" onClick={handleClickToBack}>
          <TextBlackLarge>
            <ArrowSimpleIcon color="black" direction="left" />
            <span style={{ marginLeft: '16px' }}>{t('paymentDetails.title')}</span>
          </TextBlackLarge>
        </ChangePlanButton>
        {isLoading ? (
          <div>
            <PinkLoader />
          </div>
        ) : (
          <Grid container>
            {error && <div><ErrorMessage>{error}</ErrorMessage></div>}
            <Grid lg={6} md={8} xs={10}>
              {secretData && (
              <Elements options={options} stripe={stripePromise}>
                <PaymentPage
                  styles={{
                    paymentForm: { paddingTop: '40px', paddingLeft: '0px' },
                    button: {
                      padding: '10px 20px', borderRadius: '4px', color: 'white', background: '#1565c0',
                    },
                  }}
                  returnUrl={`http://delivrable.localhost${urls.creatorPaymentSuccessful}`}
                />
              </Elements>
              )}
            </Grid>
          </Grid>
        )}
      </SettingsWrapper>
    </SettingsContainer>
  );
};
