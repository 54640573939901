import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import React, {
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '@clerk/clerk-react';
import { CommonInput } from '../../../../../../../../shared/components/FormElements/CommonInput/CommonInput';
import { PrimaryWhiteButton } from '../../../../../../../../shared/styles';
import { PinkLoader } from '../../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { changeEmailSchema } from '../../../../../../../../shared/validation/creatorSettings/settingsSchemas';
import { useTypedSelectorCreator } from '../../../../../../../../shared/hooks/useTypedSelector';
import { useDialog } from '../../../../../../../../shared/hooks/useDialog';
import { ResendConfirmEmailButton } from '../ResendConfirmEmailButton';
import { useChangeEmail } from './useChangeEmail';

export const ChangeEmail: React.FC = () => {
  const { t } = useTranslation('settings');
  const {
    isSuccess, isLoading, error, changeEmail, setIsSuccess, msCounter,
  } = useChangeEmail();
  const { setIsOpen, dialogWindow } = useDialog<string>(
    'confirmation',
    undefined,
    () => setIsSuccess(false),
    'Verification email was sent. Check your email, please',
    'Email was changed',
    'Okay',
    true,
  );

  const { user } = useUser();
  const currentUser = useTypedSelectorCreator(
    (state) => state.creator.currentUser,
  );

  const newEmail = user?.emailAddresses.find((item) => item.verification.status !== 'verified');
  const buttonText = currentUser?.active ? t('updateEmail') : 'Resend link';

  const {
    values, handleSubmit, errors, handleChange, touched, handleBlur,
  } = useFormik({
    initialValues: {
      email: currentUser?.active ? (currentUser?.email || '') : (newEmail?.emailAddress || ''),
    },
    validationSchema: changeEmailSchema,
    onSubmit: (data: { email: string }, bag) => {
      bag.setSubmitting(false);
      changeEmail(data.email);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      setIsOpen(true);
    }
  }, [isSuccess]);

  return (
    <Grid item sm={9}>
      <CommonInput
        label={t('changeEmail')}
        value={values.email}
        name="email"
        handleChange={handleChange}
        handleBlur={handleBlur}
        direction="column"
        placeholder={t('placeholders.newEmailAddress')}
        error={errors?.email || error}
        touched={touched?.email || error}
        type="email"
      />
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
        <PrimaryWhiteButton
          style={{ position: 'relative', minWidth: '167.5px' }}
          type="button"
          onClick={handleSubmit as () => void}
          disabled={isLoading || msCounter > 0}
        >
          {isLoading ? <PinkLoader size="xs" /> : buttonText}
        </PrimaryWhiteButton>
        <ResendConfirmEmailButton msCounter={msCounter} isVisible={!currentUser?.active} />
      </div>
      {dialogWindow}
    </Grid>
  );
};
