import React, {
  ChangeEvent, RefObject, useEffect, useRef, useState,
} from 'react';
import { CommonCheckbox } from '../../../../../../../shared/components/FormElements/CommonCheckbox/CommonCheckbox';
import { CustomPositionCheckbox } from '../../../../../../../shared/components/styled';
import {
  CustomFile,
  GalleryFile,
  GalleryFileInformation,
  GalleryIdea,
} from '../../../../../../../shared/types';
import { getIdeaCoverOrFile, isImage, isVideo } from '../../../../../../../shared/utils';
import {
  FileWrapper,
  CustomCheckboxPosition,
} from '../../../../../../Client/containers/Favourites/FavouritesList/styled';
import {
  ContentSetupFileOverlay,
  ContentSetupFileWrapper,
  WatermarkLoaderWrapper,
} from './styled';
import { GradientCoverWithActions } from '../../../../../../../shared/components/GradientCoverWithActions/GradientCoverWithActions';
import { ImageElement } from '../../../../../../../shared/components/Files/ImageContainer/styled';
import { VideoContainer } from '../../../../../../../shared/components/Files/VideoContainer/VideoContainer';
import { PinkLoader } from '../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';

type FileSectionItemProps = {
  file: GalleryFile | GalleryIdea;
  handleSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  isItemSelected: (id: string) => boolean;
  isChecked: boolean;
  values: GalleryFileInformation;
  isSettingsApplied: boolean;
};

type ImageElementWithLoaderProps = {
  currentFileUrl: string;
  fileId: string;
  fileName: string;
  currentFile: CustomFile;
  videoThumbnail: string;
};

const ImageElementWithLoader: React.FC<ImageElementWithLoaderProps> = ({
  fileId,
  fileName,
  currentFileUrl,
  currentFile,
  videoThumbnail,
}) => {
  const [loading, setLoading] = useState(true);
  const fileRef = useRef<HTMLImageElement | HTMLVideoElement>(null);

  const onLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (fileRef?.current?.currentSrc !== currentFileUrl) {
      setLoading(true);
    }
  }, [currentFileUrl]);

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      {isImage(currentFile) ? (
        <ImageElement
          ref={fileRef as RefObject<HTMLImageElement>}
          hasNoUrl={!currentFileUrl}
          id={fileId}
          src={currentFileUrl}
          data-src={currentFileUrl}
          alt={fileName}
          onLoad={onLoad}
        />
      ) : (
        <>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            style={{
              width: 0, height: 0, position: 'absolute',
            }}
            src={currentFileUrl}
            ref={fileRef as RefObject<HTMLVideoElement>}
            onLoadedData={onLoad}
          >
            <source src={currentFileUrl} />
          </video>
          <VideoContainer
            videoThumbnail={videoThumbnail}
            videoUrl={currentFileUrl || ''}
            littlePlayButton
          />
        </>
      )}
      {loading ? (
        <WatermarkLoaderWrapper>
          <PinkLoader color="white" size="xs" />
        </WatermarkLoaderWrapper>
      ) : null}
    </div>
  );
};

export const FileSectionItem: React.FC<FileSectionItemProps> = ({
  file,
  isItemSelected,
  handleSelect,
  isChecked,
  values,
  isSettingsApplied,
}) => {
  const currentFile = getIdeaCoverOrFile(file);

  const { name, id } = (file as GalleryFile) || (file as GalleryIdea);

  let watermarkUrl = currentFile?.url;
  if (isChecked && values.watermark) {
    watermarkUrl = currentFile?.urlWatermarked;
  }
  if (!isChecked && file.watermark) {
    watermarkUrl = currentFile?.urlWatermarked;
  }

  let thumbnail;
  if (isChecked && values.hasVideoThumbnail) {
    thumbnail = values.videoThumbnail;
  }
  if (!isChecked && currentFile?.hasVideoThumbnail) {
    thumbnail = currentFile?.videoThumbnail;
  }

  return (
    <ContentSetupFileWrapper isChecked={isChecked || isSettingsApplied}>
      <FileWrapper>
        <ContentSetupFileOverlay>
          {isChecked ? (
            <GradientCoverWithActions
              file={currentFile}
              id={id}
              disabledAll
              isAllowCommenting={!!values.allowCommenting}
              isInstantlyDownloadable={!!values.instantlyDownloadable}
              isGradientVisible
            />
          ) : null}
          <CustomCheckboxPosition>
            <CustomPositionCheckbox>
              <CommonCheckbox
                id={id}
                isChecked={isItemSelected(id)}
                handleChange={handleSelect}
                simple
                name=""
                positioned
              />
            </CustomPositionCheckbox>
          </CustomCheckboxPosition>
        </ContentSetupFileOverlay>
        <ImageElementWithLoader
          currentFileUrl={watermarkUrl || ''}
          fileId={id}
          fileName={name}
          videoThumbnail={thumbnail?.url || ''}
          currentFile={currentFile}
        />
      </FileWrapper>
    </ContentSetupFileWrapper>
  );
};
