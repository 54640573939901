import React, { ChangeEvent, CSSProperties, ReactNode } from 'react';
import { useHiddenOrVisibleValue } from '../../../hooks/useHiddenOrVisibleValue';
import { ErrorMessage } from '../../styled';
import {
  InputAndErrorWrapper,
  InputAndLabelWrapper,
} from '../styled';
import { TextLabel } from '../TextLabel';
import { CommonInputElement, InputWrapper } from './styled';

type CommonInputProps = {
  error?: string | any;
  value: string | any;
  name: string;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  type: 'password' | 'email' | 'date' | 'text' | 'file';
  placeholder?: string;
  touched?: boolean | any;
  width?: number;
  label?: string | ReactNode;
  readonly?: boolean;
  direction?: 'row' | 'column';
  disabled?: boolean;
  id?: string;
  withoutMargins?: boolean;
  helpText?: string;
  eyeButton?: boolean;
  textLabelStyles?: CSSProperties;
};

export const CommonInput: React.FC<CommonInputProps> = ({
  error,
  value,
  name,
  handleChange,
  type,
  placeholder,
  handleBlur,
  touched,
  width,
  label,
  readonly,
  direction = 'column',
  disabled,
  id,
  withoutMargins,
  helpText,
  eyeButton,
  textLabelStyles,
}) => {
  const { isValueVisible, eyeElement } = useHiddenOrVisibleValue();

  return (
    <InputAndLabelWrapper
      direction={direction}
      id={id || name}
      withoutMargins={withoutMargins}
      width={width}
    >
      <TextLabel styles={textLabelStyles} name={name} label={label} helpText={helpText} />
      <InputAndErrorWrapper>
        <InputWrapper>
          <CommonInputElement
            hasErrors={error && touched}
            readOnly={readonly}
            id={name}
            placeholder={placeholder}
            name={name}
            type={isValueVisible ? 'text' : type}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
          />
          {eyeButton ? eyeElement : null}
        </InputWrapper>
        <ErrorMessage>{error && touched ? error : null}</ErrorMessage>
      </InputAndErrorWrapper>
    </InputAndLabelWrapper>
  );
};
