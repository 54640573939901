import React, { ChangeEvent, CSSProperties, ReactNode } from 'react';
import { ErrorMessage } from '../../styled';
import { InputAndErrorWrapper, InputAndLabelWrapper } from '../styled';
import { TextLabel } from '../TextLabel';
import { CustomSwitch } from './CustomStyledSwitch';
import { SwitchAndLabelWrapper, SwitchExtraTextWrapper, SwitchTextLabelWrapper } from './styled';

type CommonInputProps = {
  error?: string | any;
  isChecked: boolean;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  touched?: boolean | any;
  shortLabel?: boolean;
  label?: string | ReactNode;
  helpText?: string;
  positionHelpText?: 'top' | 'left';
  disabled?: boolean;
  spaceBetween?: boolean;
  withoutMargins?: boolean;
  littleText?: boolean;
  blueButton?: boolean;
  id?: string;
  extraText?: string;
  textLabelStyles?: CSSProperties;
  style?: CSSProperties;
  labelWhiteSpace?: 'pre';
  hideErrorSpan?: boolean;
};

export const CommonSwitch: React.FC<CommonInputProps> = ({
  error,
  name,
  touched,
  label,
  isChecked,
  shortLabel,
  helpText,
  handleChange,
  positionHelpText = 'left',
  disabled,
  spaceBetween,
  withoutMargins,
  littleText,
  blueButton,
  extraText,
  id,
  textLabelStyles,
  style,
  labelWhiteSpace,
  hideErrorSpan,
}) => (
  <InputAndLabelWrapper
    direction="row"
    id={id || name}
    withoutMargins={withoutMargins}
    style={style}
  >
    <InputAndErrorWrapper>
      <SwitchAndLabelWrapper spaceBetween={spaceBetween}>
        <SwitchTextLabelWrapper>
          <TextLabel
            labelWhiteSpace={labelWhiteSpace}
            styles={textLabelStyles}
            name={name}
            label={label}
            helpText={helpText}
            short={shortLabel}
            disabled={disabled}
            wrapperDirection="row"
            littleText={littleText}
          />
          {extraText && <SwitchExtraTextWrapper>{extraText}</SwitchExtraTextWrapper>}
        </SwitchTextLabelWrapper>
        <div>
          <CustomSwitch
            id={name}
            checked={isChecked}
            onChange={handleChange}
            name={name}
            disabled={disabled}
            bluebutton={String(!!blueButton)}
          />
        </div>
      </SwitchAndLabelWrapper>
      {!hideErrorSpan && (<ErrorMessage>{error && touched ? error : null}</ErrorMessage>)}
    </InputAndErrorWrapper>
  </InputAndLabelWrapper>
);
