import styled from '@emotion/styled';
import { theme } from '../../../../../shared/components/GlobalContainer/styled';

export const gallerySwiperButtonsTablet = `
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    display: none;
  }
`;

export const Slide = styled.div`
  height: 100vh;
  max-height: 100vh;
  object-fit: contain;
  display: flex;
  flex-direction: column;

  & #gallery-content-container {
    padding: 20px 0px 20px !important;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    & #gallery-content-container {
      padding: 0px !important;
    }

    height: calc(100vh - 200px);
    max-height: calc(100vh - 200px);
    min-height: calc(100vh - 200px);
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    height: calc(100vh - 200px);
    max-height: calc(100vh - 200px);
    min-height: calc(100vh - 200px);
  }
`;

export const SwiperContentWrapper = styled.div`
  display: block;
  box-sizing: border-box;
  width: calc(100% - 118px);
  text-align: center;

  & .carousel-root {
    display: inline-grid;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    width: 100%;
    display: flex;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 100%;

    & .carousel-root {
      display: block;
    }
  }
`;

export const GallerySwiperRoundBtnWr = styled.div<{side: 'right' | 'left', styles: string}>`
  display: flex;
  ${({ side }) => (side === 'right' ? 'margin-left: 20px;' : 'margin-right: 20px;')}

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    ${({ side }) => (side === 'right' ? 'margin-left: 0px;' : 'margin-right: 0px;')}
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    display: none;
    margin: 0px;
  }

  ${({ styles }) => (styles)}
`;

export const SlideContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;
