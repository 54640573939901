import styled from '@emotion/styled';
import * as variables from '../../../../../../../../../../constants/styles';

export const TextGallerySecond = styled.div`
  font-weight: normal;
  font-size: 4.74902px;
  line-height: 140%;
  text-align: start;
  max-width: 90px;

  & h3 {
    margin-top: 0;
    font-weight: normal;
    font-size: 9.49804px;
    line-height: 13px;
  }
`;
export const ImageContainerSecond = styled.div`
  width: 184px;
  height: 160px;
  position: relative;
  background: ${variables.colorBleak};
  background-size: cover;
  object-fit: cover;

  & span {
    width: 40px;
    position: absolute;
    font-weight: normal;
    font-size: 4.74902px;
    line-height: 6px;
    transform: rotate(-90deg);
    left: -26px;
    bottom: 10px;
  }
`;
