import styled from '@emotion/styled';
import * as variables from '../../../constants/styles';
import { WhiteCardWithShadows } from '../../styles';
import { theme } from '../GlobalContainer/styled';

export const CookiesConsentContainer = styled(WhiteCardWithShadows)`
  width: 100%;
  height: fit-content !important;
  min-height: fit-content !important;
  max-width: 800px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  border-radius: 10px;
  box-shadow: 2px 2px 3px 0px #E7E7E7, -2px -2px 3px 0px #E7E7E7;
  padding: 30px 40px;
  z-index: 1300;
  display: flex;
  flex-direction: column;
`;

export const CookiesConsentTwoButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;

  & button {
    margin-left: 22px;
  }
`;

export const CookiesConsentButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const CookiesConsentText = styled.div`
  max-width: 500px;
  margin-bottom: 20px;
  margin-top: 16px;
`;

export const PrivacyPolicyLink = styled.a`
  color: ${variables.colorPink};
`;

export const CustomizeSettingsConteiner = styled.div`
  box-sizing: border-box;
  width: 600px;
  padding: 60px 30px 30px;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    width: 500px;
  }
`;

export const CookiesGroupHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;

  & span {
    display: block;
  }

  & .MuiSwitch-root {
    margin: -10px -12px -12px -12px;
  }
  
  & .MuiIconButton-root {
  }
`;

export const CookiesAccordion = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0px 50px;
`;

export const CookiesAccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

export const CookiesAccordionHeaderIcon = styled.div<{ expanded: boolean }>`
  position: absolute;
  width: 24px;
  height: 24px;
  left: -40px;
  top: 0px;
  background: ${variables.colorGreyLight};
  box-shadow: -5px -5px 10px #FFFFFF, 5px 5px 10px #E8E8E8;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  box-sizing: border-box;

  & div {
    width: 10px;
    height: 10px;

    &:after,
    &:before {
      background: linear-gradient(270deg, #E6934F 0%, #E186B0 49.82%, #78C0EA 100%);
    }
    &:after {
      width: 10px;
      height: 1px;
      top: 5px;
    }
    &:before {
      width: 1px;
      height: 11px;
      left: 4.5px;
    }
  }
  transform: rotate(0deg);
  transition: transform 0.3s;

  ${({ expanded }) => (expanded ? `
  & div {
    &:before {
      transition: transform 0.3s;
      transform: rotate(90deg);
    }
  }
    ` : '')}
  
`;

// transform: rotate(0deg);
// '& > div': {
// '& div': {
//   '&:before': {
//     transition: 'transform 0.3s',
//     transform: 'rotate(90deg)',
//   },
// },
//   boxShadow: 'inset -2px -2px 4px #FFFFFF, inset 0.8px 0.8px 2px #E7E7E7',
// }

// '& .MuiAccordionSummary-expandIconWrapper': {
// transform: 'rotate(0deg)',
// '& > div': {
//   '& div': {
//     '&:before': {
//       transition: 'transform 0.3s',
//       transform: 'rotate(0deg)',
//     },
//   },
// },
// },
