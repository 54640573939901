import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetToken } from '@clerk/types';
import { payment } from '../../../../api/payment/payment';
import { Plan } from '../../types/payment';

export const fetchPlans = createAsyncThunk(
  'plans/fetchPlans',
  async (getToken: GetToken, thunkApi) => {
    try {
      const token = (await getToken() || '');
      const response = await payment.getPlans(token);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

type initialState = {
  plans: Plan[];
  loading: boolean;
  error: string | null;
};

export const initialState: initialState = {
  plans: [],
  loading: false,
  error: null,
};

const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPlans.fulfilled, (state, action: PayloadAction<Plan[]>) => {
      console.log(action.payload);
      state.plans = action.payload;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(
      fetchPlans.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchPlans.pending, (state) => {
      state.loading = true;
    });
  },
});

export default plansSlice.reducer;
