import { Interpolation, Theme } from '@emotion/react';
import { Dispatch, SetStateAction } from 'react';

export type ErrorType = string | null;
export type ResultCheckDuplication = {
  field: string;
  isUnique: boolean;
  message: string;
};
export type Direction = 'up' | 'down' | 'left' | 'right';
export interface Option {
  readonly label: string;
  readonly value: string;
}

export type Pagination = {
  pageSize?: number;
  page: number;
}

export type DestinationType = 'activeGallery' | 'sales' | '';

export type TabType = {
  label: string;
  id: string;
};

export type BaseColors = 'pink' | 'black' | 'white' | 'grey' | 'blue' | 'secondary';
export type BaseSizes = 'large' | 'medium' | 'small';

export interface SelectorMenuItem <ValueType, >{
  id: string;
  value: ValueType;
}

export type OnboardingContext = {
  stepIndex: number;
  setStepIndex: Dispatch<SetStateAction<number>>;
};

export type RequestStatuses = 'pending' | 'idle' | 'succeeded' | 'loading' | 'error';

export type ScreenSizeNames = 'mobile' | 'tablet' | 'desktop';

export type OptimisedStyles = {
  desktop?: Interpolation<Theme>;
  tablet?: Interpolation<Theme>;
  mobile?: Interpolation<Theme>;
};

export type AlertTypes = 'error' | 'warning' | 'confirmation';

export enum CurrencyTypes {
  USD = '$',
  ASD = 'AS $',
  EURO ='€',
}

export type MobileOS = 'IOS' | 'Android' | 'Others';
