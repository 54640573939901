import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TermsAndPrivacyListTitle, TermsAndPrivacyText } from '../styled';

export const StorageAndSecurityPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <>
      <TermsAndPrivacyListTitle>
        {t('privacy.storageAndSecurity')}
      </TermsAndPrivacyListTitle>
      <Trans t={t} i18nKey="privacy.storageAndSecurityPoints">
        <TermsAndPrivacyText hasMarginBottom>
          We are committed to ensuring that the personal information we collect
          is secure. In order to prevent unauthorised access or disclosure, we
          have put in place suitable physical, electronic and managerial
          procedures, to safeguard and secure personal information and protect
          it from misuse, interference, loss and unauthorised access,
          modification and disclosure.
        </TermsAndPrivacyText>
        <TermsAndPrivacyText hasMarginBottom>
          While we are committed to security, we cannot guarantee the security
          of any information that is transmitted to or by us over the Internet.
          The transmission and exchange of information is carried out at your
          own risk.
        </TermsAndPrivacyText>
      </Trans>
    </>
  );
};
