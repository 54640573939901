import React from 'react';
import { Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { HeaderWithLogo } from '../../../shared/components/HeaderWithLogo/HeaderWithLogo';
import {
  BetaDescriptionConteiner,
  ProtectedPageCard,
  ProtectedPageContentWrapper,
  ProtectedPageDescriptionText,
  ProtectedPageDescriptionTitle,
  ProtectedPageFooter,
  ProtectedPageHeaderWrapper,
  ProtectedPageImage,
  ProtectedPageWrapper,
} from './styled';
import { TextHeartIcon } from '../../../shared/icons/Landing';
import { LandingFooterLogoText } from '../../Landing/containers/LandingFooter/styled';
import { BetaForm } from './PasswordProtectedForm';

export const BetaDescription: React.FC = () => {
  const { t } = useTranslation('signInBeta');

  return (
    <BetaDescriptionConteiner>
      <ProtectedPageImage />
      <ProtectedPageDescriptionTitle>
        {t('signInTitle')}
      </ProtectedPageDescriptionTitle>
      <ProtectedPageDescriptionText>
        {t('signInText')}
      </ProtectedPageDescriptionText>
    </BetaDescriptionConteiner>
  );
};

export const PasswordProtectedPage: React.FC = () => {
  const { t } = useTranslation(['signIn', 'basicComponents']);

  return (
    <div>
      <ProtectedPageWrapper>
        <ProtectedPageHeaderWrapper>
          <HeaderWithLogo isCreator={false} />
        </ProtectedPageHeaderWrapper>
        <Grid container justifyContent="space-between" alignItems="center" style={{ height: '100%' }}>
          <Grid container item alignItems="center" justifyContent="center">
            <Grid xl={5} lg={7} md={8} xs={11}>
              <ProtectedPageCard>
                <ProtectedPageContentWrapper>
                  <BetaForm />
                  <BetaDescription />
                </ProtectedPageContentWrapper>
              </ProtectedPageCard>
            </Grid>
          </Grid>
        </Grid>
        <ProtectedPageFooter>
          <LandingFooterLogoText>
            <Trans t={t} i18nKey="landing:footer.builtWith">
              built with
              {' '}
              <span>
                <TextHeartIcon />
              </span>
              {' '}
              by creators, for creators
            </Trans>
          </LandingFooterLogoText>
        </ProtectedPageFooter>
      </ProtectedPageWrapper>
    </div>
  );
};
