import { useState, MouseEvent } from 'react';
import { Filters } from '../../../shared/types';

type ReturnObj = {
    chosenFilters: Filters[];
    handleChooseFilter: (e: MouseEvent<HTMLButtonElement>) => void;
  }

export const useGalleryFilters = (): ReturnObj => {
  const [chosenFilters, setChosenFilters] = useState<Filters[]>([]);

  const handleChooseFilter = (e: MouseEvent<HTMLButtonElement>) => {
    const filterId = e.currentTarget.id as Filters;
    if (chosenFilters?.includes(filterId)) {
      const newArrFilters = chosenFilters.filter((item) => item !== filterId);
      setChosenFilters(newArrFilters);
    } else {
      setChosenFilters([...chosenFilters, filterId]);
    }
  };

  return { chosenFilters, handleChooseFilter };
};
