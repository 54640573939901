import styled from '@emotion/styled';
import * as variables from '../../../../../constants/styles';
import { ImageSizes, PreviewImageSizes } from '../../../../../constants/galleryPreviewsAndCovers';
import { PreviewFirstHeaderWrapper } from '../../../../Creator/containers/CreatorAccount/Tabs/NewGalleryCreator/NewGalleryCreatorFirstStep/PreviewGalleryCovers/PreviewFirst/styled';

export const FirstTitle = styled.div`
  font-weight: 700;
  font-size: 60px;
  line-height: 90px;
  color: ${variables.textBlack};

  & h3 {
    margin: 0;
  }
`;

export const FirstMediumText = styled.div`
  font-size: 16px;
  line-height: 150%;
  color: ${variables.textBlack};
  text-align: start;
`;

export const FirstImageContainer = styled.div`
  width: ${ImageSizes.first.desktop.width}px;
  height: ${ImageSizes.first.desktop.height}px;
  box-sizing: border-box;
  position: relative;
  background: transparent;
  background-size: cover;
  object-fit: cover;
  display: flex;
`;

export const FirstCoverDate = styled.span`
  position: absolute;
  display: flex;
  left: -80px;
  bottom: 30px;

  & span {
    transform: rotate(-90deg);
    width: 80px;
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
  }
`;

export const FirstCoverDateTablet = styled(FirstCoverDate)<{ isPreview: boolean }>`
  left: -70px;
  bottom: 30px;

  & span {
    font-size: ${({ isPreview }) => (isPreview ? '10px' : '14px')};
    line-height: 150%;
  }
`;

export const ImageContainerFirstTablet = styled(FirstImageContainer)<{ isPreview: boolean }>`
  width: ${({ isPreview }) => (isPreview ? `${PreviewImageSizes.first.tablet.width}px` : '100%')};
  height: ${({ isPreview }) => (isPreview ? `${PreviewImageSizes.first.tablet.height}px` : 'auto')};
  max-width: ${ImageSizes.first.tablet.width}px;
  height: ${ImageSizes.first.tablet.height}px;
  min-height: ${ImageSizes.first.tablet.height}px;
  max-height: ${ImageSizes.first.tablet.height}px;
  ${({ isPreview }) => (isPreview ? '' : `
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
  }
  `)}
`;

export const FirstTitleTablet = styled(FirstTitle)<{ isPreview: boolean }>`
  font-size: ${({ isPreview }) => (isPreview ? '33px' : '48px')};
  line-height: ${({ isPreview }) => (isPreview ? '50px' : '72px')};  

  & h3 {
    font-size: ${({ isPreview }) => (isPreview ? '33px' : '48px')};
    line-height: 100%;  
  }
`;

export const FirstMediumTextTablet = styled(FirstMediumText)<{ isPreview: boolean }>`
  font-size: ${({ isPreview }) => (isPreview ? '10px' : '14px')};
  line-height: 150%;

  & p {
    margin: 0;
  }
`;

export const ImageContainerFirstMobile = styled(FirstImageContainer)<{ isPreview: boolean }>`
  width: ${({ isPreview }) => (isPreview ? `${PreviewImageSizes.first.mobile.width}px` : '100%')};
  height: ${({ isPreview }) => (isPreview ? `${PreviewImageSizes.first.mobile.height}px` : 'auto')};
  margin-bottom: ${({ isPreview }) => (isPreview ? '16px' : '24px')};
  ${({ isPreview }) => (isPreview ? '' : `
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${ImageSizes.first.mobile.height}px;
  transition: all 0.3s ease-in-out;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
  }
  `)}

  @media (max-width: 360px) {
    min-height: ${ImageSizes.first.mobile.height - 54}px;
    transition: all 0.3s ease-in-out;
  }

  @media (max-width: 310px) {
    min-height: ${ImageSizes.first.mobile.height - 104}px;
    transition: all 0.3s ease-in-out;
  }
`;

export const FirstTitleMobile = styled(FirstTitle)<{ isPreview: boolean }>`
  font-size: ${({ isPreview }) => (isPreview ? '21px' : '30px')};
  line-height: 140%;
`;

export const FirstMediumTextMobile = styled(FirstMediumText)<{ isPreview: boolean }>`
  font-size: ${({ isPreview }) => (isPreview ? '8px' : '16px')};
  margin: ${({ isPreview }) => (isPreview ? '0px 0px 12px' : '0px 16px 24px')};

  & p {
    margin: 0;
  }
`;

export const MobileWrapperFirst = styled.div`
  margin: 30px 10px 22px 10px;
`;

export const FirstHeaderMobileWrapper = styled(PreviewFirstHeaderWrapper)<{ isPreview: boolean }>`
  margin: ${({ isPreview }) => (isPreview ? '0px 0px 22px' : '0px 16px 24px')};
  width: fit-content;
`;
