import styled from '@emotion/styled';
import * as variables from '../../../../constants/styles';
import { TextLabelWrapper } from '../../styled';

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RadioLabel = styled.label`
  display: flex;
  position: relative;
  cursor: pointer;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;
  align-items: center;
  border: 1px solid ${variables.colorPink};
  border-radius: 50%;
  margin-right: 12px;
  margin-top: 8px;
  margin-bottom: 8px;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ div::after {
      display: block;
    }
  }

  & > div {
    background: ${variables.colorGreyLight};
    position: absolute;
    left: 3px;
    top: 3px;
    height: 10px;
    width: 10px;
    min-width: 10px;
    min-height: 10px;
    max-width: 10px;
    max-height: 10px;
    border: 1px solid ${variables.colorPink};
    border-radius: 50%;

    &::after {
      z-index: 10;
      content: "";
      position: absolute;
      display: none;
    }
  }

  & > div {
    &::after {
      left: -1px;
      top: -1px;
      height: 10px;
      width: 10px;
      min-width: 10px;
      min-height: 10px;
      max-width: 10px;
      max-height: 10px;
      background: ${variables.colorPink};
      border-radius: 50%;
          border: 1px solid ${variables.colorPink};

    }
  }
`;

export const CustomMarginTextLabel = styled(TextLabelWrapper)`
  cursor: pointer;
  margin-top: 9px !important;
  margin-bottom: 9px !important;
`;

export const RadioCustomErrorMessage = styled.div`
  position: relative;
  width: 5px;
  height: 100%;

  & > span {
    left: 0;
    top: 50%;
    bottom: 50%;
    margin-top: -9px;
  }
`;
