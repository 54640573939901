/* eslint-disable */
import { getIn, useFormikContext } from "formik";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ErrorMessage } from "../../../../../../../shared/components/styled";
import { useDialog } from "../../../../../../../shared/hooks/useDialog";
import { EditIcon, CloseIcon } from "../../../../../../../shared/icons";
import {
  GalleryDataForm,
  GallerySection,
} from "../../../../../../../shared/types";
import { deleteGallerySection } from "../../../../../redux/gallery/galleryCreatorThunk";
import {
  SectionTitle,
  CloseButtonWrapper,
  TextUploadedWrapper,
  SectionHeaderInfoUploading,
  SectionHeaderInfoUploaded,
  SectionHeaderInfoError,
} from "./styled";
import { UploadingItem } from "../../../../../types/gallery";
import { useCreatorAppDispatch } from "../../../../../../../shared/hooks/useTypedSelector";
import { useAuth } from "@clerk/clerk-react";

type SectionHeaderProps = {
  section: GallerySection;
  uploading: UploadingItem[];
  errorFiles: UploadingItem[];
  allFiles: File[];
  stateFiles: any;
};

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  section,
  uploading,
  errorFiles,
  allFiles,
  stateFiles,
}) => {
  const { t } = useTranslation("createNewGallery");
  const { values, handleChange, setFieldValue, errors, touched } =
    useFormikContext<GalleryDataForm>();
  const dispatch = useCreatorAppDispatch();
  const { getToken } = useAuth();

  const index = useMemo(
    () => values.sections.findIndex((item) => item.id === section.id),
    [values.sections, section]
  );

  const handleDeleteSection = (id: string) => {
    dispatch(
      deleteGallerySection({ galleryId: values.id, sectionId: id, getToken })
    );
    setFieldValue(
      "sections",
      values?.sections?.filter((item) => item.id !== id)
    );
  };

  const { setIsOpen: setIsOpenDialogWindow, dialogWindow } = useDialog<string>(
    "confirmation",
    section.id,
    handleDeleteSection,
    t("secondStep.deleteSectionDialog.question"),
    t("secondStep.deleteSectionDialog.title")
  );

  const error = useMemo(
    () => (getIn(errors, "sections") ? getIn(errors, "sections")[index] : null),
    [errors?.sections]
  );
  const wasTouched = useMemo(
    () =>
      getIn(touched, "sections") ? getIn(touched, "sections")[index] : null,
    [touched?.sections]
  );
  const filesNumber = useMemo(
    () => section?.files?.length + section?.ideas.length,
    [section?.files?.length, section?.ideas.length]
  );

  return (
    <>
      <SectionTitle error={error?.title && wasTouched?.title}>
        <div>
          <input
            name={`sections[${index}].title`}
            value={section.title}
            onChange={handleChange}
            style={{ width: `${section.title.length * 11}px` }}
          />
          <EditIcon />
          {error?.title && wasTouched?.title ? (
            <ErrorMessage
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {error?.title}
            </ErrorMessage>
          ) : null}
          {section?.files?.length > 0 && (
            <>
              <Trans i18nKey="secondStep.files" t={t}>
                {{ filesNumber }} files
              </Trans>
            </>
          )}
          {uploading?.length ? (
            <SectionHeaderInfoUploading>
              Uploading {uploading?.length || 0} files...
            </SectionHeaderInfoUploading>
          ) : null}
          {!uploading?.length && !errorFiles?.length && stateFiles.length && values?.sections?.length ? (
            <SectionHeaderInfoUploaded>
              100% uploaded
            </SectionHeaderInfoUploaded>
          ) : null}
          <SectionHeaderInfoError>
            {!!errorFiles.length && (
              <TextUploadedWrapper theme="error">
                <Trans i18nKey="secondStep.wasntUploaded" t={t}>
                  Files weren&apos;t uploaded: {{ files: errorFiles.length }}
                </Trans>
              </TextUploadedWrapper>
            )}
          </SectionHeaderInfoError>
        </div>
        {values?.sections?.length > 1 && (
          <CloseButtonWrapper>
            <span>{t("secondStep.deleteSection")}</span>
            <button type="button" onClick={() => setIsOpenDialogWindow(true)}>
              <CloseIcon />
            </button>
          </CloseButtonWrapper>
        )}
      </SectionTitle>
      {dialogWindow}
    </>
  );
};
