export const getPathData = (): { businessName: string, galleryName: string } => {
  const path = window.location.href;
  const splitedPath = path.split('/');
  const businessName = splitedPath[2].split('.')[0];

  return ({
    businessName,
    galleryName: path?.split('/')[3],
  });
};
