import { Grid } from '@material-ui/core';
import { useFormik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSignIn } from '@clerk/clerk-react';
import * as urls from '../../../../constants/urls';
import { CommonInput } from '../../../../shared/components/FormElements/CommonInput/CommonInput';
import {
  ButtonWithoutShadow,
  FlexStartWrapper,
  FormBase,
  SignInUpCard,
  TitleSimple,
} from '../../../../shared/styles';
import { ResetSchema } from '../../../../shared/validation/signInUp/creator/signInUpSchemas';
import { fetchResetPasswordInitialCreator } from '../../redux/userCreator/resetPassword/resetPasswordCreatorThunk';
import { PrimaryWhiteButton } from '../../../../shared/styles/Buttons';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../shared/hooks/useTypedSelector';
import { PinkLoader } from '../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { setTimeToResendData } from '../../utils/helpers';
import { email } from '../../../../shared/validation/beta/betaSchemas';

export const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useCreatorAppDispatch();
  const { t } = useTranslation(['signIn', 'basicComponents']);
  const error = useTypedSelectorCreator((state) => state?.resetPassword?.error);
  const wasSent = useTypedSelectorCreator((state) => state.resetPassword?.emailWasSent);
  // const isLoading = useTypedSelectorCreator((state) => state?.resetPassword?.loading);
  const [isLoading, setIsLoading] = useState(false);
  const { isLoaded, signIn, setActive } = useSignIn();

  const onSubmit = async (
    data: { email: string },
    bag: FormikHelpers<{ email: string }>,
  ) => {
    bag.setSubmitting(false);
    setIsLoading(true);
    signIn?.create({
      strategy: 'reset_password_email_code',
      identifier: data.email,
    })
      .then(() => { setIsLoading(false); navigate(urls.resetSuccessUrl); })
      .catch((e) => {
        setIsLoading(false);
        console.error('error', e.errors);
        bag.setFieldError('email', e?.errors[0]?.message);
      });
    window.sessionStorage.setItem('resetEmail', data.email);
    setTimeToResendData();
    // dispatch(fetchResetPasswordInitialCreator(data.email));
  };

  const {
    values, handleSubmit, errors, handleChange, touched, handleBlur,
  } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: ResetSchema,
    onSubmit: (
      data: { email: string },
      bag: FormikHelpers<{ email: string }>,
    ) => onSubmit(data, bag),
  });

  const onBack = () => {
    navigate(urls.creatorSignInUrl);
  };

  if (wasSent) {
    navigate(urls.resetSuccessUrl);
  }

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xl={6} lg={7} md={9} xs={11}>
        <SignInUpCard theme="reset">
          <TitleSimple>{t('signIn:resetPassword')}</TitleSimple>
          <FormBase theme="column">
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xl={8} lg={9} md={10} xs={12}>
                  <CommonInput
                    error={errors.email || error}
                    value={values.email}
                    name="email"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    type="email"
                    placeholder="Your email"
                    touched={touched.email}
                    withoutMargins
                  />
                </Grid>
                <Grid item xl={8} lg={9} md={10} xs={12}>
                  <FlexStartWrapper style={{ margin: '0px' }}>
                    <ButtonWithoutShadow onClick={onBack}>{t('basicComponents:buttons.back')}</ButtonWithoutShadow>
                    <PrimaryWhiteButton type="submit" disabled={isLoading} style={{ minWidth: '213px' }}>
                      {isLoading ? <PinkLoader size="xs" /> : t('signIn:resetPassword')}
                    </PrimaryWhiteButton>
                  </FlexStartWrapper>
                </Grid>
              </Grid>
            </form>
          </FormBase>
        </SignInUpCard>
      </Grid>
    </Grid>
  );
};
