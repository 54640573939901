import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { MainLogoNoWhiteSpace } from '../../../../../shared/icons/MainLogo';
import {
  GalleryFooterWrapper,
  GalleryFooterPoweredBy,
  GalleryFooterV2Wrapper,
  GalleryFooterMediaWrp,
} from './styled';
import * as urls from '../../../../../constants/urls';
import {
  InstagramIcon,
  YouTubeIcon,
  FacebookIcon,
  LinkedInIcon,
  PinterestIcon,
  VimeoIcon,
} from '../../../../../shared/icons';
import { useTypedSelectorClient } from '../../../../../shared/hooks/useTypedSelector';

type GalleryFooterProps = {
  branding: boolean;
};

export const GalleryFooterV2: React.FC<GalleryFooterProps> = ({
  branding = true,
}) => {
  const { t } = useTranslation('gallery');
  const { pathname } = useLocation();
  const isPaymentPage = pathname.includes(urls.clientPaymentUrl);
  const isSignInPage = pathname.includes(urls.clientSignInUrl);

  if (isPaymentPage || isSignInPage) {
    return null;
  }

  return (
    <GalleryFooterV2Wrapper>
      <GalleryFooterMediaWrp>
        <NavLink to="/">
          <InstagramIcon color="grey" />
        </NavLink>
        <NavLink to="/">
          <YouTubeIcon color="grey" />
        </NavLink>
        <NavLink to="/">
          <FacebookIcon color="grey" />
        </NavLink>
      </GalleryFooterMediaWrp>
      {branding && (
        <GalleryFooterPoweredBy>
          {t('poweredBy')}
          {' '}
          <MainLogoNoWhiteSpace />
        </GalleryFooterPoweredBy>
      )}
    </GalleryFooterV2Wrapper>
  );
};

export const GalleryFooter: React.FC<GalleryFooterProps> = ({
  branding = true,
}) => {
  const { t } = useTranslation('gallery');
  const { pathname } = useLocation();
  const socialMedia = useTypedSelectorClient((state) => state?.signIn?.socialMedia);

  const isPaymentPage = pathname.includes(urls.clientPaymentUrl);
  const isSignInPage = pathname.includes(urls.clientSignInUrl);

  if (isPaymentPage || isSignInPage) {
    return null;
  }

  return (
    <GalleryFooterWrapper>
      <GalleryFooterMediaWrp>
        {socialMedia?.instagram && (
        <a target="_blank" href={socialMedia?.instagram} rel="noreferrer">
          <InstagramIcon color="grey" />
        </a>
        )}
        {socialMedia?.facebook && (
        <a target="_blank" href={socialMedia?.facebook} rel="noreferrer">
          <FacebookIcon color="grey" />
        </a>
        )}
        {socialMedia?.linkedin && (
        <a target="_blank" href={socialMedia?.linkedin} rel="noreferrer">
          <LinkedInIcon color="grey" />
        </a>
        )}
        {socialMedia?.pinterest && (
        <a target="_blank" href={socialMedia?.pinterest} rel="noreferrer">
          <PinterestIcon />
        </a>
        )}
        {socialMedia?.vimeo && (
        <a target="_blank" href={socialMedia?.vimeo} rel="noreferrer">
          <VimeoIcon />
        </a>
        )}
        {socialMedia?.youtube && (
        <a target="_blank" href={socialMedia?.youtube} rel="noreferrer">
          <YouTubeIcon color="grey" />
        </a>
        )}
      </GalleryFooterMediaWrp>
      {branding && (
        <GalleryFooterPoweredBy>
          {t('poweredBy')}
          {' '}
          <MainLogoNoWhiteSpace />
        </GalleryFooterPoweredBy>
      )}
    </GalleryFooterWrapper>
  );
};
