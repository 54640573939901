import React from 'react';
import { MediumPinkText } from '../../../../../../../shared/styles';

type ResendConfirmEmailButtonProps = {
  isVisible: boolean;
  msCounter: number;
}

export const ResendConfirmEmailButton: React.FC<ResendConfirmEmailButtonProps> = ({
  isVisible,
  msCounter,
}) => (
  <div style={{ display: 'flex', marginLeft: '30px' }}>
    <MediumPinkText>
      {msCounter > 0 ? `Resend link in ${msCounter}` : (isVisible && 'Confirm your email')}
    </MediumPinkText>
  </div>
);
