import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '@clerk/clerk-react';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../../../../shared/hooks/useTypedSelector';
import {
  MediumGreyText,
  SimpleBlackText,
  WhiteButtonWithoutShadow,
} from '../../../../../../../../../shared/styles';
import { GalleryData, GalleryFile, GalleryIdea } from '../../../../../../../../../shared/types';
import { CommentGet } from '../../../../../../../../../shared/types/client';
import { getFormattedDateOrDateName, isIdea } from '../../../../../../../../../shared/utils';
import { getAddCommentFunc, getDeleteCommentFunc } from '../../../../../../../../../shared/utils/gallery/comments';
import { FileAndCommentsWindow } from '../../../../../../../../GalleryView/components/CommentArea/FileAndCommentsWindow';
import {
  CommentItemContainer,
  CommentItemInfoWrapper,
  CommentItemFileWrapper,
  CommentItemReplyButton,
  CommentItemMessage,
} from './styled';
import { getFileInContainer } from '../../../../../../../../../shared/utils/createGallery';
import { getAllFiles } from '../../../../../../../../../shared/utils/gallery/getAllFiles';

type CommentsItemProps = {
  comment: CommentGet;
  galleryId: string;
};

export const CommentsItem: React.FC<CommentsItemProps> = ({ comment, galleryId }) => {
  const { t } = useTranslation('basicComponents');
  const [isOpenComment, setIsOpenComment] = useState(false);
  const timezone = useTypedSelectorCreator((state) => state.creator?.currentUser?.timezone);
  const dateFormat = useTypedSelectorCreator((state) => state.creator?.currentUser?.dateFormat) || 'mm/dd/yyyy';
  const activeGalleries = useTypedSelectorCreator((state) => state.gallery.activeGalleries);
  const galleries = (activeGalleries || []);
  const gallery = galleries?.find((item) => item.id === galleryId) as unknown as GalleryData;
  const dispatch = useCreatorAppDispatch();
  const { user } = useUser();

  const addComment = getAddCommentFunc(dispatch, gallery, comment.fileId);
  const deleteComment = getDeleteCommentFunc(dispatch, gallery?.id || '', comment.fileId);

  const handleClose = () => {
    setIsOpenComment(false);
  };
  const handleOpen = () => {
    setIsOpenComment(true);
  };

  const {
    email, message, date: time, fileId,
  } = comment;
  const files = getAllFiles(gallery);
  const file = files.find((state) => state.id === fileId);

  const formattedDate = getFormattedDateOrDateName(time, dateFormat, timezone, true);
  const fileItem = isIdea(file) ? (file as GalleryIdea).cover : (file as GalleryFile);
  const fileInContainer = getFileInContainer({
    file: fileItem,
  });

  return (
    <>
      <CommentItemContainer>
        <CommentItemInfoWrapper>
          <SimpleBlackText fontWeight={600}>{email || ''}</SimpleBlackText>
          <CommentItemMessage>{message}</CommentItemMessage>
          <MediumGreyText>{formattedDate}</MediumGreyText>
        </CommentItemInfoWrapper>
        <CommentItemFileWrapper>
          <CommentItemReplyButton>
            <WhiteButtonWithoutShadow type="button" onClick={handleOpen}>
              {t('buttons.reply')}
            </WhiteButtonWithoutShadow>
          </CommentItemReplyButton>
          {fileInContainer}
        </CommentItemFileWrapper>
      </CommentItemContainer>
      <FileAndCommentsWindow
        addComment={addComment}
        deleteComment={deleteComment}
        isOpen={isOpenComment}
        userProfileUrl={user?.hasImage ? user.imageUrl : ''}
        handleClose={handleClose}
        comments={[{
          text: comment.message,
          id: comment.id,
          date: comment.date,
          file: comment.fileUrl,
          email: comment.email,
          userName: comment.userName,
          photoUrl: comment.photoUrl,
        }]}
        file={fileItem}
        dateFormat={dateFormat}
      />
    </>
  );
};
