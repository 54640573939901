import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ItemWrapper } from './styled';

type ViewsLikesDownloadsSharesProps = {
  icon: ReactElement;
  numberOfData: number;
  type: 'views' | 'likes' | 'shares' | 'downloads';
};

export const ViewsLikesDownloadsShares: React.FC<ViewsLikesDownloadsSharesProps> = ({
  icon,
  numberOfData,
  type,
}) => {
  const { t } = useTranslation('gallery');

  const currentNumberOfData = numberOfData || 0;

  return (
    <ItemWrapper>
      {icon}
      {' '}
      <Trans t={t} i18nKey={type}>
        {{ numberOfData: currentNumberOfData }}
        {' '}
        {type}
      </Trans>
    </ItemWrapper>
  );
};
