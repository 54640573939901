import styled from '@emotion/styled';
import * as variables from '../../../../../../../constants/styles';
import { getColorOfText } from '../../../../../../../shared/utils/style/getColorOfText';

export const SectionWrapper = styled.div`
  margin-bottom: 85px;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-bottom: 25px;
  }
`;

export const CardsGridWrapper = styled.div`
  display: grid;
  column-gap: 30px;
  row-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(195px, 195px));
  margin: 1px 0 30px 1px;
  padding-top: 0px;
`;

export const CardsGridDocsWrapper = styled(CardsGridWrapper)`
  grid-template-columns: repeat(auto-fill, minmax(82px, max-content));
`;

export const DocCardHoverElement = styled.div`
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: #000000b8;
  border-radius: inherit;
  border-radius: 4px;
`;

export const DocCardWrapper = styled.div<{hasError?: boolean}>`
  box-sizing: border-box;
  display: flex;
  position: relative;
  border-radius: 4px;
  border: ${({ hasError }) => (hasError ? `1px solid ${variables.colorError}` : '')};

  & #button-hover {
    position: absolute;
    cursor: pointer;
    display: none;
    color: ${({ hasError }) => (hasError ? variables.colorGreyLight : variables.textBlack)};
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: inherit;
    background: ${variables.colorGreyLight};
    justify-content: center;
    align-items: center;

    & > svg {
      color: black;
      z-index: 9999;
    }
  }

 ${({ hasError }) => !hasError
    && `&:hover {
      color: transparent;
  
      & #button-hover {
        display: flex;
      }
    }`}

  ${({ hasError }) => (hasError
    ? `&:hover {
    & #doc-card-hover {
      display: flex;
    }
  }`
    : '')}
`;

export const FileCardWrapper = styled.div<{hasError?: boolean}>`
  box-sizing: border-box;
  border-radius: 16px;
  width: 195px;
  height: 171px;
  color: ${variables.textGrey};
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: #f0f1f2;
  transition: all 0.2s ease-in-out;

  & > svg {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 900;
    color: ${variables.colorGreyLight};
    opacity: 1;
    transition: 0.2s ease-in-out;
  }

  & .delete-button {
    opacity: 0;
    position: absolute;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.006613991477272707) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6884321732954546) 100%);
    right: 0;
    width: 25%;
    height: 100%;
    transition: all 0.2s ease-in-out;

    & > svg {
      position: absolute;
      top: 8px;
      right: 10px;
      color: ${variables.colorGreyLight};
    }
  }

  &:hover {
    transition: all 0.2s ease-in-out;

    & .delete-button {
      z-index: 900;
      opacity: ${({ hasError }) => (hasError ? 0 : 1)};
      transition: all 0.2s ease-in-out;
    }

    & > svg {
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }
  }

  & > div {
    margin: 0;
    border-radius: inherit;
  }
`;

export const ErrorTryAgainButtons = styled.div<{ size: 'sm' | 'lg' }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${({ size }) => (size === 'sm' ? '0px' : '14px')};

  & button {
    display: flex;
  }
`;

export const ButtonCard = styled.div<{isDragProcess?: boolean}>`
  box-sizing: border-box;
  border-radius: 16px;
  width: 195px;
  color: ${variables.textGrey};
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  background: ${(props) => (props.isDragProcess ? '#EEF6FC' : variables.colorGreyLight)};
  padding: 24px 12px;
  height: 170px;

  & input {
    cursor: pointer;
    position: absolute;
    z-index: 1000;
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  & img {
    width: auto;
    height: auto;
    margin-bottom: 14px;
  }

  & > div {
    margin-top: 16px;
  }
`;

export const CardBorder = styled.div`
  width: 195px;
  height: 169px;
  background: ${variables.colorBleak};
  position: relative;
  border-radius: 17px;
  margin: -1px;
  display: flex;
  padding: 1px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:hover {
    transition: 0.5s;
    background: ${variables.colorGradientDiagonal};
  }
`;
export const PlusIcon = styled.div`
  position: relative;
  width: 16px;

  &::before {
    content: "";
    position: absolute;
    display: block;
    ${({ theme }: { theme?: string }) => (theme === 'large'
    ? `width: 16px;
    height: 2px;`
    : `width: 13.68px;
    height: 1.58px;`)}

    background: ${variables.textGrey};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    ${({ theme }: { theme?: string }) => (theme === 'large'
    ? `height: 16px;
    width: 2px;`
    : `height: 13.68px;
    width: 1.58px;`)}

    background: ${variables.textGrey};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
  }
`;
export const ConstCardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const SectionTitle = styled.div<{error?: boolean}>`
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  color: ${variables.textBlack};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & input {
    background: ${variables.colorGreyLight};
    border: none;
    font-size: 18px;
    line-height: 27px;
    cursor: pointer;
    color: ${variables.textBlack};
    min-width: 50px;

    ${(props) => (props.error ? `border-bottom: 0.5px solid ${variables.colorError};` : '')}

    &:focus {
      cursor: text;
    }
  }

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    & > span {
      top: 30%;
      left: 100%;
    }

    & svg {
      color: ${(props) => (props.error ? variables.colorError : variables.textBlack)};
      margin-left: 12px;
      margin-right: 12px;
      min-width: 24px;
      min-height: 24px;
    }
  }
`;
export const CloseButtonWrapper = styled.div`
  & span {
    display: none;
    box-sizing: border-box;
    background: ${variables.textGrey};
    padding: 5px 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: ${variables.colorGreyLight};
    border-radius: 2px;
  }

  & button {
    & svg {
      color: ${variables.textGrey};
    }
  }

  &:hover {
    & span {
      display: block;
    }
  }
`;

export const TextUploadedWrapper = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-left: 20px;
  margin-right: 20px;
  color: ${({ theme }: { theme: string}) => getColorOfText(theme)};
`;

export const SectionFooterWrapper = styled.div`
  padding: 0;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;
export const ViewMoreButton = styled.button`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 14px;
  line-height: 150%;
  color: ${variables.textGrey};

  & span {
    margin-right: 10px;
  }
`;

export const ButtonAddWrapper = styled.button`
  display: flex;
  flex-direction: row;
  font-size: 18px;
  line-height: 27px;
  color: ${variables.textGrey};
  align-items: center;
  cursor: pointer;

  & div {
    margin-right: 14px;
  }
`;
export const FileCardText = styled.p`
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
`;
export const IdeaOpenButton = styled.div`
  position: absolute;
  height: 100%;
  width: 75%;
  left: 0;
  z-index: 1000;
`;

export const TextWithLines = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 150%;
  color: ${variables.colorBleak};
  margin-top: 15px;
  margin-bottom: 30px;

  &::before,
  &::after {
    content: "";
    flex: 1 1;
    border-bottom: 0.5px solid ${variables.colorBleak};
    margin: auto;
  }

  &::before {
    margin-right: 10px;
  }

  &::after {
    margin-left: 10px;
  }
`;

export const ErrorFilesWrapper = styled.div`
  position: relative; 
  margin-left: -24px;
`;

export const PositionedHelpTip = styled.span`
  position: absolute;
  background: transparent;
  right: 7px;
  top: 7px;

  & > span {
    bottom: 130%;
  }
`;

export const SectionHeaderInfoUploading = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #828D95;
  margin: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SectionHeaderInfoUploaded = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #76EE58;
  margin: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SectionHeaderInfoError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DuplicatedFilesText = styled.p`
  display: flex;
  height: 30px;
  color: ${variables.colorPink};
  white-space: pre;
`;
