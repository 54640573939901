import styled from '@emotion/styled';
import { theme } from '../../../../../../shared/components/GlobalContainer/styled';
import { ColorfulButton, MediumBlackText, TextBlackLarge } from '../../../../../../shared/styles';
import { BaseCartFile } from '../../CartItem/styled';
import * as variables from '../../../../../../constants/styles';

export const PaymentSuccessfulZipDownload = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 0px;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex-direction: column;
    margin-top: 38px;
    margin-bottom: 16px;

    & > div {
      margin-bottom: 24px;
    }
  }
`;

export const PaymentSuccessfulZipFolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 24px;

  & * {
    margin-right: 8px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-right: 0px;

    & * {
      &:last-child {
        margin-right: 0px;
      }
    }
  }
`;

export const PaymentSuccessfulButton = styled(ColorfulButton)`
  margin: 0;
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
  }
`;

export const PaymentFilesWrapper = styled.div`
  display: grid;
  column-gap: 30px;
  row-gap: 30px;
  grid-template-columns: repeat(2, minmax(195px, max-content));

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    grid-template-columns: repeat(2, minmax(167px, max-content));
    row-gap: 20px;
    column-gap: 20px;
  }
`;

export const PaymentSuccessfulFilesWrapper = styled.div`
  width: 100%;
  display: grid;
  column-gap: 30px;
  row-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(195px, max-content));
  justify-content: center;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    grid-template-columns: repeat(auto-fit, minmax(167px, max-content));
    row-gap: 20px;
    column-gap: 20px;
  }
  
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, max-content));
    row-gap: 8px;
    column-gap: 8px;
  }
`;

export const PaymentPositionedElement = styled.button`
  padding: 0px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
`;

export const DownloadableFileCard = styled(BaseCartFile)`
  & img {
    max-width: none;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    width: 167px;
    height: 169px;
    min-width: 167px;
    min-height: 169px;
    margin-right: 0px;
    margin-bottom: 0px;  

    & #basic-doc {
      border-radius: 16px;
      margin-bottom: 8px;
      margin-right: 8px;

      font-size: 10px;
      padding: 17.5px;

      & svg {
        width: 31px;
        height: 36px;
      }
    }
  }
`;

export const CardElementFormWrapper = styled.form`
  max-width: 380px;
  display: flex;
  flex-direction: column;

  & button {
    margin: auto;
    margin-top: 20px;
    width: fit-content;
  }
`;

export const PaymentForm = styled.form`
  width: 100%;
  box-sizing: border-box;
  padding: 100px 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    padding: 0px;
  }
`;

export const PaymentElementWrapper = styled.div`width: 100% !important;`;

export const PaymentPayBtnWrapper = styled.div`
  margin-top: 30px;
  width: 100%;

  & button {
    width: 100% !important;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  min-height: 46px;
`;

export const DownloadLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WarningTextWrapper = styled(MediumBlackText)`
  display: flex;
  margin-bottom: 40px;
  max-width: 800px;
  align-items: flex-start;

  & svg {
    color: ${variables.colorError};
    padding: 0px 8px 0px 0px;
  }
`;
