import styled from '@emotion/styled';
import * as variables from '../../../../../../../../constants/styles';

export const PreviewWrapper = styled.div`
  height: 235px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > button,
  > span {
    font-size: 14px;
    line-height: 150%;
    color: ${variables.textGrey};
    visibility: hidden;
    width: 307px;
    text-align: center;
  }

  &:hover {
    & > button,
    > span {
      visibility: visible;
    }
  }
`;

export const PreviewCard = styled.div`
  width: 307px;
  height: 200px;
  min-height: 200px;
  background: #f0f1f2;
  border-radius: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 19px 13px;
  box-sizing: border-box;
  border:
    2px solid
    ${({ theme }) => (theme ? variables.colorPink : variables.colorGreyLight)};
`;

export const ClosePreviewButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;

  & button {
    margin-right: 40px !important;
  }
`;
