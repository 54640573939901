import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { theme } from '../components/GlobalContainer/styled';
import { ScreenSizeNames } from '../types';

type ReturnObj = {
  screenSize: ScreenSizeNames;
}

export const useCheckWindowSize = (): ReturnObj => {
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));

  const [screenSize, setScreenSize] = useState<ScreenSizeNames>('desktop');

  useEffect(() => {
    if (matchesMd) {
      setScreenSize('mobile');
    } else if (matchesLg) {
      setScreenSize('tablet');
    } else {
      setScreenSize('desktop');
    }
  }, [matchesLg, matchesMd]);

  return ({
    screenSize,
  });
};
