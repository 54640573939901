import styled from '@emotion/styled';
import * as variables from '../../../../../../../../constants/styles';
import { PlanEnum, PlanTypes } from '../../../../../../../../shared/types';

export const PlanCardContainer = styled.div`
  margin-bottom: 30px;
  margin-right: 30px;
`;

export const PlanCardWrapper = styled.div<{ chosen: boolean, border: PlanTypes }>`
  width: 100%;
  height: fit-content;
  background: ${(props) => (props.chosen ? '#F5F5F5' : variables.colorGreyLight)};
  border-radius: 9px;
  transition: all 0.5s;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${(props) => {
    if (props.border === PlanEnum.Free) return 'border: 1px solid #828D9566;';
    if (props.border === PlanEnum.Basic) return 'border: 1px solid #828D9566;';
    if (props.border === PlanEnum.Pro) return `border: 1px solid ${variables.colorPink}`;
    if (props.border === PlanEnum.ProPlus) {
      return `
    border: 1px solid transparent;
    border-radius: 9px;
    background-image: linear-gradient(${variables.colorGreyLight}, ${variables.colorGreyLight}), linear-gradient(270deg, #E6934F 0%, #E186B0 49.82%, #78C0EA 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    `;
    }
    return '#828D9566';
  }}
  
`;

export const PlanCardContent = styled.div<{ chosen: boolean }>`
  padding: 28px 80px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(100% - 160px);
  border-radius: inherit;
  background: ${(props) => (props.chosen ? '#F5F5F5' : variables.colorGreyLight)};
`;

export const BillSwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const TitleAndPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 4px;
  margin-top: 4px;

  & > span {
    margin-right: 8px;
  }
`;

export const ShowHideButton = styled.button`
  padding: 0;
  font-size: 14px;
`;

export const DescriptionWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;
