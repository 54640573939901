import React, { ReactElement } from 'react';
import { StarIcon, DiamondIcon } from '../../icons';
import { GradientColorText, TitleText, TitleTextPink } from '../../styles';
import { PlanEnum, PlanTypes } from '../../types';
import { TextWithIconWrapper } from '../../../modules/Creator/containers/CreatorAccount/Tabs/Settings/ChangePlanPage/styled';
import { BetaLogo } from '../../components/BetaLogo/BetaLogo';

export const getPlanTitle = (planType: PlanTypes): ReactElement => {
  switch (planType) {
    case PlanEnum.Beta:
      return <div style={{ paddingTop: '20px' }}><BetaLogo /></div>;
    case PlanEnum.Free:
      return <TitleText style={{ display: 'block' }} fontWeight={500}>{planType}</TitleText>;
    case PlanEnum.Basic:
      return <TitleText style={{ display: 'block' }} fontWeight={500}>{planType}</TitleText>;
    case PlanEnum.Pro:
      return (
        <div style={{ display: 'flex' }}>
          <TextWithIconWrapper>
            <StarIcon />
            <TitleTextPink fontWeight={500}>
              {planType}
            </TitleTextPink>
          </TextWithIconWrapper>
        </div>
      );
    case PlanEnum.ProPlus:
      return (
        <TitleTextPink style={{ display: 'block' }}>
          <TextWithIconWrapper>
            <DiamondIcon />
            <GradientColorText fontWeight={500}>
              {planType}
            </GradientColorText>
          </TextWithIconWrapper>
        </TitleTextPink>
      );
    default:
      return <TitleText style={{ display: 'block' }} fontWeight={500}>{planType}</TitleText>;
  }
};
