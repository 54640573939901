import { Grid } from '@material-ui/core';
import { FormikHelpers, useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SignUpFirstStep } from './SignUpFirstStep';
import { SignUpSecondStep } from './SignUpSecondStep';
import { SignUpInProcess } from '../../../../../shared/types';
import { SignUpSchema } from '../../../../../shared/validation/signInUp/creator/signInUpSchemas';
import {
  ErrorWrapper,
  FormBase,
  SignInUpCard,
  TitleSimple,
} from '../../../../../shared/styles';
import { ProgressBar } from '../styled';
import {
  useTypedSelectorCreator,
} from '../../../../../shared/hooks/useTypedSelector';
import { signUpInitialValues } from '../../../../../constants/initialValues';
import { ErrorMessage } from '../../../../../shared/components/styled';

const firstStepFields = ['firstName', 'lastName', 'businessName', 'password'];

type SignUpStepsProps = {
  error: { field: string, message: string }[];
  isLoading: boolean;
  signUpSubmit: (
    data: SignUpInProcess,
    bag: FormikHelpers<SignUpInProcess>,
  ) => void;
};

export const SignUpSteps: React.FC<SignUpStepsProps> = ({
  error,
  isLoading,
  signUpSubmit,
}) => {
  const [step, setStep] = useState(1);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const { t } = useTranslation('signUp');

  const signUpErrors = useTypedSelectorCreator(
    (state) => state.signUpCreator.error,
  );

  const schema = useMemo(() => SignUpSchema(step), [step]);

  const onSubmit = async (
    data: SignUpInProcess,
    bag: FormikHelpers<SignUpInProcess>,
  ) => {
    if (step === 2) {
      window.localStorage.setItem('onboarded', 'false');
      setWasSubmitted(true);
      signUpSubmit(data, bag);
    } else {
      setStep((prev) => prev + 1);
      bag.setSubmitting(false);
      if (signUpErrors?.errors?.length === 0 || !signUpErrors) {
        bag.setTouched({});
      }
    }
  };

  const onBack = () => {
    setStep((prev) => prev - 1);
  };

  const formik = useFormik({
    initialValues: signUpInitialValues,
    validationSchema: schema,
    onSubmit: (data, bag) => onSubmit(data, bag),
  });

  useEffect(() => {
    if (error) {
      error.forEach((item) => {
        if (item.field) {
          formik.setFieldError(item.field, item.message);
          if (firstStepFields.includes(item.field) && step >= 2 && wasSubmitted) {
            setStep(1); // back to the 1st step, when just receive an error from the 1st step
          }
        }
        setWasSubmitted(false);
      });
    }
  }, [error, step]);

  const notDataErrors: string[] = useMemo(() => {
    if (error.length > 0) {
      // eslint-disable-next-line array-callback-return, consistent-return
      error.filter((item) => {
        if (!item.field) {
          return item.message;
        }
      });
    }
    return [];
  }, [error]);

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xl={6} lg={7} md={10} sm={11}>
        <SignInUpCard theme="signup">
          <ProgressBar theme={33 * (wasSubmitted ? step + 1 : step)}>
            <div />
          </ProgressBar>
          <TitleSimple>
            {step === 1 ? t('someMoreDetails') : t('almostDone')}
          </TitleSimple>
          <FormBase theme="column">
            <form onSubmit={formik.handleSubmit} noValidate>
              <Grid container justifyContent="center">
                <Grid item sm={9}>
                  <ErrorWrapper>
                    {(notDataErrors.length) ? (
                      notDataErrors.map((item) => (
                        <ErrorMessage style={{ maxWidth: '100%', width: '100%' }}>
                          {item}
                        </ErrorMessage>
                      ))
                    ) : null}
                  </ErrorWrapper>
                </Grid>
                {step === 1 ? (
                  <SignUpFirstStep
                    values={formik.values}
                    errors={formik.errors}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    touched={formik.touched}
                  />
                ) : (
                  <SignUpSecondStep
                    setFieldValue={formik.setFieldValue}
                    onBack={onBack}
                    values={formik.values}
                    errors={formik.errors}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    touched={formik.touched}
                    isLoading={isLoading}
                  />
                )}
              </Grid>
            </form>
          </FormBase>
        </SignInUpCard>
      </Grid>
    </Grid>
  );
};
