import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { SignUpCheckbox, SignUpLinks } from '../styled';
import {
  FormikCustomProps,
  SetFieldValue,
  SignUpInProcess,
} from '../../../../../shared/types';
import { CommonCheckbox } from '../../../../../shared/components/FormElements/CommonCheckbox/CommonCheckbox';
import {
  BleakText,
  ButtonWithoutShadow,
  FlexStartWrapper,
  PrimaryWhiteButton,
  MediumBlackText,
} from '../../../../../shared/styles';
import { useGetWhatDoYouDoMenuItems } from './useGetWhatDoYouDoMenuItems';
import { CustomDatePicker } from '../../../../../shared/components/FormElements/CustomDatePicker/CustomDatePicker';
import { countryValue, doValue } from '../../../../../constants/initialValues';
import { createCountriesList } from '../../../../../constants/selectors';
import { useGetSelectionItems } from '../../../../../shared/hooks/useGetSelectionItems';
import { CommonSelect } from '../../../../../shared/components/FormElements/CommonSelect/CommonSelect';
import { PinkLoader } from '../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { PositionedLoader } from '../../../../../shared/components/Loaders/PinkLoader/styled';
import * as urls from '../../../../../constants/urls';
import { ErrorMessage } from '../../../../../shared/components/styled';

type SignUpSecondStepProps = {
  onBack: () => void;
  setFieldValue: SetFieldValue;
  isLoading: boolean;
} & FormikCustomProps<SignUpInProcess>;

export const SignUpSecondStep: React.FC<SignUpSecondStepProps> = ({
  onBack,
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  setFieldValue,
  isLoading,
}) => {
  const { t } = useTranslation(['signUp, basicComponents']);
  const getSelectionItems = useGetSelectionItems();

  const countriesList = useMemo(() => createCountriesList(), []);
  const menuItemsCounties = useMemo(
    () => getSelectionItems(countriesList),
    [countriesList],
  );
  const { menuItems, items } = useGetWhatDoYouDoMenuItems();
  console.log(values, errors);
  return (
    <>
      <Grid item sm={9}>
        <CustomDatePicker
          error={errors.dob}
          data={values.dob}
          name="dob"
          limits={false}
          placeholder={t('signUp:dobPlaceholder')}
          touched={touched.dob}
          setFieldValue={setFieldValue}
          withoutMargins
        />
      </Grid>
      <Grid item sm={9}>
        <CommonSelect
          error={errors.country}
          value={values.country}
          name="country"
          placeholder={countryValue}
          handleChange={handleChange}
          touched={touched.country}
          menuItems={menuItemsCounties}
          valuesArr={countriesList}
        />
      </Grid>
      <Grid item sm={9}>
        <CommonSelect
          error={errors.occupation}
          value={values.occupation}
          name="occupation"
          placeholder={doValue}
          handleChange={handleChange}
          touched={touched.occupation}
          menuItems={menuItems}
          valuesArr={items}
        />
      </Grid>
      <Grid item sm={9}>
        <SignUpCheckbox id="marketingSubscribed">
          <CommonCheckbox
            label={(
              <MediumBlackText>
                I consent to receive marketing communications from Delivrable.
              </MediumBlackText>
            )}
            isChecked={!!values.marketingSubscribed}
            name="marketingSubscribed"
            handleChange={(e) => {
              setFieldValue('marketingSubscribed', !values.marketingSubscribed);
            }}
          />
          <ErrorMessage
            style={{ position: 'absolute', bottom: '-18px', minHeight: '18px' }}
          >
            {errors.marketingSubscribed || ''}
          </ErrorMessage>
        </SignUpCheckbox>
      </Grid>
      <Grid item sm={9}>
        <SignUpCheckbox id="isAgreed">
          <CommonCheckbox
            label={(
              <MediumBlackText>
                I have read and agree to the
                {' '}
                <SignUpLinks
                  href={`${window.location.origin}/${urls.privacyUrl}`}
                  target="_blank"
                >
                  Privacy Policy
                </SignUpLinks>
                {' '}
                and consent to the processing of my personal data.
              </MediumBlackText>
            )}
            isChecked={values.isAgreed}
            name="isAgreed"
            handleChange={handleChange}
          />
          {errors.isAgreed && touched.isAgreed && (
          <ErrorMessage
            style={{ position: 'absolute', bottom: '-18px', minHeight: '18px' }}
          >
            {errors.isAgreed}
          </ErrorMessage>
          )}
        </SignUpCheckbox>
      </Grid>
      {/* <Grid item sm={9}>
        <CommonInput
          error={errors.promoCode}
          value={values.promoCode}
          name="promoCode"
          handleBlur={handleBlur}
          handleChange={handleChange}
          type="text"
          placeholder="Promo code"
          touched={touched.promoCode}
        />
      </Grid> */}
      {/* <Grid item sm={9}>
        <WidthWrapper>
          <CommonCheckbox
            isChecked={values.subscribed}
            name="subscribed"
            handleChange={handleChange}
            label={
              <MediumGreyText style={{ width: '100%' }}>{t('signUp:checkbox')}</MediumGreyText>
            }
            littleText
          />
        </WidthWrapper>
      </Grid> */}
      <FlexStartWrapper>
        <ButtonWithoutShadow onClick={onBack} disabled={isLoading}>
          {t('basicComponents:buttons.back')}
        </ButtonWithoutShadow>
        <PrimaryWhiteButton
          type="submit"
          disabled={isLoading}
          style={{ position: 'relative' }}
        >
          {t('signUp:completeRegistration')}
          {isLoading && (
            <PositionedLoader>
              <PinkLoader size="xs" />
            </PositionedLoader>
          )}
        </PrimaryWhiteButton>
      </FlexStartWrapper>
      <BleakText>
        <Trans t={t} i18nKey="signUp:privacyPolicy">
          By clicking &apos;Complete Registration&apos; you accept
          Delivrable&apos;s
          {' '}
          <SignUpLinks
            href={`${window.location.origin}/${urls.termsUrl}`}
            target="_blank"
          >
            Terms Of Service
          </SignUpLinks>
          {' '}
          and
          {' '}
          <SignUpLinks
            href={`${window.location.origin}/${urls.privacyUrl}`}
            target="_blank"
          >
            Privacy Policy
          </SignUpLinks>
        </Trans>
      </BleakText>
    </>
  );
};
