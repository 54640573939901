import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { TickIcon } from '../../icons';
import { SimpleWhiteText } from '../../styles';
import { BaseModalWindow } from '../BaseModalWindow/BaseModalWindow';
import { NotificationWrapper } from './styled';

type SuccessNotificationProps = {
  isOpen: boolean;
  text: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  longTime?: boolean;
  unsetSuccess?: () => void;
};

export const SuccessNotification: React.FC<SuccessNotificationProps> = ({
  isOpen,
  text,
  setIsOpen,
  longTime,
  unsetSuccess,
}) => {
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsOpen(false);
      }, longTime ? 1000 : 515);
    }
    return () => {
      if (unsetSuccess) {
        unsetSuccess();
      }
    };
  }, [isOpen]);

  return (
    <BaseModalWindow isOpen={isOpen} handleClose={() => setIsOpen(false)}>
      <NotificationWrapper>
        <TickIcon isGradient={false} />
        <SimpleWhiteText>
          {text}
        </SimpleWhiteText>
      </NotificationWrapper>
    </BaseModalWindow>
  );
};
