import React, { useState } from 'react';
import {
  BaseSecondaryButton,
  GradientColorText,
  PrimaryWhiteButton,
  TertiaryButton,
  TitleText,
} from '../../styles';
import {
  CookiesConsentButtons, CookiesConsentContainer, CookiesConsentText, CookiesConsentTwoButtons,
  PrivacyPolicyLink,
} from './styled';
import useCookies from './useCookies';
import { CustomizeSettings } from './CustomizeSettings';
import * as urls from '../../../constants/urls';

export const CookiesConsent: React.FC = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['cookieConsent']);
  const [removeCookies, setRemoveCookies] = useState<string[]>([]);

  const handleAcceptCookies = () => {
    setCookie('cookieConsent', true, { path: '/' });
  };

  if (cookies.cookieConsent) {
    return null;
  }

  return (
    <CookiesConsentContainer>
      <TitleText>Cookie consent</TitleText>
      <CookiesConsentText>
        We use cookies to enhance your experience. By continuing to use our site,
        you consent to our use of cookies as described in our
        {' '}
        <PrivacyPolicyLink
          href={`${window.location.origin}/${urls.privacyUrl}`}
          target="_blank"
        >
          Privacy Policy
        </PrivacyPolicyLink>
        .
      </CookiesConsentText>
      <CookiesConsentButtons>
        <CookiesConsentTwoButtons>
          {/* <CustomizeSettings
            cookies={cookies}
            setRemoveCookies={setRemoveCookies}
            removeCookies={removeCookies}
          /> */}
          <PrimaryWhiteButton onClick={handleAcceptCookies}>
            <GradientColorText fontWeight={600}>Accept All</GradientColorText>
          </PrimaryWhiteButton>
        </CookiesConsentTwoButtons>
      </CookiesConsentButtons>
    </CookiesConsentContainer>
  );
};
