import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';
import { MediumGreyText, SimpleBlackText, TitleText } from '../../../../../../shared/styles';
import * as variables from '../../../../../../constants/styles';

export const NotificationListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NotificationsCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;  
`;

export const NotificationsCardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NotificationsCardImage = styled.div`
  width: 82px;
  height: 84px;
  border-radius: 4px;
  overflow: hidden;

  & img {
    object-fit: cover;
    width: 100%;
    height: auto;
  }
`;

export const NotificationCardGrid = styled(Grid)`
  margin-right: 30px !important;  
`;

export const NotificationTextBold = styled(SimpleBlackText)`
  font-weight: 600;
  margin-bottom: 14px;
`;

export const LoadMoreButton = styled.button`
  width: 127px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const NotReadMarker = styled.div`
  position: absolute;
  left: -20px;
  top: 6px;
  width: 10px;
  height: 10px;
  background: ${variables.colorPink};
  border-radius: 50%;
`;

export const MediumGreyTextWithMargin = styled(MediumGreyText)`
  margin-bottom: 8px;
`;

export const TitleTextCustom = styled(TitleText)`
  white-space: pre;
`;
