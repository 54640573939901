import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import * as variables from '../../../constants/styles';
import { theme } from '../../../shared/components/GlobalContainer/styled';

export const LandingContainerWrp = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
`;

export const LandingContentContainerWrap = styled.div`
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    padding: 0px 20px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 0px 16px;
  }
`;

export const CustomGrid = styled(Grid)`
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    width: 100% !important;
  }
`;
