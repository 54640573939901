import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TabContentWrapper,
  TabWrapper,
} from '../../../../../../shared/styles';
import { ViewAllLink } from '../../../../../../shared/components/ViewAllLink/ViewAllLink';
import * as url from '../../../../../../constants/urls';
import { SalesPart } from './SalesPart';
import { GalleriesList } from './GalleriesList';
import { StripeRegistractionBanner } from './StripeRegistractionBanner';
import { useTypedSelectorCreator } from '../../../../../../shared/hooks/useTypedSelector';

export const MainDashboard: React.FC = () => {
  const { t } = useTranslation('basicComponents');
  const creator = useTypedSelectorCreator((state) => state.creator?.currentUser);

  return (
    <TabWrapper>
      <TabContentWrapper>
        {creator?.onboarded ? null : <StripeRegistractionBanner />}
        <SalesPart />
        <ViewAllLink
          label={t('basicComponents:viewStatistics')}
          to={url.salesUrl}
        />
      </TabContentWrapper>
      <GalleriesList />
    </TabWrapper>
  );
};
