import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as urls from '../../../../../../../constants/urls';
import {
  PrimaryWhiteButton,
  SimpleBlackText,
} from '../../../../../../../shared/styles';
import { RowsWrapper, SettingsWrapper, TitleMgB } from '../styled';
import { ButtonMgT } from './styled';
import { getFullName } from '../../../../../../../shared/utils';
import { useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';
import { CommonInput } from '../../../../../../../shared/components/FormElements/CommonInput/CommonInput';
import { SettingsPlanCard } from './SettingsPlanCard';
import { plans } from '../../../../../../../constants';

const UpdatePaymentMethod: React.FC = () => {
  const { t } = useTranslation('settings');
  const navigate = useNavigate();
  const currentUser = useTypedSelectorCreator(
    (state) => state.creator.currentUser,
  );

  const onSubmit = () => {
    console.log('submit');
  };

  const handleUpdatePaymentMethod = () => {
    navigate(`${urls.settingsUrl}${urls.paymentMethodUrl}`);
  };

  const {
    values, handleChange, handleBlur, errors, touched,
  } = useFormik<{ nameOnInvoice: string; taxNumber: string }>({
    // validationSchema: clientSchema,
    initialValues: {
      nameOnInvoice:
          getFullName(currentUser?.firstName, currentUser?.lastName) || '',
      taxNumber: '',
    },
    onSubmit: () => onSubmit(),
  });

  return (
    <Grid container item sm={5} style={{ marginTop: '-12px' }}>
      <Grid item sm={10}>
        <CommonInput
          label={t('nameOnInvoice')}
          value={values.nameOnInvoice}
          name="nameOnInvoice"
          handleChange={handleChange}
          handleBlur={handleBlur}
          direction="column"
          error={errors?.nameOnInvoice}
          touched={touched?.nameOnInvoice}
          type="text"
          withoutMargins
        />
      </Grid>
      <Grid item sm={10}>
        <CommonInput
          withoutMargins
          label={t('taxNumber')}
          value={values.taxNumber}
          name="taxNumber"
          handleChange={handleChange}
          handleBlur={handleBlur}
          direction="column"
          error={errors?.taxNumber}
          touched={touched?.taxNumber}
          type="text"
        />
      </Grid>
      <Grid item sm={10}>
        <ButtonMgT>
          <PrimaryWhiteButton type="button" onClick={handleUpdatePaymentMethod}>
            {t('updatePayment')}
          </PrimaryWhiteButton>
        </ButtonMgT>
      </Grid>
    </Grid>
  );
};

export const Subscription: React.FC = () => {
  const { t } = useTranslation('settings');

  const currentUser = useTypedSelectorCreator(
    (state) => state.creator.currentUser,
  );
  const planDetails = plans.find(
    (item) => item.planType.toLowerCase() === currentUser?.plan.toLowerCase(),
  );

  return (
    <>
      <SettingsWrapper>
        <TitleMgB>
          <SimpleBlackText>{t('subscription')}</SimpleBlackText>
        </TitleMgB>
      </SettingsWrapper>
      <RowsWrapper>
        <Grid container item sm={12} style={{ height: 'fit-content' }}>
          <SettingsPlanCard planData={planDetails} />
          {/* <Grid item sm={10}>
            <TextMgB>
              <MediumBlackText>
                <Trans t={t} i18nKey="endsInDays">
                  Ends in
                  {' '}
                  <span style={{ fontWeight: 600 }}>
                    {{ days }}
                    {' '}
                    days
                  </span>
                </Trans>
              </MediumBlackText>
            </TextMgB>
          </Grid> */}
        </Grid>
      </RowsWrapper>
    </>
  );
};
