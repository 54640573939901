import React, { ReactElement } from 'react';
import { css, Global } from '@emotion/react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ThemeProvider } from '@material-ui/core';
import {
  GlobalStyle,
  theme,
} from './styled';
import { TranslateContainer } from './TranslateContainer';

type GlobalContainerProps = {
  children: ReactElement;
}

export const GlobalContainer: React.FC<GlobalContainerProps> = ({
  children,
}) => (
  <TranslateContainer>
    <>
      <Global
        styles={css`
              ${GlobalStyle}
            `}
      />
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </>
  </TranslateContainer>
);
