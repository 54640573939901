import styled from '@emotion/styled';

export const CoverWindow = styled.div`
  background: transparent;
  background-blend-mode: multiply;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50000;
  width: 100%;
  height: 100%;
  height: 100hv;
  min-height: 100%;
  min-height: 100hv;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
