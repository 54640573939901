import styled from '@emotion/styled';
import * as variables from '../../../../constants/styles';
import { FileInputBase } from '../../../styles';

export const AddFileLabel = styled(FileInputBase)<{
  hasError: boolean;
  hasFile: boolean;
  imageUrl: string;
}>`
  height: 268px !important;
  border-radius: 16px;
  position: relative;
  background: #F0F1F2;
  & input {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
  }

  ${(props) => (props.hasError
    ? `
    border: 0.5px solid ${variables.colorError};
  `
    : '')}

    & #input-background {
      opacity: ${({ hasFile }) => (hasFile ? '0' : '1')};
      z-index: 998;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: inherit;
      display: flex;
      width: 100%;
      height: 100%;
      transition: all 0.1s;
      cursor: pointer;
      background: transparent;
      &::before,
      &::after {
        z-index: 1000;
      }
    }
    &:hover {    
      cursor: pointer;

      & #input-background {
        opacity: 1;
        background: rgba(0, 0, 0, 0.2);
      }
      & input {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 1000;
      }
    }
`;

export const CommonFileUploadPlus = styled.div<{hasFile: boolean}>`
  position: relative;
  width: 36px;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 36px;
    height: 4px;
    background: ${({ hasFile }) => (hasFile ? 'white' : 'black')};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
    z-index: 1001;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 4px;
    height: 36px;
    background: ${({ hasFile }) => (hasFile ? 'white' : 'black')};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
    z-index: 1001;
  }
`;
