import styled from '@emotion/styled';
import * as variables from '../../../../../../../../../constants/styles';
import { MediumBlackText } from '../../../../../../../../../shared/styles';

export const RegisteredEmailDelete = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 4px;
  padding-top: 4px;

  & svg {
    color: ${variables.textBlack};
    width: 20px;
    height: 20px;
  }
`;

export const RegisteredEmailExportButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;

  & svg {
    color: ${variables.textGrey};
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
`;

export const RegisteredEmailTitle = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TitleMediumBlackTextMgB = styled(MediumBlackText)`
  padding-bottom: 12px;
`;
export const TitleMediumBlackTextMgBFlexEnd = styled(TitleMediumBlackTextMgB)`
  display: flex;
  justify-content: flex-end;
`;

export const MediumBlackTextMgTB = styled(MediumBlackText)`
  padding-bottom: 4px;
  padding-top: 4px;
`;

export const ScrolledWrapperRegisteredEmails = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${variables.textGrey};
    border-radius: 163px;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: #dbdbdb;
    border-radius: 163px;
  }
`;
