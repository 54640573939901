import React, {
  Dispatch, SetStateAction, useState,
} from 'react';
import { CustomAlert } from '../components/CustomAlert/CustomAlert';
import { AlertTypes } from '../types';

type ReturnDialogObj = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  CustomAlert: React.FC<CustomAlert>;
  dialogWindow: React.ReactElement;
  isDialogOpen: boolean;
};

// Create hook for confirmation and alerting
export const useDialog = <ItemType, >(
  type: AlertTypes,
  item?: ItemType,
  handleAction?: CallableFunction,
  message?: string,
  title?: string,
  buttonText?: string,
  noCancelBtn?: boolean,
): ReturnDialogObj => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = (data: any) => {
    if (handleAction) {
      handleAction(data);
    }
    setIsOpen(false);
  };
  return {
    setIsOpen,
    dialogWindow: (
      <CustomAlert
        onClose={() => setIsOpen(false)}
        onClick={handleClick}
        item={item}
        isOpenWindow={isOpen}
        message={message || ''}
        title={title || ''}
        buttonText={buttonText}
        noCancelBtn={noCancelBtn}
        type={type}
      />
    ),
    CustomAlert,
    isDialogOpen: isOpen,
  };
};
