import styled from '@emotion/styled';
import * as variables from '../../../../constants/styles';

export const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    width: 100%;
    margin: 0;
  }

  & > img {
    margin-right: 16px;
    margin-bottom: 18px;
  }
`;

export const SwitchAndLabelWrapper = styled.div<{spaceBetween?: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ spaceBetween }) => (spaceBetween ? 'space-between' : 'flex-start')};
`;

export const SwitchTextLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SwitchExtraTextWrapper = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${variables.colorPink};
  margin-left: -25px;
`;
