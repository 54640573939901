import React from 'react';
import { Slide } from '@mui/material';
import { ButtonMoveToTopWrapper } from './styled';
import arrowToTop from '../../../assets/image/arrowToTop.svg';
import { RoundButton } from '../../styles';
import { useVisibleOnScroll } from '../../hooks/useVisibleOnScroll';

type ButtonMoveToTopProps = {
  visibleImportant?: boolean;
  margins?: boolean;
}

export const ButtonMoveToTop: React.FC<ButtonMoveToTopProps> = ({
  visibleImportant,
  margins,
}) => {
  const visible = useVisibleOnScroll(500);

  const handleClick = () => {
    window?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    document?.querySelector('#scrolled-container')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <Slide direction="up" in={visible || visibleImportant}>
      <ButtonMoveToTopWrapper margins={margins} id="scroll-button">
        <RoundButton
          size="large"
          type="button"
          onClick={handleClick}
        >
          <img src={arrowToTop} alt="top" />
        </RoundButton>
      </ButtonMoveToTopWrapper>
    </Slide>
  );
};
