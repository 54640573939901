import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@material-ui/core';
import * as variables from '../../../../constants/styles';

export const CustomCircularProgress = styled(CircularProgress)<
  CircularProgressProps & { value: number }
>(() => ({
  '& .MuiCircularProgress-root': {
    color: variables.colorGreyLight,
  },
  '& .MuiCircularProgress-determinate': {
    color: variables.colorGreyLight,
  },
  '& .MuiCircularProgress-svg': {
    color: variables.colorGreyLight,
  },
}));

export const ProgressLoader: React.FC<CircularProgressProps & { value: number }> = (
  props,
) => (
  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
    <CustomCircularProgress variant="determinate" {...props} />
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: variables.colorGreyLight,
      }}
    >
      <Typography
        variant="caption"
        component="div"
        color={variables.colorGreyLight}
        sx={{
          color: 'white',
        }}
      >
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {`${Math.round(props.value)}%`}

      </Typography>
    </Box>
  </Box>
);
