import styled from '@emotion/styled';
import { Fade, Grid } from '@material-ui/core';
import * as variables from '../../../constants/styles';

export const GalleryCoverGrid = styled(Grid)`
  margin-top: 20px !important;
  margin-bottom: 150px !important;
`;

export const ModalWindowWrapper = styled(Fade)`
  width: 100%;
  height: auto;
  background: white;
`;

export const TabletVersionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > span {
    width: 112px;
    font-size: 14px;
    line-height: 150%;
  }

  & > div {
    box-sizing: border-box;
    width: 533px;
    height: 710px;
    border: 1px solid ${variables.colorBleak};
    border-radius: 20px;
  }
`;

export const MobileVersionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > span {
    width: 112px;
    font-size: 14px;
    line-height: 150%;
  }

  & > div {
    box-sizing: border-box;
    width: 195px;
    min-height: 422px;
    height: fit-content;
    border: 1px solid ${variables.colorBleak};
    border-radius: 14px;
  }
`;

export const CommonMobilePaddingWrapper = styled.div`
  padding: 10px 22px 29px;
`;
