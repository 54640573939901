import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TabWrapper, TitleText } from '../../../../../../shared/styles';

type SettingsContainerProps = {
  children: ReactElement;
}

export const SettingsContainer: React.FC<SettingsContainerProps> = ({
  children,
}) => {
  const { t } = useTranslation(['settings', 'basicComponents']);

  return (
    <TabWrapper>
      <TitleText>{t('title')}</TitleText>
      {children}
    </TabWrapper>
  );
};
