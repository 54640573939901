import React, { ChangeEvent, ReactElement } from 'react';
import { BaseSizes, SetFieldValue } from '../../../types';
import { CheckboxInput, CheckboxWrapper, CustomCheckbox } from './styled';
import { TextLabel } from '../TextLabel';
import { InputAndLabelWrapper } from '../styled';

type CommonCheckboxProps = {
  id?: string | number;
  isChecked: boolean;
  name: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  label?: ReactElement | string;
  simple?: boolean;
  size?: BaseSizes;
  helpTipText?: string;
  positioned?: boolean;
  littleText?: boolean;
  withoutMargins?: boolean;
  error?: boolean;
};

export const CommonCheckbox: React.FC<CommonCheckboxProps> = ({
  isChecked,
  name,
  handleChange,
  label,
  simple,
  id,
  size,
  helpTipText,
  positioned,
  littleText,
  withoutMargins,
  error,
}) => {
  const content = (
    <>
      <input
        id={`${id}`}
        onChange={handleChange}
        name={name}
        type="checkbox"
        checked={isChecked}
      />
      <span id="checkbox" />
    </>
  );
  const checkbox = simple ? (
    <CheckboxInput size={size} positioned={positioned}>{content}</CheckboxInput>
  ) : (
    <CustomCheckbox error={!!error} size={size}>{content}</CustomCheckbox>
  );

  return label ? (
    <InputAndLabelWrapper
      direction="row"
      withoutMargins={withoutMargins}
      style={{ alignItems: 'center' }}
    >
      <CheckboxWrapper>
        {checkbox}
      </CheckboxWrapper>
      <TextLabel
        label={label}
        helpText={helpTipText}
        name={name}
        wrapperDirection="row"
        littleText={littleText}
      />
    </InputAndLabelWrapper>
  ) : (
    checkbox
  );
};
