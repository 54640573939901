import styled from '@emotion/styled';
import * as variables from '../../../constants/styles';
import { ColorfulCard, TitleSimple } from '../../styles';
import image from '../../../assets/image/stars.png';

export const ColorfulCardContentWrapper = styled(ColorfulCard)`
  margin-top: 40px;
  padding: 45px 44px;
  box-sizing: border-box;
  align-items: flex-start;
  background: none;
  background-image: url(${image}), linear-gradient(275.88deg, #e6934f -9.9%, #e186b0 41.92%, #78c0ea 123.73%);
  background-position: 98% 70%, left top;
  background-repeat: no-repeat, repeat;
  max-width: 410px;
`;

export const TitleStartWhiteText = styled(TitleSimple)`
  margin-bottom: 0;
  text-align: start;
  color: ${variables.colorGreyLight};
  font-size: 36px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: 0.01em;
  text-transform: none;
`;
