import styled from '@emotion/styled';

export const ImageWrapper = styled.div<{widthStretch: boolean, hasImage: boolean }>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: inherit;
  background: ${({ hasImage }) => (hasImage ? 'transparent' : '#F0F1F2')};
`;

export const ImageElement = styled.img<{ hasNoUrl?: boolean }>`
  object-fit: cover;
  vertical-align: middle;
  height: ${({ hasNoUrl }) => (hasNoUrl ? '300px' : '100%')};
  ${({ hasNoUrl }) => (hasNoUrl ? 'background: #F0F1F2;' : '')}
  width: 100%;
`;
