import styled from '@emotion/styled';
import * as variables from '../../../../../../constants/styles';
import { TitleText } from '../../../../../../shared/styles';
import { theme } from '../../../../../../shared/components/GlobalContainer/styled';

export const ChartWrapper = styled.div`
  margin: 42px 46px 30px;
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SalesCardWrapper = styled.div`
  margin: 58px 44px;
  width: fit-content;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin: 28px 24px;
  }
`;

export const TotalSalesWrapper = styled.div`
  margin: 12px 44px 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export const SalesMonthContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const SalesMonthText = styled.div`
  font-weight: 700;
  font-size: 40px;
  line-height: 110%;
  color: ${variables.textBlack};
`;

export const PercentText = styled.div`
  margin-top: 30px;
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  color: ${variables.colorPink};
`;

export const LimitTextWrapper = styled.div`
  margin-top: 10px;
  max-width: 195px;
`;

export const TitleTextBoldWithMgBt = styled(TitleText)`
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
`;

export const BlueWrapper = styled.div`
  padding: 14px 30px;
  background: ${variables.colorBlue};
  border-radius: 13px;
  color: ${variables.colorGreyLight};
  font-weight: 700;
  font-size: 30px;
  line-height: 110%;
  text-align: center;
  text-transform: uppercase;
`;

export const HelpTipWrapper = styled.div`
  & svg {
    color: ${variables.colorPink};
  }
`;
