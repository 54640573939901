import React, { ReactNode } from 'react';
import { QuestionIcon } from '../../icons';
import { HelpTipButton, HelpTipText } from './styled';

type HelpTipProps = {
  text: string | ReactNode;
  position?: 'top' | 'left' | 'right';
  isPinkBackground?: boolean;
  withMgLeft?: boolean;
};

export const HelpTip: React.FC<HelpTipProps> = ({
  text,
  position,
  isPinkBackground = false,
  withMgLeft,
}) => (
  <HelpTipButton position={position || 'top'} type="button" withMgLeft={withMgLeft}>
    <HelpTipText>{text}</HelpTipText>
    <QuestionIcon isPinkBackground={isPinkBackground} />
  </HelpTipButton>
);
