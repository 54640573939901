import styled from '@emotion/styled';

export const SummaryRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
`;

export const SummaryTable = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
`;

export const SummaryButtonWrapper = styled.div`
  margin-top: 16px;
`;
