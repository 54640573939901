import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';
import * as urls from '../../../../../constants/urls/landingUrls';

export const DefinitionsPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={22}
      listTitle={t('terms.definitions')}
      pointsNameForTrans="definitionsPoints"
    >
      <LandingNumberedLi>
        <span className="bold">Consequential Loss</span>
        {' '}
        includes any
        consequential loss, indirect loss, real or anticipated loss of
        profit, loss of benefit, loss of revenue, loss of business, loss of
        goodwill, loss of opportunity, loss of savings, loss of reputation,
        loss of use and/or loss or corruption of data, whether under
        statute, contract, equity, tort (including negligence), indemnity or
        otherwise.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Client</span>
        {' '}
        means a client or potential
        client of a Member who wishes to purchase Content from the Member or
        otherwise download content via the Platform.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Deferred Payment</span>
        {' '}
        plan means a
        Membership Type with the benefits specified on the Platform.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Delivrable Services</span>
        {' '}
        means the
        provision of the Platform to users (including hosting and
        maintaining the Platform), processing payments between Clients and
        Members and provision of promotional opportunities for Members.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Force Majeure Event</span>
        {' '}
        means any one, or
        combination of law or government regulation which comes into force
        or any act of God, flood, war, revolution, civil commotion,
        political disturbance, fire explosion, ionizing radiation,
        contamination by radioactivity, nuclear, chemical or biological
        contamination, disease, epidemic, pandemic (including in relation to
        the coronavirus, severe acute respiratory syndrome coronavirus 2, or
        any mutation thereof), government sanctioned shutdown, global
        economic downturn or any other cause whatsoever over which a party
        has no control but excludes an obligation to pay Fees.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Intellectual Property</span>
        {' '}
        means any domain
        names, know-how, inventions, processes, trade secrets or
        confidential information; or circuit layouts, software, computer
        programs, databases or source codes, including any application, or
        right to apply, for registration of, and any improvements,
        enhancements or modifications of, the foregoing.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Intellectual Property Rights</span>
        {' '}
        means for
        the duration of the rights in any part of the world, any industrial
        or intellectual property rights, whether registrable or not,
        including in respect of Intellectual Property.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Intellectual Property Breach</span>
        {' '}
        means any
        breach by you (or any of your personnel) of any of our Intellectual
        Property Rights (or any breaches of third party rights including any
        Intellectual Property Rights of third parties).
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Liability</span>
        {' '}
        means any expense, cost,
        liability, loss, damage, claim, notice, entitlement, investigation,
        demand, proceeding or judgment (whether under statute, contract,
        equity, tort (including negligence), indemnity or otherwise),
        howsoever arising, whether direct or indirect and/or whether
        present, unascertained, future or contingent and whether involving a
        third party or a party to these Terms or otherwise.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Member</span>
        {' '}
        means a content editor
        subscriber and
        {' '}
        <span className="bold">Membership</span>
        {' '}
        has a
        corresponding meaning.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Membership Terms and Conditions</span>
        {' '}
        means
        our SAAS agreement with Members available at
        {' '}
        <a target="_blink" href={urls.termsUrl}>www.delivrable.io/terms</a>
        .
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Output Data</span>
        {' '}
        has the meaning given in
        clause 12.2.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Payment Date</span>
        {' '}
        Payment Date means the
        date specified on the Platform.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Platform</span>
        {' '}
        means our platform available
        at
        {' '}
        <a target="_blink" href="/">www.delivrable.io</a>
        {' '}
        and via other channels or
        addresses and our mobile application available on the Apple App
        Store and Google Play Store and if applicable, our application
        programme interfaces.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Privacy Policy</span>
        {' '}
        means our privacy
        policy, available at
        {' '}
        <a target="_blink" href={urls.privacyUrl}>www.delivrable.io/privacy.</a>
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">SaaS Solution</span>
        {' '}
        means our cloud-based,
        software as a service named Delivrable enabling Members to combine
        video, photo and project files to create Output Data which we make
        available via our Platform.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Terms</span>
        {' '}
        means these terms.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Your Data</span>
        {' '}
        has the meaning given in
        clause 12.1.
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
