import styled from '@emotion/styled';
import {
  ImageListItem, ImageListItemProps, styled as MUIstyled,
} from '@mui/material';
import * as variables from '../../../../../../../constants/styles';
import { BaseSecondaryButton } from '../../../../../../../shared/styles';
import { theme } from '../../../../../../../shared/components/GlobalContainer/styled';

export const SectionFilesWrapper = styled.div<{limitedWidth?: boolean}>`
  overflow-y: auto;
  ${(props) => props.limitedWidth && 'max-width: 118px;'}

  & > div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-height: 386px;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${variables.textGrey};
    border-radius: 163px;
  }

  &::-webkit-scrollbar-track {
    background: #dbdbdb;
    border-radius: 163px;
  }
`;

export const CheckboxText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${variables.textGrey};
`;

export const SectionActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 41px;
`;

export const SectionFilesActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 41px;
`;

export const ActionButton = styled.button`
  color: ${variables.textGrey};
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 0px;

  & > svg {
    margin-left: 10px;
  }

  & span {
    position: absolute;
    display: none;
    box-sizing: border-box;
    background: ${variables.textGrey};
    padding: 5px 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: ${variables.colorGreyLight};
    border-radius: 2px;
    z-index: 100;
    min-width: 125px;
    right: 100%;
  }

  &:hover {
    & span {
      display: block;
    }
  }
`;
export const PinkActionButton = styled(BaseSecondaryButton)`
  color: ${variables.textBlack};
  white-space: pre;
  display: flex;
  align-items: center;
  width: fit-content;
  transition: width 0.3s ease-in-out !important;

  &:disabled {
    box-shadow: -5px -5px 10px #FFFFFF, 5px 5px 10px #E8E8E8;
    color: ${variables.textGrey};
    cursor: auto;
    transition: all 0.2s ease-in-out;
  }
`;

export const ActionButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const TickIconWrapper = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;

  & svg {
    height: 14px;
    width: 17px;
  }
`;

export const LoadingIconWrapper = styled.div`
  margin-right: 10px;
`;

export const LabelCheckboxWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  justify-content: flex-start;
`;
export const FormLimitFreeFilesGrid = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
`;

export const EmptyFilePreview = styled.div`
  width: 100%;
  height: 100%;
  max-height: 388px;
  border: 1px solid ${variables.colorBleak};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 43px;

  & span {
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: ${variables.colorBleak};
    margin-bottom: 24px;
  }
`;
export const FilePreviewWrapper = styled.div`
  width: 100%;
  position: relative;
`;
export const FilePreviewTextUpperWrapper = styled.div`
  position: absolute;
  top: -23px;
  right: 0;
  font-size: 14px;
  line-height: 13px;
  color: ${variables.textGrey};
`;
export const FilePreviewImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 14px;
  min-width: 280px;
  width: 280px;
`;
export const TextAlignRight = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const CheckboxTextWrapper = styled.div`
  margin-bottom: 18px;
  margin-right: 12px;
  margin-left: 0px;
  white-space: pre;
`;

export const CheckboxWrapper = styled.div`
  margin-bottom: 18px;
`;

export const SectionsActionsWithErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

export const FilesGridWrapper = styled.div`
  display: grid;
  column-gap: 30px;
  row-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(70px, max-content));
`;

export const ContentSetupFileWrapper = styled.div<{ isChecked: boolean }>`
  display: flex;
  border: 1px solid ${({ isChecked }) => (isChecked ? variables.colorPink : 'transparent')};
  position: relative;
  margin-bottom: 20px;

  @media (max-width: ${theme.breakpoints.values.lg - 1}px) {
    margin-bottom: 10px;
  }

  @media (max-width: ${theme.breakpoints.values.md - 1}px) {
    margin-bottom: 0px;
  }
`;

export const FileSectionItemContainer = MUIstyled(ImageListItem)<ImageListItemProps>(() => ({
  margin: '0px',
  '&>div': {
    margin: '5px',
  },
  [theme.breakpoints.down('lg')]: {
    '&>div': {
      margin: '5px',
    },
  },
  [theme.breakpoints.down('md')]: {
    '&>div': {
      margin: '4px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '&>div': {
      margin: '4px',
    },
  },
  [theme.breakpoints.down('xs')]: {
    '&>div': {
      margin: '4px',
    },
  },
}));

export const FilesScrollbarConteiner = styled.div`
  overflow-y: auto;
  width: 100%;
  max-height: 650px;
  margin-bottom: 28px;
  margin-right: 28px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${variables.textGrey};
    border-radius: 163px;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: #dbdbdb;
    border-radius: 163px;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin-right: 0px;

    & .MuiMasonry-root {
      margin: 0px 0px 0px 0px !important;
    }
  }
`;

export const ContentSetupFileOverlay = styled.div`
  position: absolute;

  & #client-buttons-over-item-step {
    max-height: 140px;
    justify-content: space-around;

    & button {
      margin: 0px 0px 2px 0px;

      &:first-of-type {
        margin: 2px 0px 2px 0px;
      }
    }
  }  
`;

export const WatermarkLoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #000000b3;
  zIndex: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
