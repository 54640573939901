import styled from '@emotion/styled';
import * as variables from '../../../constants/styles';
import { theme } from '../GlobalContainer/styled';

export const CommonInputWrapper = styled.div<{
  fullHeight?: boolean;
  withSvg?: boolean;
  width?: number;
  withoutMargins?: boolean;
}>`
  position: relative;
  margin: ${(props) => (props.withoutMargins ? '0' : '10px 0')};
  ${(props) => props.fullHeight && 'height: 100%;'}
  ${(props) => props.withSvg
    && `& svg {
      position: absolute;
      right: 20px;
      bottom: 11.5px;
    }`}
`;

export const InputBase = styled.div<{
  direction?: string;
  width?: string;
  spaceBetween?: boolean;
  littleText?: boolean;
}>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  ${(props) => props.direction === 'row' && 'align-items: center;'}
  ${(props) => props.spaceBetween && 'justify-content: space-between;'}
  & label {
    font-size: ${(props) => (props.littleText ? '14px' : '16px')};
    line-height: 24px;
    display: inline-block;
    white-space: nowrap;
  }

  & > input,
  textarea,
  > div > input {
    font-size: 15px;
    width: ${(props) => props.width || '100%'};
    height: 44px;
    padding: 12px 24px;
    background: ${variables.colorGreyLight};
    border: 0.5px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #e7e7e7;
    border-radius: 163px;
    display: flex;
    margin-right: 0;
    align-items: flex-start;

    &:read-only {
      color: ${variables.colorBleak};
    }

    &::placeholder {
      display: flex;
      align-items: center;
      font-size: 15px;
      color: ${variables.colorBleak};
      line-height: 140%;
    }

    &:hover,
    &:active {
      box-shadow: inset -5px -5px 10px #fff, inset 3px 3px 6px #e7e7e7;
      cursor: auto;
    }
    ${({ color }) => (color === 'error'
    ? `
    box-shadow: none;
    border: 0.5px solid ${variables.colorError};
    `
    : '')}

    &:disabled {
      border: 0.5px solid #e2e2e2;
      box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #ededed;

      &::placeholder {
        color: #d0d6db;
      }

      &:hover,
      &:active {
        box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #ededed;
      }
    }
  }

  & > span {
    position: absolute;
    top: 100%;
    ${(props) => (props.direction === 'row' ? 'left: 94px;' : 'left: 24px;')}

    color: ${variables.colorError};
    line-height: 18px;
    font-size: 12px;
  }
`;

export const InputAndErrorWrapper = styled.div<{isFullHeight?: boolean}>`
  display: flex;
  flex-direction: column;
  ${({ isFullHeight }) => (isFullHeight ? 'height: 100%;' : '')}
  width: 100%;
  position: relative;

  & > span {
    margin-left: 24px;
  }
`;

export const InputAndLabelWrapper = styled.div
<{
  direction: 'row' | 'column',
  isFullHeight?: boolean,
  withoutMargins?: boolean,
  width?: number,
  isFullWidth?: boolean,
  justifyContent?: 'space-between'
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  ${({ isFullHeight }) => (isFullHeight ? 'height: 100%;' : '')}
  ${({ withoutMargins }) => (withoutMargins ? '' : 'margin-bottom: 12px;')}
  ${({ width }) => (width ? `width: ${width}px;` : '')}
  ${({ isFullWidth }) => (isFullWidth ? 'width: 100%;' : '')}
  ${({ justifyContent }) => (justifyContent ? `justify-content: ${justifyContent};` : '')}

  @media (max-width: 1050px) {
    ${({ withoutMargins }) => (withoutMargins ? '' : 'margin-bottom: 12px;')}
  }
`;

export const LabelWrapper = styled.div<{ littleText: boolean, labelWhiteSpace?: 'pre' }>`
  fontSize: ${({ littleText }) => (littleText ? '14px' : '16px')};
  width: 100%;
  white-space: ${({ labelWhiteSpace }) => (labelWhiteSpace || 'inherit')};
  
  @media (max-width: ${theme.breakpoints.values.xl}px) {
    white-space: inherit;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    white-space: ${({ labelWhiteSpace }) => (labelWhiteSpace || 'inherit')};
  }
`;

export const HelpTipWrapper = styled.div`
  @media (max-width: 1050px) {
    & > div {
      padding-right: 0px;
    }
  }
`;
