import React from 'react';
import { BaseSizes } from '../../types';
import { CustomAvatar } from './CustomAvatar';

type RoundAvatarProps = {
  urlPhoto?: string;
  size?: BaseSizes;
  userName: string;
}

export const getCircleSize = (size?: BaseSizes): string => {
  switch (size) {
    case 'large':
      return '83px';
    case 'medium':
      return '42px';
    case 'small':
      return '30px';
    default:
      return '83px';
  }
};

const stringAvatar = (name: string) => {
  if (name.split(' ').length !== 0) {
    return ({
      children: `${name[0]}`.toUpperCase(),
    });
  }
  return ({
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`.toUpperCase(),
  });
};

export const RoundAvatar: React.FC<RoundAvatarProps> = ({
  urlPhoto,
  size,
  userName,
}) => (
  <CustomAvatar
    alt={userName}
    src={urlPhoto}
    sx={{ width: getCircleSize(size), height: getCircleSize(size) }}
    {...stringAvatar(userName)}
  />
);
