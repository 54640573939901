import React, {
  useContext,
} from 'react';
import { Locale } from 'react-joyride';
import { useTranslation } from 'react-i18next';
import { useGetCreatorSteps } from './useGetCreatorSteps';
import { CreatorCustomTooltip } from './CreatorCustomTooltip/CreatorCustomTooltip';
import * as urls from '../../../../../constants/urls';
import { CreatorOnboardingContext } from '../../../context/CreatorOnboardingContext';
import { CreatorOnboardingStepIndexContext } from '../../../context/CreatorOnboardingStepIndexContext';
import { CustomJoyride } from '../../../../../shared/components/CustomJoyride/CustomJoyride';
import { useTypedSelectorCreator } from '../../../../../shared/hooks/useTypedSelector';

export const CreatorWalkThroughCards: React.FC = () => {
  const { t } = useTranslation('basicComponents');
  const { setIsStudy, isStudy } = useContext(CreatorOnboardingContext);
  const {
    setStepIndex, stepIndex,
  } = useContext(CreatorOnboardingStepIndexContext);
  const creator = useTypedSelectorCreator((state) => state.creator?.currentUser);

  const steps = useGetCreatorSteps();

  const customLocale: Locale = {
    back: 'Back',
    close: 'Close',
    last: 'Done!',
    // eslint-disable-next-line no-nested-ternary
    next: stepIndex === 4 ? (creator?.onboarded ? 'Let\'s go!' : t('registerNow')) : 'Next',
    open: 'Open the dialog',
    skip: 'Close',
  };

  // logic for beta
  if (window.localStorage.getItem('onboarded') === 'false' && !creator?.subscribed) {
    return null;
  }
  window.localStorage.setItem('onboarded', 'true');

  return (
    <>
      {/* <WalkThroughStart setIsStudy={setIsStudy} mainImageUrl={mainImage} /> */}
      <CustomJoyride
        locale={customLocale}
        stepIndex={stepIndex}
        customTooltip={CreatorCustomTooltip}
        runTraining={isStudy}
        steps={steps}
        endUrl={urls.creatorRootUrl}
        setIsStudy={setIsStudy}
        setStepIndex={setStepIndex}
      />
    </>
  );
};
