import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { ColorfulButton } from '../../../../../../shared/styles';

export const FullHeightWidth = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const WrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const GridWithMgBt = styled(Grid)`
  margin-bottom: 10px !important;
`;

export const HeaderGalleryCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const GridMgTop = styled(Grid)`
  margin-top: 50px !important;
`;

export const BannerButton = styled(ColorfulButton)`
  box-shadow: -2px -2px 3px #e77d27, 2px 2px 3px #e6934f;

  &:hover {
    box-shadow: -3px -3px 6px #bfb9eb, 3px 3px 6px #ba789d;
  }

  &:active {
    box-shadow: inset -3px -3px 6px #d7a5d2, inset 3px 3px 6px #a094be;
  }
`;

export const GalleryCardLink = styled(NavLink)`
  text-decoration: none;
  padding: 0;
  margin: 0;
  display: flex;
`;

export const MainDashboardSalesCardWrapper = styled.div`
  margin: 28px 24px;
  width: fit-content;
`;
