import { styled, Tabs } from '@mui/material';
import React, {
  ChangeEvent, SetStateAction, Dispatch, ReactNode,
} from 'react';

const StyledTabs = styled((props: any) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  width: 'fit-content',
  '& .MuiTab-wrapper': {
    width: 'fit-content',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F180B2',
    width: 'fit-content',
    minWidth: '30px',
    marginRight: '-30px',
  },
  '& .MuiTabs-indicatorSpan': {
    // maxWidth: 40,
    width: 'fit-content',
    minWidth: '30px',
    backgroundColor: '#F180B2',
  },
});

type UnderlineTabsProps = {
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
  tabsElement: ReactNode;
};

export const UnderlineTabs: React.FC<UnderlineTabsProps> = ({
  value,
  setValue,
  tabsElement,
}) => {
  const handleChange = (event: ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <StyledTabs
      value={value}
      onChange={handleChange}
      aria-label="basic tabs example"
      TabIndicatorProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
    >
      {tabsElement}
    </StyledTabs>
  );
};
