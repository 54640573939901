import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResponseErrors } from '../../../../../shared/types/httpError';
import { fetchRedirectToOnboarding, fetchSignUpCreator, fetchSignUpInitialCreator } from './signUpCreatorThunk';

type initialState = {
  isSignUp: boolean;
  wasSent: boolean;
  loading: boolean;
  error: ResponseErrors | null;
  redirectError: string;
  redirectLoading: boolean;
};

export const initialState: initialState = {
  isSignUp: false,
  loading: false,
  error: null,
  wasSent: false,
  redirectError: '',
  redirectLoading: false,
};

const signUpCreatorSlice = createSlice({
  name: 'signUpCreator',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSignUpInitialCreator.fulfilled, (state) => {
      state.isSignUp = false;
      state.error = null;
      state.loading = false;
      state.wasSent = true;
    });
    builder.addCase(
      fetchSignUpInitialCreator.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.isSignUp = false;
        state.loading = false;
        state.wasSent = false;
      },
    );
    builder.addCase(fetchSignUpInitialCreator.pending, (state) => {
      state.loading = true;
      state.wasSent = false;
    });
    builder.addCase(fetchSignUpCreator.fulfilled, (state) => {
      state.isSignUp = true;
      state.error = null;
      state.loading = false;
      state.wasSent = true;
    });
    builder.addCase(
      fetchSignUpCreator.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
        state.isSignUp = false;
        state.wasSent = false;
      },
    );
    builder.addCase(fetchSignUpCreator.pending, (state) => {
      state.loading = true;
      state.wasSent = false;
    });
    builder.addCase(fetchRedirectToOnboarding.fulfilled, (state, action) => {
      state.redirectLoading = false;
      window.open(action.payload, '_blank');
    });
    builder.addCase(
      fetchRedirectToOnboarding.rejected,
      (state, action: PayloadAction<any>) => {
        state.redirectError = action.payload;
        state.redirectLoading = false;
      },
    );
    builder.addCase(fetchRedirectToOnboarding.pending, (state) => {
      state.redirectLoading = true;
      state.redirectError = '';
    });
  },
});

export default signUpCreatorSlice.reducer;
