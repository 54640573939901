import React, { useState } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { useTranslation } from 'react-i18next';
import { instance } from '../../../../../../../../api/base/instance';
import { PinkLoader } from '../../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { BaseSecondaryButton } from '../../../../../../../../shared/styles';

export const UpdatePaymentMethodButton: React.FC = () => {
  const { t } = useTranslation(['settings', 'basicComponents']);
  const [isLoading, setIsLoading] = useState(false);

  const { getToken } = useAuth();
  const updatePaymentMethod = async () => {
    setIsLoading(true);
    const token = await getToken();
    instance.get('/subscription/paymentMethod', { headers: { crossDomain: true, Authorization: `${token}` } })
      .then((res) => window.open(res.data.url))
      .catch((err) => { console.log({ err }); })
      .finally(() => setIsLoading(false));
  };

  return (
    <BaseSecondaryButton
      color="black"
      type="button"
      fontWeight={600}
      onClick={updatePaymentMethod}
      style={{
        marginLeft: '20px',
        minWidth: '220px',
        width: '220px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {isLoading ? <PinkLoader size="xs" /> : t('updatePaymentMethod')}
    </BaseSecondaryButton>
  );
};
