import { createAsyncThunk } from '@reduxjs/toolkit';
import { clientBoughtFreeFiles } from '../../../../api/downloads/downloads';

export const fetchGetClientBoughtFreeFiles = createAsyncThunk(
  'boughtFreeFilesClient/fetchGetClientBoughtFreeFiles',
  async (galleryId: string, thunkApi) => {
    try {
      const response = await clientBoughtFreeFiles.getBoughtFreeFiles(galleryId);

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue('Error');
    }
  },
);
