import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetToken } from '@clerk/types';
import { creatorSignUp } from '../../../../../api/creator/creatorAuth';
import { SignUpRequest } from '../../../../../shared/types';
import {
  getInitialSignUpErrors,
  getSignUpErrors,
} from '../../../../../shared/utils/errors/errorHandlers';
import { creator } from '../../../../../api/creator/creator';

export const fetchSignUpInitialCreator = createAsyncThunk(
  'signUpCreator/fetchSignUpInitialCreator',
  async (email: string, thunkApi) => {
    try {
      const response = await creatorSignUp.signUpInitial(email);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(getSignUpErrors(error));
    }
  },
);

export const fetchSignUpInitialResendCreator = createAsyncThunk(
  'signUpCreator/fetchSignUpInitialCreator',
  async (email: string, thunkApi) => {
    try {
      const response = await creatorSignUp.signUpInitialResend(email);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(getInitialSignUpErrors(error));
    }
  },
);

export const fetchRedirectToOnboarding = createAsyncThunk(
  'signUpCreator/fetchRedirectToOnboarding',
  async (getToken: GetToken, thunkApi) => {
    try {
      const token = (await getToken() || '');
      const res = await creator.redirectToOnboarding(token);
      return res.data.url;
    } catch (error: any) {
      return thunkApi.rejectWithValue(`Error: ${error.response.data.message}`);
    }
  },
);

export const fetchSignUpCreator = createAsyncThunk(
  'signUpCreator/fetchSignUpCreator',
  async (
    data: SignUpRequest & { timezone: string } & {
      userId: string;
      sessionId: string;
    },
    thunkApi,
  ) => {
    try {
      const response = await creatorSignUp.signUp(data);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(getSignUpErrors(error));
    }
  },
);
