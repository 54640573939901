import styled from '@emotion/styled';
import { ImageSizes } from '../../../../../../constants/galleryPreviewsAndCovers';
import * as variables from '../../../../../../constants/styles';
import { TextContainerFourth, ImageContainerFourth } from '../../../../../Creator/containers/CreatorAccount/Tabs/NewGalleryCreator/NewGalleryCreatorFirstStep/PreviewGalleryCovers/archived/PreviewFourth/styled';
import { GalleryCoverGrid, TabletVersionWrapper, MobileVersionWrapper } from '../../../styled';

export const GalleryCoverGridFourth = styled(GalleryCoverGrid)`
  margin-top: 129px !important;
`;

export const TabletVersionWrapperFourth = styled(TabletVersionWrapper)`
  & > div {
    padding: 31px 16px;
  }
`;

export const MobileVersionWrapperFourth = styled(MobileVersionWrapper)`
  & > div {
    padding: 31px 22px;
  }
`;

export const TextGalleryFourth = styled(TextContainerFourth)`
  font-size: 14px;
  line-height: 150%;
  text-align: start;
  max-width: 315px;

  & h3 {
    font-weight: normal;
    margin-top: 0;
    font-size: 28px;
    line-height: 42px;
    width: 315px;
  }
`;
export const ImageGalleryFourth = styled(ImageContainerFourth)`
  width: ${ImageSizes.fourth.desktop.width}px;
  height: ${ImageSizes.fourth.desktop.height}px;
  box-sizing: border-box;
  position: relative;
  background: ${variables.colorBleak};
  background-size: cover;
  object-fit: cover;
  display: flex;
  
  & span {
    font-size: 14px;
    line-height: 150%;
  }
`;

export const SquareBoxes = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 195px);
  gap: 27px 30px;
  grid-template-rows: repeat(2, 189px);
  font-size: 14px;
  line-height: 150%;
`;
export const DateCell = styled.div`
  grid-column: 2 / 2;
  grid-row: 2 / 2;
  text-align: start;
`;
export const CreatorCell = styled.div`
  grid-column: 2 / 1;
  grid-row: 2 / 1;
  text-align: start;
`;
export const ImageFirstCell = styled.div`
  grid-column: 2 / 2;
  grid-row: 2 / 1;
  background: ${variables.colorBleak};
  overflow: hidden;
  object-fit: cover;
  position: relative;
`;
export const ImageSecondCell = styled.div`
  grid-column: 2 / 1;
  grid-row: 2 / 2;
  background: ${variables.colorBleak};
  overflow: hidden;
  object-fit: cover;
  position: relative;
`;

export const ImageGalleryFourthTablet = styled(ImageContainerFourth)`
  width: 100%;
  height: 331px;
  position: relative;
  margin: 0;
`;
export const TextGalleryFourthTablet = styled(TextGalleryFourth)`
  width: 100%;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  max-width: none;

  & p {
    margin: 10px 0 20px 0;
  }

  & h3 {
    font-size: 27.7604px;
    line-height: 42px;
  }
`;

export const SquareBoxesTablet = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 115px);
  gap: 14px 10px;
  grid-template-rows: repeat(2, 115px);
  font-size: 10px;
  line-height: 140%;
  margin-bottom: 17px;
`;
export const DateCellTablet = styled.div`
  grid-column: 4 / 4;
  grid-row: 2 / 2;
  text-align: start;
  display: flex;
  align-items: flex-end;
`;
export const CreatorCellTablet = styled.div`
  grid-column: 3 / span 2;
  grid-row: 1 / 2;
  text-align: start;
  display: flex;
  align-items: center;
`;
export const TitleCellTablet = styled.div`
  grid-column: 1 / span 2;
  grid-row: 1 / 2;
  text-align: start;
  display: flex;
  align-items: center;

  & h3 {
    font-size: 22px;
    line-height: 33px;
    font-weight: normal;
  }
`;
export const MessageCellTablet = styled.div`
  grid-row: 2 / 2;
  text-align: start;
  width: 100%;
  font-size: 10px;
  line-height: 100%;

  & span,
  & p {
    font-size: 7px;
    display: flex;
    display: -moz-box;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -moz-box-orient: vertical;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    line-clamp: 8;
  }
`;
export const ImageFirstCellTablet = styled.div`
  grid-row: 2 / 2;
  background: ${variables.colorBleak};
  overflow: hidden;
  object-fit: cover;
  position: relative;
`;
export const ImageSecondCellTablet = styled.div`
  grid-row: 2 / 2;
  background: ${variables.colorBleak};
  overflow: hidden;
  object-fit: cover;
  position: relative;
`;

export const ImageGalleryFourthMobile = styled(ImageContainerFourth)`
  width: 100%;
  height: 151px;
  margin-right: 0;
  margin-bottom: 9px;
  position: relative;
`;
export const TextGalleryFourthMobile = styled(TextGalleryFourth)`
  width: 100%;
  font-size: 7px;
  line-height: 130%;
  text-align: start;

  & h3 {
    font-size: 14px;
    line-height: 140%;
  }
`;

export const SquareBoxesMobile = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 71px);
  gap: 9px 9px;
  grid-template-rows: repeat(3, 71px);
  font-size: 7px;
  line-height: 140%;
`;

export const DateCellMobile = styled(DateCellTablet)`
  grid-column: 2 / 2;
  grid-row: 3 / 3;
  text-align: start;
  display: flex;
  align-items: flex-start;
`;
export const CreatorCellMobile = styled(CreatorCellTablet)`
  grid-column: 1 / 2;
  grid-row: 3 / 2;
  text-align: start;
  align-items: flex-start;
`;
export const MessageCellMobile = styled(MessageCellTablet)`
  grid-column: 2 / 2;
  grid-row: 1 / 3;

  & span {
    font-size: 7px;
    line-height: 140%;
  }
`;
export const ImageFirstCellMobile = styled(ImageSecondCellTablet)`
  grid-column: 1 / 1;
  grid-row: 1 / 1;
`;
export const ImageSecondCellMobile = styled(ImageSecondCellTablet)`
  grid-column: 2 / 2;
  grid-row: 2 / 3;
`;

export const FileUploaderWrapper = styled.div`
background: #f0f1f2;
height: 100%;
width: 100%;
margin-right: 30px;
cursor: pointer;

& input {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1000;
}


  & > div {
    opacity: 0;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    display: flex;
    width: 100%;
    height: 100%;
    transition: opacity 0.1s;
    cursor: pointer;
    background: #f0f1f282;
    &::before,
    &::after {
      z-index: 1;
      background: ${variables.textBlack};
    }
  }
  &:hover {    
    cursor: pointer;

    & > div {
      opacity: 1;
    }
    & input {
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
      z-index: 1000;
    }
  }
`;
