import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  LittleGreyText,
  SimpleBlackText,
  SimplePinkText,
  TextBlackLarge,
  TextBlockLimitedWidth,
} from '../../../../../../../shared/styles';
import { HrLine } from '../../../../../../../shared/styles/Elements';
import { ActiveGalleriesSalesCardWrapper, SalesWrapper } from './styled';

type SalesCardProps = {
  sales: number;
  salesPerWeek: number;
};

export const SalesCard: React.FC<SalesCardProps> = ({
  sales,
  salesPerWeek,
}) => {
  const { t } = useTranslation('sales');

  const currentSales = sales || 0;
  const currentSalesPerWeek = salesPerWeek || 0;

  return (
    <ActiveGalleriesSalesCardWrapper>
      <SalesWrapper>
        <SimpleBlackText style={{ marginRight: '8px' }}>
          {t('sales')}
        </SimpleBlackText>
        <TextBlackLarge fontWeight={600} title={`${currentSales}`}>
          <TextBlockLimitedWidth maxWidth={100}>
            <Trans t={t} i18nKey="salesSum">
              {{ sales: currentSales }}
            </Trans>
          </TextBlockLimitedWidth>
        </TextBlackLarge>
      </SalesWrapper>
      <HrLine />
      <SimplePinkText fontWeight={500}>
        <TextBlockLimitedWidth maxWidth={130}>
          +
          {' '}
          { currentSalesPerWeek }
        </TextBlockLimitedWidth>
      </SimplePinkText>
      <LittleGreyText>{t('perWeek')}</LittleGreyText>
    </ActiveGalleriesSalesCardWrapper>
  );
};
