import React, { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { TooltipRenderProps } from 'react-joyride';
import { useAuth } from '@clerk/clerk-react';
import * as urls from '../../../../../../constants/urls';
import { CustomTooltipElement } from '../../../../../../shared/components/CustomJoyride/CustomTooltipElement';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../shared/hooks/useTypedSelector';
import { fetchRedirectToOnboarding } from '../../../../redux/userCreator/signUp/signUpCreatorThunk';

const newId = uuidv4();

export const CreatorCustomTooltip: React.FC<TooltipRenderProps> = (props) => {
  const navigate = useNavigate();
  const { index, step, primaryProps } = props;
  const dispatch = useCreatorAppDispatch();
  const creator = useTypedSelectorCreator((state) => state.creator?.currentUser);
  const redirectLoading = useTypedSelectorCreator((state) => state.signUpCreator.redirectLoading);
  const redirectError = useTypedSelectorCreator((state) => state.signUpCreator.redirectError);

  const { getToken } = useAuth();

  const handleClickNext = (e: MouseEvent<HTMLElement>) => {
    if (index === 4 && !creator?.onboarded) {
      dispatch(fetchRedirectToOnboarding(getToken));
    }
    if (index === 4 && creator?.onboarded) {
      navigate(`${urls.newGalleryUrl}/${newId}`);
    }
    if ((step.target as string).includes('supportSidebar')) {
      navigate(`${urls.supportUrl}`);
    }
    if (!(index === 4 && !creator?.onboarded)) {
      primaryProps.onClick(e);
    }
  };

  return (
    <CustomTooltipElement
      {...props}
      handleClickNext={handleClickNext}
      isLoading={redirectLoading}
    />
  );
};
