import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import * as variables from '../../../../../constants/styles';
import { theme } from '../../../../../shared/components/GlobalContainer/styled';
import { CustomPositionCheckbox } from '../../../../../shared/components/styled';
import { TextBlackLarge } from '../../../../../shared/styles';
import { ScreenSizeNames } from '../../../../../shared/types';

export const BaseCartFile = styled.div`
  position: relative;
  width: 195px;
  height: 199px;
  min-width: 195px;
  min-height: 195px;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    width: 74px;
    height: 75px;
    min-width: 74px;
    min-height: 75px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 181px;
    height: 181px;
    min-width: 181px;
    min-height: 181px;
  }
`;

export const CartDeleteButtonGrid = styled(Grid)<{screenSize: ScreenSizeNames}>`
  display: ${({ screenSize }) => (screenSize === 'desktop' ? 'block' : 'none')};

  & button {
    padding: 0px;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    display: ${({ screenSize }) => (screenSize === 'desktop' ? 'none' : 'block')};
  }
`;

export const CartFileWrapper = styled(BaseCartFile)<{isChecked: boolean}>`
  border: 1px solid ${({ isChecked }) => (isChecked ? variables.colorPink : variables.colorGreyLight)};

  & #basic-doc {
    border-radius: 16px;
    font-size: 14px;
    padding: 17.5px;

    & svg {
      width: 31px;
      height: 36px;
    }
  }

  & div {
    & img {
      max-width: none !important;
    }
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    & #basic-doc {
      border-radius: 4px;
      font-size: 7px;
      padding: 16px 6px;

      & svg {
        width: 16px;
        height: 18px;
      }
    }
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    & #basic-doc {
      border-radius: 16px;
      margin-bottom: 8px;
      margin-right: 8px;

      font-size: 10px;
      padding: 17.5px;

      & svg {
        width: 31px;
        height: 36px;
      }
    }
  }
`;

export const CartItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  width: 100%;

  & img {
    max-width: inherit !important;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin-bottom: 20px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-bottom: 8px;
  }
`;

export const CartItemDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 30px;
  width: 100%;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin: 0px 52px 0px 20px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin: 0 20px;

    & div {
      font-size: 14px;
      line-height: 19.6px;
      margin-bottom: 10px;

      &:last-child {
        margin-top: 4px;
      }
    }
  }
`;

export const CartItemTextDescription = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin-top: 0px;
  }
`;

export const CustomPositionCheckboxCart = styled(CustomPositionCheckbox)`
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    & > label {
      height: 14px;
      width: 14px;

      & > span, & #checkbox {
        height: 14px;
        width: 14px;  
        top: 6px;
        left: 8px;
        margin-top: 0;
      }
    }
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    & #checkbox {
      height: 22px;
      width: 22px;
    }

    & > label {
      height: 22px;
      width: 22px;

      & > span, & #checkbox {
        height: 22px;
        width: 22px;
        top: 10px;
        left: 11px;
        margin-top: 0;
      }
    }
  }
`;

const CartItemDescriptionTextBase = styled(TextBlackLarge)`
  & p {
    padding: 0px;
  }
`;

export const CartItemDescriptionTitle = styled(CartItemDescriptionTextBase)`
  & p {
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    max-width: 175px;
    font-size: 18px;
    line-height: 140%;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    & p {
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      max-width: 165px;
      font-size: 14px;
    }
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    & p {
      padding: 0;
      white-space: initial;
      overflow: initial;
      text-overflow: ellipsis;
      margin: 0;
      max-width: 150px;
    }
  }
`;

export const CartItemDescriptionText = styled(CartItemDescriptionTextBase)`
  display: flex;
  display: -moz-box;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  line-clamp: 4;
  margin: 0;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    -webkit-line-clamp: 2;
    line-clamp: 2;
    font-size: 12px;
    line-height: 140%;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    -webkit-line-clamp: 4;
    line-clamp: 4;
    font-size: 14px;
  }
`;
