import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import * as variables from '../../../../constants/styles';
import { theme } from '../../../../shared/components/GlobalContainer/styled';
import { LittleGreyText } from '../../../../shared/styles';

export const LandingFooterWrapper = styled.div`
  box-sizing: border-box;
  padding: 64px 152px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid rgba(235, 235, 235, 0.8);
  margin-top: 40px;
  filter: drop-shadow(0px -5px 15px #fff);
  box-shadow: 0px -5px 15px #fff;
  max-height: 272px;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    padding: 64px 70px 24px;
  }

  @media (max-width: 650px) {
    padding: 64px 30px 24px 40px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 53px 16px 36px 16px;
    max-height: fit-content;
  }
`;

export const LandingFooterLogoWrp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > svg {
    width: 215px;
    height: 34px;
    margin-bottom: 24px;
    
    & path {
      width: 215px;
    }
  }
`;

export const LandingFooterTextsWrp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const LandingFooterLinkWrp = styled.div`
  margin-bottom: 24px;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    & span {
      font-size: 14px;
      line-height: 150%;  
    }
  }
`;

export const LandingFooterRightsDesktopMobWrp = styled(LittleGreyText)`
  margin-top: 72px;
  display: flex;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    display: none;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    display: flex;
    margin-top: 60px;
  }
`;

export const LandingFooterRightsTabletWrp = styled(LittleGreyText)`
  margin-top: 64px;
  display: block;
  text-align: center;
  width: 100%;

  @media (min-width: ${theme.breakpoints.values.lg}px) {
    display: none;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    display: none;
  }
`;

export const LandingFooterLogoText = styled(LittleGreyText)`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: pre;
  margin-top: 30px;

  & span {
    display: flex;
    height: fit-content;
    margin: 0px 5px;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    font-size: 14px;
    line-height: 150%;
  }
`;

export const LandingFooterMediaWrp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-end;

  & * {
    margin-left: 5px;
    color: ${variables.textGrey};

    &:first-of-type {
      margin-right: 2px;
    }
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    & svg {
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    justify-content: center;
    margin-top: 80px;

    & svg {
      width: 32px;
      height: 32px;
    }
  }
`;

export const LandingFooterGridTabletDesktop = styled(Grid)`
  @media (max-width: ${theme.breakpoints.values.md}px) {
    display: none !important;
  }
`;

export const LandingFooterGridMobile = styled(Grid)`
  @media (min-width: ${theme.breakpoints.values.md}px) {
    display: none !important;
  }
`;
