/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import {
  baseURL, clientInstance, instance,
} from './instance';
import creatorStore from '../../modules/Creator/redux/configureStore';
import clientStore from '../../modules/Client/redux/configureStore';
import { setCreatorIsNotSignIn } from '../../modules/Creator/redux/userCreator/signIn/signInCreatorSlice';
import { setClientIsNotSignIn } from '../../modules/Client/redux/signIn/signInClientSlice';
import { getPathData } from '../../modules/Client/utils/helpers';
import { setErrorBetaCode, unsetErrorBetaCode } from '../../modules/Creator/redux/userCreator/creator/creatorSlice';

export function interceptorCreator(): void {
  instance.interceptors.response.use(
    (config) => config,
    async (error) => {
      try {
        const originalRequest = error.config;
        if (error.response.status === 401 && error.config && !error.config._retry) {
          originalRequest._retry = true;
          try {
            await axios.all([
              axios.get(`${baseURL}auth/beta/refresh`, { withCredentials: true }),
              // axios.get(`${baseURL}auth/refresh`, { withCredentials: true }),
            ]);
            return instance.request(originalRequest);
          } catch (e: any) {
            if (e?.response?.config?.url?.includes('beta')) {
              creatorStore?.dispatch(setErrorBetaCode());
            } else {
              if (creatorStore.getState().creator.getErrorCode !== 'beta') {
                creatorStore?.dispatch(unsetErrorBetaCode());
              }
              creatorStore?.dispatch(setCreatorIsNotSignIn());
            }
          }
        }
        if (error && error.message === 'Network Error') {
          return Promise.reject(error);
        }
        throw error;
      } catch (err: any) {
        return Promise.reject(error);
      }
    },
  );
}

export function interceptorClient(): void {
  const { businessName, galleryName } = getPathData();
  clientInstance.interceptors.response.use((config) => config, async (error) => {
    try {
      const originalRequest = error.config;
      if (error.response.status === 401 && error.config && !error.config._retry) {
        originalRequest._retry = true;
        try {
          await axios.get(`${baseURL}auth/client/refresh/${businessName}/${galleryName}`, { withCredentials: true, headers: { role: 'client' } });
          return clientInstance.request(originalRequest);
        } catch (e) {
          clientStore?.dispatch(setClientIsNotSignIn());
        }
      }
      if (error && error.message === 'Network Error') {
        return Promise.reject(error);
      }
      throw error;
    } catch (err: any) {
      return Promise.reject(error);
    }
  });
}
