import { useTypedSelectorCreator } from '../../../shared/hooks/useTypedSelector';

export const useLoadingFiles = (id: string): boolean => {
  const loadingFiles = useTypedSelectorCreator(
    (state) => state.uploadFiles.loadingFiles,
  );
  const isLoading = loadingFiles.includes(id);

  return isLoading;
};
