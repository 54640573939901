import styled from '@emotion/styled';
import {
  SixthInfoCardCover, SixthImageContainer, SixthTitle, SixthMediumTextCenter,
} from '../../../../../../../../GalleryView/components/GalleryCovers/GallerySixth/styled';

export const SixthInfoCardCoverPreview = styled(SixthInfoCardCover)`
  top: calc(50% - ${(props) => (props.currentHeight ? (props.currentHeight / 2) : '31')}px);
  left: calc(50% - 57px);
  width: 114px;
  min-height: 62px;
  padding: 16px 13px 14px;
`;

export const ImageContainerSixthPreview = styled(SixthImageContainer)`
  width: 238px;
  height: 158px;
  margin-bottom: 5px;
`;

export const SixthTitlePreview = styled(SixthTitle)`
  font-size: 7.65977px;
  line-height: 11px;
  margin-bottom: 0;
`;

export const SixthMediumTextPreview = styled(SixthMediumTextCenter)`
  font-size: 4px;
  line-height: 140%;

  & p,
  & span {
    margin-top: 4px;
  }
`;

export const SixthMobileWrapper = styled.div`
  margin: 60px 0px;
`;
