import styled from '@emotion/styled';
import { PlanEnum, PlanTypes } from '../../../../../../../shared/types';
import * as variables from '../../../../../../../constants/styles';

export const SettingsGalleryCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  min-height: fit-content;
  margin-bottom: 30px;
`;

export const SettingsGalleryCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const SettingsGalleryCardFile = styled.div`
  width: 195px;
  height: 131px;
  border-radius: 4px;
`;

export const RowCards = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 700px;
  position: relative;
`;

export const StorageLoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-right: 30px;
  max-width: 700px;
  margin-bottom: 30px;
`;

export const ButtonMgT = styled.div`
  margin-top: 30px;
`;

export const TextAndIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & svg {
    margin-right: 8px;
    width: 28px;
    height: 28px;
    color: ${variables.colorBlack};
  }
`;

export const UpgradeToPremiumWrapper = styled.div`
  background: #F5F5F5;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  border-radius: 10px;
`;

export const SettingsPlanCardContainer = styled.div<{ border: PlanTypes }>`
  width: 100%;
  height: fit-content;
  background: variables.colorGreyLight;
  border-radius: 9px;
  transition: all 0.5s;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${(props) => {
    if (props.border === PlanEnum.Free) return 'border: 1px solid #828D9566;';
    if (props.border === PlanEnum.Basic) return 'border: 1px solid #828D9566;';
    if (props.border === PlanEnum.Pro) return `border: 1px solid ${variables.colorPink}`;
    if (props.border === PlanEnum.ProPlus) {
      return `
    border: 1px solid transparent;
    border-radius: 9px;
    background-image: linear-gradient(${variables.colorGreyLight}, ${variables.colorGreyLight}), linear-gradient(270deg, #E6934F 0%, #E186B0 49.82%, #78C0EA 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    `;
    }
    return 'border: 1px solid #828D9566;';
  }}
`;

export const SettingsPlanCardContent = styled.div`
  box-sizing: border-box;
  padding: 28px 38px 28px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  border-radius: inherit;
  background: variables.colorGreyLight;
`;

export const SettingsPlanCardFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 26px;
  justify-content: space-between;
`;
