import styled from '@emotion/styled';
import { theme } from '../../../../shared/components/GlobalContainer/styled';
import { MediumBlackText, PrimaryWhiteButton, SignInUpCardCenter } from '../../../../shared/styles';
import * as variables from '../../../../constants/styles';

export const SignInForm = styled.div<{ direction: 'row' | 'column' }>`
  margin-top: 28px;
  width: 100%;

  & form {
  display: flex;
  flex-direction: ${({ direction }) => direction};
  width: 100%;
  justify-content: space-between;
  align-items: center;
  }
`;

export const ResetSuccessButton = styled(PrimaryWhiteButton)`
  margin-top: 24px;
  min-width: 213px;
`;

export const ResetButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 38px;

  & button {
    margin: 0 8px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex-direction: column;

    & button {
      margin: 18px 8px;
    }
  }
`;

export const ResetPasswordCard = styled(SignInUpCardCenter)`
  padding-left: 40px;
  padding-right: 40px;
`;

export const SendResendText = styled(MediumBlackText)`
  text-align: center;

  & > svg {
    margin-right: 5px;
  }

  & > a, button {
    margin-left: 5px;
    margin-right: 5px;
    color: ${variables.colorPink};
    border-bottom: 1px solid ${variables.colorPink};
    padding: 0;
    
  }
`;

export const ResetLittleText = styled.span`
  color: ${variables.textBlack};
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  line-height: 150%;
  margin-bottom: 10px;

  & > a {
    margin-left: 5px;
    margin-right: 5px;
    color: ${variables.colorPink};
    border-bottom: 1px solid ${variables.colorPink};
  }
`;
