import styled from '@emotion/styled';
import * as variables from '../../../../../../../constants/styles';
import { BaseColors } from '../../../../../../../shared/types';
import { getBaseColor } from '../../../../../../../shared/utils/style/getBaseColor';

export const SalesChartWrapper = styled.div`
  margin-top: 40px;
  width: 100%;

  & div {
    font-size: 0.6rem;
  }
`;

export const GiantText = styled.div`
  font-size: 46px;
  line-height: 69px;
  display: flex;
  align-items: center;
  color: ${variables.colorBleak};
`;

export const TabsWrapper = styled.div<{tabButtonHeight?: number, tabButtonWidth?: number }>`
  width: ${(props) => (props.tabButtonWidth ? props.tabButtonWidth * 3 : '390')}px;
  height: ${(props) => (props.tabButtonHeight ? props.tabButtonHeight : '34')}px !important;
`;

export const CustomTooltipWrapper = styled.div`
  background: ${variables.colorGreyLight};
  padding: 4px 15px;
  border: 1px solid ${variables.colorPink};
  border-radius: 3px;
`;

export const CustomTooltipText = styled.p<{ color: BaseColors }>`
  font-size: 12px;
  color: ${({ color }) => getBaseColor(color)};
`;
