import React, { ChangeEvent } from 'react';
import {
  FormControlLabel, Radio, RadioGroup, styled,
} from '@mui/material';
// import styled from '@emotion/styled';
import { SetFieldValue } from '../../../types';
import { ErrorMessage } from '../../styled';
import {
  RadioWrapper,
  RadioLabel,
  CustomMarginTextLabel,
  RadioCustomErrorMessage,
} from './styled';
import * as variables from '../../../../constants/styles';

type CommonRadioButton = {
  value: string;
  label: string;
  error?: string;
  nameGroup?: string;
  nameRadio: string;
  touched?: boolean;
  setFieldValue: SetFieldValue;
  likeCheckbox?: boolean;
};

type GroupRadioButtonsProps = {
  radioButtons: { value: string, label: string }[];
  value: string;
  name: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: SetFieldValue;
}

export const CustomFormControlLabel = styled(FormControlLabel)(() => ({
  root: {
    fontFamily: variables.mainFont,
  },
  '&.MuiFormControlLabel-label': {
    fontFamily: variables.mainFont,
  },
  '& .MuiTypography-root': {
    fontFamily: variables.mainFont,
    fontSize: '14px',
  },
}));

export const CustomRadio = styled(Radio)(() => ({
  root: {
    color: `${variables.colorPink} !important`,
    background: variables.colorPink,
    backgroundColor: variables.colorPink,
  },
  '&.MuiRadio-root': {
    color: `${variables.colorPink} !important`,
  },
  '&.Mui-checked': {
    color: `${variables.colorPink} !important`,
    // background: variables.colorPink,
    // backgroundColor: variables.colorPink,

  },
  '&.MuiRadio-colorPrimary': {
    color: `${variables.colorPink} !important`,
    // background: variables.colorPink,
    // backgroundColor: variables.colorPink,

  },
  '& svg': {
    color: `${variables.colorPink} !important`,
  },
  '&.MuiSvgIcon-root': {
    color: `${variables.colorPink} !important`,
  },
  '&.PrivateSwitchBase-input': {
    color: `${variables.colorPink} !important`,
  },
  '&, &.Mui-checked': {
    color: `${variables.colorPink} !important`,
  },
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: `${variables.colorPink} !important`,
  },
  '& input': {
    backgroundColor: `${variables.colorPink} !important`,
    color: `${variables.colorPink} !important`,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: variables.colorPink,
  },
}));

export const GroupRadioButtons: React.FC<GroupRadioButtonsProps> = ({
  radioButtons,
  value,
  name,
  handleChange,
  setFieldValue,
}) => {
  const onClick = (v: string) => {
    setFieldValue(name, v);
  };
  return (
    <RadioGroup
      aria-labelledby="demo-controlled-radio-buttons-group"
      name={name}
      value={value}
      onChange={handleChange}
    >
      {radioButtons.map((item) => (
        <CustomFormControlLabel
          value={item.value}
          control={(
            <RadioLabel>
              <input
                type="radio"
                name={name}
                value={value}
                onChange={() => onClick(item.value)}
              />
              <div />
            </RadioLabel>
)}
          label={item.label}
        />
      ))}
    </RadioGroup>
  );
};

export const CommonRadioButton: React.FC<CommonRadioButton> = ({
  label,
  nameRadio,
  error,
  touched,
  value,
  setFieldValue,
  nameGroup,
  likeCheckbox,
}) => {
  const handleClick = () => {
    const nextValue = likeCheckbox && value === nameRadio ? '' : nameRadio;
    setFieldValue(nameGroup || nameRadio, nextValue);
  };

  return (
    <RadioWrapper>
      <RadioLabel>
        <input
          type="radio"
          name={nameRadio}
          value={value}
          onClick={handleClick}
          checked={value === nameRadio}
        />
        <div />
      </RadioLabel>
      {label && (
      <CustomMarginTextLabel onClick={handleClick}>
        {label}
      </CustomMarginTextLabel>
      )}
      <RadioCustomErrorMessage>
        {error && touched ? <ErrorMessage>{error}</ErrorMessage> : null}
      </RadioCustomErrorMessage>
    </RadioWrapper>
  );
};
