import { isImage, isVideo, isDocument } from '..';
import { GalleryFile } from '../../types';

type ReturnObj = {
  arrImagesAndVideos: GalleryFile[];
  arrDocuments: GalleryFile[];
}

export const getImagesVideosDocuments = (files: GalleryFile[]): ReturnObj => {
  const arrImagesAndVideos: GalleryFile[] = [];
  const arrDocuments: GalleryFile[] = [];
  files?.forEach((item) => {
    if (isImage(item) || isVideo(item)) {
      arrImagesAndVideos.push(item);
    }
    if (isDocument(item)) {
      arrDocuments.push(item);
    }
  });

  return { arrImagesAndVideos, arrDocuments };
};
