import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import * as variables from '../../../constants/styles';
import waves from '../../../assets/image/landing/pricing/waves.png';
import { theme } from '../GlobalContainer/styled';
import { OptimisedStyles } from '../../types';

export const PaymentGrid = styled.div<{styles?: OptimisedStyles}>`
  box-shadow: -5px -5px 10px #e8e8e8 !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  min-height: 100vh;
  height: 100vh;
  min-width: 48%;
  ${({ styles }) => styles?.desktop}

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    box-shadow: none !important;
    width: fit-content !important;
    margin-bottom: 80px;
    min-height: fit-content;
    height: fit-content;
    max-width: 420px;
    min-width: 420px;
    width: 420px;
    ${({ styles }) => styles?.tablet}
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 100%;
    ${({ styles }) => styles?.mobile}
  }
`;

export const PaymentSuccessfulWrapper = styled.div<{styles?: OptimisedStyles, hasBackground: boolean}>`
  height: calc(100vh - 70px);
  box-sizing: border-box;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  ${({ hasBackground }) => (hasBackground ? `
  background-image: url(${waves});
  background-position: 20% 30%;
  background-repeat: no-repeat;
  background-size: 120%;
  width: calc(100% + 48px);
  margin-left: -24px;
  ` : '')}
  ${({ styles }) => styles?.desktop}

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    background-position: 20% 0%;
    ${({ styles }) => styles?.tablet}
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 100%;
    ${({ hasBackground }) => (hasBackground ? `
    margin-left: -32px;
    width: calc(100% + 64px);
    background-position: 20% 0%;
    background-size: 200%;
    ` : '')}
    padding: 67px 0px 0px;
    box-sizing: border-box;
    height: calc(100vh - 117px);
    ${({ styles }) => styles?.mobile}
  }
`;

export const PaymentSuccessfulTitle = styled.div`
  font-weight: 600;
  font-size: 40px;
  line-height: 110%;
  color: ${variables.textBlack};
  margin-bottom: 28px;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    font-weight: 700;
    font-size: 30px;
    line-height: 130%;
    margin-bottom: 20px;
  }
`;

export const TextWithCheckmark = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & svg {
    margin-right: 3px;
    color: ${variables.colorPink};
    width: 16px;
    height: 13px;
  }
`;

export const ScreenOptimizedGrid = styled(Grid)`
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    justify-content: center !important;
  }
`;

export const PaymentFilesListWrapper = styled.div`
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin-top: 10px;
    margin-bottom: 40px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 100%;
  }
`;

export const PaymentBackToCartBtn = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  padding: 0;
  margin-top: 100px;

  & div {
    &:first-of-type {
      width: 11px;
    }
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin-top: 31px;
    width: 100%;
  }
`;

export const PaymentMainLogoWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;

  & svg {
    width: 118px;
  }
`;

export const CardElementFormPrice = styled.div<{styles?: OptimisedStyles}>`
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 24px;
  ${({ styles }) => styles?.desktop}

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin-bottom: 40px;
    ${({ styles }) => styles?.tablet}
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-bottom: 32px;
    font-size: 30px;
    line-height: 130%;
    ${({ styles }) => styles?.mobile}
  }
`;

export const PaymentPageWrapper = styled.div<{styles?: OptimisedStyles}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${({ styles }) => styles?.desktop}

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    ${({ styles }) => styles?.tablet}
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 0px 16px;
    ${({ styles }) => styles?.mobile}
  }
`;

export const PaymentPageOrderSide = styled.div<{styles?: OptimisedStyles}>`
  display: flex;
  flex-direction: column;
  ${({ styles }) => styles?.desktop}

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    ${({ styles }) => styles?.tablet}
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    ${({ styles }) => styles?.mobile}
  }
`;

export const PaymentPageOrderWrap = styled.div<{styles?: OptimisedStyles}>`
  display: flex;
  ${({ styles }) => styles?.desktop}

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    max-width: 420px;
    width: 100%;
    ${({ styles }) => styles?.tablet}
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    ${({ styles }) => styles?.mobile}
  }
`;

export const PaymentSuccessfulHeader = styled.div<{styles?: OptimisedStyles}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  margin-bottom: 20px;
  ${({ styles }) => styles?.desktop}

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    ${({ styles }) => styles?.tablet}
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 30px 0px;

    & svg {
      width: 165px;
      height: 27px;
    }

    ${({ styles }) => styles?.mobile}
  }
`;
