import styled from '@emotion/styled';
import * as variables from '../../../constants/styles';
import { theme } from '../GlobalContainer/styled';

export const TechnicalPageWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(297.92deg, #E6934F -25.37%, #E186B0 52.4%, #78C0EA 130.11%);
  box-sizing: border-box;
  padding: 20px;
`;

export const TechnicalPageTitle = styled.div`
  font-weight: 600;
  font-size: 120px;
  line-height: 180px;
  color: ${variables.colorGreyLight};
  margin-bottom: 16px;
  text-align: center;
  transition: all 0.2s ease-in-out;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.01em;
    transition: all 0.2s ease-in-out;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    font-size: 36px;
    line-height: 130%;
    font-weight: 700;
    transition: all 0.2s ease-in-out;
  }
`;

export const TechnicalPageText = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: ${variables.colorGreyLight};
  margin-bottom: 40px;
  max-width: 700px;
  text-align: center;
  transition: all 0.2s ease-in-out;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    font-size: 18px;
    line-height: 160%;
    max-width: 541px;
    transition: all 0.2s ease-in-out;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    font-size: 16px;
    line-height: 150%;
    max-width: 310px;
    transition: all 0.2s ease-in-out;
  }
`;
