import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import * as urls from '../../../constants/urls';
import { ActiveGalleries } from '../containers/CreatorAccount/Tabs/ActiveGalleries/ActiveGalleries';
import { DraftGalleries } from '../containers/CreatorAccount/Tabs/DraftGalleries/DraftGalleries';
import { MainDashboard } from '../containers/CreatorAccount/Tabs/MainDashboard/MainDashboard';
import { Notifications } from '../containers/CreatorAccount/Tabs/Notifications/Notifications';
import { Sales } from '../containers/CreatorAccount/Tabs/Sales/Sales';
import { Settings } from '../containers/CreatorAccount/Tabs/Settings/Settings';
import { Support } from '../containers/CreatorAccount/Tabs/Support/Support';
import { ResetPassword } from '../containers/SignIn/ResetPassword';
import { ResetSuccess } from '../containers/SignIn/ResetSuccess';
import { SignInCard } from '../containers/SignIn/SignInCard';
import { NewGalleryCreatorContainer } from '../containers/CreatorAccount/Tabs/NewGalleryCreator/NewGalleryCreatorContainer';
import { SetNewPassword, SetNewPasswordSuccess } from '../containers/SignIn/SetNewPassword';
import { ChangePlanPage } from '../containers/CreatorAccount/Tabs/Settings/ChangePlanPage/ChangePlanPage';
import { PaymentDetails } from '../containers/CreatorAccount/Tabs/Settings/PaymentDetailsPage/PaymentDetails';
import { SignUpContainer } from '../containers/SignUp/SignUpContainer';

export const CreatorSignedOutRoutes: React.FC = () => (
  <Routes>
    <Route path="/signup/*" element={<SignUpContainer />} />
    <Route path="/signin" element={<SignInCard />} />
    <Route path="/reset" element={<ResetPassword />} />
    <Route path="/reset/success" element={<ResetSuccess />} />
    <Route path="/resetpw/confirm" element={<SetNewPassword />} />
    <Route path="/resetpw/confirm/success" element={<SetNewPasswordSuccess />} />
    <Route path="*" element={<Navigate to="/creator/signup" replace />} />
  </Routes>
);

export const CreatorSignedInRoutes: React.FC = () => (
  <Routes>
    <Route path="/new-gallery/:id" element={<NewGalleryCreatorContainer />} />
    <Route path="/notifications" element={<Notifications />} />
    <Route path="/sales" element={<Sales />} />
    <Route path="/support" element={<Support />} />
    <Route path="/settings" element={<Settings />} />
    <Route path="/settings/changeplan" element={<ChangePlanPage />} />
    <Route path="/settings/payment" element={<PaymentDetails />} />
    <Route path="/active-gallery" element={<ActiveGalleries />} />
    <Route path="/draft-gallery" element={<DraftGalleries />} />
    <Route path="/" element={<MainDashboard />} />
    <Route path="*" element={<Navigate to="/creator" replace />} />
  </Routes>
);
