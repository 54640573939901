import {
  format, isYesterday, isToday,
} from 'date-fns';
import moment from 'moment-timezone';
import { months } from '../../constants';

// transform date from Date format to "yyyy-mm-dd"
export const getFormatDateForDob = (date: Date): string => format(date, 'yyyy-MM-dd');

export const getLocalDateTime = (date: string, timezone?: string): string => {
  const normalDate = moment.tz(new Date(date).toString(), timezone || moment.tz.guess());
  return normalDate.format();
};

export const getDateFromSec = (seconds?: string | number): string => {
  if (seconds) {
    const normalDate = new Date(Number(seconds) * 1000).toString();
    return normalDate;
  }
  return new Date(Number(0) * 1000).toString();
};

export const getFormattedDateOrDateName = (
  date: string,
  dateFormat: string,
  timezone?: string,
  withName?: boolean,
): string => {
  if (!date) {
    return '';
  }
  let resDate = '';
  const dateFormatUpperCase = (dateFormat || 'mm/dd/yyyy')
    .toUpperCase()
    .replaceAll('D', 'd')
    .replaceAll('Y', 'y');
  try {
    const d = new Date(getLocalDateTime(date, timezone));

    if (dateFormatUpperCase) {
      if (isYesterday(d) && withName) {
        resDate = 'Yesterday';
      } else if (isToday(d) && withName) {
        resDate = 'Today';
      } else {
        resDate = format(d, dateFormatUpperCase);
      }
    }
    return resDate;
  } catch (e) {
    console.log(e);
    return '';
  }
};

export const getFormattedTime = (date: string, timezone?: string, timeFormat?: string): string => {
  let resTime = '';

  try {
    const d = new Date(getLocalDateTime(date, timezone));

    resTime = format(d, timeFormat || 'hh:mm a');

    return resTime.toLowerCase();
  } catch (e) {
    console.log(e);
    return '';
  }
};

export const getDayMonthYear = (date: string, timezone?: string, dateFormat?: 'm d y' | 'd m y'): string => {
  const d = new Date(getLocalDateTime(date, timezone));
  const monthD = months[d.getMonth()];
  const year = d.getFullYear();
  const day = d.getDate();

  if (dateFormat === 'd m y') {
    return `${day} ${monthD} ${year}`;
  }
  if (dateFormat === 'm d y') {
    return `${monthD} ${day}, ${year}`;
  }
  return `${day} ${monthD} ${year}`;
};
