import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const RefundsAndCancelPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={10}
      listTitle={t('terms.refundsCancel')}
      pointsNameForTrans="refundsCancelPoints"
    >
      <LandingNumberedLi>
        Subject to this clause, all payments made by a Client via our
        Platform and final and refunds are not available via the Platform.
      </LandingNumberedLi>
      <LandingNumberedLi>
        The cancellation, exchange, or refund of any goods ordered on this
        Platform is strictly a matter between the relevant Client and
        Member. The terms and conditions relating to cancellation and
        refunds (if allowable) must be set out clearly in the relevant
        Project Terms.
      </LandingNumberedLi>
      <LandingNumberedLi>
        Should the Member and Client agree to a refund of the Project Fees,
        both the Member and Client acknowledge and agree that to the maximum
        extent permitted by law, any Deferred Payment Service Fee payable by
        the Member to us and taken out of the Client’s Project Fees before
        payment to the Member, is not refundable.
      </LandingNumberedLi>
      <LandingNumberedLi>
        Should the Member and Client agree to a refund of the Project Fees,
        both the Member and Client acknowledge and agree that to the maximum
        extent permitted by law, this is a matter for the individual parties
        to privately arrangement off Platform. For the avoidance of doubt,
        the Platform will not facilitate this refund.
      </LandingNumberedLi>
      <LandingNumberedLi>
        For disputes between Clients and Member, we encourage Parties to
        attempt to resolve disputes (including claims for returns or
        refunds) with the other Party directly and in good faith, through
        external communication methods. In the event that a dispute cannot
        be resolved through these means, the Parties may choose to resolve
        the dispute in any manner agreed between the Parties or otherwise in
        accordance with applicable laws.
      </LandingNumberedLi>
      <LandingNumberedLi>
        This clause will survive the termination or expiry of these Terms.
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
