import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SimpleBlackText,
} from '../../../../../../../../../shared/styles';
import { RegisteredEmail } from '../../../../../../../../../shared/types';
import { RegisteredEmailRow } from './RegisteredEmailRow';
import {
  RegisteredEmailTitle,
  ScrolledWrapperRegisteredEmails,
  TitleMediumBlackTextMgB,
  TitleMediumBlackTextMgBFlexEnd,
} from './styled';

type RegisteredEmailsProps = {
  registeredEmails: RegisteredEmail[];
  galleryId: string;
};

export const RegisteredEmailsTab: React.FC<RegisteredEmailsProps> = ({
  registeredEmails,
  galleryId,
}) => {
  const { t } = useTranslation('gallery');

  if (!registeredEmails?.length) { return <SimpleBlackText>{t('noEmailRegistrations')}</SimpleBlackText>; }
  const sortedArr = [...registeredEmails];

  sortedArr.sort(
    (item1: RegisteredEmail, item2: RegisteredEmail) => (new Date(item2.lastVisitDate || '') as any)
      - (new Date(item1.lastVisitDate || '') as any),
  );

  return (
    <>
      <RegisteredEmailTitle>
        <SimpleBlackText>{t('registeredEmails.title')}</SimpleBlackText>
        {/* hide for now */}
        {/* <RegisteredEmailExportButton>
          <DownloadIcon />
          <MediumGreyText>{t('registeredEmails.export')}</MediumGreyText>
        </RegisteredEmailExportButton> */}
      </RegisteredEmailTitle>
      <Grid container item sm={12}>
        <Grid item sm={6}>
          <TitleMediumBlackTextMgB fontWeight={500}>
            {t('registeredEmails.visitorEmail')}
          </TitleMediumBlackTextMgB>
        </Grid>
        <Grid item sm={4}>
          <TitleMediumBlackTextMgB fontWeight={500}>
            {t('registeredEmails.lastVisit')}
          </TitleMediumBlackTextMgB>
        </Grid>
        <Grid item sm={2}>
          <TitleMediumBlackTextMgBFlexEnd fontWeight={500}>
            {t('registeredEmails.actions')}
          </TitleMediumBlackTextMgBFlexEnd>
        </Grid>
      </Grid>
      <ScrolledWrapperRegisteredEmails>
        <Grid container spacing={0}>
          {sortedArr.map((item) => (
            <RegisteredEmailRow
              key={item.email}
              row={item}
              galleryId={galleryId}
            />
          ))}
        </Grid>
      </ScrolledWrapperRegisteredEmails>
    </>
  );
};
