import styled from '@emotion/styled';

export const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: inherit;
  position: relative;
`;

export const VideoElement = styled.video<{controls: boolean}>`
  ${({ controls }) => (controls ? `
    max-width: 100%;
    height: inherit;
  ` : `
    max-width: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
  `)}
  vertical-align: middle;
  aspect-ratio: auto;
`;

export const ButtonWrapper = styled.div<{isPlay: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: #00000045;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  ${({ isPlay }) => (isPlay
    ? `background: transparent;
    transition: all 0.3s ease-in-out;

    & > button {
      display: none;
    }
    &:hover {
      background: #00000045;
      transition: all 0.3s ease-in-out;

      & > button {
        display: flex;
      }
    }
    `
    : `background: #00000045;

    & > button {
      display: flex;
    }
    `)}
`;

export const PlayOrPauseButton = styled.button<{ little: boolean }>`
  padding: 0;
  transition: all 0.3s ease-in-out;
  display: flex !important;
  ${({ little }) => (little ? `
    & svg {
      width: 32px;
      height: 32px;
    }
  ` : '')}

  &:hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.1);
  }
`;
