import React, { ReactElement, useEffect, useState } from 'react';
import { CustomAlert } from '../../../shared/components/CustomAlert/CustomAlert';
import { SetFieldError } from '../../../shared/types/formik';
import { errorMessages } from '../../../shared/utils';

export const useErrors = (error: any, setFieldError?: SetFieldError): ReactElement => {
  const [open, setOpen] = useState(false);

  const setErrors = () => {
    if (!setFieldError) return;

    (error as any)?.data?.field?.forEach((item: any) => {
      setFieldError(
        item,
        (error as any)?.data.message || errorMessages.smthWentWrong,
      );
    });
  };

  useEffect(() => {
    if (typeof error === 'string' && error) {
      setOpen(!!error);
    }
    if (!(error as any)?.data?.field?.length) {
      setOpen((error as any)?.data?.message);
    }
    if ((error as any)?.data?.field?.length) {
      setErrors();
    }
  }, [error]);

  return (
    <CustomAlert
      isOpenWindow={open}
      message={(error as any)?.data?.message || errorMessages.smthWentWrong}
      title="Error"
      onClick={() => setOpen(false)}
      type="error"
    />
  );
};
