import React, {
  useContext,
  useEffect,
  useMemo, useRef, useState,
} from 'react';
import { NavigationSelect } from './NavigationSelect';
import { GallerySection } from '../../../../../../../shared/types';
import { NavButtons } from '../../../../../../Client/containers/ClientHeader/NavButtons';
import { scrollToElement } from '../../../../../utils/scrollToElement';
import {
  NavigationContainerMobile,
  NavigationLinkButtons,
  NavigationWrapperMobile,
} from './styled';
import { ClientStateContext } from '../../../../../../Client/context/ClientStateContext';

type SectionNavigationSelectProps = {
  sections: GallerySection[];
  currentSection: GallerySection;
};

export const SectionNavigationSelect: React.FC<
  SectionNavigationSelectProps
> = ({ sections, currentSection }) => {
  const { isClientSignIn } = useContext(ClientStateContext);
  const [isFixed, setIsFixed] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const offsetHeight = useRef<number>(headerRef.current?.offsetTop || 0);

  const sectionTitles = sections?.map((item) => ({
    id: item.id,
    value: item.title,
  }));

  const handleClick = (id: string) => {
    scrollToElement(id, 'scrolled-container');
  };

  const currentMenuItem = useMemo(
    () => ({ id: currentSection.id, value: currentSection.title }),
    [currentSection],
  );

  const scrollHandler = () => {
    if (headerRef.current?.offsetTop !== 0) {
      offsetHeight.current = headerRef.current?.offsetTop || 0;
    }

    if (window.pageYOffset > offsetHeight.current) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <NavigationContainerMobile
      isFixed={isFixed}
      ref={headerRef}
    >
      <NavigationWrapperMobile isFixed={isFixed}>
        <NavigationSelect
          currentMenuItem={currentMenuItem}
          action={handleClick}
          menuItems={sectionTitles}
        />
        {isClientSignIn && (
          <NavigationLinkButtons>
            <NavButtons />
          </NavigationLinkButtons>
        )}
      </NavigationWrapperMobile>
    </NavigationContainerMobile>
  );
};
