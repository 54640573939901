import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import * as urls from '../../../../../constants/urls';
import {
  UserCardWrapper,
  UserInfoWrapper,
  UserName,
  UserPhoto,
} from './styled';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../shared/hooks/useTypedSelector';
import { getFullName } from '../../../../../shared/utils';
import { fetchGetNotReadNotifications } from '../../../redux/notifications/notificationsThunk';
import { RoundAvatar } from '../../../../../shared/components/RoundAvatar/RoundAvatar';

export const UserCard: React.FC = () => {
  const dispatch = useCreatorAppDispatch();
  const { user } = useUser();
  // const numberNotReadNotifications = useTypedSelectorCreator(
  //   (state) => state.notifications.numberNotRead,
  // );
  const currentUser = useTypedSelectorCreator(
    (state) => state.creator?.currentUser,
  );
  const fullName = useMemo(
    () => getFullName(currentUser?.firstName, currentUser?.lastName),
    [currentUser],
  );

  useEffect(() => {
    dispatch(fetchGetNotReadNotifications());
  }, []);

  return (
    <UserCardWrapper>
      <Link to={urls.accountUrl}>
        <UserPhoto>
          <RoundAvatar size="large" userName={fullName} urlPhoto={user?.hasImage ? (user?.imageUrl || currentUser?.profileImage) : ''} />
        </UserPhoto>
      </Link>
      <UserInfoWrapper>
        <UserName>{fullName}</UserName>
        {/* hide for now */}
        {/* <div>
          <CustomBadge badgeContent={numberNotReadNotifications}>
            <AlignCenterNavLink to={urls.notificationsUrl}>
              <RoundButton
                className={pathname.includes(urls.notificationsUrl) ? ' active ' : ''}
                color="grey"
              >
                <NotificationIcon />
              </RoundButton>
            </AlignCenterNavLink>
          </CustomBadge>
        </div> */}
      </UserInfoWrapper>
    </UserCardWrapper>
  );
};
