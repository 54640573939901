import React, { ChangeEvent } from 'react';
import { GalleryFileInformation, GalleryIdea, GallerySection } from '../../../../../../../shared/types';
import {
  FileSectionItemContainer, FilesScrollbarConteiner,
} from './styled';
import {
  CustomMasonry,
} from '../../../../../../../shared/styles';
import { FileSectionItem } from './FileSectionItem';
import { isItemWithAppliedSettings } from './helpers';

type FilesSectionProps = {
  section: GallerySection;
  handleSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  isItemSelected: (id: string) => boolean;
  values: GalleryFileInformation;
};

export const FilesSection: React.FC<FilesSectionProps> = ({
  section,
  handleSelect,
  isItemSelected,
  values,
}) => {
  const ideas = section.ideas as GalleryIdea[];

  return (
    <FilesScrollbarConteiner>
      <CustomMasonry
        spacing={0}
        columns={3}
        // eslint-disable-next-line react/no-array-index-key
        key={section?.id}
        style={{ margin: '-5px -5px 0px -5px' }}
      >
        {[...(section?.files || [])].map((item) => (
          <FileSectionItemContainer key={item.id}>
            <FileSectionItem
              values={values}
              isChecked={isItemSelected(item.id)}
              file={item}
              handleSelect={handleSelect}
              isItemSelected={isItemSelected}
              isSettingsApplied={isItemWithAppliedSettings(item)}
            />
          </FileSectionItemContainer>
        ))}
      </CustomMasonry>
    </FilesScrollbarConteiner>
  );
};
