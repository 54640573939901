import React, { useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Appearance,
  loadStripe,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { useLocation } from 'react-router-dom';
import * as urls from '../../../../../../constants/urls';
import {
  ClientPaymentOrder,
  ClientPaymentState,
} from '../../../../../../shared/types/payment';
import { ClientPaymentFilesList } from './ClientPaymentFilesList';
import { useGetClientSecretKey } from '../../../../hooks/useGetClientSecretKey';
import { GalleryFile, GalleryIdea } from '../../../../../../shared/types';
import { PaymentPageContainer } from '../../../../../../shared/components/PaymentPages/PaymentPageContainer';
import { clientPaymentUrl } from '../../../../../../constants/urls';
import { useCheckWindowSize } from '../../../../../../shared/hooks/useCheckWindowSize';
import { isIdea } from '../../../../../../shared/utils';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

const appearance: Appearance = {
  theme: 'stripe',
};

const styles = {
  orderList: {
    tablet: {
      maxWidth: '100%',
    },
    mobile: {
      maxWidth: '420px',
    },
  },
  orderSideWrap: {
    tablet: {
      width: '50%',
      justifyContent: 'flex-start',
    },
    mobile: {
      width: '100%',
      justifyContent: 'center',
    },
  },
  wrapper: {
    tablet: {
      flexDirection: 'row' as any,
      alignItems: 'flex-start',
    },
    mobile: {
      flexDirection: 'column' as any,
      alignItems: 'center',
    },
  },
  paymentSideWrap: {
    tablet: {
      minWidth: '50%',
      boxShadow: '-5px -5px 10px #e8e8e8 !important',
      minHeight: '100vh',
      height: '100vh',
    },
    mobile: {
      minWidth: '100%',
      boxShadow: 'none !important',
      minHeight: 'fit-content',
      height: 'fit-content',
      marginBottom: '45px',
    },
  },
  priceWrapper: {
    tablet: {
      marginBottom: '24px',
    },
  },
};

const getOrderData = (
  amount: number,
  files: (GalleryFile | GalleryIdea)[],
  galleryId: string,
  email: string,
): ClientPaymentOrder => {
  const orderedFiles: string[] = [];
  const orderedIdeas: string[] = [];
  files.forEach((item) => {
    if (isIdea(item)) {
      orderedIdeas.push(item.id);
    } else {
      orderedFiles.push(item.id);
    }
  });

  const orderId = uuidv4();

  return {
    id: orderId,
    amount,
    ideas: orderedIdeas,
    files: orderedFiles,
    galleryId,
    email,
  };
};

export const ClientPaymentPageContainer: React.FC = () => {
  const {
    isLoading,
    error,
    setError,
    clientSecretKey,
    getClientSecretKey,
  } = useGetClientSecretKey();
  const { screenSize } = useCheckWindowSize();

  const location = useLocation();
  const {
    files, amount, email, galleryId,
  } = location.state;

  const orderData: ClientPaymentOrder = useMemo(
    () => getOrderData(amount, files, galleryId, email),
    [amount, files, galleryId, email],
  );

  const handleGetClientSecretKey = () => {
    getClientSecretKey(orderData, clientPaymentUrl);
  };

  useEffect(() => {
    handleGetClientSecretKey();
  }, []);

  const options: StripeElementsOptions = {
    clientSecret: clientSecretKey,
    appearance,
  };

  const path = window.location.pathname;
  const splitedPath = path.split('/');
  const galleryName = splitedPath[1];

  return (
    <PaymentPageContainer
      elementWithProducts={<ClientPaymentFilesList files={files} />}
      error={error}
      isLoading={isLoading}
      setError={setError}
      amount={amount / 100}
      numberProducts={files?.length}
      backTo={urls.clientCartUrl}
      returnUrl={`${window.location.origin}/${galleryName}${urls.clientPaymentSuccessUrl}/${orderData.id}`}
      options={options}
      stripePromise={stripePromise}
      clientSecretKey={clientSecretKey}
      styles={styles}
      reverseColumn={screenSize === 'mobile'}
      handleTryAgain={handleGetClientSecretKey}
    />
  );
};
