import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TickIcon } from '../../icons';
import { LittleBlackText } from '../../styles';

type ReturnObj = {
  handleCopy: (value: string) => () => void;
  isCopied: boolean;
  wasCopiedText: ReactElement;
}

export const useCopyValue = (): ReturnObj => {
  const { t } = useTranslation('basicComponents');

  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = (value: string) => async () => {
    await navigator.clipboard.writeText(value);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  };

  const wasCopiedText = (
    <>
      <TickIcon />
      <LittleBlackText fontWeight={500}>{t('copied')}</LittleBlackText>
    </>
  );

  return ({
    handleCopy,
    isCopied,
    wasCopiedText,
  });
};
