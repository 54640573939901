import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCheckWindowSize } from '../../../../shared/hooks/useCheckWindowSize';
import { ButtonBlackTextPinkHover } from '../../../../shared/styles';
import { GallerySection } from '../../../../shared/types';
import { scrollToElement } from '../../utils/scrollToElement';
import {
  NavigationTitle,
  NavigationWrapper,
  SectionNavigationList,
} from './styled';

type NavigationProps = {
  sections: GallerySection[];
};

export const Navigation: React.FC<NavigationProps> = ({ sections }) => {
  const { t } = useTranslation('gallery');
  const { screenSize } = useCheckWindowSize();

  const handleClick = (id: string) => {
    scrollToElement(id, 'scrolled-container');
  };

  if (screenSize !== 'desktop') {
    return null;
  }

  return (
    <NavigationWrapper id="client-navigation-step">
      <NavigationTitle>{t('navigation')}</NavigationTitle>
      <SectionNavigationList>
        {sections?.map((item) => (
          <ButtonBlackTextPinkHover
            type="button"
            onClick={() => handleClick(item?.id)}
            key={item?.id}
          >
            {item?.title}
          </ButtonBlackTextPinkHover>
        ))}
      </SectionNavigationList>
    </NavigationWrapper>
  );
};
