import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';
import * as variables from '../../../../../constants/styles';
import { ImageSizes, PreviewImageSizes } from '../../../../../constants/galleryPreviewsAndCovers';

export const FifthTitle = styled.div`
  font-size: 28px;
  line-height: 42px;
  color: ${variables.textBlack};
  width: 100%;

  & h3 {
    text-align: center;
    width: 100%;
    font-weight: 400;
    margin: 0;
  }
`;

export const FifthMediumText = styled.div`
  font-size: 16px;
  line-height: 150%;
  color: ${variables.textBlack};
  text-align: start;

  & p {
    margin: 0;
  }
`;

export const FifthMediumTextMessage = styled(FifthMediumText)`
  text-align: center;

  & p {
    margin: 0;
  }
`;

export const FifthImageContainer = styled.div<{ withCropper?: boolean }>`
  width: ${({ withCropper }) => (withCropper ? `${ImageSizes.fifth.desktop.width}px` : '100%')};
  height: ${({ withCropper }) => (withCropper ? `${ImageSizes.fifth.desktop.height}px` : '100%')};
  max-width: 1280px;
  box-sizing: border-box;
  position: relative;
  background-size: cover;
  object-fit: cover;
  display: flex;
`;

export const ImageContainerFifthTablet = styled(FifthImageContainer)<{isPreview: boolean}>`
  width: ${({ isPreview }) => (isPreview ? `${PreviewImageSizes.fifth.tablet.width}px` : '100%')};
  height: ${({ isPreview }) => (isPreview ? `${PreviewImageSizes.fifth.tablet.height}px` : 'auto')};
  ${({ isPreview }) => (isPreview ? '' : `
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
  }
  `)}
`;

export const FifthTitleTablet = styled(FifthTitle)<{isPreview: boolean}>`
  font-size: ${({ isPreview }) => (isPreview ? '20px' : '28px')};
  line-height: ${({ isPreview }) => (isPreview ? '30px' : '42px')};
  width: 100%;
`;

export const FifthMediumTextTablet = styled(FifthMediumText)<{isPreview: boolean}>`
  font-size: ${({ isPreview }) => (isPreview ? '10px' : '16px')};
  line-height: ${({ isPreview }) => (isPreview ? '15px' : '24px')};

  & p {
    margin: 0;
  }
`;

export const FifthMediumTextMessageTablet = styled(FifthMediumTextMessage)<{isPreview: boolean}>`
  font-size: ${({ isPreview }) => (isPreview ? '10px' : '16px')};
  line-height: ${({ isPreview }) => (isPreview ? '15px' : '24px')};
  height: fit-content;
`;

export const ImageContainerFifthMobile = styled(FifthImageContainer)<{isPreview: boolean}>`
  width: ${({ isPreview }) => (isPreview ? `${PreviewImageSizes.fifth.mobile.width}px` : 'fit-content')};
  height: ${({ isPreview }) => (isPreview ? `${PreviewImageSizes.fifth.mobile.height}px` : 'fit-content')};
  margin: ${({ isPreview }) => (isPreview ? '13px 0px' : '24px 0px')};
`;

export const FifthTitleMobile = styled(FifthTitle)<{isPreview: boolean}>`
  font-size: ${({ isPreview }) => (isPreview ? '15px' : '28px')};
  line-height: ${({ isPreview }) => (isPreview ? '23px' : '42px')};
  text-align: center;

  & h3 {
    text-align: center;
  }
`;

export const FifthMediumTextMobile = styled(FifthMediumText)<{isPreview: boolean}>`
  font-size: ${({ isPreview }) => (isPreview ? '8px' : '16px')};
  line-height: ${({ isPreview }) => (isPreview ? '12px' : '24px')};

  & p {
    margin: 0;
  }
`;

export const FifthMediumTextMessageMobile = styled(FifthMediumTextMessage)<{isPreview: boolean}>`
  font-size: ${({ isPreview }) => (isPreview ? '8px' : '16px')};
  line-height: ${({ isPreview }) => (isPreview ? '12px' : '24px')};
  height: fit-content;
  margin-bottom: ${({ isPreview }) => (isPreview ? '10px' : '32px')};
`;

export const GridWithBorderR = styled(Grid)`
  border-right: 1px solid ${variables.textGrey} !important;
  height: fit-content;
`;

export const GridWithBorderL = styled(Grid)`
  border-left: 1px solid ${variables.textGrey} !important;
  height: fit-content;
`;
