import { Collapse, Grid } from '@mui/material';
import { FormikErrors, FormikTouched, useFormikContext } from 'formik';
import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonSwitch } from '../../../../../../../shared/components/FormElements/CommonSwitch/CommonSwitch';
import {
  GalleryFileInformation, SetFieldValue, GallerySection, GalleryData,
} from '../../../../../../../shared/types';
import { onlyVideosWereSelected } from './helpers';
import { ErrorMessage } from '../../../../../../../shared/components/styled';
import { MiniFileUpload } from '../../../../../../../shared/components/MiniFileUpload/MiniFileUpload';
import { useUploadFiles } from '../../../../../hooks/useUploadFiles';
import { FileRole } from '../../../../../types/gallery';
import { resetFiles, resetErrorFiles, unsetWarning } from '../../../../../redux/gallery/uploadFiles/uploadFilesSliceV2';
import { getCroppedVideoThumbnail } from '../../../../../../../shared/utils/createGallery/getCroppedImage';
import { CustomAlert } from '../../../../../../../shared/components/CustomAlert/CustomAlert';
import { useCreatorAppDispatch } from '../../../../../../../shared/hooks/useTypedSelector';

type VideoThumbnailProps = {
  selected: string[];
  values: GalleryFileInformation;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: SetFieldValue;
  errors: FormikErrors<GalleryFileInformation>;
  touched: FormikTouched<GalleryFileInformation>;
  section: GallerySection;
  videoId: string;
  disabled?: boolean;
}

export const VideoThumbnail: React.FC<VideoThumbnailProps> = ({
  selected,
  values,
  handleChange,
  errors,
  touched,
  section,
  setFieldValue,
  disabled,
  videoId,
}) => {
  const { t } = useTranslation('createNewGallery');
  const {
    uploading, onUpload, stateFiles, errorFiles, warning,
  } = useUploadFiles();
  const { values: gallery } = useFormikContext<GalleryData>();
  const videoFile = section.files?.find((item) => item.id === videoId);
  const dispatch = useCreatorAppDispatch();

  const onlyVideosSelected = useMemo(
    () => onlyVideosWereSelected(section.files, selected),
    [selected, section.files],
  );

  const handleChangeSwitch = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    setFieldValue('videoThumbnail', '');
  };
  const handleUpload = async (files: File[]) => {
    const width = Number(window.localStorage.getItem('thumbnailVideoWidth')) || 0;
    const height = Number(window.localStorage.getItem('thumbnailVideoHeight')) || 0;
    let img;
    if (files[0] || videoFile?.videoThumbnail) {
      const file = files[0] || videoFile?.videoThumbnail;
      const url = window.URL.createObjectURL(file);

      img = await getCroppedVideoThumbnail({
        url,
        id: file.name,
        name: file.name,
        type: file.type,
      }, file?.name, {
        width, height, x: 0, y: 0,
      });
    }

    dispatch(resetFiles());
    dispatch(resetErrorFiles());
    if (img) {
      onUpload({
        files: [img],
        galleryId: gallery.id,
        fileRole: FileRole.VideoCover,
        anchorFileId: selected[0],
      });
    }
  };

  const error = errors.videoThumbnail && touched.videoThumbnail ? errors.videoThumbnail : null;
  const errorText = error === 'Full' ? 'Storage is full' : (error || errors.videoThumbnail);

  useEffect(() => {
    if (stateFiles.length) {
      setFieldValue('videoThumbnail', stateFiles[0]);
    }
  }, [stateFiles]);

  if (!onlyVideosSelected) {
    return null;
  }

  return (
    <Grid item sm={12}>
      <CommonSwitch
        isChecked={!!values.hasVideoThumbnail}
        handleChange={handleChangeSwitch}
        name="hasVideoThumbnail"
        label={t('thirdStep.uploadThumbnail')}
        disabled={selected?.length === 0 || disabled}
        withoutMargins
        helpText={t('thirdStep.helpTexts.videoThumbnail')}
        spaceBetween
      />
      <div style={{ marginTop: error ? '-18px' : '' }}>
        <Collapse in={!!values.hasVideoThumbnail}>
          <ErrorMessage>
            {errorFiles.length ? 'File wasn\'t uploaded' : null}
          </ErrorMessage>
          <MiniFileUpload
            handleUpload={handleUpload}
            disabled={disabled}
            value={values.videoThumbnail?.url || ''}
            setFieldValue={setFieldValue}
            name="videoThumbnail"
            error={errorText}
            touched={touched.videoThumbnail}
            isLoading={!!uploading.length}
          />
        </Collapse>
      </div>
      <CustomAlert
        type="warning"
        message={warning}
        title="The file wasn't uploaded"
        isOpenWindow={!!warning}
        onClose={() => dispatch(unsetWarning())}
      />
    </Grid>
  );
};
