import styled from '@emotion/styled';
import { theme } from '../../../../../shared/components/GlobalContainer/styled';

export const GallerySwiperDetails = styled.button`
  display: none;
  padding: 0;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;

    & > * {
      margin-right: 8px;
    }
  }
`;

export const GallerySwiperRightBtnsCont = styled.div`
  display: flex;
  box-sizing: border-box;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 100%;
    padding: 0px 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const GallerySwiperRightBtnsWrMobile = styled.div`
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  box-sizing: border-box;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const GallerySwiperRightButtonsWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  top: 20px;
  right: 84px;
  
  & > * {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    display: none;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    display: flex;
    position: initial;
    flex-direction: row;
    justify-content: flex-end;

    & > * {
      margin-left: 16px;

      &:not(:last-child) {
        margin-bottom: 0px;
      }
      &:last-child {
        box-sizing: border-box;
        padding-right: 5px;
      }
    }
  }
`;
