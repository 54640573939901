import React from 'react';
import { LittleBlackText, SimpleGreyText } from '../../../../../../../shared/styles';
import { getFileInContainer } from '../../../../../../../shared/utils/gallery/getFileInContainer';
import { CustomFile } from '../../../../../../../shared/types';
import {
  SettingsGalleryCardWrapper,
  SettingsGalleryCardHeader,
  SettingsGalleryCardFile,
} from './styled';
import { NoFileWrapper } from '../../../../../../../shared/components/Files/styled';

type SettingsGalleryCardProps = {
  file?: CustomFile;
  name: string;
  size: string;
  galleryId: string;
};

export const SettingsGalleryCard: React.FC<SettingsGalleryCardProps> = ({
  file,
  name,
  size,
  galleryId,
}) => (
  <SettingsGalleryCardWrapper id={galleryId}>
    <SettingsGalleryCardHeader>
      <div>
        <LittleBlackText>{name || 'No gallery name'}</LittleBlackText>
      </div>
      <div>
        <LittleBlackText fontWeight={500}>
          {size}
        </LittleBlackText>
      </div>
    </SettingsGalleryCardHeader>
    <SettingsGalleryCardFile>
      {file?.url ? getFileInContainer({ file }) : (
        <NoFileWrapper>
          <SimpleGreyText>No file</SimpleGreyText>
        </NoFileWrapper>
      )}
    </SettingsGalleryCardFile>
  </SettingsGalleryCardWrapper>
);
