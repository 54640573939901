import {
  ClientGallery, GalleryData, GalleryFile, GalleryIdea,
} from '../../types';

export const getAllFiles = (
  gallery: GalleryData | ClientGallery,
): (
  GalleryFile | GalleryIdea
)[] => {
  let allFiles: (GalleryFile | GalleryIdea)[] = [];
  gallery?.sections?.forEach((item) => {
    allFiles = [...allFiles, ...item.files, ...item.ideas];
  });

  return allFiles;
};
