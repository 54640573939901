import { useSignUp } from '@clerk/clerk-react';
import { FormikHelpers, replace } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import * as urls from '../../../../constants/urls';
import { setTimeToResendData } from '../../utils/helpers';
import { SignUpInProcess } from '../../../../shared/types';
import { getFormatDateForDob } from '../../../../shared/utils';
import { creatorSignUp } from '../../../../api/creator/creatorAuth';
import { creator } from '../../../../api/creator/creator';
import { getSignUpClerkErrors, getSignUpErrors } from '../../../../shared/utils/errors/errorHandlers';

type useCreatorSignUpReturn = {
  isLoading: boolean;
  error: { field: string, message: string }[];
  signUpSubmit: (
    data: SignUpInProcess,
    bag: FormikHelpers<SignUpInProcess>
  ) => void;
  sendEmail: (
    data: { email: string },
    bag: FormikHelpers<{ email: string }>
  ) => void;
};

export const useCreatorSignUp = (): useCreatorSignUpReturn => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<{ field: string, message: string }[]>([]);
  const { isLoaded, signUp, setActive } = useSignUp();

  const sendEmail = async (
    data: { email: string },
    bag: FormikHelpers<{ email: string }>,
  ) => {
    if (!isLoaded) {
      return;
    }
    if (!signUp) {
      return;
    }
    if (!setActive) {
      return;
    }
    setError([]);
    setIsLoading(true);

    try {
      await signUp?.create({
        emailAddress: data.email,
      });
      await signUp.prepareEmailAddressVerification({
        strategy: 'email_link',
        redirectUrl: `${window.location.origin}/creator/signup/confirm`,
      });

      setTimeToResendData();

      setActive({
        session: signUp?.id,
      });
      setIsLoading(false);
      navigate(urls.signUpConfirmWasSentUrl);
    } catch (err: any) {
      setIsLoading(false);
      bag.setFieldError('email', err?.errors[0]?.message);
    }
  };

  const signUpSubmit = async (
    data: SignUpInProcess,
    bag: FormikHelpers<SignUpInProcess>,
  ) => {
    setIsLoading(true);
    const tz = moment.tz.guess();
    try {
      const response = await creator.checkClerkUser({
        ...data,
        timezone: tz,
        email: signUp?.emailAddress || '',
      });

      const res = await signUp?.update({
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
        unsafeMetadata: {
          creatorId: response.data.creatorId,
        },
      });
      if (res) {
        if (res.createdSessionId && setActive) {
          setActive({ session: res.createdSessionId });
        }
      }
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      setError(getSignUpClerkErrors(err));
    }
  };

  return {
    isLoading,
    error,
    sendEmail,
    signUpSubmit,
  };
};
