/* eslint-disable  */
import axios, { AxiosResponse } from 'axios';
import { InvitePostDto, UploadUrlsDto, UploadUrlsResponseDto, UploadingItem, UploadingItemResponse } from '../../modules/Creator/types/gallery';
import { GalleryForRequest } from '../../shared/types';
import { instance } from '../base/instance';

const galleryBaseUrl = 'gallery';
const testGalleryId = '7d7c3e8a-be9c-4144-81ee-254b56ca57b2';

export const gallery = {
  deleteGallery: (id: string, token: string): Promise<AxiosResponse<any>> =>
    instance.delete(`${galleryBaseUrl}/${id}`, { headers: { crossDomain: true, Authorization: `${token}` } }),
  createGallery: (data: { id: string }, token: string): Promise<AxiosResponse<any>> =>
    instance.post(`${galleryBaseUrl}`, data, { headers: { crossDomain: true, Authorization: `${token}` } }),
  getGalleries: (token: string): Promise<AxiosResponse<GalleryForRequest[]>> => instance.get(`${galleryBaseUrl}`, { headers: { crossDomain: true, Authorization: `${token}` } }),
  getGallery: (id: string, token: string): Promise<AxiosResponse<GalleryForRequest>> => instance.get(`${galleryBaseUrl}/${id}`, { headers: { crossDomain: true, Authorization: `${token}` } }),
  updateGallery: (id: string, data: GalleryForRequest, token: string): Promise<AxiosResponse<any>> =>
    instance.put(`${galleryBaseUrl}/${id}`, data, { headers: { crossDomain: true, Authorization: `${token}` } }),
  deleteSection: (data: {galleryId: string, sectionId: string}, token: string): Promise<AxiosResponse<any>> =>
    instance.delete(`${galleryBaseUrl}/section`, { data, headers: { crossDomain: true, Authorization: `${token}` } }),
  deleteFile: (data: {galleryId: string, sectionId: string, fileId: string}, token: string): Promise<AxiosResponse<any>> =>
    instance.delete(`${galleryBaseUrl}/file`, { data, headers: { crossDomain: true, Authorization: `${token}` } }),
  sendInvite: (data: InvitePostDto, token: string): Promise<AxiosResponse<any>> =>
    instance.post(`${galleryBaseUrl}/invite`, data, { headers: { crossDomain: true, Authorization: `${token}` } }),
};

export const uploadFilesAPI = {
  getUploadFileUrl: (data: UploadUrlsDto, token: string): Promise<AxiosResponse<UploadUrlsResponseDto>> =>
    instance.post(`${galleryBaseUrl}/upload/url`, data, { headers: { crossDomain: true, Authorization: `${token}` } }),
  uploadFile: (url: string, file: File, config: any): Promise<AxiosResponse<any>> =>
    axios.put(url, file, config),
};
