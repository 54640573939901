import React from 'react';
import { components, GroupBase, MultiValueRemoveProps } from 'react-select';
import { Option } from '../../../types';
import { CloseIcon } from '../../../icons';

export const MultiValueRemove:
  | React.ComponentType<MultiValueRemoveProps<any, true, GroupBase<Option>>>
  | undefined = (props: MultiValueRemoveProps<any>) => (
    <components.MultiValueRemove {...props}>
      <CloseIcon />
    </components.MultiValueRemove>
  );
