import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  WhiteMainLogo, TwitterIcon, LinkedInIcon, FacebookIcon, InstagramIcon,
} from '../../../../../../../../shared/icons';
import { PreviewEmailFooterContainer, PreviewEmailFooterText, PreviewEmailFooterMedia } from './styled';

export const PreviewEmailFooter: React.FC = () => {
  const { t } = useTranslation('createNewGallery');

  return (
    <PreviewEmailFooterContainer>
      <WhiteMainLogo />
      <PreviewEmailFooterText>
        {t('fourthStep.reminder.previewEmail.chosenByCreativePeople')}
      </PreviewEmailFooterText>
      <PreviewEmailFooterMedia>
        <TwitterIcon color="white" />
        <LinkedInIcon color="white" />
        <FacebookIcon color="white" />
        <InstagramIcon color="white" />
      </PreviewEmailFooterMedia>
    </PreviewEmailFooterContainer>
  );
};
