import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const NoticeApplePoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={19}
      listTitle={t('terms.noticeApple')}
      pointsNameForTrans="noticeApplePoints"
    >
      <LandingNumberedLi>
        To the extent that you are using or accessing our Platform on an iOS
        device, you further acknowledge and agree to the terms of this
        clause. You acknowledge that these Terms are between you and us
        only, not with Apple Inc. (
        <span className="bold">Apple</span>
        ), and Apple is not responsible for the Platform and any content
        available on the Platform.
      </LandingNumberedLi>
      <LandingNumberedLi>
        Apple has no obligation to furnish you with any maintenance and
        support services with respect to our Platform.
      </LandingNumberedLi>
      <LandingNumberedLi>
        If our mobile application fails to conform to any applicable
        warranty, you may notify Apple and Apple will refund the purchase
        price of the mobile application to you. To the maximum extent
        permitted by applicable law, Apple will have no other warranty
        obligation whatsoever with respect to the mobile application and any
        other claims, losses, liabilities, damages, costs or expenses
        attributable to any failure to conform to any warranty will be our
        responsibility.
      </LandingNumberedLi>
      <LandingNumberedLi>
        Apple is not responsible for addressing any claims by you or any
        third party relating to our mobile application or your use of our
        mobile application, including but not limited to (1) product
        liability claims; (2) any claim that our mobile application fails to
        conform to any applicable legal or regulatory requirement; and (3)
        claims arising under consumer protection or similar legislation.
      </LandingNumberedLi>
      <LandingNumberedLi>
        Apple is not responsible for the investigation, defence, settlement
        and discharge of any third-party claim that our mobile application
        infringes that third party’s intellectual property rights.
      </LandingNumberedLi>
      <LandingNumberedLi>
        You agree to comply with any applicable third-party terms when using
        our mobile application, including any Usage Rules set forth in the
        Apple App Store Agreement of Service.
      </LandingNumberedLi>
      <LandingNumberedLi>
        Apple and Apple’s subsidiaries are third-party beneficiaries of
        these Terms, and upon your acceptance of these Terms, Apple will
        have the right (and will be deemed to have accepted the right) to
        enforce these Terms against you as a third-party beneficiary of
        these Terms.
      </LandingNumberedLi>
      <LandingNumberedLi>
        You hereby represent and warrant that (1) you are not located in a
        country that is subject to a U.S. Government embargo, or that has
        been designated by the U.S. Government as a &quot;terrorist
        supporting&quot; country; and (2) you are not listed on any U.S.
        Government list of prohibited or restricted parties.
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
