import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CommonPriceInputReadOnly } from '../../../../../shared/components/CommonPriceInputReadOnly/CommonPriceInputReadOnly';
import { CartSection } from '../../../../../shared/types/client';
import {
  CartCheckoutSectionText,
  CartSummaryFilesNumberPrice,
  CartSummarySectionWrapper,
  CartCheckoutSectionTextLimit,
} from './styled';
import { CurrencyTypes } from '../../../../../shared/types';

type CartSummarySectionProps = {
  section: CartSection;
};

export const CartSummarySection: React.FC<CartSummarySectionProps> = ({
  section,
}) => {
  const { t } = useTranslation('sales');
  const {
    sectionId, title, filesNumber, totalSum,
  } = section;

  return (
    <CartSummarySectionWrapper key={sectionId}>
      <CartCheckoutSectionTextLimit>
        <p>{title}</p>
      </CartCheckoutSectionTextLimit>
      <CartSummaryFilesNumberPrice>
        <CartCheckoutSectionText style={{ whiteSpace: 'pre' }}>
          <Trans t={t} i18nKey="files">
            {{ files: filesNumber }}
            files
          </Trans>
        </CartCheckoutSectionText>
        <CartCheckoutSectionText>
          <CommonPriceInputReadOnly currenyType={CurrencyTypes.USD} currencyLetters="USD" value={totalSum || 0} />
        </CartCheckoutSectionText>
      </CartSummaryFilesNumberPrice>
    </CartSummarySectionWrapper>
  );
};
