import styled from '@emotion/styled';
import { RoundButton } from '../../../../shared/styles';
import * as variables from '../../../../constants/styles';
import { theme } from '../../../../shared/components/GlobalContainer/styled';

export const HeaderWrapper = styled.div<{ isSignIn: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: ${({ isSignIn }) => (isSignIn ? '24' : '15')}px;
  margin-bottom: ${({ isSignIn }) => (isSignIn ? '20' : '13')}px;
  width: 100%;
  
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    box-sizing: border-box;
    padding-bottom: 40px;
    padding-top: ${({ isSignIn }) => (isSignIn ? '24' : '15')}px;
    margin-bottom: 0px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    box-sizing: border-box;
    padding: 40px 16px;
    margin-bottom: ${({ isSignIn }) => (isSignIn ? '0' : '13')}px;

    & > svg {
      width: 150px !important;
    }
  }
`;

export const HeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  & svg {
    width: 28px;
    height: 28px;
  }

  & > button,
  & > span,
  & > div {
    margin-left: 20px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    display: none;
  }
`;

export const RoundButtonWithPopup = styled(RoundButton)`
  transition: all 0.3s ease-in-out;

  &:hover {
    transition: all 0.3s ease-in-out;

    & > div {
      display: flex;
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 44px;
      height: 100px;
    }
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    &:hover {
  
      & > div {
        display: none;
        opacity: 0;
      }
    }
  }
`;

export const RoundButtonSupportWrapper = styled.div`
  position: relative;

  & svg {
    width: 30px;
    height: 30px;
  }
`;

export const MenuMainLogoWrapper = styled.div`
  display: flex;
  margin-bottom: 45px;
  justify-content: center;

  & svg {
    width: 150px;
  }
`;

export const MenuSocialMedias = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  & svg {
    width: 24px;
    height: 24px;

    &:not(:last-child) {
      margin-right: 23px;
    }
  }
`;

export const MenuPrivacyTermsWrapper = styled.div`
  font-size: 14px;
  line-height: 140%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${variables.textGrey};
  margin-bottom: 43px;

  & a {
    margin-bottom: 11px;
  }
`;

export const MenuCopyright = styled.div`
  font-size: 12px;
  line-height: 140%;
  display: flex;
  justify-content: center;
  text-align: center;
  color: ${variables.textGrey};
  margin-bottom: 30px;
`;
