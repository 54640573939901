import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  TermsAndPrivacyTopScreenWrapper,
  TermsAndPrivacyTopScreenHeader,
  TermsAndPrivacyTopScreenTitle,
  TermsAndPrivacyLastUpdate,
  TermsAndPrivacyTopScreenImage,
} from './styled';

type TermsAndPrivacyTopScreenProps = {
  title: string;
  lastUpdateDate: string;
  imageUrl: string;
};

export const TermsAndPrivacyTopScreen: React.FC<
  TermsAndPrivacyTopScreenProps
> = ({ title, lastUpdateDate, imageUrl }) => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyTopScreenWrapper>
      <TermsAndPrivacyTopScreenHeader>
        <TermsAndPrivacyTopScreenTitle>{title}</TermsAndPrivacyTopScreenTitle>
        <TermsAndPrivacyLastUpdate>
          <Trans t={t} i18nKey="lastUpdates">
            Last update:
            {' '}
            {{ date: lastUpdateDate }}
          </Trans>
        </TermsAndPrivacyLastUpdate>
      </TermsAndPrivacyTopScreenHeader>
      <TermsAndPrivacyTopScreenImage>
        <img src={imageUrl} alt={title} />
      </TermsAndPrivacyTopScreenImage>
    </TermsAndPrivacyTopScreenWrapper>
  );
};
