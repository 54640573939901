import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import * as variables from '../../../constants/styles';

export const ViewAllNavLink = styled(NavLink)`
  font-size: 14px;
  line-height: 150%;
  color: ${variables.textGrey};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ArrowWrapper = styled.div`
  margin-left: 13px; 
  width: 12px;
  height: 8px;
`;
