import styled from '@emotion/styled';
import { theme } from '../GlobalContainer/styled';

export const ButtonMoveToTopWrapper = styled.div<{margins?: boolean}>`
  position: sticky;
  bottom: 39px;
  left: 100%;
  z-index: 1000;
  width: fit-content;
  margin: -54px ${({ margins }) => (margins ? '30px 0 0' : '0 0 0')};

  & button {
    margin: 0;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    left: calc(100% - 40px);
    margin: -40px 0 0 0;

    & button {
      box-shadow: none;
      border: 1px solid rgba(235, 235, 235, 0.8);
      max-width: 40px;
      max-height: 40px;

      &:hover {
        box-shadow: none;
      }

      &:active {
        box-shadow: none;
      }
    }
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 0px 16px;
  }
`;
