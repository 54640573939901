import styled from '@emotion/styled';
import * as variables from '../../../../../constants/styles';
import { theme } from '../../../../../shared/components/GlobalContainer/styled';

export const IdeaBigCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const IdeaBigCardWrapper = styled.div`
  width: 421px;
  max-width: 421px;
  min-width: 421px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 142px;
`;

export const IdeaBigCardTabletWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const IdeaCoverTabletWrapper = styled.div`
  margin-bottom: 30px;
  position: relative;
`;

export const IdeaCoverWrapper = styled.div`
  margin-bottom: 30px;
  position: relative;
  width: 421px;
  height: 448px;

  & #basic-doc {
    border-radius: 21px;
    padding: 60px;
    font-size: 16px;

    & svg {
      width: 69px;
      height: 80px;
    }
  }
`;

export const IdeaFilesSlider = styled.div<{scrollbarVisible: boolean}>`
  width: 420px;

  & > div {
    display: grid;
    column-gap: 30px;
    row-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(82px, max-content));
    grid-template-rows: minmax(82px, 1fr);
    grid-auto-flow: column;
    overflow-x: scroll;
    scroll-snap-type: x proximity;

    &::-webkit-scrollbar {
      height: 5px;
      ${({ scrollbarVisible }) => (scrollbarVisible ? '' : 'height: 0px;')}
    }
  
    &::-webkit-scrollbar-thumb {
      background: ${variables.textGrey};
      border-radius: 163px;
    }
  
    &::-webkit-scrollbar-track {
      background: #dbdbdb;
      border-radius: 163px;
    }
  }
`;

export const IdeaFilesSliderTablet = styled(IdeaFilesSlider)`
  width: 100%;
`;

export const IdeaFileCardWrapper = styled.div`
  z-index: 5;
  box-sizing: border-box;
  background: #f0f1f2;
  height: 82px;
  width: 82px;
  min-height: 82px;
  min-width: 82px;
  max-height: 82px;
  max-width: 82px;
  border-radius: 10px;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;

  & > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;
  }

  & > div {
    overflow: visible;
  }
`;

export const IdeaDescriptionButtons = styled.div`
  max-width: 532px;
  display: flex;
  flex-direction: column;
`;

export const IdeaPriceAndButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 56px;
`;

export const IdeaTooltipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid ${variables.colorPink};
  border-radius: 8px;
  width: 100%;
  font-size: 14px;
  line-height: 150%;
  color: ${variables.textGrey};
  margin-top: 20px;
  margin-bottom: 24px;

  & svg {
    margin-right: 8px;
    min-height: 24px;
    min-width: 24px;
  }

  & span {
    text-align: start;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin-top: 0px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-top: 20px;
  }
`;
