import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as variables from '../../../constants/styles';

export const WalkThroughDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'transparent',
    },
  },
  '& .MuiPaper-root': {
    borderRadius: '18px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'calc(100% - 120px)',
      margin: 0,
      borderRadius: '0px',
      boxShadow: 'none',
      background: variables.colorGreyLight,
    },
  },
  '& .MuiDialog-paper': {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      bottom: 0,
    },
  },
}));
