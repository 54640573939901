import React, { CSSProperties } from 'react';
import { MainLogoNoWhiteSpace } from '../../icons';
import { BetaCheckoutLogoWrp, BetaCheckoutBetaLogo } from './styled';

export const BetaLogo: React.FC<{ wrapperStyle?: CSSProperties }> = ({
  wrapperStyle,
}) => (
  <BetaCheckoutLogoWrp style={wrapperStyle}>
    <MainLogoNoWhiteSpace />
    <BetaCheckoutBetaLogo>beta</BetaCheckoutBetaLogo>
  </BetaCheckoutLogoWrp>
);
