import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const PlatformSummaryPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={5}
      listTitle={t('terms.platformSummary')}
      pointsNameForTrans="platformSummaryPoints"
    >
      <LandingNumberedLi>
        The Platform is a marketplace where Members and Clients can
        advertise, sell and make available Output Content online. We offer
        paid Memberships to Members who wish to use our SaaS Solution (see
        further our Membership Terms and Conditions). We provide the
        Platform to Member and Client users (including hosting and
        maintaining the Platform), process payments between Clients and
        Members and provide promotional opportunities for Members (together
        the
        {' '}
        <span className="bold">Delivrable Services</span>
        ).
      </LandingNumberedLi>
      <LandingNumberedLi>
        You, a Member, agree that we make available the SaaS Solution under
        the terms of our Membership Terms and Conditions.
      </LandingNumberedLi>
      <LandingNumberedLi>
        You, a Client, understand and agree that we only make available to
        you the Delivrable Services and do not give you access to the SaaS
        Solution other than as set out in these Terms.
      </LandingNumberedLi>
      <LandingNumberedLi>
        If you are a Client, we make no guarantees relating to availability
        of the Platform to you. To the maximum extent permitted by law, we
        will not be liable for any Liability to you (a Client) arising in
        respect of any unavailability of the Platform including due to
        scheduled and emergency maintenance. If you are a Member, our
        availability commitments and potential Liability to you are set out
        in our Membership Terms and Conditions.
      </LandingNumberedLi>
      <LandingNumberedLi>
        You, a Client or a Member, agree that we are not party to any
        agreement entered into between a Client and a Member and we have no
        control over the conduct of Member, Clients or any other users of
        the Platform.
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
