import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { MainLogo } from '../../../../../../../shared/icons';
import { MediumBlackText } from '../../../../../../../shared/styles';
import {
  GalleryPreviewHeaderContent,
  GalleryPreviewHeaderLogo,
  GalleryPreviewHeaderWrapper,
  HeaderTextWrapper,
} from './styled';

type GalleryPreviewHeaderProps = {
  children: ReactNode;
};

export const GalleryPreviewHeader: React.FC<GalleryPreviewHeaderProps> = ({
  children,
}) => {
  const { t } = useTranslation('createNewGallery');

  return (
    <GalleryPreviewHeaderWrapper id="moveToTheNext">
      <GalleryPreviewHeaderLogo>
        <MainLogo />
      </GalleryPreviewHeaderLogo>
      <GalleryPreviewHeaderContent>
        <HeaderTextWrapper>
          <MediumBlackText>{t('fifthStep.alert')}</MediumBlackText>
        </HeaderTextWrapper>
        {children}
      </GalleryPreviewHeaderContent>
    </GalleryPreviewHeaderWrapper>
  );
};
