import React, { CSSProperties } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { today } from '../../../../constants';
import { CalendarIcon } from '../../../icons';
import { SetFieldValue } from '../../../types';
import { ErrorMessage } from '../../styled';
import { InputAndErrorWrapper, InputAndLabelWrapper } from '../styled';
import { TextLabel } from '../TextLabel';
import { CalendarContainer, renderDayContents } from './CustomDatePickerParts';
import { DatePickerHeader } from './DatePickerHeader';
import { DatePickerCustom, DatePickerCustomContainer } from './styled';

type CustomDatePickerProps = {
  error?: string | any;
  data: string | any;
  name: string;
  touched?: boolean | any;
  label?: string;
  setFieldValue: SetFieldValue;
  placeholder?: string;
  limits?: boolean;
  withoutMargins?: boolean;
  dateFormat?: string;
  style?: CSSProperties;
};

export const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  data,
  error,
  name,
  setFieldValue,
  touched,
  label,
  placeholder,
  limits = true,
  withoutMargins,
  dateFormat = 'yyyy/MM/dd',
  style,
}) => {
  dateFormat = 'dd/mm/yyyy';

  const fixedDateFormat = dateFormat.replace('mm', 'MM');
  const onChange = (val: any) => {
    setFieldValue(name, val);
  };

  return (
    <InputAndLabelWrapper style={style} direction="column" withoutMargins={withoutMargins}>
      <TextLabel name={name} label={label} />
      <InputAndErrorWrapper>
        <DatePickerCustomContainer>
          <DatePicker
            showPopperArrow={false}
            dateFormat={fixedDateFormat}
            selected={data}
            onChange={onChange}
            calendarContainer={CalendarContainer}
            renderDayContents={renderDayContents}
            name={name}
            placeholderText={placeholder}
            minDate={limits ? today : null}
            customInput={(
              <DatePickerCustom
                withPlaceholder={!!placeholder}
                className="example-custom-input"
                type="text"
                theme={error && touched ? 'error' : 'success'}
                placeholder={placeholder}
              />
            )}
            useWeekdaysShort={false}
            calendarClassName="calendar"
            renderCustomHeader={({
              date,
              changeYear,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <DatePickerHeader
                date={date}
                changeYear={changeYear}
                decreaseMonth={decreaseMonth}
                increaseMonth={increaseMonth}
                prevMonthButtonDisabled={prevMonthButtonDisabled}
                nextMonthButtonDisabled={nextMonthButtonDisabled}
              />
            )}
          />
          <CalendarIcon />
        </DatePickerCustomContainer>
        <ErrorMessage>{error && touched ? error : null}</ErrorMessage>
      </InputAndErrorWrapper>
    </InputAndLabelWrapper>
  );
};
