export type UploadingStatus = 'idle' | 'uploading' | 'thumbnail' | 'done';

export enum FileRole {
  Regular = 'regular',
  RegularWatermarked = 'regular-watermarked',
  Watermark = 'watermark',
  VideoCover = 'video-cover',
  GalleryCover = 'cover-original',
  GalleryCoverCropped = 'cover-cropped',
  Idea = 'idea',
  ProfileOriginal = 'profile-original',
  ProfileCropped = 'profile-cropped',
}

export type GalleryId = string | undefined | null;

export type UploadingItem = {
  name: string;
  type: string;
};

export type ErrorItem = {
  name: string;
  type: string;
  errorMessage?: string;
};

export type UploadingItemResponse = {
  url: string;
  awsKey: string;
  fileId: string;
};

export type UploadUrlDTO = {
  url: string;
};

export type InvitePostDto = {
  email: string;
  url: string;
};

type FileUploadDto = {
  name: string;
  type: string;
  size: number;
  role: string;
};

export type UploadUrlsDto = {
  galleryId: string;
  files: FileUploadDto[];
};

export type UploadUrlsResponseDto = {
  [name: string]: { url: string; id: string; errorMessage: string };
}[];
