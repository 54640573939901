import { Grid } from '@material-ui/core';
import React, { useRef } from 'react';
import { GalleryCover } from '../../../../../shared/types';
import { useGalleryContent } from '../../../hooks/useGalleryContent';
import {
  SixthMediumTextMobile,
  SixthTitleMobile,
  ImageContainerSixthMobile,
  SixthInfoCardCoverMobile,
  SixthDateAndNameWrapper,
} from './styled';

export const GalleryDemoSixthMobile: React.FC<GalleryCover> = (props) => {
  const refCardMobile = useRef<HTMLDivElement>(null);
  const { isPreview } = props;

  const data = useGalleryContent({
    ...props,
    size: 'mobile',
    galleryNumber: 'sixth',
  });

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ paddingLeft: '0px', paddingRight: '0px', height: '100%' }}
    >
      <Grid container item xs={12} alignItems="center" justifyContent="center">
        <ImageContainerSixthMobile isPreview={!!isPreview}>
          {data.file}
          <SixthInfoCardCoverMobile
            isPreview={!!isPreview}
            ref={refCardMobile}
            currentHeight={refCardMobile?.current?.offsetHeight || 0}
          >
            <div>
              <SixthTitleMobile isPreview={!!isPreview}>{data.name}</SixthTitleMobile>
              <SixthMediumTextMobile isPreview={!!isPreview}>{data.message}</SixthMediumTextMobile>
            </div>
            <SixthDateAndNameWrapper isPreview={!!isPreview}>
              <SixthMediumTextMobile isPreview={!!isPreview}>{data.userName}</SixthMediumTextMobile>
              <SixthMediumTextMobile isPreview={!!isPreview}>{data.date}</SixthMediumTextMobile>
            </SixthDateAndNameWrapper>
          </SixthInfoCardCoverMobile>
        </ImageContainerSixthMobile>
      </Grid>
    </Grid>
  );
};
