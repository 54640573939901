import styled from '@emotion/styled';
import * as variables from '../../../constants/styles';

export const BetaCheckoutLogoWrp = styled.div`
  position: relative;
  display: flex;
  width: fit-content;
  margin-bottom: 16px;
  & svg {
    width: 165px;
    height: 26.57px;
  }
`;
export const BetaCheckoutBetaLogo = styled.div`
  background: ${variables.textBlack};
  right: -20px;
  top: -15px;
  width: 40px;
  height: 15px;
  font-size: 11px;
  content: 'beta';
  color: ${variables.colorGreyLight};
  position: absolute;
  border-radius: 137px;
  box-sizing: border-box;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;
