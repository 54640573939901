import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useAuth } from '@clerk/clerk-react';
import {
  SimpleBlackText,
  TabContentWrapper,
  TabWrapper,
  WhiteCardWithShadows,
} from '../../../../../../shared/styles';
import {
  LimitTextWrapper,
  SalesCardWrapper,
  SalesMonthContainer,
  SalesMonthText,
} from './styled';
import { SalesChart } from './SalesChart/SalesChart';
import { getDataByScale } from '../../../../../../shared/utils/sales/getDataByScale';
import { BaseTabs } from '../../../../../../shared/components/Tabs/BaseTabs/BaseTabs';
import { TabsWrapper } from './SalesChart/styled';
import { useGetTabsSales } from './useGetTabsSales';
import { fetchGetAllSales } from '../../../../redux/sales/sales/allSalesThunk';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../shared/hooks/useTypedSelector';

export const Sales: React.FC = () => {
  const { t } = useTranslation('sales');
  const dispatch = useCreatorAppDispatch();
  const {
    amountsPerDay,
    amountsPerMonth,
    amountsPerWeek,
    purchasesLastThirtyDays,
    netRevenueAllTime,
    netRevenueLastThirtyDays,
  } = useTypedSelectorCreator((state) => state.allSales.data);
  const { getToken } = useAuth();

  const [value, setValue] = useState(0);
  const tabs = useGetTabsSales();
  const dataForChart = useMemo(
    () => getDataByScale(value, amountsPerDay, amountsPerWeek, amountsPerMonth),
    [value, amountsPerDay, amountsPerWeek, amountsPerMonth],
  );

  useEffect(() => {
    dispatch(fetchGetAllSales(getToken));
  }, [dispatch]);

  return (
    <TabWrapper>
      <TabContentWrapper>
        <Grid container spacing={4}>
          <Grid item sm={12}>
            <WhiteCardWithShadows>
              <SalesChart dataForChart={dataForChart}>
                <TabsWrapper>
                  <BaseTabs tabs={tabs} value={value} setValue={setValue} />
                </TabsWrapper>
              </SalesChart>
            </WhiteCardWithShadows>
          </Grid>
          <Grid item xl={4} lg={4} md={12}>
            <WhiteCardWithShadows style={{ justifyContent: 'center' }}>
              <SalesCardWrapper>
                <SalesMonthContainer style={{ justifyContent: 'center' }}>
                  <SalesMonthText style={{ color: '#F180B2' }}>
                    {purchasesLastThirtyDays}
                  </SalesMonthText>
                </SalesMonthContainer>
                <LimitTextWrapper style={{ textAlign: 'center' }}>
                  <SimpleBlackText fontWeight={500}>{t('purchases')}</SimpleBlackText>
                </LimitTextWrapper>
                <LimitTextWrapper style={{ textAlign: 'center', marginTop: '5px' }}>
                  <SimpleBlackText fontWeight={500}>{t('lastThirtyDays')}</SimpleBlackText>
                </LimitTextWrapper>
              </SalesCardWrapper>
            </WhiteCardWithShadows>
          </Grid>
          <Grid item xl={4} lg={4} md={12}>
            <WhiteCardWithShadows style={{ justifyContent: 'center' }}>
              <SalesCardWrapper>
                <SalesMonthContainer style={{ justifyContent: 'center' }}>
                  <SalesMonthText style={{ color: '#F180B2' }}>
                    $
                    {netRevenueLastThirtyDays}
                  </SalesMonthText>
                </SalesMonthContainer>
                <LimitTextWrapper style={{ textAlign: 'center' }}>
                  <SimpleBlackText fontWeight={500}>{t('netRevenue')}</SimpleBlackText>
                </LimitTextWrapper>
                <LimitTextWrapper style={{ textAlign: 'center', marginTop: '5px' }}>
                  <SimpleBlackText fontWeight={500}>{t('lastThirtyDays')}</SimpleBlackText>
                </LimitTextWrapper>
              </SalesCardWrapper>
            </WhiteCardWithShadows>
          </Grid>
          <Grid item xl={4} lg={4} md={12}>
            <WhiteCardWithShadows style={{ justifyContent: 'center' }}>
              <SalesCardWrapper>
                <SalesMonthContainer style={{ justifyContent: 'center' }}>
                  <SalesMonthText style={{ color: '#F180B2' }}>
                    $
                    {netRevenueAllTime}
                  </SalesMonthText>
                </SalesMonthContainer>
                <LimitTextWrapper style={{ textAlign: 'center' }}>
                  <SimpleBlackText fontWeight={500}>{t('netRevenue')}</SimpleBlackText>
                </LimitTextWrapper>
                <LimitTextWrapper style={{ textAlign: 'center', marginTop: '5px' }}>
                  <SimpleBlackText fontWeight={500}>{t('allTime')}</SimpleBlackText>
                </LimitTextWrapper>
              </SalesCardWrapper>
            </WhiteCardWithShadows>
          </Grid>
        </Grid>
      </TabContentWrapper>
    </TabWrapper>
  );
};
