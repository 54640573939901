import React from 'react';
import { GallerySection } from '../../../../../shared/types';
import { SectionItem } from './SectionItem/SectionItem';
import { SectionsListWrapper } from '../styled';

type SectionsListProps = {
  sections: GallerySection[];
}

export const SectionsList: React.FC<SectionsListProps> = ({
  sections,
}) => (
  <SectionsListWrapper>
    {sections?.map((item) => (
      <SectionItem sections={sections} section={item} key={item?.id} />
    ))}
  </SectionsListWrapper>
);
