import * as Yup from 'yup';
import { titleMaxLength } from '../../../constants/validationValues';
import { errorMessages } from '../../utils/errors/errorsMessages';

export const clientSchema = Yup.object().shape({
  name: Yup.string()
    .required(errorMessages.requiredField)
    .max(titleMaxLength, errorMessages.tooLong),
  email: Yup.string()
    .email(errorMessages.invalidEmail)
    .required(errorMessages.requiredField),
});
