import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import { theme } from '../../../../shared/components/GlobalContainer/styled';

export const ShareDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '10px',
    maxWidth: '562px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
      borderRadius: '10px 10px 0px 0px',
    },
  },
  '& .MuiDialog-paper': {
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      bottom: 0,
    },
  },
}));
