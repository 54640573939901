import styled from '@emotion/styled';
import * as variables from '../../constants/styles';

export const ColorfulCard = styled.div`
  background: ${variables.colorGradientDiagonal};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
`;

export const SignInUpCardColorful = styled(ColorfulCard)`
  align-items: center;
  padding: 90px 100px;

  @media (max-width: 1150px) {
    padding: 90px 50px;
  }
`;

type ThemeSignInUpCard = 'signin' | 'signup' | 'reset' | 'thanks' | '';

const getMinHeight = (theme: ThemeSignInUpCard): string => {
  switch (theme) {
    case 'signin':
      return '530';
    case 'signup':
      return '600';
    case 'reset':
      return '480';
    case 'thanks':
      return '407';
    default:
      return '480';
  }
};

const getTopPadding = (theme: ThemeSignInUpCard): string => {
  switch (theme) {
    case 'signin':
      return '80';
    case 'signup':
      return '60';
    case 'reset':
      return '96';
    case 'thanks':
      return '60';
    default:
      return '60';
  }
};

export const SignInUpCard = styled.div`
  background: ${variables.colorGreyLight};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: ${({ theme }: {theme: ThemeSignInUpCard}) => getTopPadding(theme)}px 100px 60px;
  box-shadow: -2px -2px 3px #fff, 10px 10px 17px #e7e7e7;
  min-height: ${({ theme }: {theme: ThemeSignInUpCard}) => getMinHeight(theme)}px;

  @media (max-width: 1200px) {
    padding: ${({ theme }: {theme: ThemeSignInUpCard}) => getTopPadding(theme)}px 50px 60px;
  }
`;

export const SignInUpCardCenter = styled(SignInUpCard)`
  justify-content: center;
`;

export const WhiteCardWithShadows = styled.div<{fullHeight?: boolean}>`
  box-shadow: -5px -5px 10px #FFFFFF, 5px 5px 10px #E8E8E8;
  border-radius: 4px;
  background: ${variables.colorGreyLight};
  display: flex;
  box-sizing: border-box;
  width: 100%;
  ${(props) => (props.fullHeight ? 'height: 100%;' : '')}
`;

export const WhiteSignCard = styled.div`
  background: ${variables.colorGreyLight};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0;
  width: 100%;
  height: 100%;
  box-shadow: -2px -2px 3px #fff, 10px 10px 17px #e7e7e7;
`;
