import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { SimpleBlackText } from '../../../../../../shared/styles';
import { GalleryCard } from '../../../../../../shared/components/GalleryCard/GalleryCard';
import { Dashboard } from './Dashboard/Dashboard';
import { GalleryForRequest } from '../../../../../../shared/types';

type ActiveGalleriesCardsProps = {
  galleries: GalleryForRequest[];
}

export const ActiveGalleriesCards: React.FC<ActiveGalleriesCardsProps> = ({ galleries }) => {
  const { t } = useTranslation('activeGalleries');

  return (
    galleries.length
      ? (
        galleries.map((item) => (
          <Grid
            key={item?.id}
            item
            lg={6}
            md={12}
          >
            <GalleryCard
              title={item?.name}
              coverFile={{
                id: item?.coverFile?.desktop?.id || item?.coverFile?.original?.id || '',
                type: item?.coverFile?.desktop?.type || item?.coverFile?.original?.type || 'image/jpeg',
                name: item?.coverFile?.desktop?.name || (item?.coverFile?.original?.name ? item?.coverFile?.original?.name ?? 'cover' : 'cover'),
                url: item?.coverFile?.desktop?.url || item?.coverFile?.original?.url || '',
              }}
                // TODO: check on the backend before response if a gallery was watched
                // wasWatchedByClient={item?.wasWatched}
              galleryId={item?.id}
            >
              <Dashboard
                gallery={item}
              />
            </GalleryCard>
          </Grid>
        ))
      )
      : (
        <Grid item sm={6}>
          <SimpleBlackText>{t('noActiveGalleries')}</SimpleBlackText>
        </Grid>
      )
  );
};
