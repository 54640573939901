import * as Yup from 'yup';
import { maxPriceLength, messageMaxLength } from '../../../constants/validationValues';
import { errorMessages } from '../../utils/errors/errorsMessages';

export const galleryFileInformationSchema = Yup.object().shape({
  description: Yup.string().max(messageMaxLength, 'Too long'),
  price: Yup.mixed().when('instantlyDownloadable', {
    is: false,
    then: Yup.string().max(maxPriceLength, errorMessages.tooLong),
  }),
  videoThumbnail: Yup.mixed().when('hasVideoThumbnail', {
    is: true,
    then: Yup.mixed().required(errorMessages.requiredField),
  }),
});
