import { useState } from 'react';
import { useGalleryAppDispatch, useTypedSelectorClient } from '../../../../shared/hooks/useTypedSelector';
import {
  fetchRemoveFromFavourites,
  fetchAddToFavourites,
} from '../../../Client/redux/favourites/favouritesClientThunk';
import { getSectionId } from '../../utils/helpers';

type ReturnObj = {
  isLiked: boolean;
  handleLike: (fileId: string) => void;
};

export const useLike = (isFileLiked: boolean): ReturnObj => {
  const [isLiked, setIsLiked] = useState(!!isFileLiked); // temporary
  const dispatch = useGalleryAppDispatch();

  const gallery = useTypedSelectorClient((state) => state.signIn?.gallery);

  const handleLike = (fileId: string) => {
    if (gallery) {
      const reqData = {
        galleryId: gallery.id,
        favouriteCartItems: [{ sectionId: getSectionId(gallery, fileId), fileId }],
      };
      if (isLiked) {
        dispatch(fetchRemoveFromFavourites(reqData));
        setIsLiked(false);
      } else {
        dispatch(
          fetchAddToFavourites(reqData),
        );
        setIsLiked(true);
      }
    }
  };

  return {
    isLiked,
    handleLike,
  };
};
