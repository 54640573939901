import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetToken } from '@clerk/types';
import { creatorInteractions } from '../../../../../api/interactions/interactions';
import { creatorViews } from '../../../../../api/views/views';

export const fetchGetCreatorInteractions = createAsyncThunk(
  'interactionsCreator/fetchGetCreatorInteractions',
  async ({ galleryId, getToken }: { galleryId: string, getToken: GetToken }, thunkApi) => {
    try {
      const token = (await getToken()) || '';
      const response = await creatorInteractions.getInteractions(galleryId, token);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchDeleteCreatorViews = createAsyncThunk(
  'interactionsCreator/fetchDeleteCreatorViews',
  async (data: { galleryId: string, email: string, getToken: GetToken }, thunkApi) => {
    try {
      const token = (await data.getToken()) || '';
      const response = await creatorViews.deleteView(data.galleryId, data.email, token);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
