import styled from '@emotion/styled';
import * as variables from '../../../../../../../../../../constants/styles';
import { PreviewCard } from '../../styled';
import { TextGallerySecond } from '../PreviewSecond/styled';

export const PreviewCardFourth = styled(PreviewCard)`
  padding: 53px 12px;
`;

export const TextContainerFourth = styled(TextGallerySecond)`
  font-size: 4.59314px;
  line-height: 140%;
  max-width: 88px;

  & h3 {
    font-size: 8.03799px;
    line-height: 10px;
    width: 88px;
  }
`;

export const ImageContainerFourth = styled.div`
  width: 88px;
  height: 92px;
  background: ${variables.colorBleak};
  overflow: hidden;
  object-fit: cover;
  margin-right: 9px;
`;

export const SquareBoxes = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 39px);
  gap: 14px 9px;
  grid-template-rows: repeat(2, 39px);
  font-size: 4.59314px;
  line-height: 140%;
`;

export const DateCell = styled.div`
  grid-column: 2 / 2;
  grid-row: 2 / 2;
  text-align: start;
`;

export const CreatorCell = styled.div`
  grid-column: 2 / 1;
  grid-row: 2 / 1;
  text-align: start;
`;

export const ImageFirstCell = styled.div`
  grid-column: 2 / 2;
  grid-row: 2 / 1;
  background: ${variables.colorBleak};
  overflow: hidden;
  object-fit: cover;
  position: relative;
`;

export const ImageSecondCell = styled.div`
  grid-column: 2 / 1;
  grid-row: 2 / 2;
  background: ${variables.colorBleak};
  overflow: hidden;
  object-fit: cover;
  position: relative;
`;
