import React from 'react';
import {
  Tooltip, TooltipProps, styled, tooltipClasses,
} from '@mui/material';

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: '#fff',
    color: 'rgba(5, 27, 44, 0.8)',
    maxWidth: 520,
    fontSize: '14px',
    boxSizing: 'border-box',
    border: '0px',
    whiteSpace: 'pre',
    lineHeight: '120%',
    padding: '8px 10px',
    width: 'fit-content',
    boxShadow: '2px 2px 3px #e7e7e7',
    borderRadius: '4px',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
