import {
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { SignIn } from '../types/types';
import { betaAuth } from '../../../api/beta/betaAuth';
import { setErrorBetaCode, unsetErrorBetaCode } from '../../Creator/redux/userCreator/creator/creatorSlice';

type BetaSIgnInReturnObj = {
    isSuccess: boolean;
    isLoading: boolean;
    error: string;
    setError: Dispatch<SetStateAction<string>>;
    signIn: (data: SignIn) => void;
  };

export const useBetaSignIn = (): BetaSIgnInReturnObj => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const signIn = async (data: SignIn) => {
    setIsSuccess(false);
    setIsLoading(true);
    setError('');

    betaAuth
      .signIn(data)
      .then(() => {
        setIsSuccess(true);
        dispatch(unsetErrorBetaCode());
      })
      .catch((err) => {
        setError(err.response.data.message);
        dispatch(setErrorBetaCode());
        setIsSuccess(false);
      })
      .finally(() => setIsLoading(false));
  };

  return {
    isSuccess,
    isLoading,
    error,
    setError,
    signIn,
  };
};
