import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useAuth } from '@clerk/clerk-react';
import {
  TabContentWrapper,
  TabWrapper,
  TitleText,
} from '../../../../../../shared/styles';
import { DraftGalleriesCards } from './DraftGalleriesCards';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../shared/hooks/useTypedSelector';
import { fetchGetCreatorGalleries } from '../../../../redux/gallery/galleryCreatorThunk';

export const DraftGalleries: React.FC = () => {
  const { t } = useTranslation('draftGalleries');
  const dispatch = useCreatorAppDispatch();
  const draftGalleries = useTypedSelectorCreator((state) => state.gallery.draftGalleries);
  const galleries = draftGalleries || [];
  const { getToken } = useAuth();

  useEffect(() => {
    dispatch(fetchGetCreatorGalleries(getToken));
  }, [dispatch]);

  return (
    <TabWrapper>
      <TitleText>{t('title')}</TitleText>
      <TabContentWrapper>
        {/* {isLoading ? (
          <span>Loading...</span>
        ) : ( */}
        <Grid container spacing={4}>
          <DraftGalleriesCards galleries={galleries} />
        </Grid>
        {/* )} */}
      </TabContentWrapper>
    </TabWrapper>
  );
};
