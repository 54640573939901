import { FormikHelpers, useFormik } from 'formik';
import React from 'react';
import { SendIcon } from '../../../../shared/icons';
import { CommentForm, CommentTextareaElement, RoundSendButton } from './styled';

export const CommentTextarea: React.FC<{
  addComment: (comment: string) => void;
}> = ({ addComment }) => {
  const onSubmit = (data: { comment: string }, bag: FormikHelpers<any>) => {
    addComment(data.comment);
    bag.resetForm();
    bag.setSubmitting(false);
  };

  const {
    values, handleChange, handleSubmit,
  } = useFormik({
    initialValues: { comment: '' },
    // validationSchema: clientSignInSchema,
    onSubmit: (data: { comment: string }, bag: any) => onSubmit(data, bag),
  });

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (event.key === 'Enter') {
      handleSubmit();
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <CommentForm onSubmit={handleSubmit} noValidate>
      <CommentTextareaElement>
        <textarea
          id="comment"
          name="comment"
          value={values.comment}
          onChange={handleChange}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          onKeyDown={onKeyDown}
        />
      </CommentTextareaElement>
      <div id="button-send">
        <RoundSendButton type="submit">
          <SendIcon />
        </RoundSendButton>
      </div>
    </CommentForm>
  );
};
