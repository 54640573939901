import styled from '@emotion/styled';
import { theme } from '../../../../../../../shared/components/GlobalContainer/styled';

export const PositionedDocItems = styled.div`
  position: relative;

  & #doc-like {
    & > div {
      right: 23px !important;
    }
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    & #doc-like {
      display: none;
    }
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    & #doc-like {
      display: flex;
      
      & > div {
        right: 10px !important;
      }
    }
  }
`;
