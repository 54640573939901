import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetToken } from '@clerk/types';
import { creatorDownloads } from '../../../../../api/downloads/downloads';

export const fetchGetCreatorDownloads = createAsyncThunk(
  'downloadsCreator/fetchGetCreatorDownloads',
  async ({ galleryId, getToken }: { galleryId: string, getToken: GetToken }, thunkApi) => {
    try {
      const token = (await getToken()) || '';
      const response = await creatorDownloads.getDownloads(galleryId, token);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
