import React, { useContext, useEffect } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { GridMgTop } from './styled';
import * as url from '../../../../../../constants/urls';
import { GalleryCardCustom } from './GalleryCardCustom';
import { CreatorOnboardingContext } from '../../../../context/CreatorOnboardingContext';
import { fakeGalleries } from '../../../../../../constants/walkThroughCards/fakeGalleryData';
import { fetchGetCreatorGalleries } from '../../../../redux/gallery/galleryCreatorThunk';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../shared/hooks/useTypedSelector';

export const GalleriesList: React.FC = () => {
  const { isStudy } = useContext(CreatorOnboardingContext);
  const dispatch = useCreatorAppDispatch();
  const draftGalleries = useTypedSelectorCreator((state) => state.gallery.draftGalleries);
  const activeGalleries = useTypedSelectorCreator((state) => state.gallery.activeGalleries);
  const { getToken } = useAuth();

  const checkedStudyActiveGalleries = isStudy ? fakeGalleries : activeGalleries;
  const checkedStudyDraftGalleries = isStudy ? fakeGalleries : draftGalleries;

  useEffect(() => {
    dispatch(fetchGetCreatorGalleries(getToken));
  }, []);

  return (
    <GridMgTop container spacing={4} justifyContent="space-between">
      <GalleryCardCustom
        gallery={
          checkedStudyActiveGalleries?.length
            ? checkedStudyActiveGalleries[0]
            : null
        }
        galleriesNumber={checkedStudyActiveGalleries?.length || 0}
        type="Active"
        toUrl={url.activeGalleriesUrl}
      />
      <GalleryCardCustom
        gallery={
          checkedStudyDraftGalleries?.length
            ? checkedStudyDraftGalleries[0]
            : null
        }
        galleriesNumber={checkedStudyDraftGalleries?.length || 0}
        type="Draft"
        toUrl={url.draftGalleriesUrl}
      />
    </GridMgTop>
  );
};
