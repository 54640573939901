import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetToken } from '@clerk/types';
import { sales } from '../../../../../api/sales/sales';

export const fetchGetAllSales = createAsyncThunk(
  'allSales/fetchGetAllSales',
  async (getToken: GetToken, thunkApi) => {
    try {
      const token = (await getToken() || '');
      const response = await sales.getAllSales(token);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
