import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchGetBillingInfo } from './billingInfoThunk';
import { BillingInfo } from '../../../../shared/types';

type InitialState = {
  loading: boolean;
  error: string;
  billingInfo: BillingInfo;
};

export const initialState: InitialState = {
  loading: false,
  error: '',
  billingInfo: {
    billingHistory: [],
    last4: '',
  },
};

const billingInfoSlice = createSlice({
  name: 'billingInfoSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetBillingInfo.fulfilled,
      (state, action: PayloadAction<BillingInfo>) => {
        state.error = '';
        state.loading = false;
        state.billingInfo = action.payload;
      },
    );
    builder.addCase(
      fetchGetBillingInfo.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchGetBillingInfo.pending, (state) => {
      state.loading = true;
    });
  },
});

export default billingInfoSlice.reducer;
