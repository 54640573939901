import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorType } from '../../../../shared/types';
import {
  fetchGetClientBoughtFreeFiles,
} from './boughtFreeFilesClientThunk';
import { BoughtFreeFilesClientItem } from '../../types/types';

type initialState = {
  sections: BoughtFreeFilesClientItem[];
  loadingGet: boolean;
  error: ErrorType;
};

export const initialState: initialState = {
  sections: [],
  loadingGet: false,
  error: null,
};

const boughtFreeFilesClientSlice = createSlice({
  name: 'boughtFreeFilesClientSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetClientBoughtFreeFiles.fulfilled,
      (state, action: PayloadAction<BoughtFreeFilesClientItem[]>) => {
        state.error = null;
        state.loadingGet = false;
        state.sections = action.payload;
      },
    );
    builder.addCase(
      fetchGetClientBoughtFreeFiles.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingGet = false;
      },
    );
    builder.addCase(fetchGetClientBoughtFreeFiles.pending, (state) => {
      state.loadingGet = true;
    });
  },
});

export default boughtFreeFilesClientSlice.reducer;
