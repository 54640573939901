import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyInsideListWrapper, TermsAndPrivacyListItem } from '../styled';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const ExclusionsPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={16}
      listTitle={t('terms.exclusions')}
      pointsNameForTrans="exclusionsPoints"
    >
      <LandingNumberedLi>
        Despite anything to the contrary, to the maximum extent permitted by
        law, we will not be liable for, and you waive and release us from
        and against, any Liability caused or contributed to by, arising from
        or connected with:
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            if you are a Member, any unavailability of the Platform
            including due to scheduled and emergency maintenance;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            any aspect of the Client and Member interaction including Your
            Data, goods offered by the Member (Output Data), the description
            of the goods requested or offered, any advice provided, or
            supply and delivery of goods; and
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            any event outside of our reasonable control.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        You agree to indemnify us for any Liability we incur due to your
        breach of the Acceptance and Platform Licence clause 3, the
        Confidentiality clause 21.3 and the Intellectual Property clauses
        11, 12 and 13 of these Terms.
      </LandingNumberedLi>
      <LandingNumberedLi>
        This clause will survive the termination or expiry of these Terms.
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
