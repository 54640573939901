import * as Yup from 'yup';
import { galleryPasswordMaxLength } from '../../../../constants/validationValues';
import { errorMessages } from '../../../utils/errors/errorsMessages';

export const clientSignInSchema = Yup.object().shape(
  {
    email: Yup.string()
      .email(errorMessages.invalidEmail)
      .required(errorMessages.requiredField),
    password: Yup.string()
      .max(galleryPasswordMaxLength, errorMessages.tooLong),
  },
  [['email', 'password']],
);
