import React, { ReactElement } from 'react';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { getConfig as getI18nextConfig } from '../../../translations';

i18next.init(getI18nextConfig());

type TranslateContainerProps = {
  children: ReactElement;
}

export const TranslateContainer: React.FC<TranslateContainerProps> = ({
  children,
}) => (
  <I18nextProvider i18n={i18next}>
    {children}
  </I18nextProvider>
);
