import styled from '@emotion/styled';
import * as variables from '../../../constants/styles';
import { TitleText } from '../../styles';
import { theme } from '../GlobalContainer/styled';

export const TooltipBody = styled.div<{tooltipPosition: string}>`
  background-color: ${variables.colorGreyLight};
  z-index: 100000;
  border-radius: 10px;
  padding: 12px 20px;
  position: relative;
  box-sizing: border-box;
  width: 260px;
  box-shadow: none;
  filter: none;

  ${(props) => {
    if (props.tooltipPosition === 'top' || props.tooltipPosition === 'bottom') {
      return `
      margin-top: 0px;
      margin-bottom: 0px;
      margin-right: 0px;
      margin-left: -10px;
      `;
    }
    if (props.tooltipPosition === 'right' || props.tooltipPosition === 'left') {
      return `
      margin-right: 0px;
      margin-left: 0px;
      margin-top: -10px;
      margin-bottom: 0px;
      `;
    }
    return 'margin: 0;';
  }}
`;

export const TooltipContent = styled.div`
  max-width: 200px;
`;

export const TooltipFooter = styled.div<{isFirstCard: boolean}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.isFirstCard ? 'flex-end' : 'space-between')};
  margin-top: 20px;
`;

export const TooltipCloseButton = styled.button`
  position: absolute;
  top: 12px;
  right: 8px;

  & > svg {
    color: ${variables.textBlack};
  }
`;

export const TooltipNextButton = styled.button`
  padding: 0;
  font-weight: normal;
  font-size: 13px;
  line-height: 145%;
  text-align: right;
  color: ${variables.colorBlue};
`;

export const TooltipBackButton = styled(TooltipNextButton)`
  text-align: left;
  color: ${variables.textGrey};
`;

export const WalkThroughButtons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex-direction: column;
    justify-content: center;

    & > button {
      &:first-of-type {
        width: 100%;
        margin-bottom: 27px;
      }
    }
  }
`;

export const WalkThroughStartWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-round;
  align-items: center;
  padding: 60px 65px 60px 70px;
  border-radius: 18px;
  box-sizing: border-box;
  background: ${variables.colorGreyLight};
  max-width: 616px;

  & > div {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 30px 16px 60px;
    height: 100%;

    & > button {
      display: none;
    }
  }
`;

export const FormattedMessage = styled.div``;

export const WalkThroughStartContentWr = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-round;
  align-items: center;
  background: transparent;

  & > div {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    height: 100%;
    justify-content: space-between;
  }
`;

export const StartOnboardingTextWr = styled.div`
  display: inline;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    & > span, p {
      display: flex;
    }
  }
`;

export const StartOnboardingTitle = styled(TitleText)`
  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 130%;
    font-weight: 700;
  }
`;

export const StartOnboardingText = styled(TitleText)`
  @media (max-width: ${theme.breakpoints.values.md}px) {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 30px;
  }
`;
