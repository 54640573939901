import * as Yup from 'yup';
import { passwordMinLength } from '../../../constants/validationValues';
import { errorMessages } from '../../utils';

export const password = Yup.object().shape({
  password: Yup.string()
    .required(errorMessages.requiredField)
    .min(passwordMinLength, errorMessages.passwordLength),
});

export const email = Yup.object().shape({
  email: Yup.string()
    .email(errorMessages.invalidEmail)
    .required(errorMessages.requiredField),
});
