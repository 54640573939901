import React, { ReactElement, useState } from 'react';
import { EyeIcon, CrossedEyeIcon } from '../icons';
import { EyeButton } from '../styles';

type ReturnObj = {
  isValueVisible: boolean;
  eyeElement: ReactElement;
}

export const useHiddenOrVisibleValue = (): ReturnObj => {
  const [isValueVisible, setIsValueVisible] = useState(false);

  const handleEyeClick = () => {
    setIsValueVisible((prev) => !prev);
  };

  const eyeElement = (
    <EyeButton type="button" onClick={handleEyeClick}>
      {isValueVisible ? <EyeIcon /> : <CrossedEyeIcon />}
    </EyeButton>
  );

  return ({
    isValueVisible,
    eyeElement,
  });
};
