import { combineReducers } from '@reduxjs/toolkit';
import commentsSlice from '../../GalleryView/redux/comments/commentsSlice';
import cartClientSlice from './cart/cartClientSlice';
import favouritesClientSlice from './favourites/favouritesClientSlice';
import signInClientSlice from './signIn/signInClientSlice';
import onboardingSlice from './onboarding/onboardingSlice';
import swiperSlice from '../../GalleryView/redux/gallery/swiperSlice';
import gallerySlice from '../../GalleryView/redux/gallery/gallerySlice';
import boughtFreeFilesClientSlice from './boughtFreeFiles/boughtFreeFilesClientSlice';

export const rootReducer = combineReducers({
  onboarding: onboardingSlice,
  cart: cartClientSlice,
  favourites: favouritesClientSlice,
  signIn: signInClientSlice,
  comments: commentsSlice,
  swiper: swiperSlice,
  gallery: gallerySlice,
  boughtFreeFilesClient: boughtFreeFilesClientSlice,
});
