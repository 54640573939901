import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { CommonInput } from '../../../../../shared/components/FormElements/CommonInput/CommonInput';
import { FormikCustomProps, SignUpInProcess } from '../../../../../shared/types';
import { PrimaryWhiteButton } from '../../../../../shared/styles';
import { PasswordTipText, PasswordTipWrapper, SignUpStepsButtonWrapper } from '../styled';
import { InfoIcon } from '../../../../../shared/icons';

type SignUpFirstStepType = FormikCustomProps<SignUpInProcess>;

export const SignUpFirstStep: React.FC<SignUpFirstStepType> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
}) => {
  const { t } = useTranslation(['basicComponents', 'signUp']);

  return (
    <>
      <Grid item sm={9}>
        <CommonInput
          error={errors.firstName}
          value={values.firstName}
          name="firstName"
          handleChange={handleChange}
          handleBlur={handleBlur}
          type="text"
          placeholder="Your first name"
          touched={touched.firstName}
          withoutMargins
        />
      </Grid>
      <Grid item sm={9}>
        <CommonInput
          error={errors.lastName}
          value={values.lastName}
          name="lastName"
          handleChange={handleChange}
          handleBlur={handleBlur}
          type="text"
          placeholder="Your last name"
          touched={touched.lastName}
          withoutMargins
        />
      </Grid>
      <Grid item sm={9}>
        <CommonInput
          error={errors.businessName}
          value={values.businessName}
          name="businessName"
          handleChange={handleChange}
          handleBlur={handleBlur}
          type="text"
          placeholder="Business name"
          touched={touched.businessName}
          withoutMargins
        />
      </Grid>
      <Grid item sm={9}>
        <CommonInput
          error={errors.password}
          value={values.password}
          name="password"
          handleBlur={handleBlur}
          handleChange={handleChange}
          type="password"
          placeholder="Password"
          touched={touched.password}
          withoutMargins
        />
      </Grid>
      <Grid item sm={9}>
        <CommonInput
          error={errors.confirmPassword}
          value={values.confirmPassword}
          name="confirmPassword"
          handleBlur={handleBlur}
          handleChange={handleChange}
          type="password"
          placeholder={t('signUp:confirmPasswordPlaceholder')}
          touched={touched.confirmPassword}
          withoutMargins
        />
        <PasswordTipWrapper>
          <InfoIcon />
          <PasswordTipText>
            {t('signUp:passwordTip')}
          </PasswordTipText>
        </PasswordTipWrapper>
      </Grid>
      <Grid item sm={8}>
        <SignUpStepsButtonWrapper>
          <PrimaryWhiteButton type="submit" fullWidth>
            {t('basicComponents:buttons.next')}
          </PrimaryWhiteButton>
        </SignUpStepsButtonWrapper>
      </Grid>
    </>
  );
};
