import styled from '@emotion/styled';
import { theme } from '../../../../../shared/components/GlobalContainer/styled';
import { ErrorMessage } from '../../../../../shared/components/styled';
import { TitleText } from '../../../../../shared/styles';

export const ClientContentWrapper = styled.div`
  margin-top: 30px;
`;

export const ClientContentTitleText = styled(TitleText)`
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const ClientContentLoaderWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 0px 20px; 
`;

export const ClientContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 40px;
`;

export const ClientContentErrorMessage = styled(ErrorMessage)`
  font-size: 16px;
  line-height: 140%;
  margin: 0px 20px;
  text-align: center;
  display: flex;
  align-items: center;
`;
