import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import { GalleryData, GallerySection } from '../../../../../../../shared/types';
import { CommonSwitch } from '../../../../../../../shared/components/FormElements/CommonSwitch/CommonSwitch';
import { useDialog } from '../../../../../../../shared/hooks/useDialog';

type DisableDownloadsSwitchProps = {
  section: GallerySection;
};

export const DisableDownloadsSwitch: React.FC<DisableDownloadsSwitchProps> = ({
  section,
}) => {
  const { t } = useTranslation('createNewGallery');

  const { values, setFieldValue } = useFormikContext<GalleryData>();

  const sectionIndex = useMemo(
    () => values.sections.findIndex((item) => item.id === section.id),
    [section.id, values.sections],
  );

  const handleSwitch = () => {
    setFieldValue(
      `sections[${sectionIndex}].disableDownloads`,
      !values.sections[sectionIndex].disableDownloads,
    );
    values.sections[sectionIndex].files.forEach((item, index) => {
      setFieldValue(
        `sections[${sectionIndex}].files[${index}].instantlyDownloadable`,
        false,
      );
    });
  };

  const { setIsOpen, dialogWindow } = useDialog<string>(
    'confirmation',
    section.id,
    handleSwitch,
    'Instantly downloadable files from this section will be switched off after the confirmation. Are you sure that you want to disable downloads for this section?',
    'Disable downloads for this section',
  );

  return (
    <Grid item xs={10}>
      <CommonSwitch
        withoutMargins
        spaceBetween
        isChecked={!!section.disableDownloads}
        name={`sections[${sectionIndex}].disableDownloads`}
        label={t('thirdStep.disableDownloads')}
        helpText={t('thirdStep.helpTexts.disableDownloads')}
        positionHelpText="top"
        handleChange={() => (section.disableDownloads ? handleSwitch() : setIsOpen(true))}
      />
      {dialogWindow}
    </Grid>
  );
};
