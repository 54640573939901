import { createAsyncThunk } from '@reduxjs/toolkit';
import { favourites } from '../../../../api/favourites/favourites';
import { FavouritesCartReq } from '../../../../shared/types/client';
import { likeHandle } from '../../../GalleryView/redux/gallery/gallerySlice';
import { likeHandle as likeHandleClient } from '../signIn/signInClientSlice';

export const fetchGetClientFavourites = createAsyncThunk(
  'favouritesClient/fetchGetClientFavourites',
  async (galleryId: string, thunkApi) => {
    try {
      const response = await favourites.getFavourites(galleryId);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchAddToFavourites = createAsyncThunk(
  'favouritesClient/fetchAddToFavourites',
  async (data: FavouritesCartReq, thunkApi) => {
    try {
      const response = await favourites.addToFavourites(data);
      thunkApi.dispatch(likeHandleClient(data.favouriteCartItems));
      thunkApi.dispatch(likeHandle(data.favouriteCartItems));

      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchRemoveFromFavourites = createAsyncThunk(
  'favouritesClient/fetchRemoveFromFavourites',
  async (data: FavouritesCartReq, thunkApi) => {
    try {
      const response = await favourites.removeFavourites(data);
      thunkApi.dispatch(likeHandleClient(data.favouriteCartItems));
      thunkApi.dispatch(likeHandle(data.favouriteCartItems));
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
