import styled from '@emotion/styled';
import * as variables from '../../../../../constants/styles';
import gradientStars from '../../../../../assets/image/plans/gradientStars.svg';
import { theme } from '../../../../../shared/components/GlobalContainer/styled';

export const LandingPurchasedPlanWrapper = styled.div`
  box-sizing: border-box;
  padding: 32px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${variables.colorBleak};
  border-radius: 16px;
  min-width: 420px;
  position: relative;
  background-image: url(${gradientStars});
  background-position: calc(100% - 20px) 20px;
  background-repeat: no-repeat;
  background-size: auto;
  margin-bottom: 16px;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    min-width: 120px;
  }
`;

export const LandingPurchasedPlanTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
  margin-right: 63px;
  
  & > div,
  & > span {
    &:first-of-type {
      margin-right: 12px;
    }
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-right: 0px;
  }
`;

export const LandingPaymentButton = styled.button`
  display: flex;
  padding: 0;
`;

export const LandingPaymentSuccessButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  & button {
    margin: 16px 0px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-top: 34px;
  }
`;
