import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyInsideListWrapper, TermsAndPrivacyListItem } from '../styled';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const UploadedContentPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={12}
      listTitle={t('terms.uploadedContent')}
      pointsNameForTrans="uploadedContentPoints"
    >
      <LandingNumberedLi>
        You, a Client may be invited to upload data, information or content
        such as video, photo and project files onto the Platform or into the
        SaaS Solution via a Member or may otherwise make available such data
        to a Member and/or you may input data such as a tag or comment onto
        our Platform (
        <span className="bold">Your Data</span>
        ).
      </LandingNumberedLi>
      <LandingNumberedLi>
        You (a Client or Member) acknowledge and agree that the Platform and
        the integrity and accuracy of the Output Data is reliant on the
        accuracy and completeness of Your Data, and the provision by you, a
        Client, of Your Data that is inaccurate or incomplete may affect the
        use, output and operation of the Platform. If you, a Client, upload
        or make available any of Your Data, you represent and warrant that:
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            you are either the sole and exclusive owner of Your Data or you
            have all rights, licences, consents and releases that are
            necessary to grant the rights in Your Data (as contemplated by
            these Terms);
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            neither the use of Your Data nor the posting, uploading,
            publication, submission or transmission of Your Data or our use
            of Your Data on, through or by means of our Platform of SaaS
            Solution will infringe, misappropriate or violate a third
            party’s intellectual property rights, or rights of publicity or
            privacy, or result in the violation of any applicable law or
            regulation.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        You, a Member, own or have the rights to use any data or information
        output from your use of the SaaS Solution and the Platform using
        Your Data and other materials as input (
        <span className="bold">Output Data</span>
        ). Note that Output Data does not include the Analytics (as
        described below). You, a Member, agree that you are solely
        responsible for all of the Output Data that you and your authorised
        users make available on or through the Platform or SaaS Solution.
        You represent and warrant that:
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            you are either the sole and exclusive owner of the Output Data
            or you have all rights, licences, consents and releases that are
            necessary to grant to the rights in the Output Data (as
            contemplated by these Terms); and
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            neither the Output Data nor the posting, uploading, publication,
            submission or transmission of the Output Data or our use of the
            Output Data on, through or by means of our Platform or SaaS
            Solution will infringe, misappropriate or violate a third
            party’s intellectual property rights, or rights of publicity or
            privacy, or result in the violation of any applicable law or
            regulation.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        You, a Client and/or a Member, grant us a limited licence to copy,
        transmit, store, backup and/or otherwise access or use Your Data
        and/or the Output Data to:
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            communicate with you (including to send you information we
            believe may be of interest to you);
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            make available the SaaS Solution to you;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            supply the Platform to you and otherwise perform our obligations
            under these Terms;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            diagnose problems with the Platform;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            enhance and otherwise modify the Platform;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            perform Analytics;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            develop other services, provided we de-identify Your Data; and
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            as reasonably required to perform our obligations under these
            Terms.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        You acknowledge and agree that we may monitor, analyse and compile
        statistical and performance information based on and/or related to
        your (and your authorised user’s) use of the Platform, in an
        aggregated and anonymised format (
        <span className="bold">Analytics</span>
        ). You acknowledge and agree that we own all rights in the
        Analytics, and that we may use the Analytics for our own internal
        business purposes, provided that the Analytics do not contain any
        identifying information.
      </LandingNumberedLi>
      <LandingNumberedLi>
        We do not endorse or approve, and are not responsible for, any of
        Your Data or the Output Data.
      </LandingNumberedLi>
      <LandingNumberedLi>
        Six months after termination of these Terms, we may at our sole
        discretion anonymise or delete all of Your Data or the Output Data
        which we hold or control in relation to these Terms.
      </LandingNumberedLi>
      <LandingNumberedLi>
        This clause will survive the termination or expiry of these Terms or
        your Membership.
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
