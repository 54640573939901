import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ClientGallery,
  GalleryCreatorSocialMedia,
} from '../../../../shared/types';
import {
  CartItem,
  CommentPutRes,
  FavouriteCartItem,
} from '../../../../shared/types/client';
import { fetchGetClientGallery, fetchSignInClient, fetchCheckPassGallery } from './signInClientThunk';
import { ErrorStatusAndMessage } from '../../../../shared/types/httpError';

type initialState = {
  isSignIn: boolean;
  gallery: ClientGallery | null;
  socialMedia: GalleryCreatorSocialMedia | null;
  clientEmail: string;
  loading: boolean;
  checkLoading: boolean;
  error: ErrorStatusAndMessage | null;
  wasWatched: boolean;
  hasPassword: boolean;
};

export const initialState: initialState = {
  isSignIn: false,
  gallery: null,
  clientEmail: '',
  checkLoading: false,
  loading: true,
  error: null,
  wasWatched: false,
  socialMedia: null,
  hasPassword: false,
};

const signInClientSlice = createSlice({
  name: 'signInClient',
  initialState,
  reducers: {
    setWasWatched(state) {
      state.wasWatched = true;
    },
    setClientIsNotSignIn(state) {
      state.isSignIn = false;
      state.gallery = null;
      state.clientEmail = '';
      state.loading = false;
      state.error = null;
    },
    likeHandle(state, action: PayloadAction<FavouriteCartItem[]>) {
      state.gallery?.sections.forEach((section) => {
        section.files.forEach((file) => {
          if (action.payload.find((item) => item.fileId === file.id)) {
            file.isLiked = !file.isLiked;
          }
        });
        section.ideas.forEach((idea) => {
          if (action.payload.find((item) => item.fileId === idea.id)) {
            idea.isLiked = !idea.isLiked;
          }
        });
      });
    },
    cartHandle(state, action: PayloadAction<CartItem[]>) {
      state.gallery?.sections.forEach((section) => {
        section.files.forEach((file) => {
          if (action.payload.find((item) => item.fileId === file.id)) {
            file.isInCart = !file.isInCart;
          }
        });
        section.ideas.forEach((idea) => {
          if (action.payload.find((item) => item.fileId === idea.id)) {
            idea.isInCart = !idea.isInCart;
          }
        });
      });
    },
    addCommentHandle(state, action: PayloadAction<CommentPutRes>) {
      state.gallery?.sections.forEach((section) => {
        section.files.forEach((file) => {
          if (action.payload.fileId === file.id) {
            file.comments = [
              ...(file.comments || []),
              {
                id: action.payload.messageId,
                text: action.payload.text,
                date: action.payload.date,
                email: action.payload.email,
              },
            ];
          }
        });
        section.ideas.forEach((idea) => {
          if (action.payload.fileId === idea.id) {
            idea.comments = [
              ...(idea.comments || []),
              {
                id: action.payload.messageId,
                text: action.payload.text,
                date: action.payload.date,
                email: action.payload.email,
              },
            ];
          }
        });
      });
    },
    deleteCommentHandle(
      state,
      action: PayloadAction<{ fileId: string; commentId: string }>,
    ) {
      state.gallery?.sections.forEach((section) => {
        section.files.forEach((file) => {
          if (action.payload.fileId === file.id) {
            file.comments = file.comments?.filter(
              (item) => item.id !== action.payload.commentId,
            );
          }
        });
        section.ideas.forEach((idea) => {
          if (action.payload.fileId === idea.id) {
            idea.comments = idea.comments?.filter(
              (item) => item.id !== action.payload.commentId,
            );
          }
        });
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchSignInClient.fulfilled,
      (state) => {
        state.isSignIn = true;
        state.error = null;
        state.loading = false;
      },
    );
    builder.addCase(
      fetchSignInClient.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
        state.clientEmail = '';
      },
    );
    builder.addCase(fetchSignInClient.pending, (state) => {
      state.loading = true;
      state.clientEmail = '';
      state.error = null;
    });
    builder.addCase(
      fetchGetClientGallery.fulfilled,
      (
        state,
        action: PayloadAction<{
          gallery: ClientGallery;
          email: string;
          wasWatched: boolean;
          socialMedia: GalleryCreatorSocialMedia;
        }>,
      ) => {
        const {
          email, gallery, wasWatched, socialMedia,
        } = action.payload;
        state.isSignIn = true;
        state.error = null;
        state.loading = false;
        state.gallery = gallery;
        state.clientEmail = email;
        state.wasWatched = wasWatched;
        state.socialMedia = socialMedia;
      },
    );
    builder.addCase(
      fetchGetClientGallery.rejected,
      (state) => {
        // state.error = action.payload;
        state.loading = false;
        state.clientEmail = '';
      },
    );
    builder.addCase(fetchGetClientGallery.pending, (state) => {
      state.loading = true;
      state.clientEmail = '';
      state.error = null;
    });
    builder.addCase(
      fetchCheckPassGallery.fulfilled,
      (
        state,
        action: PayloadAction<{
          password: boolean;
        }>,
      ) => {
        const {
          password,
        } = action.payload;
        state.checkLoading = false;
        state.hasPassword = password;
      },
    );
    builder.addCase(fetchCheckPassGallery.pending, (state) => {
      state.checkLoading = true;
      state.clientEmail = '';
    });
    builder.addCase(fetchCheckPassGallery.rejected, (state, action: PayloadAction<any>) => {
      // state.error = action.payload;
      state.checkLoading = false;
      // state.clientEmail = '';
    });
  },
});

export const {
  likeHandle,
  cartHandle,
  addCommentHandle,
  deleteCommentHandle,
  setClientIsNotSignIn,
  setWasWatched,
} = signInClientSlice.actions;

export default signInClientSlice.reducer;
