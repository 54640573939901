import { NewPassword, SignIn } from '../../shared/types';

export const signInInitialValues: SignIn = {
  email: '',
  password: '',
};

export const resetPassword: NewPassword = {
  code: '',
  newPassword: '',
  confirmPassword: '',
};
