import { FormikHelpers } from 'formik';
import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';
import {
  CustomFile,
  GalleryDataForm,
} from '../../../../../../../shared/types';
import { getGalleryDataForRequest } from '../../../../../utils/getGalleryDataForRequest';
import { useErrors } from '../../../../../hooks/useErrors';
import { useUpdateGallery } from './useUpdateGallery';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';
import { setSuccessUpdateFalse } from '../../../../../redux/gallery/galleryCreatorSlice';
import { CreatorOnboardingContext } from '../../../../../context/CreatorOnboardingContext';
import { unsetAllValues } from '../../../../../redux/gallery/fileSettings/fileSettingsCreatorSlice';
import { YesOrNoAlert } from '../../../../../../../shared/components/CustomAlert/CustomAlert';
import { isVideo } from '../../../../../../../shared/utils';
import { areObjectsSimilar } from '../../../../../utils/helpers';

type ReturnObj = {
  onSubmit: (
    data: GalleryDataForm,
    bag: FormikHelpers<GalleryDataForm>
  ) => void;
  isLoading: boolean;
  step: number;
  errorAlert: ReactElement;
  errorUpdate: string;
  clickSteps: (step: number) => void;
  confirmAlert: ReactElement;
}

export const useSubmitGalleryObj = (): ReturnObj => {
  const [step, setStep] = useState(1);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const [draftStep, setDraftStep] = useState(1);
  const [gallery, setGallery] = useState<GalleryDataForm | null>(null);
  const [bagObj, setBagObj] = useState<FormikHelpers<GalleryDataForm> | null>(
    null,
  );
  const { isStudy } = useContext(CreatorOnboardingContext);
  const dispatch = useCreatorAppDispatch();
  const {
    updateGallery,
  } = useUpdateGallery();
  const isSuccess = useTypedSelectorCreator((state) => state.gallery.isSuccessUpdate);
  const isLoading = useTypedSelectorCreator((state) => state.gallery.loadingUpdate);
  const error = useTypedSelectorCreator((state) => state.gallery.errorUpdate);
  const fileSettings = useTypedSelectorCreator((state) => state.fileSettingsCreator.data);
  const errorAlert = useErrors(error, bagObj?.setFieldError);

  const clickSteps = (num: number) => {
    setDraftStep(num);
  };

  const onSubmit = (
    data: GalleryDataForm,
    bag: FormikHelpers<GalleryDataForm>,
  ) => {
    setBagObj(bag);
    if ((fileSettings.length) && step === 3) {
      setIsOpenDialog(true);
      return;
    }
    const dataForRequest = getGalleryDataForRequest(data, data.status || 'draft');
    if (!areObjectsSimilar(gallery, data)) {
      if (!isStudy) {
        updateGallery(data.id, dataForRequest);
        dispatch(unsetAllValues());
      }
    } else if (!isStudy) {
      bagObj?.setSubmitting(false);
      bagObj?.setTouched({});
      bagObj?.setFieldValue('step', draftStep);
      setStep(draftStep);
    }
    setGallery(data);
  };

  const onNo = () => {
    if (gallery) {
      dispatch(unsetAllValues());
      const dataForRequest = getGalleryDataForRequest(gallery, gallery.status || 'draft');
      if (!isStudy) {
        updateGallery(gallery.id, dataForRequest);
        dispatch(unsetAllValues());
      }
    }
  };

  const confirmSubmit = () => {
    if (gallery && bagObj) {
      const galleryCopy: GalleryDataForm = JSON.parse(
        JSON.stringify(gallery),
      );
      const sections = galleryCopy.sections.map((section, sectionIndex) => {
        const currentSection = fileSettings.find((item) => item.sectionId === section.id);
        if (!currentSection) {
          return section;
        }
        try {
          const resFiles = section?.files?.map((item) => {
            if (currentSection.selected.includes(item.id)) {
              if (isVideo(item)) {
                item = {
                  ...item,
                  ...currentSection.settings,
                  videoThumbnail: {
                    ...currentSection.settings.videoThumbnail,
                    id: (currentSection.settings.videoThumbnail as unknown as any)?.thumbnail || '',
                  } as CustomFile,
                };
              } else {
                item = { ...item, ...currentSection.settings };
              }
            }
            return item;
          });
          const resIdeas = section?.ideas.map((item) => {
            if (currentSection.selected.includes(item.id)) {
              item = {
                ...item,
                // instantlyDownloadable: data.instantlyDownloadable,
                watermark: currentSection.settings.watermark,
                allowCommenting: currentSection.settings.allowCommenting,
              };
            }
            return item;
          });
          bagObj.setFieldValue(`sections[${sectionIndex}].files`, resFiles);
          bagObj.setFieldValue(`sections[${sectionIndex}].ideas`, resIdeas);
          return { ...section, files: resFiles, ideas: resIdeas };
        } catch (e) {
          throw new Error('Something went wrong');
        }
      });
      dispatch(unsetAllValues());
      const dataForRequest = getGalleryDataForRequest(galleryCopy, gallery.status || 'draft');
      if (!isStudy) {
        updateGallery(gallery.id, dataForRequest);
      }
    }
    dispatch(unsetAllValues());
    setIsOpenDialog(false);
  };

  useEffect(() => {
    if (isSuccess) {
      bagObj?.setSubmitting(false);
      bagObj?.setTouched({});
      bagObj?.setFieldValue('step', draftStep);
      setStep(draftStep);
      dispatch(setSuccessUpdateFalse());
    }
    if (isStudy) {
      bagObj?.setFieldValue('step', draftStep);
      setStep(draftStep);
    }
    if (!isLoading && error && !isSuccess) {
      setDraftStep(draftStep);
    }
  }, [bagObj, gallery?.step, isSuccess]);

  return {
    onSubmit,
    isLoading,
    step,
    errorAlert,
    errorUpdate: error,
    clickSteps,
    confirmAlert: (
      <YesOrNoAlert
        onClose={() => setIsOpenDialog(false)}
        onYes={confirmSubmit}
        onNo={() => {
          onNo();
          setIsOpenDialog(false);
        }}
        isOpenWindow={isOpenDialog}
        message="Would you like to save settings for selected files?"
        title="Apply settings"
      />
    ),
  };
};
