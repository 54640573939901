import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import {
  PaymentSuccessfulWrapper, PaymentSuccessfulTitle, TextWithCheckmark, PaymentSuccessfulHeader,
} from './styled';
import { ContentWrapper } from '../../../modules/Client/containers/styled';
import { MainLogoNoWhiteSpace, TickIcon } from '../../icons';
import { SimpleBlackText } from '../../styles';
import { OptimisedStyles } from '../../types';

type PaymentSuccessStyles = {
  paymentWrapper?: OptimisedStyles;
  header?: OptimisedStyles;
}

type PaymentSuccessfulProps = {
  children: ReactNode;
  rootUrl: string;
  hasBackground?: boolean;
  styles?: PaymentSuccessStyles;
  extraTickTexts?: string[];
}

export const PaymentSuccessful: React.FC<PaymentSuccessfulProps> = ({
  children,
  rootUrl,
  hasBackground,
  styles,
  extraTickTexts,
}) => {
  const { t } = useTranslation('sales');

  return (
    <>
      <PaymentSuccessfulHeader styles={styles?.header}>
        <NavLink to={rootUrl} replace><MainLogoNoWhiteSpace /></NavLink>
      </PaymentSuccessfulHeader>
      <PaymentSuccessfulWrapper styles={styles?.paymentWrapper} hasBackground={!!hasBackground}>
        <PaymentSuccessfulTitle>
          {t('paymentSuccessful.title')}
        </PaymentSuccessfulTitle>
        <TextWithCheckmark>
          <TickIcon isGradient={false} />
          <SimpleBlackText>{t('paymentSuccessful.invoice')}</SimpleBlackText>
        </TextWithCheckmark>
        {extraTickTexts?.length
          ? extraTickTexts?.map((item) => (
            <TextWithCheckmark>
              <TickIcon isGradient={false} />
              <SimpleBlackText>{item}</SimpleBlackText>
            </TextWithCheckmark>
          ))
          : null}
        {children}
      </PaymentSuccessfulWrapper>
    </>
  );
};
