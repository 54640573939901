import { Dispatch, SetStateAction, useState } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { payment } from '../../../api/payment/payment';
import { SubscriptionResponse } from '../types/payment';

type ReturnObj = {
  isLoading: boolean;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  secretData: SubscriptionResponse | null;
  getSecretData: (priceId: string) => void;
}

export const useSubscription = (): ReturnObj => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [secretData, setSecretData] = useState<SubscriptionResponse | null>(null);
  const { getToken } = useAuth();
  const getSecretData = async (priceId: string) => {
    setIsLoading(true);

    try {
      const token = (await getToken()) || '';
      const res = await payment.subscribeCreator(priceId, token);
      setSecretData(res.data);
    } catch (err: any) {
      console.log({ err }); setError('Something went wrong. Try it later');
    } finally {
      setIsLoading(false);
    }
  };

  return ({
    isLoading,
    error,
    secretData,
    setError,
    getSecretData,
  });
};
