import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { paymentCurrencyData } from '../../../../../../../constants/selectors';
import { CommonSelect } from '../../../../../../../shared/components/FormElements/CommonSelect/CommonSelect';
import { useGetSelectionItems } from '../../../../../../../shared/hooks/useGetSelectionItems';
import { SimpleBlackText } from '../../../../../../../shared/styles';
import { CreatorForm } from '../../../../../../../shared/types';

export const PaymentCurrency: React.FC = () => {
  const { t } = useTranslation('settings');
  const getSelectionItems = useGetSelectionItems();

  const {
    values, errors, handleChange, touched,
  } = useFormikContext<CreatorForm>();

  const menuItemsPaymentCurrency = useMemo(() => getSelectionItems(paymentCurrencyData), []);

  return (
    <>
      <SimpleBlackText style={{ marginBottom: '10px', display: 'block' }}>{t('paymentCurrency')}</SimpleBlackText>
      <Grid container>
        <Grid item sm={5}>
          <CommonSelect
            error={errors.paymentCurrency}
            value={values.paymentCurrency}
            name="paymentCurrency"
            placeholder="Choose..."
            handleChange={handleChange}
            touched={touched.paymentCurrency}
            menuItems={menuItemsPaymentCurrency}
            valuesArr={paymentCurrencyData}
          />
        </Grid>
      </Grid>
    </>

  );
};
