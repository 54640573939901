import styled from '@emotion/styled';
import { theme } from '../../../../../shared/components/GlobalContainer/styled';

export const GalleryContentContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 20px 59px 0px;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    min-height: 100hv;
    min-height: 100%;
    padding: 0px 20px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    justify-content: center;
    padding: 0px;
    margin-bottom: 70px;
    margin-top: 0px;
    height: calc(100% - 150px);
    min-height: calc(100% - 150px);
  }
`;

export const GallerySwiperFileWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 100%;
    height: auto;  
  }
`;
