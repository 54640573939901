import styled from '@emotion/styled';
import * as variables from '../../../../../constants/styles';
import { ImageSizes, PreviewImageSizes } from '../../../../../constants/galleryPreviewsAndCovers';

export const SixthTitle = styled.div`
  font-size: 28px;
  line-height: 42px;
  color: ${variables.textBlack};
  width: 100%;
  margin-bottom: 7px;

  & h3 {
    width: 100%;
    font-weight: 400;
    margin: 0;
  }
`;

export const SixthMediumText = styled.div`
  font-size: 16px;
  line-height: 150%;
  color: ${variables.textBlack};
  text-align: start;
`;

export const SixthMediumTextCenter = styled(SixthMediumText)`
  text-align: center;
`;

export const SixthImageContainer = styled.div<{ withCropper?: boolean }>`
  width: ${({ withCropper }) => (withCropper ? `${ImageSizes.sixth.desktop.width}px` : '100%')};
  height: ${({ withCropper }) => (withCropper ? `${ImageSizes.sixth.desktop.height}px` : '100%')};
  max-width: 1200px;
  box-sizing: border-box;
  position: relative;
  background-size: cover;
  object-fit: cover;
  display: flex; 
`;

export const SixthInfoCardCover = styled.div<{ currentHeight: number }>`
  position: absolute;
  top: calc(50% - ${(props) => (props.currentHeight ? (props.currentHeight / 2) : '114')}px);
  left: calc(50% - 210px);
  box-sizing: border-box;
  width: 420px;
  min-height: 228px;
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  background: #F8F8F8C2;

  & > div,
  & > span {
    text-align: center;
  }
`;

// top: calc(50% - ${(props) => (props.currentHeight ? (props.currentHeight / 2) : '129')}px);
// left: calc(50% - 266px);

export const SixthInfoCardCoverTablet = styled(SixthInfoCardCover)<{ isPreview: boolean }>`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 46px;
  width: ${({ isPreview }) => (isPreview ? '374px' : '528px')};
  min-height: ${({ isPreview }) => (isPreview ? '178px' : '258px')};
`;

export const ImageContainerSixthTablet = styled(SixthImageContainer)<{ isPreview: boolean }>`
  width: ${({ isPreview }) => (isPreview ? `${PreviewImageSizes.sixth.tablet.width}px` : '100%')};
  height: ${({ isPreview }) => (isPreview ? `${PreviewImageSizes.sixth.tablet.height}px` : 'auto')};
`;

export const SixthTitleTablet = styled(SixthTitle)<{ isPreview: boolean }>`
  font-size: ${({ isPreview }) => (isPreview ? '20px' : '28px')};
  line-height: ${({ isPreview }) => (isPreview ? '30px' : '42px')};  
  margin-bottom: ${({ isPreview }) => (isPreview ? '5px' : '10px')};
`;

export const SixthMediumTextTablet = styled(SixthMediumTextCenter)<{ isPreview: boolean }>`
  font-size: ${({ isPreview }) => (isPreview ? '11px' : '16px')};
  line-height: 140%;

  & p {
    margin: 6px;
  }
`;

export const WrapperMarginBTablet = styled.div<{ isPreview: boolean }>`
  margin-bottom: ${({ isPreview }) => (isPreview ? '10.6px' : '18px')};
`;

export const ImageContainerSixthMobile = styled(SixthImageContainer)<{ isPreview: boolean }>`
  width: ${({ isPreview }) => (isPreview ? `${PreviewImageSizes.sixth.mobile.width}px` : 'fit-content')};
  height: ${({ isPreview }) => (isPreview ? `${PreviewImageSizes.sixth.mobile.height}px` : 'fit-content')};

  &:hover {
    & > div {
        &:last-child {
          display: none;
        }
      }
    }
  }
`;

export const SixthInfoCardCoverMobile = styled(SixthInfoCardCover)<{ isPreview: boolean }>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: ${({ isPreview }) => (isPreview ? '0px 11px 13px' : '0px 20px 24px')};
  justify-content: center;
  
  &:hover {
    display: none;
  }
`;

export const SixthTitleMobile = styled(SixthTitle)<{ isPreview: boolean }>`
  font-size: ${({ isPreview }) => (isPreview ? '15px' : '28px')};
  line-height: 140%;

  & h3 {
    margin-bottom: ${({ isPreview }) => (isPreview ? '10px' : '16px')};
  }
`;

export const SixthMediumTextMobile = styled(SixthMediumTextCenter)<{ isPreview: boolean }>`
  font-size: ${({ isPreview }) => (isPreview ? '8px' : '14px')};
  line-height: 140%;

  & p {
    margin: 5px;
  }
`;

export const MobileWrapperFifth = styled.div`
  margin: 30px 10px 0 10px;
`;

export const SixthDateAndNameWrapper = styled.div<{ isPreview: boolean }>`
  position: absolute;
  bottom: ${({ isPreview }) => (isPreview ? '13px' : '24px')};
`;
