import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useAuth } from '@clerk/clerk-react';
import {
  SimpleBlackText,
  WhiteCardWithShadows,
} from '../../../../../../shared/styles';
import {
  LimitTextWrapper,
  SalesMonthContainer,
  SalesMonthText,
} from '../Sales/styled';
import { SalesChart } from '../Sales/SalesChart/SalesChart';
import {
  GridWithMgBt,
  MainDashboardSalesCardWrapper,
} from './styled';
import { getDataByScale } from '../../../../../../shared/utils/sales/getDataByScale';
import { useGetTabsSales } from '../Sales/useGetTabsSales';
import { BaseTabs } from '../../../../../../shared/components/Tabs/BaseTabs/BaseTabs';
import { TabsWrapper } from '../Sales/SalesChart/styled';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../shared/hooks/useTypedSelector';
import { fetchGetAllSales } from '../../../../redux/sales/sales/allSalesThunk';

export const SalesPart: React.FC = () => {
  const { t } = useTranslation('sales');
  const dispatch = useCreatorAppDispatch();
  const {
    amountsPerDay,
    amountsPerMonth,
    amountsPerWeek,
    netRevenueAllTime,
  } = useTypedSelectorCreator((state) => state.allSales.data);
  const { getToken } = useAuth();

  const [value, setValue] = useState(0);
  const tabs = useGetTabsSales();
  const dataForChart = useMemo(
    () => getDataByScale(value, amountsPerDay, amountsPerWeek, amountsPerMonth),
    [value, amountsPerDay, amountsPerWeek, amountsPerMonth],
  );

  useEffect(() => {
    dispatch(fetchGetAllSales(getToken));
  }, [dispatch]);

  return (
    <GridWithMgBt container spacing={4} justifyContent="space-between">
      <Grid item xl={8} lg={9} md={12}>
        <WhiteCardWithShadows>
          <SalesChart
            chartHeight={180}
            dataForChart={dataForChart}
          >
            <TabsWrapper
              tabButtonWidth={80}
              tabButtonHeight={40}
            >
              <BaseTabs tabs={tabs} value={value} setValue={setValue} />
            </TabsWrapper>
          </SalesChart>
        </WhiteCardWithShadows>
      </Grid>
      <Grid item xl={4} lg={3} md={12}>
        <WhiteCardWithShadows fullHeight style={{ justifyContent: 'center', alignItems: 'center' }}>
          <MainDashboardSalesCardWrapper>
            <SalesMonthContainer style={{ justifyContent: 'center' }}>
              <SalesMonthText style={{ color: '#F180B2', marginBottom: '20px' }}>
                $
                {netRevenueAllTime}
              </SalesMonthText>
            </SalesMonthContainer>
            <LimitTextWrapper style={{ textAlign: 'center' }}>
              <SimpleBlackText fontWeight={500}>{t('netRevenue')}</SimpleBlackText>
            </LimitTextWrapper>
            <LimitTextWrapper style={{ textAlign: 'center', marginTop: '5px' }}>
              <SimpleBlackText fontWeight={500}>{t('allTime')}</SimpleBlackText>
            </LimitTextWrapper>
          </MainDashboardSalesCardWrapper>
        </WhiteCardWithShadows>
      </Grid>
    </GridWithMgBt>
  );
};
