import { createApi } from '@reduxjs/toolkit/query/react';
import { GalleryForRequest } from '../../../../shared/types';
import { baseQueryWithReauth } from '../../../../api/base/baseQuery';

export type RequestSuccess = {
  galleries: GalleryForRequest[];
  draftGalleries: GalleryForRequest[];
  activeGalleries: GalleryForRequest[];
};

const galleryBaseUrl = 'gallery';

export const galleryApi = createApi({
  reducerPath: 'galleryApi',
  tagTypes: ['Galleries'],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllGalleries: builder.query<RequestSuccess, any>({
      query: () => ({
        url: `${galleryBaseUrl}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 2,
      providesTags: () => [{ type: 'Galleries' }],
      transformResponse: (res: GalleryForRequest[]) => ({
        galleries: res || [],
        activeGalleries:
          res?.filter(
            (item) => item.status === 'active' || item.status === 'inactive',
          ) || [],
        draftGalleries: res?.filter((item) => item.status === 'draft') || [],
      }),
    }),
    deleteGallery: builder.mutation<RequestSuccess, { id: string }>({
      query: ({ id }) => ({
        url: `${galleryBaseUrl}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'Galleries' }],
    }),
    updateGallery: builder.mutation<
      RequestSuccess,
      Partial<{ id: string; gallery: GalleryForRequest }>
    >({
      query: ({ id, gallery }) => ({
        url: `${galleryBaseUrl}/${id}`,
        method: 'PUT',
        body: gallery,
      }),
      invalidatesTags: () => [{ type: 'Galleries' }],
    }),
    createGalleryById: builder.mutation<RequestSuccess, { id: string }>({
      query: (data) => ({
        url: `${galleryBaseUrl}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'Galleries' }],
    }),
  }),
});

export const {
  useGetAllGalleriesQuery,
  useCreateGalleryByIdMutation,
  useDeleteGalleryMutation,
  useUpdateGalleryMutation,
} = galleryApi;
