import React, { useContext, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { FormikHelpers, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as urls from '../../../../constants/urls';
import {
  ButtonSubmitSignUp, ButtonWrapper, LittleText, TextNote, TitleText,
} from './styled';
import { CommonInput } from '../../../../shared/components/FormElements/CommonInput/CommonInput';
import { SignUpEmail } from '../../../../shared/validation/signInUp/creator/signInUpSchemas';
import {
  CustomGridWithoutPadding,
  FormBase,
  SignInUpCardColorful,
} from '../../../../shared/styles';
import { useTypedSelectorCreator } from '../../../../shared/hooks/useTypedSelector';
import { PinkLoader } from '../../../../shared/components/Loaders/PinkLoader/PinkLoader';

type SignUpEmailConfirmationProps = {
  isLoading: boolean;
  sendEmail: (data: { email: string },
    bag: FormikHelpers<{ email: string }>) => void;
};

export const SignUpEmailConfirmation: React.FC<SignUpEmailConfirmationProps> = ({
  isLoading,
  sendEmail,
}) => {
  const { t } = useTranslation(['signUp', 'basicComponents']);
  const signUpError = useTypedSelectorCreator((state) => state.signUpCreator.error);

  const onSubmit = async (
    data: { email: string },
    bag: FormikHelpers<{ email: string }>,
  ) => {
    bag.setSubmitting(false);
    sendEmail(data, bag);
  };

  const {
    values,
    handleSubmit,
    errors,
    handleChange,
    touched,
    handleBlur,
    setFieldError,
  } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: SignUpEmail,
    onSubmit: (data, bag) => onSubmit(data, bag),
  });

  useEffect(() => {
    const emailError = signUpError?.errors[0];
    if (emailError) {
      setFieldError('email', emailError.message);
    }
  }, [signUpError]);

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xl={5} lg={6} md={8} xs={11}>
        <SignInUpCardColorful>
          <TitleText>{t('callForSignUp')}</TitleText>
          <LittleText>
            Already have an account?
            <Link to={urls.creatorSignInUrl}>{t('basicComponents:signIn')}</Link>
          </LittleText>
          <FormBase theme="row">
            <form onSubmit={handleSubmit} noValidate>
              <Grid container>
                <CustomGridWithoutPadding item xs={11}>
                  <CommonInput
                    error={errors.email}
                    value={values.email}
                    name="email"
                    handleChange={handleChange}
                    type="email"
                    placeholder="Your email"
                    touched={touched.email}
                    handleBlur={handleBlur}
                  />
                </CustomGridWithoutPadding>
              </Grid>
              <ButtonWrapper>
                <ButtonSubmitSignUp type="submit" disabled={isLoading}>
                  {isLoading ? (<PinkLoader size="xs" />) : t('basicComponents:signUp')}
                </ButtonSubmitSignUp>
              </ButtonWrapper>
            </form>
          </FormBase>
        </SignInUpCardColorful>
      </Grid>
      {/* <Grid container justifyContent="center">
        <TextNote>{t('noCreditCard')}</TextNote>
      </Grid> */}
    </Grid>
  );
};
