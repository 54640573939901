import React, { ChangeEvent } from 'react';
import { PlusIcon } from '../../../modules/Creator/containers/CreatorAccount/Tabs/NewGalleryCreator/NewGalleryCreatorSecondStep/styled';
import { SetFieldValue } from '../../types';
import { MiniChooseFileCard } from '../GalleryCreator/styled';
import { FileLoader } from '../Loaders/FileLoader/FileLoader';
import { ErrorMessage } from '../styled';

type MiniFileUploadProps = {
  value: string;
  name: string;
  setFieldValue: SetFieldValue;
  disabled?: boolean;
  handleUpload?: (files: File[]) => void;
  isLoading?: boolean;
  error?: string;
  touched?: boolean;
}

export const MiniFileUpload: React.FC<MiniFileUploadProps> = ({
  value,
  name,
  setFieldValue,
  disabled,
  handleUpload,
  isLoading,
  error,
  touched,
}) => {
  const handleClick = (files: File[], e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, null);
    e.preventDefault();

    if (files.length > 0) {
      // setFieldValue(name, getURLByFile(files[0]));
      if (handleUpload) {
        handleUpload(files);
      }
    }
  };

  return (
    <MiniChooseFileCard imageUrl={value} hasFile={!!value}>
      <PlusIcon id="plus-icon" theme="small" />
      <input
        disabled={disabled}
        name={name}
        type="file"
        accept="image/*"
        onChange={(e) => handleClick(Array.from(e.target.files || []), e)}
      />
      <FileLoader isLittle isLoading={!!isLoading} />
      <ErrorMessage>{error && touched ? error : null}</ErrorMessage>
    </MiniChooseFileCard>
  );
};
