import styled from '@emotion/styled';
import { theme } from '../../../../../shared/components/GlobalContainer/styled';
import { PrimaryWhiteButton } from '../../../../../shared/styles';

export const ContentActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 40px;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin-bottom: 40px;
    width: fit-content;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-bottom: 0px;
  }
`;

export const PrimaryWhiteButtonMgR = styled(PrimaryWhiteButton)`
  margin-right: 60px;
  position: relative;

  & > div {
    top: calc(100% + 14px);
    left: 50%;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin-right: 20px;
  }
`;

export const GallerySwiperBtnDownWrpMobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;

  & button {
    &:first-of-type {
      margin-right: 16px;
    }
  }
`;
