import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { NewGalleryCreatorFirstStep } from '../NewGalleryCreatorFirstStep/NewGalleryCreatorFirstStep';
import { NewGalleryCreatorFourthStep } from '../NewGalleryCreatorFourthStep/NewGalleryCreatorFourthStep';
import { NewGalleryCreatorSecondStep } from '../NewGalleryCreatorSecondStep/NewGalleryCreatorSecondStep';
import { NewGalleryCreatorThirdStep } from '../NewGalleryCreatorThirdStep/NewGalleryCreatorThirdStep';
import { NewGalleryCreatorFifthStep } from '../NewGalleryCreatorFifthStep/NewGalleryCreatorFifthStep';

type ReturnStepAndText = {
  stepContent: ReactNode;
  text: string;
}

export const useStepContentAndTitle = (step: number): ReturnStepAndText => {
  const { t } = useTranslation('createNewGallery');
  switch (step) {
    case 1:
      return {
        stepContent: <NewGalleryCreatorFirstStep />,
        text: t('firstStep.title'),
      };
    case 2:
      return {
        stepContent: <NewGalleryCreatorSecondStep />,
        text: t('secondStep.title'),
      };
    case 3:
      return {
        stepContent: <NewGalleryCreatorThirdStep />,
        text: t('thirdStep.title'),
      };
    case 4:
      return {
        stepContent: <NewGalleryCreatorFourthStep />,
        text: t('fourthStep.title'),
      };
    case 5:
      return {
        stepContent: <NewGalleryCreatorFifthStep />,
        text: t('fifthStep.title'),
      };
    default:
      return {
        stepContent: <NewGalleryCreatorFirstStep />,
        text: t('firstStep.title'),
      };
  }
};
