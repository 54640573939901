import styled from '@emotion/styled';
import { galleryGap } from '../../../../../constants/styles';
import { theme } from '../../../../../shared/components/GlobalContainer/styled';
import { DocumentsListWrapper } from '../../../../GalleryView/components/GalleryContent/styled';

export const optimizationStylesMiniFileCard = `
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin-right: 20px;
    margin-bottom: 20px;
    width: 167px;
    height: 167px;
    border-radius: 16px;
    font-size: 10px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-right: 8px;
    margin-bottom: 8px;
    width: 187px;
    height: 187px;
    border-radius: 16px;
    font-size: 10px;

    & > svg {
      width: 35px;
      height: 40px;
    }
  }
`;

export const FavouritesList = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  column-gap: ${galleryGap}px;
  row-gap: ${galleryGap}px;

  & img {
    width: 100%;
  }
  & video {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    grid-template-columns: repeat(4, 167px);
    grid-template-rows: 167px;
    grid-gap: 20px 20px;
    grid-auto-rows: unset;
    grid-auto-columns: 167px;
    margin-bottom: 20px;

    & > div {
      margin-bottom: 0px;
    }
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    grid-template-columns: calc(50% - 4px) calc(50% - 4px);
    grid-template-rows: 187px;
    grid-gap: 8px 8px;
    grid-auto-columns: auto;
    margin-bottom: 8px;
  }
`;

export const FileMasonryContainer = styled.div`
  margin-bottom: 20px;

  @media (max-width: ${theme.breakpoints.values.lg - 1}px) {
    margin-bottom: 10px;
  }

  @media (max-width: ${theme.breakpoints.values.md - 1}px) {
    margin-bottom: 0px;
  }
`;

export const FileWrapper = styled.div`
  position: relative;

  & > div {
    width: 100%;
    height: 100%;  
  }

  & video {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    & div {
      margin-bottom: 0px;
    }
  }
`;

export const CustomCheckboxPosition = styled.div`
  position: absolute;
  top: 4px;
  left: 6px;
`;

export const FavouritesDocumentsListWrapper = styled(DocumentsListWrapper)`
  margin-right: 0px;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    display: none;
  }
`;

export const FavouritesFileWrapper = styled.div`
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    & img,
    & video {
      max-width: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & > div {
      width: 100%;
      height: 167px;

      & > div {
        width: 100%;
        height: 100%;
      }
    }

    & > div {
      margin-bottom: 0px;
    }

    & #basic-doc {
      margin-right: 0;
      margin-bottom: 0;
      width: 167px;
      height: 167px;
      border-radius: 16px;
      font-size: 10px;

      & > svg {
        width: 35px;
        height: 40px;
      }
    }
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    & img,
    & video {
      max-width: none;
    }

    & > div {
      width: 100%;
      height: 187px;
    }

    & > div {
      margin-bottom: 0px;
    }

    & #basic-doc {
      width: 187px;
      height: 187px;
    }
  }
`;
