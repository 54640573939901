import React from 'react';
import { DeleteButton, ErrorTryAgainContainer, ErrorTryAgainText } from './styled';
import { RefreshIcon, DeleteIcon } from '../../../../../../../../shared/icons/CommonIcons';
import { ErrorTryAgainButtons } from '../styled';
import { ErrorMessage } from '../../../../../../../../shared/components/styled';

type ErrorTryAgainProps = {
  error: boolean;
  fileName: string;
  size: 'sm' | 'lg';
  handleDelete: () => void;
  handleTryAgain: () => void;
  errorMessage?: string;
}

export const ErrorTryAgain: React.FC<ErrorTryAgainProps> = ({
  error,
  fileName,
  size,
  handleDelete,
  handleTryAgain,
  errorMessage,
}) => {
  if (!error) {
    return null;
  }

  return (
    <ErrorTryAgainContainer size={size}>
      {size !== 'sm' ? <ErrorTryAgainText size={size} type="text">{fileName}</ErrorTryAgainText> : null}
      {size !== 'sm' && errorMessage ? <ErrorMessage style={{ marginTop: '10px' }}>{errorMessage}</ErrorMessage> : null}
      <ErrorTryAgainButtons size={size}>
        <button type="button" aria-label="Refresh" onClick={handleTryAgain}><RefreshIcon /></button>
        <DeleteButton size={size} type="button" onClick={handleDelete}><DeleteIcon /></DeleteButton>
      </ErrorTryAgainButtons>
    </ErrorTryAgainContainer>
  );
};
