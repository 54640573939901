import { Grid } from '@mui/material';
import React, { ReactElement } from 'react';
import { LandingContentContainerWrap } from './styled';

type LandingContentContainerProps = {
  children: ReactElement;
  id?: string;
}

export const LandingContentContainer: React.FC<LandingContentContainerProps> = ({
  children, id = '',
}) => (
  <LandingContentContainerWrap>
    <Grid container spacing={0} justifyContent="center" id={id}>
      <Grid item xl={10} lg={11} md={12} style={{ width: '100%' }}>
        {children}
      </Grid>
    </Grid>
  </LandingContentContainerWrap>
);
