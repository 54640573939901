import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  TermsAndPrivacyListTitle,
  TermsAndPrivacyText,
  TermsAndPrivacyListItem,
  TermsAndPrivacyListWrapper,
  TermsAndPrivacyListNoMarkers,
} from '../styled';

export const InfoWeCollectPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <>
      <TermsAndPrivacyListTitle>
        {t('privacy.infoWeCollect')}
      </TermsAndPrivacyListTitle>
      <TermsAndPrivacyText hasMarginBottom>
        <Trans t={t} i18nKey="privacy.personalInfo">
          <span className="bold">Personal information:</span>
          {' '}
          is information or
          an opinion, whether true or not and whether recorded in a material
          form or not, about an individual who is identified or reasonably
          identifiable.
        </Trans>
      </TermsAndPrivacyText>
      <TermsAndPrivacyText hasMarginBottom>
        {t('privacy.typesOfPersonalInfo')}
        <TermsAndPrivacyListNoMarkers>
          <TermsAndPrivacyListItem listMarker="none">
            <Trans t={t} i18nKey="privacy.asAContentEditor">
              As a content editor user of our software and/or platform (
              <span className="bold">Content Editor User</span>
              ):
            </Trans>
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListWrapper hasBigBottomMargin>
            <Trans t={t} i18nKey="privacy.asAContentEditorPoints">
              <TermsAndPrivacyListItem listMarker="disc">
                your first and last name;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                your contact details including your work email address and/or
                telephone num ber;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                your username and password;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                the organisation your work for and your role;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                your credit card or other payment details (through our third
                party payment processor);
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                your preferences and/or opinions;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                information you provide to us, including through feedback,
                customer surveys or otherwise;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                your sensitive information as set out below;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                details of products and services we have provided to you and/or
                that you have enquired about, and our response to you;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                support requests submitted to us and our response to you;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                information about your access and use of our Services, including
                through the use of Internet cookies, your communications with
                our online Services, the type of browser you are using, the type
                of operating system you are using and the domain name of your
                Internet service provider;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                additional personal information that you provide to us, directly
                or indirectly, through your use of our Services, associated
                applications, associated social media platforms and/or accounts
                from which you permit us to collect information;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                any other personal information requested by us and/or provided
                by you or a third party;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                your browser session and geo-location data, device and network
                information, statistics on page views and sessions, acquisition
                sources, search queries and/or browsing behavior.
              </TermsAndPrivacyListItem>
            </Trans>
          </TermsAndPrivacyListWrapper>
          <Trans t={t} i18nKey="privacy.asCustomer">
            <TermsAndPrivacyListItem listMarker="none">
              As a customer of a Content Editor User when you visit our website
              platform and/or use our website platform to purchase pay for
              and/or download content (
              <span className="black">Customer User</span>
              ):
            </TermsAndPrivacyListItem>
            <TermsAndPrivacyListWrapper hasBigBottomMargin>
              <TermsAndPrivacyListItem listMarker="disc">
                your name; and
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                your contact details, including your work email address and/or
                telephone number;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                and your username and password;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                the organisation your work for and your role;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                your credit card or other payment details (through our third
                party payment processor);
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                your preferences and/or opinions;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                personal information including images of you that you provide to
                the Content Editor User or give the Content Editor User
                permission to use;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                personal information you provide to us, including through
                feedback, customer surveys or otherwise;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                your sensitive information as set out below;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                details of products and services we or the Content Editor User
                via our platform have provided to you and/or that you have
                enquired about, and our response or the Content Editor
                User&apos;s response to you via our platform;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                support requests submitted to us and our response to you;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                your browser session and geo-location data, device and network
                information, statistics on page views and sessions, acquisition
                sources, search queries and/or browsing behaviour;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                information about your access and use of our Services, including
                through the use of Internet cookies, your communications with
                our online Services, the type of browser you are using, the type
                of operating system you are using and the domain name of your
                Internet service provider;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                additional personal information that you provide to us, directly
                or indirectly, through your use of our Services, associated
                applications, associated social media platforms and/or accounts
                from which you permit us to collect information; and
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                any other personal information requested by us and/or provided
                by you or a third party.
              </TermsAndPrivacyListItem>
            </TermsAndPrivacyListWrapper>
          </Trans>
          <Trans t={t} i18nKey="privacy.whenYouVisit">
            <TermsAndPrivacyListItem listMarker="none">
              When you (any person) visit our website:
            </TermsAndPrivacyListItem>
            <TermsAndPrivacyListWrapper hasBigBottomMargin>
              <TermsAndPrivacyListItem listMarker="disc">
                information you provide to us, including through feedback,
                customer surveys or otherwise;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                your sensitive information as set out below;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                details of products and services we have provided to you and/or
                that you have enquired about, and our response to you;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                support requests submitted to us and our response to you;
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                your browser session and geo-location data, device and network
                information, statistics on page views and sessions, acquisition
                sources, search queries and/or browsing behaviour; and
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                information about your access and use of our Services, including
                through the use of Internet cookies, your communications with
                our online Services, the type of browser you are using, the type
                of operating system you are using and the domain name of your
                Internet service provider.
              </TermsAndPrivacyListItem>
            </TermsAndPrivacyListWrapper>
          </Trans>
        </TermsAndPrivacyListNoMarkers>
        <Trans t={t} i18nKey="privacy.sensitiveInfo">
          <TermsAndPrivacyText hasMarginBottom>
            <span className="bold">Sensitive information:</span>
            {' '}
            is a sub-set of
            personal information that is given a higher level of protection.
            Sensitive information means information relating to your racial or
            ethnic origin, political opinions, religion, trade union or other
            professional associations or memberships, philosophical beliefs,
            sexual orientation or practices, criminal records, health
            information or biometric information.
          </TermsAndPrivacyText>
          <TermsAndPrivacyText hasMarginBottom>
            We do not actively request sensitive information about you. If at
            any time we need to collect sensitive information about you, unless
            otherwise permitted by law, we will first obtain your consent and we
            will only use it as required or authorised by.
          </TermsAndPrivacyText>
          <TermsAndPrivacyText>
            Whilst we do not actively request sensitive information about you,
            if you are a Customer User and use our website platform to purchase
            and/or download content, sensitive information belonging to you (a
            Customer User) may be indirectly obtained by us via the personal
            information (including images of you) that you provide to the
            Content Editor User or give the Content Editor User permission to
            use. You should confirm with the relevant Content Editor User the
            privacy policy of the Content Editor User.
          </TermsAndPrivacyText>
        </Trans>
      </TermsAndPrivacyText>
    </>
  );
};
