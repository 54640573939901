import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetToken } from '@clerk/types';
import { payment } from '../../../../api/payment/payment';

export const fetchGetBillingInfo = createAsyncThunk(
  'invoices/fetchGetBillingInfo',
  async (getToken: GetToken, thunkApi) => {
    try {
      const token = (await getToken() || '');
      const response = await payment.getBillingInfo(token);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
