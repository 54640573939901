import React, { ReactNode } from 'react';
import { LandingNumberedLiWrapper, TermsAndPrivacyListItem, TermsAndPrivacyText } from './styled';

export const LandingNumberedLi: React.FC<{
  children: ReactNode;
  isTitle?: boolean;
}> = ({ children, isTitle }) => (
  <LandingNumberedLiWrapper isTitle={isTitle}>
    <TermsAndPrivacyListItem hasCounter isTitle={isTitle}>
      <span id="number" />
    </TermsAndPrivacyListItem>
    <TermsAndPrivacyText style={{ lineHeight: '140%' }}>
      {children}
    </TermsAndPrivacyText>
  </LandingNumberedLiWrapper>
);
