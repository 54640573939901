import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { useAuth, useSession } from '@clerk/clerk-react';
import {
  TabContentWrapper,
  PrimaryWhiteButton,
} from '../../../../../../shared/styles';
import { UnderlineTabs } from '../../../../../../shared/components/Tabs/UnderlineTabs/UnderlineTabs';
import { SettingsWrapper } from './styled';
import { ProfileTab } from './ProfileTab/ProfileTab';
import { useGetTabsSettings } from './ProfileTab/useGetTabsSettings';
import { UsageAndBilling } from './UsageAndBilling/UsageAndBilling';
import { AdvancedSettings } from './AdvancedSettings/AdvancedSettings';
import { StyledSettingsTab } from './SettingsTabs/StyledSettingsTab';
import { SettingsTabPanel } from './SettingsTabs/SettingsTabPanel';
import { SettingsContainer } from './SettingsContainer';
import { creatorInitialValues } from '../../../../../../constants/initialValues';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../shared/hooks/useTypedSelector';
import { CreatorForm } from '../../../../../../shared/types';
import { fetchCurrentUserCreator, fetchUpdateProfile } from '../../../../redux/userCreator/creator/creatorThunk';
import { PinkLoader } from '../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { CoverWindow } from '../../../../../../shared/components/LoadingForRequest/styled';
import { SettingsAlerts } from './SettingsAlerts';
import { getSettingsPutData } from '../../../../utils/helpers';
import { fetchGetBillingInfo } from '../../../../redux/invoices/billingInfoThunk';

export const Settings: React.FC = () => {
  const { t } = useTranslation(['settings', 'basicComponents']);
  const [value, setValue] = useState(0);
  const dispatch = useCreatorAppDispatch();
  const { getToken } = useAuth();

  const currentUser = useTypedSelectorCreator(
    (state) => state.creator.currentUser,
  );
  const isLoading = useTypedSelectorCreator(
    (state) => state.creator.localLoading,
  );
  const isLoadingUser = useTypedSelectorCreator(
    (state) => state.creator.loading,
  );

  const onSubmit = (data: CreatorForm, bag: any) => {
    bag.setSubmitting(false);
    const res = getSettingsPutData(data);
    dispatch(fetchUpdateProfile({ profile: { ...res }, getToken }));
  };

  useEffect(() => {
    dispatch(fetchCurrentUserCreator(getToken));
    dispatch(fetchGetBillingInfo(getToken));
  }, [dispatch]);

  const settingsTabs = useGetTabsSettings();
  const initialValues = creatorInitialValues(currentUser);

  return (
    <Formik<CreatorForm>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(data: CreatorForm, bag) => onSubmit(data, bag)}
    >
      <Form>
        <SettingsContainer>
          <>
            <TabContentWrapper style={{ marginBottom: '50px' }}>
              <UnderlineTabs
                value={value}
                setValue={setValue}
                tabsElement={settingsTabs.map((item) => (
                  <StyledSettingsTab
                    tabsnumber={settingsTabs.length}
                    key={item.id}
                    label={item.label}
                    id={item.id}
                  />
                ))}
              />
              <SettingsWrapper>
                <SettingsTabPanel
                  value={value}
                  index={0}
                >
                  <ProfileTab />
                </SettingsTabPanel>
                <SettingsTabPanel
                  value={value}
                  index={1}
                >
                  <UsageAndBilling />
                </SettingsTabPanel>
                <SettingsTabPanel
                  value={value}
                  index={2}
                >
                  <AdvancedSettings />
                </SettingsTabPanel>
                {/* //! hide for now */}
                {/* <SettingsTabPanel
              value={value}
              index={3}
            >
              <ReferFriendTab />
            </SettingsTabPanel> */}
              </SettingsWrapper>
            </TabContentWrapper>
            <PrimaryWhiteButton color="pink" type="submit" disabled={isLoading}>
              {t('basicComponents:buttons.saveChanges')}
            </PrimaryWhiteButton>
            {isLoading && (
            <CoverWindow>
              <PinkLoader />
            </CoverWindow>
            )}
            <SettingsAlerts />
          </>
        </SettingsContainer>
      </Form>
    </Formik>

  );
};
