export type Statistics = {
  views: number;
  likes: number;
  downloads: number;
  shares: number;
  sales: number;
  ideas: number;
};

export type PlanTypes = 'free' | 'basic' | 'pro' | 'pro +' | 'beta';
export enum PlanEnum {
  Beta = 'beta',
  Free = 'free',
  Basic = 'basic',
  Pro = 'pro',
  ProPlus = 'pro +',
}

export type BillType = 'annually' | 'monthly' | 'free';

export type Plan = {
  planType: PlanTypes;
  current: boolean;
  priceMonthly: number;
  priceAnnually: number;
  description: string;
  details: string[];
  storage: number;
};

export type PlanDetail = {
  main: string;
  extra?: string;
};

export type BetaPlan = {
  planType: PlanTypes;
  price: number;
  code: string;
  discount: number;
  details: (PlanDetail)[];
  storage: number;
};

export type ChosenPlan = {
  planType: PlanTypes;
  cost: number;
  isAnnually: boolean;
  monthlyCost: number;
};
