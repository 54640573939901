import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  TermsAndPrivacyListTitle,
  TermsAndPrivacyText,
  TermsAndPrivacyListItem,
  TermsAndPrivacyListNoMarkers,
} from '../styled';

export const HowCollectInfoPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <>
      <TermsAndPrivacyListTitle>
        {t('privacy.howCollectInfo')}
      </TermsAndPrivacyListTitle>
      <Trans t={t} i18nKey="privacy.howCollectInfoPoints">
        <TermsAndPrivacyText>
          We collect personal information in a variety of ways, including:
        </TermsAndPrivacyText>
        <TermsAndPrivacyListNoMarkers>
          <TermsAndPrivacyListItem listMarker="none">
            <span className="bold">Directly:</span>
            {' '}
            We collect personal
            information which you directly provide to us, including when you
            register for a Content Ed itor User or Customer account with us,
            through the ’contact us’ form on our website or when you request our
            assistance via email, or over the telephone or use of our Services.
            Additionally, we may collect payment information which you directly
            provide to us, including through your completion of any direct debit
            form provided by us or you entering an agreement with us for the
            supply of our Services.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="none">
            <span className="bold">Indirectly:</span>
            {' '}
            We may collect personal
            information which you indirectly provide to us while interacting
            with us, such as when you use our website, in emails, over the
            telephone and in your online enquiries.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="none">
            <span className="bold">From third parties:</span>
            {' '}
            We collect
            personal information from third parties, such as details of your use
            of our website from our analytics and cookie providers and marketing
            providers.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="none" style={{ marginBottom: '0px' }}>
            Additionally, if you are a Customer User and use our platform to
            purchase and/or download content, we may indirectly collectpersonal
            information via the personal information (including images of you)
            that you provide to the Content Editor User or give the Content
            Editor User permission to use.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyListNoMarkers>
      </Trans>
    </>
  );
};
