import React from 'react';
import { useTranslation } from 'react-i18next';
import { LittleBlackText } from '../../../../../../../../shared/styles';
import { PreviewEmailTooltipContainer } from './styled';

export const PreviewTooltip: React.FC = () => {
  const { t } = useTranslation('createNewGallery');

  return (
    <PreviewEmailTooltipContainer>
      <LittleBlackText>
        {t('fourthStep.reminder.previewEmail.tooltip')}
      </LittleBlackText>
    </PreviewEmailTooltipContainer>
  );
};
