import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import * as urls from '../../../../constants/urls';
import { TickIcon } from '../../../../shared/icons';
import {
  GreyLink,
  TextWithIcon,
  TitleSimple,
} from '../../../../shared/styles';
import { fetchResetPasswordInitialCreator } from '../../redux/userCreator/resetPassword/resetPasswordCreatorThunk';
import {
  ResetButtonsWrapper, ResetLittleText, ResetPasswordCard, ResetSuccessButton,
} from './styled';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../shared/hooks/useTypedSelector';
import { ErrorMessage } from '../../../../shared/components/styled';
import { setTimeToResendData } from '../../utils/helpers';
import { useTimer } from '../../../../shared/hooks/useTimer';
import { ResendButton } from '../../../../shared/components/ResendButton/ResendButton';
import { LittleText } from '../SignUp/styled';

export const ResetSuccessResendLink: React.FC = () => {
  const { t } = useTranslation(['signIn', 'basicComponents']);
  const navigate = useNavigate();
  const dispatch = useCreatorAppDispatch();
  const isLoading = useTypedSelectorCreator((state) => state?.resetPassword?.loading);
  const wasSent = useTypedSelectorCreator((state) => state?.resetPassword?.emailWasSent);
  const { msCounter, startNewTimer } = useTimer();

  useEffect(() => {
    if (wasSent) {
      startNewTimer();
    }
  }, [wasSent]);

  const handleResend = () => {
    const email = window.sessionStorage.getItem('resetEmail');
    if (email) {
      setTimeToResendData();
      dispatch(fetchResetPasswordInitialCreator(email));
    } else {
      navigate(urls.resetUrl);
    }
  };

  return (
    <ResendButton
      buttonText={t('resendCode')}
      msCounter={msCounter}
      isLoading={isLoading}
      handleResend={handleResend}
    />
  );
};

export const ResetSuccess: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['signIn', 'basicComponents']);
  const error = useTypedSelectorCreator((state) => state?.resetPassword?.error);

  const onBack = () => {
    navigate(urls.creatorSignInUrl);
  };

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item lg={6} md={9} xs={11}>
        <ResetPasswordCard theme="">
          <TitleSimple style={{ textAlign: 'center' }}>{t('resetPassword')}</TitleSimple>
          <TextWithIcon>
            {error ? <ErrorMessage style={{ fontSize: 'inherit' }}>{error.errors.map((item) => item.message)}</ErrorMessage>
              : (
                <>
                  <TickIcon />
                  <span style={{ textAlign: 'center' }}>{t('codeSent')}</span>
                </>
              )}
          </TextWithIcon>
          <ResetButtonsWrapper>
            <ResetSuccessResendLink />
            <ResetSuccessButton color="pink" type="button" width="213" onClick={() => navigate(urls.resetConfirmUrl)}>
              Reset password
            </ResetSuccessButton>
          </ResetButtonsWrapper>
          <GreyLink>
            <Link to={urls.creatorSignInUrl}>Back to Sign in</Link>
          </GreyLink>
        </ResetPasswordCard>
      </Grid>
    </Grid>
  );
};
