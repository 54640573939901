/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth, useUser } from '@clerk/clerk-react';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../shared/hooks/useTypedSelector';
import { CreatorOnboardingContext } from '../context/CreatorOnboardingContext';
import { CreatorOnboardingStepIndexContext } from '../context/CreatorOnboardingStepIndexContext';
import { Creator } from './Creator';
import { MainLoader } from '../../../shared/components/Loaders/MainLoader/MainLoader';
import { ErrorCode } from '../types/types';
import { interceptorCreator } from '../../../api/base/interceptors';
import { fetchCurrentUserCreator } from '../redux/userCreator/creator/creatorThunk';

interceptorCreator();

export const CreatorContainer: React.FC = () => {
  const [isLoadingComponent, setIsLoadingComponent] = useState<boolean>(true);
  const [isStudy, setIsStudy] = useState<boolean>(false);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const { isLoaded } = useUser();
  const { getToken, isSignedIn } = useAuth();
  const dispatch = useCreatorAppDispatch();
  const currentUser = useTypedSelectorCreator((state) => state.creator.currentUser);
  const currentUserErrorCode = useTypedSelectorCreator((state) => state.creator.getErrorCode);
  const userLoading = useTypedSelectorCreator((state) => state.creator.loading);

  useEffect(() => {
    if (isLoaded) {
      dispatch(fetchCurrentUserCreator(getToken));
    }
  }, [isLoaded, isSignedIn]);

  useEffect(() => {
    if (!userLoading && isLoaded) {
      setIsLoadingComponent(false);
    }
  }, [userLoading, isLoaded]);

  useEffect(() => {
    // condition for beta
    if (
      window.localStorage.getItem('onboarded') === 'false'
      && currentUser?.subscribed
      && currentUser
    ) {
      setIsStudy(true);
    }
  }, [currentUser]);

  if (isLoadingComponent) {
    return <MainLoader />;
  }

  if (currentUserErrorCode === ErrorCode.Beta) {
    return (
      <Navigate
        state="beta"
        to="/"
      />
    );
  }

  return (
    <CreatorOnboardingContext.Provider value={{ isStudy, setIsStudy }}>
      <CreatorOnboardingStepIndexContext.Provider
        value={{ stepIndex, setStepIndex }}
      >
        <Creator />
      </CreatorOnboardingStepIndexContext.Provider>
    </CreatorOnboardingContext.Provider>
  );
};
