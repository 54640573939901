import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreatorProfileGet, ErrorType,
} from '../../../../../shared/types';
import { fetchCurrentUserCreator, fetchUpdateProfile } from './creatorThunk';
import { ErrorCode } from '../../../types/types';

type initialState = {
  currentUser: CreatorProfileGet | null;
  loading: boolean;
  getError: ErrorType;
  getErrorCode: ErrorCode | null;
  error: ErrorType;
  localLoading: boolean;
  isSuccess: boolean;
};

export const initialState: initialState = {
  currentUser: null,
  loading: true,
  error: null,
  getError: null,
  localLoading: false,
  isSuccess: false,
  getErrorCode: null,
};

const creatorSlice = createSlice({
  name: 'creator',
  initialState,
  reducers: {
    unsetError(state) {
      state.error = null;
    },
    unsetSuccess(state) {
      state.isSuccess = false;
    },
    setErrorBetaCode(state) {
      state.getErrorCode = ErrorCode.Beta;
    },
    unsetErrorBetaCode(state) {
      state.getErrorCode = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUserCreator.fulfilled, (state, action) => {
      state.currentUser = action.payload;
      state.getError = null;
      state.getErrorCode = null;
      state.loading = false;
    });
    builder.addCase(
      fetchCurrentUserCreator.rejected,
      (state, action: PayloadAction<any>) => {
        state.getError = action.payload?.message || 'Something went wrong';
        state.loading = false;
        state.currentUser = null;
      },
    );
    builder.addCase(fetchCurrentUserCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUpdateProfile.fulfilled, (state, action) => {
      state.currentUser = {
        ...state.currentUser, ...action.payload,
      } as CreatorProfileGet;
      state.error = null;
      state.localLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(
      fetchUpdateProfile.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.localLoading = false;
        state.isSuccess = false;
        // state.currentUser = null;
      },
    );
    builder.addCase(fetchUpdateProfile.pending, (state) => {
      state.localLoading = true;
      state.isSuccess = false;
      state.error = null;
    });
  },
});

export const {
  unsetError, unsetSuccess, setErrorBetaCode, unsetErrorBetaCode,
} = creatorSlice.actions;

export default creatorSlice.reducer;
