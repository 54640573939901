import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as variables from '../../../constants/styles';

export const AlertDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: 350,
    maxWidth: 400,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '320px',
      minWidth: 280,
    },
  },
  '& .MuiDialog-paper': {
    background: variables.colorGreyLight,
    padding: '26px 16px',
    [theme.breakpoints.down('sm')]: {
      padding: '14px 0px',
    },
  },
}));
