import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomFile, ErrorType } from '../../../../../shared/types';
import { fetchTryAgainFiles } from './uploadFilesThunk';

type initialState = {
  isSuccess: boolean;
  loadingFiles: string[];
  error: ErrorType[];
  uploadedFiles: CustomFile[];
  progress: number;
  loading: boolean;
  uploadingField: string;
};

export const initialState: initialState = {
  isSuccess: false,
  loadingFiles: [],
  error: [],
  uploadedFiles: [],
  loading: true,
  progress: 0,
  uploadingField: '',
};

const uploadFilesSlice = createSlice({
  name: 'uploadFiles',
  initialState,
  reducers: {
    setUploadingField(state, action) {
      state.uploadingField = action.payload;
    },
    unsetIsSuccess(state) {
      state.isSuccess = false;
      state.loading = false;
      state.loadingFiles = [];
      state.uploadedFiles = [];
    },
    fetchingFiles(state, action) {
      state.isSuccess = false;
      state.error = [];
      state.loadingFiles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTryAgainFiles.fulfilled, (state) => {
      state.isSuccess = true;
      state.error = [];
      state.loadingFiles = [];
      state.loading = false;
      // state.uploadedFiles = action.payload;
    });
    builder.addCase(
      fetchTryAgainFiles.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingFiles = [];
        state.isSuccess = false;
        state.loading = false;
      },
    );
    builder.addCase(fetchTryAgainFiles.pending, (state, action: any) => {
      state.isSuccess = false;
      state.error = [];
      state.loading = true;
      state.loadingFiles = action.meta.arg?.map((item: any) => item.id);
    });
  },
});

export const { unsetIsSuccess, fetchingFiles, setUploadingField } = uploadFilesSlice.actions;
export default uploadFilesSlice.reducer;
