import React, { ReactElement } from 'react';
import { TickIcon } from '../../icons';
import { LittleGreyText, MediumBlackText } from '../../styles';
import { PlanDetail } from '../../types';
import { ListItemWithIconWrp, TickWrapper } from './styled';

type ListItemWithIconProps = {
  item: string | PlanDetail;
  isGradientTick?: boolean;
  icon?: ReactElement;
};

export const ListItemWithIcon: React.FC<ListItemWithIconProps> = ({
  item,
  isGradientTick,
  icon,
}) => (
  <ListItemWithIconWrp key={(item as PlanDetail)?.main || (item as string)}>
    {icon || <TickWrapper><TickIcon isGradient={!!isGradientTick} /></TickWrapper>}
    <div>
      <MediumBlackText>
        {(item as PlanDetail)?.main || (item as string)}
      </MediumBlackText>
      {(item as PlanDetail)?.extra ? (
        <LittleGreyText>{(item as PlanDetail)?.extra}</LittleGreyText>
      ) : null}
    </div>
  </ListItemWithIconWrp>
);
