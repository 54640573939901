import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyInsideListWrapper, TermsAndPrivacyListItem } from '../styled';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const HowPlatformWorksPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={14}
      listTitle={t('terms.howPlatformWorks')}
      pointsNameForTrans="howPlatformWorksPoints"
    >
      <LandingNumberedLi>
        You represent, warrant and agree that:
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            you will not use our Platform, including Our Intellectual
            Property, in any way that competes with our business;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            there are no legal restrictions preventing you from entering
            into these Terms;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            all information and documentation that you provide to us in
            connection with these Terms is true, correct and complete;
            {' '}
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            you have not relied on any representations or warranties made by
            us in relation to the Platform (including as to whether the
            Platform is or will be fit or suitable for your particular
            purposes), unless expressly stipulated in these Terms;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            where you are a Member, you will comply with the Membership
            Terms and Conditions;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            where you are a Member, you are responsible for complying with
            all laws, rules and regulations which apply to providing the
            goods in your Projects;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            where you are a Member, you are appropriately qualified, and
            have any required skills, knowledge or training, to provide the
            goods; and
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            where you are a Member, you are solely responsible for
            determining which projects to accept, the type, timing, manner
            and means, methods or processes of providing your goods, the
            price you charge for goods. You are not our employee and are not
            entitled to any employment benefits. We do not supervise, direct
            or control any of the goods you offer. We do not set your fees,
            work hours, your schedule or your location. We do not provide
            you with training, equipment or tools or any materials to
            provide your goods. You are responsible for any taxes payable on
            any fee you receive for your goods.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
