import React from 'react';
import { MaxLengthTextWrapper } from '../../styled';

type MaxLengthTextProps = {
  currentLength: number;
  maxLength?: number;
  isSuccess: boolean;
}

export const MaxLengthText: React.FC<MaxLengthTextProps> = ({
  currentLength,
  maxLength,
  isSuccess,
}) => (
  <MaxLengthTextWrapper isSuccess={isSuccess} id="max-length">
    <span>
      {currentLength || 0}
      /
      {maxLength || 220}
    </span>
  </MaxLengthTextWrapper>
);
