/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useEffect, useState } from 'react';
import { Client } from './Client';
import { ClientStateContext } from '../context/ClientStateContext';
import { useClientAppDispatch, useTypedSelectorClient } from '../../../shared/hooks/useTypedSelector';
import { ClientOnboardingContext } from '../context/CreatorOnboardingContext';
import { GlobalContainer } from '../../../shared/components/GlobalContainer/GlobalContainer';
import {
  fetchCheckPassGallery,
  fetchGetClientGallery,
} from '../redux/signIn/signInClientThunk';
import { TechnicalRoute } from '../../../shared/components/TechnicalPage/TechnicalRoute';
import { interceptorClient } from '../../../api/base/interceptors';

interceptorClient();

export const ClientContainer: React.FC = () => {
  const [isStudy, setIsStudy] = useState<boolean>(false);

  const dispatch = useClientAppDispatch();

  const currentClientIsSignIn = useTypedSelectorClient(
    (state) => state.signIn.isSignIn,
  );
  const isLoading = useTypedSelectorClient((state) => state.signIn.loading);
  const error = useTypedSelectorClient((state) => state.signIn.error);
  const gallery = useTypedSelectorClient((state) => state.signIn.gallery);

  const [isClientSignIn, setIsClientSignIn] = useState<boolean>(
    currentClientIsSignIn,
  );

  useEffect(() => {
    if (!gallery) {
      dispatch(fetchCheckPassGallery());
      dispatch(fetchGetClientGallery());
    }
    setIsClientSignIn(currentClientIsSignIn);
  }, [currentClientIsSignIn]);

  return (
    <ClientStateContext.Provider value={{ isClientSignIn, setIsClientSignIn }}>
      <ClientOnboardingContext.Provider value={{ isStudy, setIsStudy }}>
        <GlobalContainer>
          <>
            <Client
              error={error}
              isLoading={
                !!(
                  isLoading
                  || currentClientIsSignIn !== isClientSignIn
                )
              }
              isSignIn={isClientSignIn}
            />
            <TechnicalRoute />
          </>
        </GlobalContainer>
      </ClientOnboardingContext.Provider>
    </ClientStateContext.Provider>
  );
};
