import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  TermsAndPrivacyListTitle,
  TermsAndPrivacyText,
  TermsAndPrivacyListWrapper,
  TermsAndPrivacyListItem,
} from '../styled';

export const WhyCollectInfoPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <>
      <TermsAndPrivacyListTitle>
        {t('privacy.whyCollectInfo')}
      </TermsAndPrivacyListTitle>
      <Trans t={t} i18nKey="privacy.whyCollectInfoPoints">
        <TermsAndPrivacyText>
          As a Content Editor User:
        </TermsAndPrivacyText>
        <TermsAndPrivacyListWrapper hasBigBottomMargin>
          <TermsAndPrivacyListItem listMarker="disc">
            to enable you to create an account and access and use our software
            and platform, including to provide you with a login;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            to provide our Services to you;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            to contact and communicate with you about our Services, including in
            response to any support requests you lodge with us or other
            enquiries you make with us;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            for internal record keeping, administrative, invoicing and billing
            purposes;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            for analytics, market research and business development, including
            to operate and improve our Services and associated applications;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            to comply with our legal obligations and resolve any disputes that
            we may have;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc" style={{ marginBottom: '0px' }}>
            and/or if otherwise required or authorised by law.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyListWrapper>

        <TermsAndPrivacyText>
          As a Customer User:
        </TermsAndPrivacyText>
        <TermsAndPrivacyListWrapper hasBigBottomMargin>
          <TermsAndPrivacyListItem listMarker="disc">
            to enable you to create an account and access and use our software
            and platform, including to provide you with a login;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            to provide our Services to you;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            to contact and communicate with you about our Services, including in
            response to any support requests you lodge with us or other
            enquiries you make with us;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            for internal record keeping, administrative, invoicing and billing
            purposes;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            for analytics, market research and business development, including
            to operate and improve our Services and associated applications;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            to comply with our legal obligations and resolve any disputes that
            we may have; and/or
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc" style={{ marginBottom: '0px' }}>
            if otherwise required or authorised by law.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyListWrapper>

        <TermsAndPrivacyText>
          When you (any person) visit our website:
        </TermsAndPrivacyText>
        <TermsAndPrivacyListWrapper hasBigBottomMargin>
          <TermsAndPrivacyListItem listMarker="disc">
            to provide our website to you;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            to contact and communicate with you about any enquiries you make
            with us via our website;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            for advertising and marketing, including to send you promotional
            information about our services and other information that we
            consider may be of interest to you;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            to comply with our legal obligations and resolve any disputes that
            we may have; and/or
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc" style={{ marginBottom: '0px' }}>
            if otherwise required or authorised by law.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyListWrapper>

        <TermsAndPrivacyText>
          <span className="bold">Personal information:</span>
          {' '}
          We may collect,
          hold, use and disclose personal information for the following
          purposes:
        </TermsAndPrivacyText>
        <TermsAndPrivacyListWrapper hasBigBottomMargin>
          <TermsAndPrivacyListItem listMarker="disc">
            to enable you to access and use our Services, including to provide
            you with a login;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            to provide our Services to you, including to connect you (a Content
            Editor) with a Customer using our Services or to connect you (a
            Customer) with a Content Editor using our Services;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            to enable you to access and use our associated applications and
            associated social media platforms;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            to contact and communicate with you about our Services, including in
            response to any support requests you lodge with us or other
            enquiries you make with us;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            for internal record keeping, administrative, invoicing and billing
            purposes;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            for analytics, market research and business development, including
            to operate and improve our Services, associated applications and
            associated social media platforms;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            to run promotions, competitions and/or offer additional benefits to
            you;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            for advertising and marketing, including to send you promotional
            information about our products and services and other information
            that we consider may be of interest to you;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            to comply with our legal obligations and resolve any disputes that
            we may have;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            if you have applied for employment with us; to consider your
            employment application; and
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc" style={{ marginBottom: '0px' }}>
            if otherwise required or authorised by law.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyListWrapper>

        <TermsAndPrivacyText>
          We may disclose personal information to:
        </TermsAndPrivacyText>
        <TermsAndPrivacyListWrapper hasBigBottomMargin>
          <TermsAndPrivacyListItem listMarker="disc">
            if you are a Content Editor User, to a Customer User who is your
            customer and if you are a Customer User, to a Content Ed itor User
            who is your supplier;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            third party service providers for the purpose of enabling them to
            provide their services, to us, including (without limitation)
            web-hosting and server providers such as Amazon Web Services, data
            storage and marketing providers, maintenance or problem-solving
            providers, cloud-based storage providers, professional advisors and
            payment systems operators;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            our employees, contractors and/or related entities;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            our existing or potential agents or business partners;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            sponsors or promoters of any promotions or competition we run;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            anyone to whom our business or assets (or any part of them) are, or
            may (in good faith) be, transferred;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            courts, tribunals and regulatory authorities, in the event you fail
            to pay for goods or services we have provided to you;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            courts, tribunals, regulatory authorities and law enforcement
            officers, as required or authorised by law, in connection with any
            actual or prospective legal proceedings, or in order to establish,
            exercise or defend our legal rights;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            third parties to collect and process data, such as Amazon Web
            Services; and
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc" style={{ marginBottom: '0px' }}>
            any other third parties as required or permitted by law, such as
            where we receive a subpoena.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyListWrapper>
        <TermsAndPrivacyText hasMarginBottom>
          <span className="bold">Sensitive information:</span>
          {' '}
          We do not
          actively collect sensitive information but sensitive information
          belonging to a Customer User may be indirectly collected, held and
          disclosed by us through our software and website platform via content
          a Customer User authorises a Content Editor User to collect and use.
          If you (a Customer User) do not consent to this, you should not
          authorise the Content Editor User to use this sensitive information
          and should directly contact the Content Editor User.
        </TermsAndPrivacyText>
      </Trans>
    </>
  );
};
