import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

type ReturnObj = {
  confirm: any;
  promise: any;
  handleConfirm: any;
  handleCancel: any;
}

type ConfirmationStatus = 'canceled' | 'redirected';

const useConfirm = (): ReturnObj => {
  const [promise, setPromise] = useState<any>(null);
  const [confirmationStatus, setConfirmationStatus] = useState<ConfirmationStatus | null>(null);
  const { navigator } = useContext(NavigationContext);

  const confirm = () => new Promise((resolve, reject) => {
    setPromise({ resolve });
  });

  useEffect(() => {
    // eslint-disable-next-line prefer-destructuring
    const push = navigator.push;

    navigator.push = async (...args: Parameters<typeof push>) => {
      const res = await confirm();
      if (promise === null && res !== 'canceled') {
        push(...args);
      }
    };

    // eslint-disable-next-line consistent-return
    return () => {
      navigator.push = push;
    };
  }, [navigator, confirm, promise, confirmationStatus]);

  const handleClose = async () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    setConfirmationStatus('canceled');
    promise?.resolve('canceled');
    handleClose();
  };

  return {
    confirm, promise, handleCancel, handleConfirm,
  };
};

export default useConfirm;
