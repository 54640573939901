import React from 'react';
import { ButtonAddWrapper, PlusIcon } from './styled';

type ButtonAddProps = {
  size: 'large' | 'small';
  handleClick: () => void;
  text: string;
  id?: string;
};

export const ButtonAdd: React.FC<ButtonAddProps> = ({
  size,
  handleClick,
  text,
  id,
}) => (
  <ButtonAddWrapper type="button" onClick={handleClick} id={id || text}>
    <PlusIcon theme={size} />
    {text}
  </ButtonAddWrapper>
);
