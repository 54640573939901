import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CommonPriceInputReadOnly } from '../../../../../shared/components/CommonPriceInputReadOnly/CommonPriceInputReadOnly';
import { PinkLoader } from '../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { useTypedSelectorClient } from '../../../../../shared/hooks/useTypedSelector';
import { MediumBlackText, SimpleBlackText } from '../../../../../shared/styles';
import { HrLine } from '../../../../../shared/styles/Elements';
import { ClientContentErrorMessage } from '../../ClientShared/ClientPageContent/styled';
import { CartSummarySection } from '../CartSummary/CartSummarySection';
import {
  CartPopupBlurBackground,
  CartPopupContainer, CartPopupSectionsWrapper, CartPopupSubtotalWrapper, CartPopupWrapper,
} from './styled';
import { CurrencyTypes } from '../../../../../shared/types';

type CartPopupBlurProps = {
  isLoading: boolean;
  error: string | null;
};

const CartPopupBlur: React.FC<CartPopupBlurProps> = ({ isLoading, error }) => {
  if (!isLoading && !error) {
    return null;
  }
  return (
    <CartPopupBlurBackground>
      {isLoading ? <PinkLoader /> : null}
      {error ? <ClientContentErrorMessage>{error}</ClientContentErrorMessage> : null}
    </CartPopupBlurBackground>
  );
};
export const CartPopupWindow: React.FC = () => {
  const { t } = useTranslation(['gallery', 'sales']);

  const cartSections = useTypedSelectorClient((state) => state.cart?.sections);
  const totalNumberFiles = useTypedSelectorClient(
    (state) => state.cart?.totalFiles,
  );
  const isLoading = useTypedSelectorClient((state) => state.cart?.loadingGet);
  const error = useTypedSelectorClient((state) => state.cart?.error);

  const subtotal = useMemo(() => {
    let result = 0;
    cartSections?.forEach((item) => {
      result += +(item?.totalSum || 0);
    });
    return result;
  }, [cartSections]);

  return (
    <CartPopupContainer>
      <CartPopupWrapper>
        <SimpleBlackText fontWeight={600}>
          {totalNumberFiles
            ? (
              <Trans t={t} i18nKey="pageTitle">
                {{ title: t('cart.title') }}
                {' '}
                {{ files: totalNumberFiles }}
              </Trans>
            )
            : t('cart.title')}
        </SimpleBlackText>
        <div style={{ width: '100%' }}>
          <CartPopupSectionsWrapper>
            {cartSections?.map((item) => (
              <CartSummarySection section={item} key={item?.sectionId} />
            ))}
          </CartPopupSectionsWrapper>
          <HrLine />
          <CartPopupSubtotalWrapper>
            <MediumBlackText fontWeight={500}>{t('sales:subTotal')}</MediumBlackText>
            <MediumBlackText fontWeight={500}>
              <CommonPriceInputReadOnly currencyLetters="USD" currenyType={CurrencyTypes.USD} value={subtotal || 0} />
            </MediumBlackText>
          </CartPopupSubtotalWrapper>
        </div>
        <CartPopupBlur isLoading={isLoading} error={error} />
      </CartPopupWrapper>
    </CartPopupContainer>
  );
};
