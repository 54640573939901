import styled from '@emotion/styled';
import * as variables from '../../../../constants/styles';

export const WalkThroughStartWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-round;
  align-items: center;
  padding: 60px 100px;
  border-radius: 18px;
  box-sizing: border-box;
  background: ${variables.colorGreyLight};
  max-width: 616px;

  & > div {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`;

export const NeedHelpContainer = styled.div<{isOpen: boolean}>`
  background: ${variables.colorGreyLight};
  position: absolute;
  top: 60px;
  right: -2px;
  z-index: 10;
  padding: 20px 16px 11px 16px;
  box-sizing: border-box;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  border: 0.5px solid #e0e0e0;
  min-width: 253px;

  &::before, &::after {
    content: '';
    position: absolute;
    right: 8px;
    top: -24px;
    border: 11.5px solid transparent;
    border-bottom: 12.5px solid #e0e0e0;
    border-radius: 0px;
    z-index: 5;
  }
  &::after {
    right: 9.5px;
    top: -22px;
    border: 10px solid transparent;
    border-bottom: 11px solid ${variables.colorGreyLight};
  }
`;

export const NeedHelpButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 11px;
  width: 100%;
`;

export const NeedHelpQAndText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-right: 20px;
  box-sizing: border-box;

  & div, span {
    margin-bottom: 8px;
    text-align: start;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 13px;
  right: 11px;
  padding: 0;
  height: 20px;
  width: 20px;
  z-index: 2;

  & > svg {
    cursor: pointer;
    color: ${variables.textBlack} !important;
  }
`;
