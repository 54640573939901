const isFileAlignedByHeight = (
  containerWidth: number,
  containerHeight: number,
  fileWidth: number,
  fileHeight: number,
): boolean => {
  const widthToWidthCropper = Math.round(fileWidth / containerWidth);

  if (fileHeight / widthToWidthCropper < containerHeight) {
    return true;
  }
  return false;
};

export const getObjectFit = (
  fileUrl: string,
  cropperWidth: number,
  cropperHeight: number,
): string => {
  const file = new Image();
  file.src = fileUrl || '';

  if (isFileAlignedByHeight(cropperWidth, cropperHeight, file?.width, file?.height)) {
    return 'vertical-cover';
  }
  return 'horizontal-cover';
};
