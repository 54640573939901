import styled from '@emotion/styled';
import { theme } from '../../../../../shared/components/GlobalContainer/styled';
import { MediumBlackText } from '../../../../../shared/styles';

export const ClientOnboardingTextWr = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const ClientOnboardingText = styled(MediumBlackText)`
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    font-size: 16px;
    line-height: 150%;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    font-size: 14px;
    line-height: 140%;
  }
`;
