import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyInsideListWrapper, TermsAndPrivacyListItem } from '../styled';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const LimitationsPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={17}
      listTitle={t('terms.limitations')}
      pointsNameForTrans="limitationsPoints"
    >
      <LandingNumberedLi>
        To the maximum extent permitted by law:
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            neither Party will be liable for Consequential Loss;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            each Party’s liability for any Liability under these Terms will
            be reduced proportionately to the extent the relevant Liability
            was caused or contributed to by the acts or omissions of the
            other Party or any of that Party’s personnel, including any
            failure by that party to mitigate its losses; and
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            if you are a Member, our aggregate liability for any Liability
            arising from or in connection with these Terms will be excluded
            and limited as set out in the Membership Terms and Conditions;
            and
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            if you are a Client, our aggregate liability for any Liability
            arising from or in connection with these Terms will be limited
            to us resupplying the Delivrable Services to you or, in our sole
            discretion, to us paying you $1.00.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        This clause will survive the termination or expiry of these Terms.
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
