import { AxiosResponse } from 'axios';
import { clientInstance, instance } from '../base/instance';
import { interactionsUrl } from '../../constants/urls';
import { DownloadsPut } from '../../shared/types/interactions';
import { BoughtFreeFilesClientItem } from '../../modules/Client/types/types';

export const creatorDownloads = {
  getDownloads: (galleryId: string, token: string): Promise<AxiosResponse<any>> => instance.get(`${interactionsUrl}/creators/downloads/${galleryId}`, { headers: { crossDomain: true, Authorization: `${token}` } }),
  addDownloads: (data: DownloadsPut, token: string): Promise<AxiosResponse<any>> => instance.put(`${interactionsUrl}/creators/download/${data.galleryId}`, { filesIds: data.filesIds }, { headers: { crossDomain: true, Authorization: `${token}` } }),
};

export const clientBoughtFreeFiles = {
  getBoughtFreeFiles: (galleryId: string): Promise<AxiosResponse<BoughtFreeFilesClientItem[]>> => clientInstance.get(`${interactionsUrl}/client/bought/${galleryId}`),
};
