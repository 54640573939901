import styled from '@emotion/styled';
import * as variables from '../../../../constants/styles';

export const StyledTextarea = styled.textarea`
  background: transparent;
  display: flex;
  width: 100%;
  height: 100%;
  padding-right: 10px;
  align-items: flex-start;
  box-sizing: border-box;
  resize: none;
  font-size: 15px;
  border: none;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${variables.colorBleak};
    border-radius: 100px !important;
  }

  &::placeholder {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: ${variables.colorBleak};
    line-height: 140%;
  }
`;
