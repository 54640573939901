import React from 'react';
import { styled } from '@material-ui/core';
import { ExtraTextWrapper, TabWrapper } from './styled';
import { BaseUnderlineTab } from '../../../../../../../shared/components/Tabs/UnderlineTabs/BaseUnderlineTab';

export const StyledSettingsTab = styled((props: any) => (
  <TabWrapper style={{ position: 'relative' }}>
    {props.disabled && <ExtraTextWrapper>Coming soon</ExtraTextWrapper>}
    <BaseUnderlineTab disableRipple {...props} />
  </TabWrapper>
))(() => ({}));
