import React from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Fade } from '@mui/material';
import {
  ActionButtonWrapper, LoadingIconWrapper, PinkActionButton, TickIconWrapper,
} from '../styled';
import { TickIcon } from '../../../../../../../../shared/icons';
import { PinkLoader } from '../../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';

type ApplyAndChosenButtonsProps = {
  settingsApplied: boolean;
  isLoading: boolean;
  handleSubmit: () => void;
};

const getText = (isSuccess: boolean, t: TFunction) => {
  if (isSuccess) {
    return t('thirdStep.settingsApplied');
  }
  return t('thirdStep.applySettings');
};

export const ApplyAndChosenButtons: React.FC<ApplyAndChosenButtonsProps> = ({
  settingsApplied,
  isLoading,
  handleSubmit,
}) => {
  const { t } = useTranslation('createNewGallery');

  return (
    <ActionButtonWrapper>
      <PinkActionButton
        type="button"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading && (
          <Fade in={isLoading}>
            <LoadingIconWrapper>
              <PinkLoader size="xs" />
            </LoadingIconWrapper>
          </Fade>
        )}
        {settingsApplied && (
          <Fade in={settingsApplied}>
            <TickIconWrapper>
              <TickIcon />
            </TickIconWrapper>
          </Fade>
        )}
        {getText(settingsApplied, t)}
      </PinkActionButton>
    </ActionButtonWrapper>
  );
};
