import { FormikHelpers, useFormik } from 'formik';
import React, {
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { CommonInput } from '../../../shared/components/FormElements/CommonInput/CommonInput';
import {
  TitleSimple, PrimaryWhiteButtonBlueTextNoMg, MediumBlackText,
} from '../../../shared/styles';
import { SignIn } from '../types/types';
import {
  BetaFormConteiner,
  BetaFormWrapper,
} from './styled';
import { useBetaSignIn } from '../hooks/useBetaSignIn';
import { password } from '../../../shared/validation/beta/betaSchemas';
import { useTypedSelectorCreator } from '../../../shared/hooks/useTypedSelector';
import * as urls from '../../../constants/urls';
import { PinkLoader } from '../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { Watermark } from '../../Creator/containers/CreatorAccount/Tabs/Settings/AdvancedSettings/Watermark';
import { InfoIcon } from '../../../shared/icons';
import { WatermarkTooltipWrapper, WatermarkTooltipText } from '../../Creator/containers/CreatorAccount/Tabs/Settings/AdvancedSettings/styled';

export const BetaFormPassword: React.FC = () => {
  const { t } = useTranslation('signInBeta');

  const {
    isSuccess, isLoading, error, signIn,
  } = useBetaSignIn();

  const onSubmit = (data: SignIn, bag: FormikHelpers<SignIn>) => {
    bag.setSubmitting(false);
    signIn(data);
  };

  const {
    values, handleSubmit, errors, handleChange, touched, handleBlur, resetForm,
  } = useFormik({
    initialValues: { password: '' },
    validationSchema: password,
    onSubmit: (data: SignIn, bag) => onSubmit(data, bag),
  });

  if (isSuccess) {
    return <Navigate to={urls.creatorRootUrl} />;
  }

  return (
    <>
      <TitleSimple>
        {t('formTitle')}
      </TitleSimple>
      <MediumBlackText>
        Enter the password you were given to continue
      </MediumBlackText>
      <BetaFormWrapper onSubmit={handleSubmit} noValidate>
        <CommonInput
          error={errors.password || error}
          value={values.password}
          name="password"
          handleBlur={handleBlur}
          handleChange={handleChange}
          type="password"
          placeholder="Password"
          touched={touched.password}
        />
        <PrimaryWhiteButtonBlueTextNoMg
          type="submit"
          style={{ minWidth: '100%' }}
          disabled={isLoading}
        >
          {isLoading ? <PinkLoader /> : t('formButton')}
        </PrimaryWhiteButtonBlueTextNoMg>
      </BetaFormWrapper>
    </>
  );
};

export const BetaForm: React.FC = () => {
  const currentUserErrorCode = useTypedSelectorCreator((state) => state.creator.getErrorCode);

  if (!currentUserErrorCode) {
    return <Navigate to={urls.creatorRootUrl} />;
  }

  return (
    <BetaFormConteiner>
      <BetaFormPassword />
    </BetaFormConteiner>
  );
};
