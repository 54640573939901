import { AxiosResponse } from 'axios';
import { CommentDeleteReq, CommentGet, CommentPutReq } from '../../shared/types/client';
import { clientInstance, instance } from '../base/instance';
import { interactionsUrl } from '../../constants/urls';

export const commentsClient = {
  addComment: (data: CommentPutReq): Promise<AxiosResponse<any>> => clientInstance.put(`${interactionsUrl}/comment`, data),
  deleteComment: (data: CommentDeleteReq): Promise<AxiosResponse<string[]>> => clientInstance.put(`${interactionsUrl}/comment/delete`, data),
};

export const commentsCreator = {
  deleteComment: (data: CommentDeleteReq, token: string): Promise<AxiosResponse<string[]>> => instance.put(`${interactionsUrl}/comment/delete`, data, { headers: { crossDomain: true, Authorization: `${token}` } }),
  addComment: (data: CommentPutReq, token: string): Promise<AxiosResponse<any>> => instance.put(`${interactionsUrl}/comment`, data, { headers: { crossDomain: true, Authorization: `${token}` } }),
  getComments: (galleryId: string, token: string): Promise<AxiosResponse<CommentGet[]>> => instance.get(`${interactionsUrl}/comment/${galleryId}`, { headers: { crossDomain: true, Authorization: `${token}` } }),
};
