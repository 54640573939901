import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyInsideListWrapper, TermsAndPrivacyListItem } from '../styled';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const TerminationPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={18}
      listTitle={t('terms.termination')}
      pointsNameForTrans="terminationPoints"
    >
      <LandingNumberedLi>
        Your Account and these Terms may be terminated by you at any time,
        using the ‘cancel Account’ functionality (or similar) in the Account
        page section of your Account settings.
      </LandingNumberedLi>
      <LandingNumberedLi>
        We may terminate these Terms at any time by giving 30 days’ written
        notice to you (
        <span className="bold">Termination for Convenience</span>
        ).
      </LandingNumberedLi>
      <LandingNumberedLi>
        These Terms will terminate immediately upon written notice by a
        Party (
        <span className="bold">Non-Defaulting Party</span>
        ) if:
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            the other Party (
            <span className="bold">Defaulting Party</span>
            )
            breaches a material term of these Terms and that breach has not
            been remedied within 10 Business Days of the Defaulting Party
            being notified of the breach by the Non-Defaulting Party; or
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            the Defaulting Party is unable to pay its debts as they fall
            due.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Should we suspect that you are in breach of these Terms, we may
        suspend your Account while we investigate the suspected breach.
      </LandingNumberedLi>
      <LandingNumberedLi>
        Upon expiry or termination of these Terms:
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            we will remove your access to the Platform;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            we will immediately cease providing the Delivrable Services;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            where you are a Member, you agree that other than where
            termination is due to our Termination for Convenience and to the
            maximum extent permitted by law, any payments made by you to us
            (including any Membership Fees) are not refundable to you and
            any refunds will be as set out in the Membership Terms and
            Conditions;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            where you are a Member, we will cancel any existing confirmed
            Projects and you will lose any Project Fees and other amounts
            paid in advance for goods/services not yet received;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            where you are a Client, we will cancel any existing confirmed
            Projects and if you have paid in advance, we will refund you for
            any payments made in advance for services not yet received less
            our Deferred Payment Service Fee; and
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            where we terminate the Terms for any reason other than a
            Termination for Convenience, you also agree to pay us our
            reasonable additional costs directly arising from such
            termination (including legal fees, debt collector fees and
            mercantile agent fees).
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Termination of these Terms will not affect any rights or liabilities
        that a Party has accrued under it.
      </LandingNumberedLi>
      <LandingNumberedLi>
        This clause will survive the termination or expiry of these Terms.
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
