import styled from '@emotion/styled';
import { theme } from '../../../../../../../../shared/components/GlobalContainer/styled';

export const PositionedElementWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 12px;
  z-index: 2;

  & svg {
    z-index: 10;
    color: white;
  }
`;

export const ExpandAndShrinkWrapper = styled.button`
  background: white;
  padding: 10px 17px;
  border-radius: 53px;
  z-index: 1000;
`;

export const ImageOrVideoCardWrapper = styled.div<{fullWidth: boolean}>`
  margin-bottom: 20px;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};

  @media (max-width: ${theme.breakpoints.values.lg - 1}px) {
    margin-bottom: 10px;
  }

  @media (max-width: ${theme.breakpoints.values.md - 1}px) {
    margin-bottom: 8px;
  }
`;

export const LikeAndInstDownloadableWrapper = styled(PositionedElementWrapper)`
  & #client-like-button-step {
    display: none;
  }
  & #inst-downloadable {
    display: flex;
  }

  & svg {
    width: 30px;
    height: 30px;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    top: 5px;
    right: 4px;

    & #inst-downloadable {
      display: none;
    }

    & #client-like-button-step {
      display: flex;

      & svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    top: 3px;
    right: 1px;

    & #client-like-button-step {

      & svg {
        width: 32px;
        height: 32px;
      }
    }
  }
`;

export const FileMasonryContainer = styled.div`
  overflow: hidden;
  display: flex;
  width: 100%;
`;
