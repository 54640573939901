import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const AccountsPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={4}
      listTitle={t('terms.accounts')}
      pointsNameForTrans="accountsPoints"
    >
      <LandingNumberedLi>
        You must register on the Platform and create an account (
        <span className="bold">Account</span>
        ) to access the Platform’s
        features.
      </LandingNumberedLi>
      <LandingNumberedLi>
        You may only have 1 Account as a Member and 1 Account as a Client on
        the Platform. If you are a Member and have a Premium Plus Membership
        (as defined on our Platform), you have the option to appoint 2
        authorised users who must each hold an authorised user account on
        our Platform as set out in our Membership Terms and Conditions.
      </LandingNumberedLi>
      <LandingNumberedLi>
        If you are a Member, your Account as a Member must comply with our
        Membership Terms and Conditions Account requirements.
      </LandingNumberedLi>
      <LandingNumberedLi>
        If you are a Client, you must provide basic information when
        registering for an Account including your name, email address and
        payment details and you must choose a username and password. The
        username you choose must not (1) be offensive or in other ways
        insulting or (2) contain characteristics which belong to a third
        party, including names of famous persons, or personal names to which
        you do not own the rights.
      </LandingNumberedLi>
      <LandingNumberedLi>
        You may link your Account to your Instagram, Facebook, Google or
        other social media network account (
        <span className="bold">Social Media Account</span>
        ). If you link your Account to your Social Media Account, we may
        collect your personal information from the social network. We will
        do this in accordance with the privacy settings you have chosen on
        that social network and our Privacy Policy.
      </LandingNumberedLi>
      <LandingNumberedLi>
        Once you have registered an Account, your Account information will
        be used to create a profile which you may then curate.
      </LandingNumberedLi>
      <LandingNumberedLi>
        All personal information you provide to us will be treated in
        accordance with our Privacy Policy.
      </LandingNumberedLi>
      <LandingNumberedLi>
        You agree to provide and maintain up to date information in your
        Account and to not share your Account password with any other
        person. Your Account is personal and you must not transfer it to
        others.
      </LandingNumberedLi>
      <LandingNumberedLi>
        4.9 activity on your Account, including purchases made using your
        Account details. You agree to immediately notify us of any
        unauthorised use of your Account.
      </LandingNumberedLi>
      <LandingNumberedLi>
        We may make access to and use of certain parts of the Platform
        subject to conditions or requirements, including cancellation
        history, payment history, quality of services and threshold of
        reviews.
      </LandingNumberedLi>
      <LandingNumberedLi>
        If you are a Client, once you have created an Account you will have
        access to Output Data via a Member’s gallery. Should you wish to
        purchase this Output Data, you will need to update your Account to
        include your name and payment details (being your Stripe account or
        credit card). . You acknowledge and agree that we have no control
        over the actions of the third-party provider, and your use of the
        third-party payment method may be subject to additional terms and
        conditions.
      </LandingNumberedLi>
      <LandingNumberedLi>
        As a Member, your access to our Platform and SaaS Solution will
        depend upon your Membership tier. For all Members, to be paid for
        the sale of Output Data via the Platform, you must link your Stripe
        or PayPal account to your Account. You acknowledge and agree that we
        have no control over the actions of the third-party provider, and
        your use of the third-party payment method may be subject to
        additional terms and conditions.
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
