import React, { ReactNode } from 'react';
import { SimpleTooltipText } from './styled';

type SimpleTooltipProps = {
  text: string | ReactNode;
  relativeWidth: number;
};

export const SimpleTooltip: React.FC<SimpleTooltipProps> = ({
  text,
  relativeWidth,
}) => (
  <SimpleTooltipText relativeWidth={relativeWidth} id="tooltip">{text}</SimpleTooltipText>
);
