import React from 'react';
import { NumericFormat } from 'react-number-format';
import { ReadOnlyPriceInput } from './styled';
import { CurrencyTypes } from '../../types';

type CommonPriceInputProps = {
  value: string | number;
  currenyType: CurrencyTypes;
  textAlign?: 'right' | 'left';
  maxWidth?: number;
  currencyLetters?: string;
};

export const CommonPriceInputReadOnly: React.FC<CommonPriceInputProps> = ({
  value,
  textAlign,
  maxWidth = 150,
  currencyLetters = '',
  currenyType,
}) => (
  <ReadOnlyPriceInput textAlign={textAlign || 'right'} maxWidth={maxWidth}>
    <NumericFormat
      prefix={`${currencyLetters ? `${currencyLetters} ` : ''}${currenyType}`}
      placeholder={`${currenyType}0.00`}
      thousandSeparator=" "
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
      autoComplete="off"
      value={Number(value) / 100}
      disabled
    />
  </ReadOnlyPriceInput>
);
