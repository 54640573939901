import React, { ReactNode, useCallback, useState } from 'react';
import { ShareWindow } from '../../components/ShareWindow/ShareWindow';

type ReturnObj = {
  handleOpenClose: () => void;
  shareWindow: ReactNode;
};

export const useShare = (): ReturnObj => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenClose = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return {
    shareWindow: (
      <ShareWindow
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    ),
    handleOpenClose,
  };
};
