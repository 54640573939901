import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyInsideListWrapper, TermsAndPrivacyListItem } from '../styled';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const UploadedContentToSocMediaPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={13}
      listTitle={t('terms.uploadedContentToSocMedia')}
      pointsNameForTrans="uploadedContentToSocMediaPoints"
    >
      <LandingNumberedLi>
        You, a Client or Member, may be permitted by the content owner to
        post, upload, publish, submit or transmit relevant information and
        content generated through our Platform or SaaS Solution (
        <span className="bold">User Content</span>
        ) on a social media
        platform. You must obtain the prior consent in writing of the
        relevant content owner to do this and must not commit an
        Intellectual Property Breach.
      </LandingNumberedLi>
      <LandingNumberedLi>
        We may run campaigns via the Platform and via social media that
        encourage you to post User Content on social media using specific
        hashtags (#delivrable) (
        <span className="bold">Tag</span>
        ).
      </LandingNumberedLi>
      <LandingNumberedLi>
        Subject to clause 13.1, if you make any User Content available on or
        through the Platform, including on social media using a Tag, you
        grant to us a worldwide, irrevocable, perpetual, non-exclusive,
        transferable, royalty-free licence to use the User Content, with the
        right to use, view, copy, adapt, modify, distribute, license,
        transfer, communicate, publicly display, publicly perform, transmit,
        stream, broadcast, access, or otherwise exploit such User Content
        on, through or by means of the Platform and our social media
        platforms. We agree to only modify User Content to the extent
        reasonably required by us. You may request that any of your User
        Content is removed from the Platform or social media by sending us
        an email to the address at the end of these Terms. We will endeavour
        to action any removal requests within a reasonable time.
      </LandingNumberedLi>
      <LandingNumberedLi>
        You agree that you are solely responsible for all User Content that
        you make available on or through the Platform, including on social
        media using a Tag. You represent and warrant that:
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            you are either the sole and exclusive owner of all User Content
            or you have all rights, licences, consents and releases that are
            necessary to grant to us the rights in such User Content (as
            contemplated by these Terms); and
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            neither the User Content nor the posting, uploading,
            publication, submission or transmission of the User Content or
            our use of the User Content on, through or by means of our
            Platform (including on social media) will infringe,
            misappropriate or violate a third party’s intellectual property
            rights, or rights of publicity or privacy, or result in an
            Intellectual Property Breach or the violation of any applicable
            law or regulation.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        We do not endorse or approve, and are not responsible for, any User
        Content. We may, at any time (at our sole discretion), remove any
        User Content.
      </LandingNumberedLi>
      <LandingNumberedLi>
        Nothing in the above clause restricts your ability to publish, post
        or repost User Content on your social media page or blog, provided
        that:
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            you do not commit an Intellectual Property Breach;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            you do not assert that you are the owner of Our Intellectual
            Property;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            unless explicitly agreed by us in writing, you do not assert
            that you are endorsed or approved by us;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            you do not damage or take advantage of our reputation, including
            in a manner that is illegal, unfair, misleading or deceptive;
            and
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            you comply with all other terms of these Terms.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        This clause will survive the termination or expiry of these Terms.
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
