import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDialog } from '../../hooks/useDialog';
import { DeleteIcon, EyeIcon } from '../../icons';
import {
  RoundItem,
  SimpleBlackText,
  WhiteButtonWithoutShadow,
} from '../../styles';
import { CustomFile } from '../../types';
import { getFileInContainer } from '../../utils/createGallery';
import {
  ButtonWrapperWithOpacity,
  EyePositionWrapper,
  GalleryCardCover,
  GalleryCardHeader,
  GalleryCardHeaderButton,
  GalleryCardTitle,
  GalleryCardWrapper,
} from './styled';

type GalleryCardProps = {
  title: string;
  coverFile?: CustomFile;
  buttonText?: string;
  handleClick?: () => void;
  wasWatchedByClient?: boolean;
  children?: ReactNode;
  handleDelete?: (id: string) => void;
  galleryId: string;
};

export const GalleryCard: React.FC<GalleryCardProps> = ({
  title,
  coverFile,
  buttonText,
  handleClick,
  wasWatchedByClient,
  children,
  handleDelete,
  galleryId,
}) => {
  const { t } = useTranslation('draftGalleries');
  const { setIsOpen, dialogWindow } = useDialog<string>(
    'confirmation',
    galleryId,
    handleDelete,
    t('deleteConfirmation.question'),
    t('deleteConfirmation.title'),
  );
  const coverFileInContainer = coverFile ? getFileInContainer({ file: coverFile }) : null;

  return (
    <>
      <GalleryCardWrapper>
        <GalleryCardHeader>
          <GalleryCardTitle>{title}</GalleryCardTitle>
          {handleDelete && (
          <GalleryCardHeaderButton type="button" onClick={() => setIsOpen(true)}>
            <DeleteIcon />
          </GalleryCardHeaderButton>
          )}
        </GalleryCardHeader>
        <GalleryCardCover>
          {wasWatchedByClient && (
          <EyePositionWrapper>
            <RoundItem>
              <EyeIcon />
            </RoundItem>
          </EyePositionWrapper>
          )}
          <ButtonWrapperWithOpacity>
            {children || (
            <WhiteButtonWithoutShadow type="button" onClick={handleClick}>
              {buttonText}
            </WhiteButtonWithoutShadow>
            )}
          </ButtonWrapperWithOpacity>
          {coverFileInContainer || (
            <SimpleBlackText>{t('noCoverPhoto')}</SimpleBlackText>
          )}
        </GalleryCardCover>
      </GalleryCardWrapper>
      {dialogWindow}
    </>
  );
};
