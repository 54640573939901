import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryWhiteButton } from '../../../../../../../../shared/styles';
import { ChangePasswordWindow } from './ChangePasswordWindow';
import { ChangePasswordWrapper } from './styled';

export const ChangePassword: React.FC = () => {
  const { t } = useTranslation('settings');
  const [isOpenWindow, setIsOpenWindow] = useState(false);

  return (
    <Grid item sm={9} style={{ position: 'relative' }}>
      <ChangePasswordWrapper>
        <PrimaryWhiteButton
          style={{ marginTop: '20px' }}
          type="button"
          onClick={() => setIsOpenWindow(true)}
        >
          {t('updatePassword')}
        </PrimaryWhiteButton>
      </ChangePasswordWrapper>
      <ChangePasswordWindow
        isOpen={isOpenWindow}
        setIsOpen={setIsOpenWindow}
      />
    </Grid>
  );
};
