import { useFormik } from 'formik';
import React, {
  Dispatch, FormEvent, SetStateAction, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModalWindow } from '../../../../../../../../shared/components/BaseModalWindow/BaseModalWindow';
import { CloseModalWindowButton } from '../../../../../../../../shared/components/CloseModalWindowButton/CloseModalWindowButton';
import {
  PrimaryWhiteButton,
  ButtonWithoutShadow,
  TitleText,
} from '../../../../../../../../shared/styles';
import { ButtonsWrapper } from '../../../NewGalleryCreator/styled';
import { ConfirmCurrentPasswordWindowContainer } from './styled';
import { CommonInput } from '../../../../../../../../shared/components/FormElements/CommonInput/CommonInput';
import { TitleMgB } from '../../styled';
import { changePassword } from '../../../../../../../../shared/validation/creatorSettings/settingsSchemas';
import { PinkLoader } from '../../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { SuccessNotification } from '../../../../../../../../shared/components/SuccessNotification/SuccessNotification';
import { useChangePassword } from './useChangePassword';

type ChangePasswordForm = { password: string, confirmPassword: string, currentPassword: string };

type ChangePasswordWindowProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const ChangePasswordWindow: React.FC<ChangePasswordWindowProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation(['settings', 'basicComponents']);
  const [isSuccessWindow, setIsSuccessWindow] = useState(false);
  const {
    isLoading, isSuccess, error, changePasswordSubmit, setIsSuccess,
  } = useChangePassword();

  const {
    values, handleChange, handleBlur, handleSubmit, errors, touched, resetForm,
  } = useFormik<ChangePasswordForm>({
    validationSchema: changePassword,
    initialValues: { password: '', confirmPassword: '', currentPassword: '' },
    onSubmit: (data, bag) => {
      bag.setSubmitting(false);
      changePasswordSubmit({
        currentPassword: data.currentPassword, password: data.password,
      });
    },
  });

  const handleConfirm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit(e);
  };

  const handleClose = () => {
    setIsOpen(false);
    resetForm();
  };

  useEffect(() => {
    if (isSuccess) {
      setIsSuccessWindow(true);
    }
  }, [isSuccess]);

  return (
    <>
      <BaseModalWindow
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
      >
        <form onSubmit={handleConfirm}>
          <ConfirmCurrentPasswordWindowContainer>
            <TitleMgB>
              <TitleText>{t('settings:changePassword')}</TitleText>
            </TitleMgB>
            <CommonInput
              value={values.currentPassword}
              name="currentPassword"
              handleChange={handleChange}
              handleBlur={handleBlur}
              direction="column"
              placeholder={t('placeholders.currentPassword')}
              error={errors?.currentPassword || error}
              touched={touched?.currentPassword || error}
              type="password"
            />
            <CommonInput
              value={values.password}
              name="password"
              handleChange={handleChange}
              handleBlur={handleBlur}
              direction="column"
              placeholder={t('placeholders.newPassword')}
              error={errors?.password || error}
              touched={touched?.password || error}
              type="password"
              eyeButton
            />
            <div style={{ paddingBottom: '1px' }} />
            <CommonInput
              value={values.confirmPassword}
              name="confirmPassword"
              handleChange={handleChange}
              handleBlur={handleBlur}
              direction="column"
              placeholder={t('placeholders.confirmPassword')}
              error={errors?.confirmPassword}
              touched={touched?.password}
              type="password"
              eyeButton
            />
            <ButtonsWrapper>
              <PrimaryWhiteButton
                type="submit"
                disabled={isLoading}
                style={{ marginRight: '40px', minWidth: '212px' }}
              >
                {isLoading ? <PinkLoader size="xs" /> : t('settings:updatePassword')}
              </PrimaryWhiteButton>
              <ButtonWithoutShadow type="button" onClick={handleClose}>
                {t('basicComponents:buttons.cancel')}
              </ButtonWithoutShadow>
            </ButtonsWrapper>
          </ConfirmCurrentPasswordWindowContainer>
        </form>
        <CloseModalWindowButton
          handleClick={handleClose}
          color="black"
        />
      </BaseModalWindow>
      <SuccessNotification
        isOpen={isSuccessWindow}
        text={t('passwordWasChanged')}
        setIsOpen={setIsSuccessWindow}
        longTime
        unsetSuccess={() => setIsSuccess(false)}
      />
    </>
  );
};
