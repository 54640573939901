import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModalWindow } from '../../../../../../../../shared/components/BaseModalWindow/BaseModalWindow';
import {
  EyeIcon,
  CloseIcon,
} from '../../../../../../../../shared/icons';
import {
  MediumGreyText,
  CloseButtonPositioned,
  BaseSecondaryButton,
} from '../../../../../../../../shared/styles';
import { ReminderEmailOpenPreviewBtn } from '../ReminderEmailForm/styled';
import { PreviewEmailContainer, PreviewEmailContentWrapper } from './styled';
import { PreviewEmailContent } from './PreviewEmailContent';
import { PreviewTooltip } from './PreviewTooltip';

export const PreviewEmail: React.FC = () => {
  const { t } = useTranslation('createNewGallery');

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <ReminderEmailOpenPreviewBtn type="button" onClick={handleOpen}>
        <EyeIcon />
        <MediumGreyText>
          {t('fourthStep.reminder.previewEmail.button')}
        </MediumGreyText>
      </ReminderEmailOpenPreviewBtn>
      <BaseModalWindow
        isOpen={isOpen}
        handleClose={handleClose}
        transparentBackground
        fitContent
        scroll="body"
      >
        <CloseButtonPositioned
          color="black"
          type="button"
          onClick={handleClose}
          style={{
            top: '18px',
            right: '20px',
          }}
        >
          <CloseIcon />
        </CloseButtonPositioned>
        <PreviewEmailContainer>
          <PreviewTooltip />
          <PreviewEmailContentWrapper>
            <PreviewEmailContent />
            <BaseSecondaryButton type="button" onClick={handleClose}>
              {t('fourthStep.reminder.previewEmail.save')}
            </BaseSecondaryButton>
          </PreviewEmailContentWrapper>
        </PreviewEmailContainer>
      </BaseModalWindow>
    </>
  );
};
