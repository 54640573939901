import { Grid } from '@material-ui/core';
import React from 'react';
import { GalleryCover } from '../../../../../shared/types';
import { PreviewFirstHeaderWrapper } from '../../../../Creator/containers/CreatorAccount/Tabs/NewGalleryCreator/NewGalleryCreatorFirstStep/PreviewGalleryCovers/PreviewFirst/styled';
import { useGalleryContent } from '../../../hooks/useGalleryContent';
import {
  FirstCoverDate, FirstImageContainer, FirstMediumText, FirstTitle,
} from './styled';
import { ImageSizes } from '../../../../../constants/galleryPreviewsAndCovers';

export const GalleryCoverFirst: React.FC<GalleryCover> = (props) => {
  const data = useGalleryContent({
    ...props,
    galleryNumber: 'first',
  });

  const { withCropper } = props;

  return (
    <Grid
      style={{ marginTop: '20px', marginBottom: '150px', maxWidth: withCropper ? ImageSizes.first.desktop.width : '1480px' }}
      container
      spacing={4}
      justifyContent="space-between"
    >
      <Grid item sm={12}>
        <PreviewFirstHeaderWrapper>
          <FirstTitle>{data.name}</FirstTitle>
          <FirstMediumText>{data.userName}</FirstMediumText>
        </PreviewFirstHeaderWrapper>
      </Grid>
      <Grid item sm={4}>
        <FirstMediumText>{data.message}</FirstMediumText>
      </Grid>
      <Grid container item sm={8} justifyContent="flex-end">
        <FirstImageContainer>
          {data.file}
          <FirstCoverDate>{data.date}</FirstCoverDate>
        </FirstImageContainer>
      </Grid>
    </Grid>
  );
};
