import { FormikHelpers } from 'formik';
import { useSignIn } from '@clerk/clerk-react';
import { useState } from 'react';
import { SignIn } from '../../../../shared/types';

type useCreatorSignInReturn = {
    isLoading: boolean;
    error: string;
    signInSubmit: (data: SignIn, bag: FormikHelpers<SignIn>) => void;
  }

export const useCreatorSignIn = (): useCreatorSignInReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { signIn, isLoaded, setActive } = useSignIn();

  const signInSubmit = async (data: SignIn, bag: FormikHelpers<SignIn>) => {
    if (!isLoaded) {
      return;
    }
    setError('');
    setIsLoading(true);
    try {
      const completeSignIn = await signIn.create({
        identifier: data.email,
        password: data.password,
      });

      if (completeSignIn.status !== 'complete') {
        console.log(JSON.stringify(completeSignIn, null, 2));
      }

      if (completeSignIn.status === 'complete') {
        await setActive({ session: completeSignIn.createdSessionId });
      }
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      setError(err?.errors[0]?.message);
    }
  };

  return ({
    isLoading,
    error,
    signInSubmit,
  });
};
