import styled from '@emotion/styled';
import * as variables from '../../../../../constants/styles';

export const CartPopupContainer = styled.div`
  position: absolute;
  top: 50px;
  right: 0px;
  height: fit-content;
  z-index: 10;
  min-width: 360px;
  background: #F5F5F5;
  border-radius: 10px;
  transition: all 0.5s;
  box-sizing: border-box;
  display: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  flex-direction: column;
  border: 2px solid transparent;
  border-radius: 9px;
  background-image: linear-gradient(${variables.colorGreyLight}, ${variables.colorGreyLight}), linear-gradient(270deg, #E6934F 0%, #E186B0 49.82%, #78C0EA 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

export const CartPopupWrapper = styled.div`
  width: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: inherit;
  background: #F8F8F8;
  box-sizing: border-box;
`;

export const CartPopupSectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin-top: 30px;

  & * {
    font-size: 14px !important;
  }
  & > div {
    margin-bottom: 0px;
    padding-top: 0px;
  }
`;

export const CartPopupSubtotalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  box-sizing: border-box;
`;

export const CartPopupBlurBackground = styled.div`
  position: absolute;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(2px);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
