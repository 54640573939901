import styled from '@emotion/styled';
import { Dialog, styled as MUIStyled } from '@mui/material';
import * as variables from '../../../../../../../constants/styles';
import { theme } from '../../../../../../../shared/components/GlobalContainer/styled';

export const GalleryDemoWindow = MUIStyled(Dialog)(() => ({
  '& .MuiPaper-root': {
    background: variables.colorGreyLight,
    padding: 0,
    minHeight: '100vh',
    position: 'relative',
  },
}));

export const PreviewArrowButton = styled.button`
  color: ${variables.colorBlack};
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;

  & div {
    display: inline-block;
    margin-right: 15px;

    & svg {
      color: ${variables.colorBlack};
    }
  }
`;

export const SentLinkWrapper = styled.div`
  position: absolute;
  left: 100%;
  bottom: 15px;
  white-space: pre;
  width: fit-content;
  display: flex !important;
  flex-direction: row;
  font-weight: 500;
  align-items: center;
  justify-content: center;

  & > svg {
    margin-right: 10px;
  }
`;

export const ButtonsGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  & > div:last-child {
    margin-left: 30px;
  }
`;

export const SharableLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
`;

export const GalleryPreviewHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background: ${variables.colorGreyLight};
  box-shadow: 0px 0px 20px 5px #E7E7E7;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 3000;
  box-sizing: border-box;
`;

export const HeaderTextWrapper = styled.div`
  max-width: 755px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 0px;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin-right: 0px;
    margin-bottom: 20px;

  }
`;

export const GalleryPreviewHeaderLogo = styled.div`
  width: 104px;
  height: fit-content;
  display: flex;

  & > svg {
    width: inherit;
    height: auto;
  }
`;

export const SendLinkButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: fit-content;

  & > button {
    margin-right: 15px;
  }
`;

export const GalleryPreviewHeaderContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;
