import { styled, Badge, BadgeProps } from '@material-ui/core';
import * as variables from '../../../constants/styles';

export const CustomBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    fontFamily: variables.mainFont,
    fontSize: '10px',
    lineHeight: '9px',
    height: '18px',
    width: 'fit-content',
    padding: '4px',
    minWidth: '18px',
    minHeight: '18px',
    boxSizing: 'border-box',
    color: variables.colorGreyLight,
    background: `${variables.colorPink} !important`,
    backgroundColor: `${variables.colorPink} !important`,
  },
  '& > span': {
    fontFamily: variables.mainFont,
    fontSize: '10px',
    lineHeight: '9px',
    height: '18px',
    width: 'fit-content',
    padding: '4px',
    minWidth: '18px',
    minHeight: '18px',
    boxSizing: 'border-box',
    color: variables.colorGreyLight,
    background: `${variables.colorPink} !important`,
    backgroundColor: `${variables.colorPink} !important`,
  },
}));
