import React, { useState, MouseEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getFileInContainer } from '../../../../../../../shared/utils/createGallery';
import { useDialog } from '../../../../../../../shared/hooks/useDialog';
import { DeleteIcon, BulbIcon } from '../../../../../../../shared/icons';
import { GalleryIdea } from '../../../../../../../shared/types';
import { IdeaModalWindow } from './Ideas/IdeaModalWindow';
import {
  FileCardWrapper,
  FileCardText,
  IdeaOpenButton,
} from './styled';

type IdeaCardProps = {
  handleDeleteIdea: (id: string) => void;
  idea: GalleryIdea;
  indexSection: number;
  indexIdea: number;
};

export const IdeaCard: React.FC<IdeaCardProps> = ({
  idea,
  handleDeleteIdea,
  indexSection,
  indexIdea,
}) => {
  const { t } = useTranslation('createNewGallery');
  const [isOpenIdeaModal, setIsOpenIdeaModal] = useState(false);

  const { setIsOpen, dialogWindow } = useDialog(
    'confirmation',
    idea.id,
    handleDeleteIdea,
    t('secondStep.confirmDelete.question'),
    t('secondStep.confirmDelete.title'),
  );

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (!(e.target instanceof HTMLButtonElement)) setIsOpen(true);
  };

  const coverFile = useMemo(
    () => getFileInContainer({ file: idea?.cover }),
    [idea?.cover],
  );

  return (
    <FileCardWrapper id="open">
      {idea?.cover ? (
        coverFile
      ) : (
        <FileCardText>{t('secondStep.withoutCover')}</FileCardText>
      )}
      <BulbIcon />
      <button aria-label="delete" className="delete-button" type="button" onClick={(e) => handleClick(e)}>
        <DeleteIcon />
      </button>
      <IdeaOpenButton onClick={() => setIsOpenIdeaModal(true)} />
      <IdeaModalWindow
        isOpen={isOpenIdeaModal}
        setIsOpen={setIsOpenIdeaModal}
        idea={idea}
        indexSection={indexSection}
        indexIdea={indexIdea}
      />
      {dialogWindow}
    </FileCardWrapper>
  );
};
