import styled from '@emotion/styled';
import * as variables from '../../../../../../../../constants/styles';
import { PrimaryWhiteButton, SimpleBlackText } from '../../../../../../../../shared/styles';
import { StyledTextarea } from '../../../../../../../../shared/components/FormElements/CommonTextarea/styled';

export const PreviewEmailContainer = styled.div`
  background: ${variables.colorGreyLight};
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

export const PreviewEmailContentWrapper = styled.div`
  background: ${variables.colorGreyLight};
  border-radius: 10px;
  margin: 16px 56px 32px;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PreviewEmailWrapper = styled.div`
  background: #fff;
  box-sizing: border-box;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const PreviewEmailWrapperContent = styled.div`
  padding: 30px 60px 60px 60px;
  box-sizing: border-box;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const PreviewEmailLogo = styled.div`
  & svg {
    width: 119.5px;
  }
`;

export const PreviewEmailTitle = styled(SimpleBlackText)`
  margin-bottom: 6px;
  text-align: center;
`;

export const PreviewEmailImage = styled.div`
  margin-top: 58px;
  margin-bottom: 23.5px;

`;

export const PreviewEmailTextareaWrapper = styled.div<{isSuccess: boolean}>`
  position: relative;
  width: 100%;
  margin-bottom: 24px;

  & #max-length {
    display: none;
  }

  &:focus-within {
    margin-bottom: 45px;

    & #max-length {
      display: flex;
    }

    & > div > svg {
      opacity: 0;
      transition: 0.1s ease-in-out;
    }
  }

  & > div > svg {
    opacity: 0;
    transition: 0.1s ease-in-out;
  }

  &:hover {
    & > div > svg {
      opacity: 1;
      transition: 0.1s ease-in-out;
    }
  }
`;

export const PreviewEmailTextareaElement = styled(StyledTextarea)<{isSuccess: boolean}>`
  color: ${variables.textBlack} !important;
  border-radius: 6px;
  box-sizing: border-box;
  background: #f5f5f5;
  padding: 17px;
  width: 100%;
  border: 1px solid ${({ isSuccess }) => (isSuccess ? '#f5f5f5' : variables.colorError)};
  text-align: flex-start;
  overflow: hidden;
  transition: 0.2s;
  font-size: 12px;

  &:focus {
    transition: 0.2s;
    background: transparent;
    border: 1px solid ${({ isSuccess }) => (isSuccess ? variables.colorBleak : variables.colorError)};
  }

  &:hover {
    transition: 0.2s;
    background: transparent;
    border: 1px solid ${({ isSuccess }) => (isSuccess ? variables.colorBleak : variables.colorError)};
  }
`;

export const PreviewEmailFooterContainer = styled.div`
  width: 100%;
  background: linear-gradient(297.92deg, #E6934F -25.37%, #E186B0 52.4%, #78C0EA 130.11%);
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-radius: 0px 0px 9px 9px;
`;

export const PreviewEmailFooterMedia = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;

  & svg {
    &:not(:first-of-type) {
      margin-left: 15px;
    }
  }
`;

export const PreviewEmailFooterText = styled.div`
  font-size: 8.8px;
  line-height: 13px;
  color: #fff;
  margin-top: 5px;
  font-weight: 400;
`;

export const PreviewEmailPrimaryWhiteButton = styled(PrimaryWhiteButton)`
  padding: 9.5px 21px;
`;

export const PreviewEmailTextareaEditIcon = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;

  & > svg {
    color: ${variables.textBlack};
  }
`;

export const PreviewEmailTooltipContainer = styled.div`
  width: 100%;
  background: ${variables.colorGreyLight};
  padding: 22px 56px;
  display: flex;
  box-sizing: border-box;
  border-radius: 9px 9px 0px 0px;
  box-shadow: 0px 0px 20px 5px #e7e7e7;

  & * {
    max-width: 444px;
  }
`;
