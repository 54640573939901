import * as Yup from 'yup';
import { errorMessages } from '../../utils/errors/errorsMessages';
import { passwordMinLength, password } from '../../../constants/validationValues';
import { CancelRenewalEnum } from '../../../modules/Creator/types/types';

export const changeEmailSchema = Yup.object().shape({
  email: Yup.string()
    .email(errorMessages.invalidEmail),
});

export const changePassword = Yup.object().shape({
  currentPassword: Yup.string()
    .required(errorMessages.requiredField)
    .min(passwordMinLength, errorMessages.passwordLength)
    .matches(password, errorMessages.passwordCharacters),
  password: Yup.string()
    .notOneOf(
      [Yup.ref('currentPassword'), null],
      errorMessages.passwordMustNotMatch,
    )
    .required(errorMessages.requiredField)
    .min(passwordMinLength, errorMessages.passwordLength)
    .matches(password, errorMessages.passwordCharacters),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    errorMessages.passwordMustMatch,
  ).required(errorMessages.requiredField),
});

export const watermarkSchema = Yup.object().shape({
  watermark: Yup.string(),
  file: Yup.string().when(
    'watermark',
    {
      is: !'',
      then: Yup.string()
        .required(errorMessages.requiredField),
    },
  ),
  text: Yup.string().when(
    'watermark',
    {
      is: !'',
      then: Yup.string()
        .required(errorMessages.requiredField),
    },
  ),
});

export const CancelRenewalSchema = (
  step: number,
) => {
  if (step === 1) {
    return Yup.object().shape({
      cause: Yup.string().required('You should choose one'),
      causeText: Yup.string().when(
        'cause',
        {
          is: CancelRenewalEnum.other,
          then: Yup.string()
            .required('You should leave a message'),
        },
      ),
    });
  }
  if (step === 2) {
    return Yup.object().shape({
      isAgreed: Yup.boolean()
        .oneOf([true], 'You should agree with this statement'),
    });
  }
  return null;
};
