import { createAsyncThunk } from '@reduxjs/toolkit';
import { commentsClient } from '../../../../api/comments/comments';
import { CommentDeleteReq, CommentPutReq } from '../../../../shared/types/client';
import { addCommentHandle as addCommentClient, deleteCommentHandle as delCommentClient } from '../../../Client/redux/signIn/signInClientSlice';
import { addCommentHandle, deleteCommentHandle } from '../gallery/gallerySlice';

export const fetchAddComment = createAsyncThunk(
  'comments/fetchAddComment',
  async (data: CommentPutReq, thunkApi) => {
    try {
      const response = await commentsClient.addComment(data);
      thunkApi.dispatch(addCommentClient(response.data));
      thunkApi.dispatch(addCommentHandle(response.data));
      return response.data;
    } catch (err: any) {
      return thunkApi.rejectWithValue(err.message);
    }
  },
);

export const fetchDeleteComment = createAsyncThunk(
  'comments/fetchDeleteComment',
  async (data: CommentDeleteReq, thunkApi) => {
    try {
      const response = await commentsClient.deleteComment(data);
      thunkApi.dispatch(delCommentClient({ commentId: data.messageId, fileId: data.fileId }));
      thunkApi.dispatch(deleteCommentHandle({ commentId: data.messageId, fileId: data.fileId }));

      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  },
);
