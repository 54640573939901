import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const PaymentsToClientsPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={8}
      listTitle={t('terms.paymentsToClients')}
      pointsNameForTrans="paymentsToClientsPoints"
    >
      <LandingNumberedLi>
        This clause applies to you, a Client.
      </LandingNumberedLi>
      <LandingNumberedLi>
        We do not charge you, a Client, for access to the Platform (except
        will pass on any third-party payment processing fees).
      </LandingNumberedLi>
      <LandingNumberedLi>
        The payment methods we offer for the Project Fees are set out on the
        Platform. We may offer payment through a third-party provider for
        example, Stripe and Paypal. You acknowledge and agree that we have
        no control over the actions of the third-party provider, and your
        use of the third-party payment method may be subject to additional
        terms and conditions.
      </LandingNumberedLi>
      <LandingNumberedLi>
        You must not pay, or attempt to pay, the Project Fees by fraudulent
        or unlawful means. If you make a payment by debit card or credit
        card, you warrant that you are authorised to use the debit card or
        credit card to make the payment.
      </LandingNumberedLi>
      <LandingNumberedLi>
        If you wish to download Output Data for a Project or Idea via our
        Platform, you must pay for the Output Data via the Platform and your
        chosen payment method will be charged the relevant Project Fees.
      </LandingNumberedLi>
      <LandingNumberedLi>
        If you use the Platform to make a Payment for Output Data, we will
        account to Members as set out in these Terms. You agree that if the
        applicable Member has a Deferred Payment plan, a Deferred Payment
        Service Fee will be deducted from the Project Fees before the
        Project Fees are accounted to the relevant Member as set out in
        clause 9.3.
      </LandingNumberedLi>
      <LandingNumberedLi>
        We do not store any credit card details, and all payment information
        is collected and stored through our third-party payment processor.
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
