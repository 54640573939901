import React, { CSSProperties, ReactNode } from 'react';
import { HelpTip } from '../HelpTip/HelpTip';
import { TextLabelWrapper } from '../styled';
import { LabelWrapper } from './styled';

type TextLabelProps = {
  name: string;
  label?: string | ReactNode;
  disabled?: boolean;
  short?: boolean;
  wrapperDirection?: 'row' | 'column';
  helpText?: string;
  littleText?: boolean;
  styles?: CSSProperties;
  labelWhiteSpace?: 'pre';
};

export const TextLabel: React.FC<TextLabelProps> = ({
  styles,
  name,
  label,
  disabled,
  short,
  wrapperDirection = 'column',
  helpText,
  littleText,
  labelWhiteSpace,
}) => (label ? (
  <TextLabelWrapper
    style={styles}
    wrapperDirection={wrapperDirection}
    id={name}
    disabled={disabled}
    short={short}
  >
    <LabelWrapper littleText={!!littleText} labelWhiteSpace={labelWhiteSpace}>{label}</LabelWrapper>
    <div>{helpText && <HelpTip text={helpText} withMgLeft />}</div>
  </TextLabelWrapper>
) : null);
