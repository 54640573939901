import { v4 as uuidv4 } from 'uuid';
import gallery7 from '../../../assets/image/walkThroughCards/gallery7.jpg';
import gallery8 from '../../../assets/image/walkThroughCards/gallery8.jpg';
import gallery9 from '../../../assets/image/walkThroughCards/gallery9.jpg';
import { GalleryComment } from '../../../shared/types';

export const fakeComments: GalleryComment[] = [
  {
    id: uuidv4(),
    text: 'No problem, Sarah! This will take a few minutes. WIll upload a new photo in a few hours ',
    date: `${new Date(2021, 10, 7, 11, 58)}`,
    photoUrl: '',
    userName: 'User one',
    email: 'example@gmail.com',
    file: gallery7,
  },
  {
    id: uuidv4(),
    text: 'Really love this pic! Wanna download it, but would like you to retouch it so that my face look smoother. Thanks in advance ',
    date: `${new Date()}`,
    photoUrl: '',
    userName: 'User one',
    email: 'example@gmail.com',
    file: gallery8,
  },
  {
    id: uuidv4(),
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ',
    date: `${new Date(2021, 8, 8, 16, 12)}`,
    photoUrl: '',
    userName: 'User one',
    email: 'example@gmail.com',
    file: gallery9,
  },
  {
    id: uuidv4(),
    text: 'Really love this pic! Wanna download it, but would like you to retouch it so that my face look smoother. Thanks in advance ',
    date: `${new Date(2021, 9, 8, 16, 12)}`,
    photoUrl: '',
    userName: 'User one',
    email: 'example@gmail.com',
    file: gallery8,
  },
  {
    id: uuidv4(),
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ',
    date: `${new Date(2021, 8, 8, 16, 12)}`,
    photoUrl: '',
    userName: 'User one',
    email: 'example@gmail.com',
    file: gallery9,
  },
];
