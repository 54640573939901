import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const GeneralPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={21}
      listTitle={t('terms.general')}
      pointsNameForTrans="generalPoints"
    >
      <LandingNumberedLi>
        <span className="bold">Assignment:</span>
        {' '}
        Subject to the below
        clause, a Party must not assign or deal with the whole or any part
        of its rights or obligations under these Terms without the prior
        written consent of the other Party (such consent is not to be
        unreasonably withheld).
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Assignment of Debt:</span>
        {' '}
        You agree that we
        may assign or transfer any debt owed by you to us, arising under or
        in connection with these Terms, to a debt collector, debt collection
        agency, or other third party.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Confidentiality:</span>
        {' '}
        Other than where the
        disclosure is permitted by law, each Party agrees not to disclose
        any confidential information it may access on or through the
        Platform to a third party, or otherwise misuse such confidential
        information. Confidential information may include confidential
        information supplied to you by us, by a Client, or by a Member.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Disputes:</span>
        {' '}
        In relation to a dispute,
        controversy or claim arising from, or in connection with, these
        Terms (including any question regarding its existence, validity or
        termination) (
        <span className="bold">Dispute</span>
        ) between a
        Client and us, or a Member and us, a Party may not commence court
        proceedings relating to a Dispute without first meeting with a
        senior representative of the other Party to seek (in good faith) to
        resolve the Dispute. If the Parties cannot agree how to resolve the
        Dispute at that initial meeting, either Party may refer the matter
        to a mediator. If the Parties cannot agree on who the mediator
        should be, either Party may ask the Queensland Law Society to
        appoint a mediator. The mediator will decide the time, place and
        rules for mediation. The Parties agree to attend the mediation in
        good faith, to seek to resolve the Dispute. The costs of the
        mediation will be shared equally between the Parties. Nothing in
        this clause will operate to prevent a Party from seeking urgent
        injunctive or equitable relief from a court of appropriate
        jurisdiction.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Entire Terms:</span>
        {' '}
        Subject to your Consumer
        Law Rights, these Terms contains the entire understanding between
        the Parties and the Parties agree that no representation or
        statement has been made to, or relied upon by, either of the
        Parties, except as expressly stipulated in these Terms, and these
        Terms supersedes all previous discussions, communications,
        negotiations, understandings, representations, warranties,
        commitments and agreements, in respect of its subject matter.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Further assurance:</span>
        {' '}
        Each Party must
        promptly do all things and execute all further instruments necessary
        to give full force and effect to these Terms and their obligations
        under it.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Governing law:</span>
        {' '}
        This Agreement is
        governed by the laws of Queensland. Each Party irrevocably and
        unconditionally submits to the exclusive jurisdiction of the courts
        operating in Queensland and any courts entitled to hear appeals from
        those courts and waives any right to object to proceedings being
        brought in those courts.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Notices:</span>
        {' '}
        Any notice given under these
        Terms must be in writing addressed to us at the details set out
        below or to you at the details provided in your Account. Any notice
        may be sent by standard post or email, and will be deemed to have
        been served on the expiry of 48 hours in the case of post, or at the
        time of transmission in the case of transmission by email.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Publicity:</span>
        {' '}
        With your prior written
        consent, you agree that we may advertise or publicise the broad
        nature of our supply of the Delivrable Services to you, including on
        our website or in our promotional material.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Relationship of Parties:</span>
        {' '}
        These Terms
        are not intended to create a partnership, joint venture, employment
        or agency relationship between the Parties.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Severance:</span>
        {' '}
        If a provision of these
        Terms is held to be void, invalid, illegal or unenforceable, that
        provision is to be read down as narrowly as necessary to allow it to
        be valid or enforceable, failing which, that provision (or that part
        of that provision) will be severed from these Terms without
        affecting the validity or enforceability of the remainder of that
        provision or the other provisions in these Terms.
      </LandingNumberedLi>
      <LandingNumberedLi>
        <span className="bold">Third party sites:</span>
        {' '}
        The Platform may
        contain links to websites operated by third parties. Unless we tell
        you otherwise, we do not control, endorse or approve, and are not
        responsible for, the content on those websites. We recommend that
        you make your own investigations with respect to the suitability of
        those websites. If you purchase goods or services from a third party
        website linked from the Platform, such third party provides the
        goods and services to you, not us. We may receive a benefit (which
        may include a referral fee or a commission) should you visit certain
        third-party websites via a link on the Platform (
        <span className="bold">Affiliate Link</span>
        ) or for featuring
        certain products or services on the Platform. We will make it clear
        by notice to you which (if any) products or services we receive a
        benefit to feature on the Platform, or which (if any) third party
        links are Affiliate Links.
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
