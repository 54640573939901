import { Button } from '@mui/material';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import React, {
  CSSProperties, FormEvent, ReactElement, useEffect, useState,
} from 'react';
import { PinkLoader } from '../Loaders/PinkLoader/PinkLoader';
import {
  PaymentForm,
  PaymentPayBtnWrapper,
  PaymentElementWrapper,
} from '../../../modules/Client/containers/Cart/CartSummary/ClientPayment/styled';
import { BaseColors } from '../../types';

type PaymentPageProps = {
  returnUrl: string;
  styles?: {
    paymentForm?: CSSProperties;
    button?: CSSProperties;
  };
  loader?: {
    color?: BaseColors;
    size?: 'xs';
  }
  canBeSubmited?: () => boolean;
  agreement?: ReactElement;
};

export const PaymentPage: React.FC<PaymentPageProps> = ({
  returnUrl,
  styles,
  canBeSubmited,
  agreement,
  loader,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<{
    message: string;
    type: string;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentElementLoading, setIsPaymentElementLoading] = useState(true);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage({ type: 'success', message: 'Payment succeeded!' });
          break;
        case 'processing':
          setMessage({
            type: 'process',
            message: 'Your payment is processing.',
          });
          break;
        case 'requires_payment_method':
          setMessage({
            type: 'error',
            message: 'Your payment was not successful, please try again.',
          });
          break;
        default:
          setMessage({ type: 'error', message: 'Something went wrong.' });
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const check = canBeSubmited ? canBeSubmited() : true;

    if (check) {
      if (!stripe || !elements) {
        return;
      }

      setIsLoading(true);

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: returnUrl,
        },
      });

      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage({
          type: 'error',
          message: error.message || 'Validation failed',
        });
        console.log(error);
      } else {
        setMessage({ type: 'error', message: 'An unexpected error occurred.' });
        console.log(error);
      }

      setIsLoading(false);
    }
  };

  return (
    <PaymentForm
      style={styles?.paymentForm}
      id="payment-form"
      onSubmit={handleSubmit}
    >
      <PaymentElementWrapper>
        <PaymentElement
          id="payment-element"
          onReady={() => setIsPaymentElementLoading(false)}
        />
      </PaymentElementWrapper>
      {agreement}
      {isPaymentElementLoading ? (
        <PinkLoader />
      ) : (
        <PaymentPayBtnWrapper>
          <Button
            style={styles?.button}
            type="submit"
            disabled={isLoading || !stripe || !elements}
            id="submit"
            variant="contained"
          >
            <span id="button-text">
              {isLoading ? <PinkLoader size={loader?.size} color={loader?.color} /> : 'Pay now'}
            </span>
          </Button>
        </PaymentPayBtnWrapper>
      )}
      {message && (
        <div
          style={{
            color: message.type === 'error' ? 'red' : 'black',
            marginTop: '20px',
          }}
          id="payment-message"
        >
          {message.message}
        </div>
      )}
    </PaymentForm>
  );
};
