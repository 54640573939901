import React from 'react';
import { Grid, ThemeProvider } from '@material-ui/core';
import { ClientGallery } from '../../../../shared/types';
import { Navigation } from './Navigation';
import { SectionsList } from './SectionsList/SectionsList';
import { GalleryContentWrapper } from './styled';
import { theme } from '../../../../shared/components/GlobalContainer/styled';

type GalleryContentProps = {
  gallery: ClientGallery | null;
};

export const GalleryContent: React.FC<GalleryContentProps> = ({ gallery }) => {
  const sections = gallery?.sections || [];

  return (
    <GalleryContentWrapper>
      <ThemeProvider theme={theme}>
        <Grid container spacing={0} id="galleryContent">
          <Grid item lg={2} md={12} sm={12} xs={12}>
            <Navigation sections={sections} />
          </Grid>
          <Grid item lg={9} md={12} sm={12} xs={12}>
            <SectionsList sections={sections} />
          </Grid>
        </Grid>
      </ThemeProvider>
    </GalleryContentWrapper>
  );
};
