import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { FormikHelpers, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as urls from '../../../../constants/urls';
import { CommonInput } from '../../../../shared/components/FormElements/CommonInput/CommonInput';
import {
  GreyLink,
  MediumBlackTextAndLink,
  SignInUpCard,
  TitleSimple,
  CustomGrid,
  ErrorWrapper,
  PrimaryWhiteButtonBlueTextNoMg,
} from '../../../../shared/styles';
import { SignInSchema } from '../../../../shared/validation/signInUp/creator/signInUpSchemas';
import { SignIn } from '../../../../shared/types';
import { signInInitialValues } from '../../../../constants/initialValues';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../shared/hooks/useTypedSelector';
import { ErrorMessage } from '../../../../shared/components/styled';
import { SignInForm } from './styled';
import { useCreatorSignIn } from './useCreatorSignIn';
import { PinkLoader } from '../../../../shared/components/Loaders/PinkLoader/PinkLoader';

export const SignInCard: React.FC = () => {
  const { t } = useTranslation(['signIn', 'basicComponents']);
  const { isLoading, error, signInSubmit } = useCreatorSignIn();
  const onSubmit = async (data: SignIn, bag: FormikHelpers<SignIn>) => {
    bag.setSubmitting(false);
    signInSubmit(data, bag);
  };

  const {
    values, handleSubmit, errors, handleChange, touched, handleBlur,
  } = useFormik({
    initialValues: signInInitialValues,
    validationSchema: SignInSchema,
    onSubmit: (data: SignIn, bag) => onSubmit(data, bag),
  });

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xl={6} lg={7} md={8} xs={11}>
        <SignInUpCard theme="signin">
          <TitleSimple>{t('basicComponents:signIn')}</TitleSimple>
          <MediumBlackTextAndLink>
            {t('dontHaveAccount')}
            <Link to={urls.signUpInitialUrl}>
              {t('basicComponents:signUp')}
            </Link>
          </MediumBlackTextAndLink>
          <SignInForm direction="column">
            <form onSubmit={handleSubmit} noValidate>
              <Grid container justifyContent="center">
                <CustomGrid item lg={9} md={11} xs={11}>
                  <CommonInput
                    error={errors.email}
                    value={values.email}
                    name="email"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    type="email"
                    placeholder="Your email"
                    touched={touched.email}
                  />
                </CustomGrid>
                <CustomGrid item lg={9} md={11} xs={11}>
                  <CommonInput
                    error={errors.password}
                    value={values.password}
                    name="password"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    type="password"
                    placeholder="Password"
                    touched={touched.password}
                  />
                </CustomGrid>
                {(error)
                && (
                  <CustomGrid item xs={11}>
                    <ErrorWrapper style={{
                      display: 'flex', justifyContent: 'center',
                    }}
                    >
                      <ErrorMessage>{error}</ErrorMessage>
                    </ErrorWrapper>
                  </CustomGrid>
                )}
              </Grid>
              <PrimaryWhiteButtonBlueTextNoMg type="submit" width="307" disabled={isLoading}>
                {isLoading ? (<PinkLoader size="xs" />) : t('basicComponents:signIn')}
              </PrimaryWhiteButtonBlueTextNoMg>
            </form>
          </SignInForm>
          <GreyLink>
            <Link to={urls.resetUrl}>{t('forgotPassword')}</Link>
          </GreyLink>
        </SignInUpCard>
      </Grid>
    </Grid>
  );
};
