import styled from '@emotion/styled';
import { BaseTextCopied } from '../styled';

export const CopyIconButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

export const CopyLinkButton = styled.button`
  display: flex;
  padding: 0;
  position: relative;
  float: left;
  transition: 0s opacity;

  &:hover {
    & > #tooltip {
      transition-property: opacity;
      transition-delay: 0.5s;
      opacity: 1;
    }
  }
`;

export const TextLinkCopied = styled(BaseTextCopied)`
  top: -20px;
  left: calc(40% - 24px);
`;
