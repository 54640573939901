import React from 'react';
import {
  createStyles, Switch, withStyles,
} from '@material-ui/core';

export const CustomSwitch = withStyles(() => createStyles({
  root: {
    margin: '-12px',
    height: 22,
    width: 42,
    boxSizing: 'content-box',
  },
  switchBase: {
    padding: 0,
    color: (props: any) => (props.bluebutton === 'true' ? '#54C2EF' : '#F8F8F8'),
    top: 10,
    left: 12,
    boxShadow: '-2px -2px 3px #FFFFFF, 2px 2px 3px #E7E7E7',
    '&$checked': {
      transform: 'translateX(16px)',
      color: '#F8F8F8',
      left: 14,
      boxShadow: '2px 2px 3px #E7E7E7 !important',
      '& + $track': {
        width: 42,
        height: 22,
        backgroundColor: '#54C2EF',
        opacity: 1,
        border: '0.5px solid #e2e2e2',
        boxShadow: 'inset -1px 1px 4px #A4D4EF, inset 1px 1px 4px #239DCE',
        top: 0,
      },
      '& + $thumb': {
        color: '#F8F8F8',
        border: '0.5px solid #e2e2e2',
      },
    },
    '&.MuiSwitch-colorSecondary.Mui-checked': {
      color: '#F8F8F8 !important',
    },
    '&$focusVisible $thumb': {
      color: '#F8F8F8 !important',
      boxShadow: '-2px -2px 3px #FFFFFF, 2px 2px 3px #E7E7E7',
      border: '0.5px solid #e2e2e2',

    },
    '&.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
      top: 2,
      width: 42,
      height: 22,
      borderRadius: 163,
      border: 'none',
      backgroundColor: '#F8F8F8',
      boxShadow: 'inset 1px 1px 4px #DFDEDE, inset -1px 1px 5px rgba(223, 222, 222, 0.4), inset -5px -5px 7px #FFFFFF',
      opacity: 1,
    },
    '&.MuiSwitch-colorSecondary.Mui-disabled': {
      color: '#F8F8F8',
    },
  },
  thumb: {
    top: 8,
    width: 24,
    height: 24,
    boxShadow: '2px 2px 3px #E7E7E7',
    border: '0.5px solid #e2e2e2',
  },
  track: {
    top: 2,
    width: 42,
    height: 22,
    borderRadius: 163,
    // border: 'none',
    backgroundColor: '#F8F8F8',
    boxShadow: 'inset 1px 1px 4px #DFDEDE, inset -1px 1px 5px rgba(223, 222, 222, 0.4), inset -5px -5px 7px #FFFFFF',
    opacity: 1,
    border: '0.5px solid #e2e2e2',
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }: any) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    {...props}
  />
));
