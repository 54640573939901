import styled from '@emotion/styled';

export const InvisibleButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
`;
