import React from 'react';
import { FileLoaderWrapper } from './styled';

type FileLoaderProps = {
  isLoading: boolean;
  isLittle?: boolean;
}

export const FileLoader: React.FC<FileLoaderProps> = ({
  isLoading,
  isLittle,
}) => (
  isLoading ? (
    <FileLoaderWrapper id="loader" isLittle={!!isLittle}>
      <svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M53.0023 26.76C52.9389 23.2105 52.1751 19.7084 50.7546 16.4549C49.3341 13.2014 47.285 10.2605 44.7248 7.80104C40.9545 4.15754 36.1873 1.71547 31.0275 0.784373C25.8677 -0.146719 20.5476 0.475092 15.7416 2.57099C12.5988 3.95001 9.75562 5.94873 7.40277 8.40032C5.04993 10.8519 3.19081 13.7598 1.96501 16.9026C-0.492129 23.2156 -0.351357 30.2444 2.35659 36.454C3.69134 39.4912 5.62878 42.2425 8.00205 44.517C10.3753 46.7915 13.1878 48.5859 16.2285 49.7709C19.2644 50.9506 22.5028 51.521 25.759 51.4495C30.579 51.3561 35.2629 49.8353 39.2183 47.0794C43.1737 44.3234 46.2228 40.4563 47.98 35.9671C48.6709 34.1895 49.1453 32.3352 49.393 30.4442L49.5939 30.451C50.0647 30.4507 50.5304 30.3528 50.9614 30.1635C51.3925 29.9742 51.7796 29.6975 52.0983 29.3509C52.417 29.0044 52.6604 28.5955 52.8131 28.1501C52.9657 27.7048 53.0244 27.2326 52.9853 26.7634H52.9989L53.0023 26.76ZM47.5918 35.8002C46.3422 38.6298 44.5341 41.1937 42.3141 43.3116C40.094 45.4295 37.4722 47.098 34.6392 48.1944C31.81 49.29 28.7921 49.8153 25.759 49.7402C22.7682 49.6756 19.8195 49.0212 17.082 47.8146C14.3446 46.608 11.8722 44.873 9.80669 42.7089C7.76974 40.5751 6.17306 38.0609 5.10782 35.3099C2.9915 29.8326 3.13354 23.7395 5.50279 18.3667C6.6673 15.7449 8.34936 13.3682 10.4094 11.4069C12.4694 9.44565 14.9074 7.90319 17.5326 6.89191C20.1578 5.87723 22.967 5.40053 25.759 5.47544C28.5511 5.55035 31.3058 6.16325 33.822 7.28689C36.3417 8.40713 38.6231 10.0279 40.506 12.0096C42.389 13.9913 43.8702 16.3339 44.8372 18.857C45.8032 21.3756 46.2625 24.0601 46.1889 26.7566H46.2026C46.129 27.6414 46.4039 28.52 46.9687 29.205C47.5335 29.89 48.3436 30.3274 49.2262 30.4238C48.898 32.274 48.3481 34.0781 47.5884 35.7968L47.5918 35.8002Z" fill="white" />
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          values="0,0,0;360,0,0"
          dur="1.4s"
          repeatCount="indefinite"
        />
      </svg>
    </FileLoaderWrapper>
  ) : null
);
