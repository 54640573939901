import { Grid } from '@material-ui/core';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import { useFormikContext } from 'formik';
import { CommonInput } from '../../../../../../../../shared/components/FormElements/CommonInput/CommonInput';
import { GalleryData, GalleryIdea } from '../../../../../../../../shared/types';
import { CommonTextarea } from '../../../../../../../../shared/components/FormElements/CommonTextarea/CommonTextarea';
import { IdeaFormWrapper, IdeaWrapper, TextareaHeight } from './styled';
import { IdeaFilesInput } from './IdeaFilesInput';
import { getIdeasErrorsAndTouched } from '../../../../../../../../shared/utils/createGallery/getErrorsAndTouched';
import { CommonPriceInput } from '../../../../../../../../shared/components/FormElements/CommonPriceInput/CommonPriceInput';

type IdeaFormProps = {
  idea?: GalleryIdea;
  ideaField: string;
  indexSection: number;
  indexIdea: number;
  submitButton: ReactElement;
};

export const IdeaForm: React.FC<IdeaFormProps> = ({
  idea,
  ideaField,
  indexSection,
  indexIdea,
  submitButton,
}) => {
  const { t } = useTranslation('createNewGallery');
  const {
    handleChange, handleBlur, errors, touched, setFieldValue,
  } = useFormikContext<GalleryData>();

  const errorsAndTouched = useMemo(
    () => getIdeasErrorsAndTouched(errors, touched, indexSection, indexIdea),
    [errors, touched],
  );

  return (
    <IdeaWrapper>
      <IdeaFormWrapper style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid container item sm={12} spacing={1} justifyContent="space-between">
          <Grid container item sm={7} alignContent="flex-start">
            <Grid item sm={11}>
              <CommonInput
                error={errorsAndTouched?.error?.name || ''}
                value={idea?.name}
                name={`${ideaField}.name`}
                handleChange={handleChange}
                handleBlur={handleBlur}
                type="text"
                touched={!!errorsAndTouched?.wasTouched?.name}
                placeholder={t('secondStep.nameIdea')}
              />
            </Grid>
            <Grid item sm={11}>
              <TextareaHeight>
                <CommonTextarea
                  name={`${ideaField}.description`}
                  value={idea?.description}
                  handleChange={handleChange}
                  isFullHeight
                  error={errorsAndTouched?.error?.description || ''}
                  touched={!!errorsAndTouched?.wasTouched?.description}
                  placeholder={t('secondStep.description')}
                  maxTextLength={500}
                />
              </TextareaHeight>
            </Grid>
            <Grid item sm={7}>
              <CommonPriceInput
                label={t('secondStep.price')}
                value={idea?.price}
                name={`${ideaField}.price`}
                // handleChange={handleChange}
                // handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                direction="row"
                error={errorsAndTouched?.error?.price || ''}
                touched={!!errorsAndTouched?.wasTouched?.price}
              />
            </Grid>
            {submitButton}
          </Grid>
          {idea
          && (
          <IdeaFilesInput
            idea={idea}
            ideaField={ideaField}
            errors={errorsAndTouched?.error}
            touched={errorsAndTouched?.wasTouched}
          />
          )}
        </Grid>
      </IdeaFormWrapper>
    </IdeaWrapper>
  );
};
