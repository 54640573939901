import React, {
  ReactElement, useCallback, useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Fade } from '@mui/material';
import { AfterAddToCart } from '../../../../shared/styles/Elements';
import {
  fetchAddToCart,
  fetchRemoveFromCart,
} from '../../../Client/redux/cart/cartClientThunk';
import { useGalleryAppDispatch, useTypedSelectorClient } from '../../../../shared/hooks/useTypedSelector';
import { getCartReqData } from '../../utils/helpers';
import { useFreeFilesInSection } from '../useFreeFilesInSection';

type ReturnObj = {
  isInCart: boolean;
  handleClickCart: (id: string, sectionIdLocal?: string) => void;
  hoverElement?: ReactElement | null;
  isVisible: boolean;
};

const AfterAddToCartNotification: React.FC<{
  isVisible: boolean;
  sectionId: string;
}> = ({ isVisible, sectionId }) => {
  const { t } = useTranslation('gallery');
  const { remainFreeFiles } = useFreeFilesInSection(sectionId || '');

  return (
    <Fade in={isVisible}>
      <AfterAddToCart>
        <Trans t={t} i18nKey="moreFreeFiles">
          You have
          {' '}
          {{ files: remainFreeFiles }}
          {' '}
          more free files in this section!
        </Trans>
      </AfterAddToCart>
    </Fade>
  );
};

export const useAddToCart = (
  isFileInCart: boolean,
  sectionId: string,
): ReturnObj => {
  const [isInCart, setIsInCart] = useState(isFileInCart);
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useGalleryAppDispatch();
  const gallery = useTypedSelectorClient((state) => state.signIn?.gallery);
  const { isFree, remainFreeFiles } = useFreeFilesInSection(sectionId);

  const showElement = () => {
    setIsVisible(true);
    setTimeout(() => setIsVisible(false), 1500);
  };

  const handleClickCart = useCallback(
    (fileId: string, sectionIdLocal?: string) => {
      const data = getCartReqData([fileId], gallery, [{ id: sectionIdLocal || '', remainFreeFiles }]);

      if (!isInCart) {
        dispatch(fetchAddToCart(data));
        setIsInCart(true);
        showElement();
      } else {
        dispatch(fetchRemoveFromCart(data));
        setIsInCart(false);
      }
    },
    [gallery, isInCart, dispatch, remainFreeFiles],
  );

  return {
    isInCart,
    handleClickCart,
    hoverElement: isFree ? (
      <AfterAddToCartNotification sectionId={sectionId} isVisible={isVisible} />
    ) : null,
    isVisible,
  };
};
