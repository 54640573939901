import React from 'react';
import { RowsWrapper, TabPanelColumnWrapper } from '../styled';
import { Payments } from './Payments/Payments';
import { RecentBillingHistory } from './RecentBillingHistory';
import { Storage } from './Storage';
import { Subscription } from './Subscription';
import { PinkLoader } from '../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';
import { TabSettingsWrapper } from '../ProfileTab/styled';

export const UsageAndBilling: React.FC = () => {
  const isLoadingUser = useTypedSelectorCreator(
    (state) => state.creator.loading,
  );

  // if (isLoadingUser) {
  //   return (
  //     <TabSettingsWrapper>
  //       <RowsWrapper>
  //         <PinkLoader />
  //       </RowsWrapper>
  //     </TabSettingsWrapper>
  //   );
  // }
  return (
    <TabPanelColumnWrapper>
      <Storage />
      <Subscription />
      <Payments />
      <RecentBillingHistory />
      {/* //! hide for now */}
      {/* <UpgradeToPremium /> */}
    </TabPanelColumnWrapper>
  );
};
