import styled from '@emotion/styled';

export const ButtonWithIconAndTextWrapper = styled.button<{withText: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  
  ${({ withText }) => (withText ? `
  & > button {
    margin-right: 7px;
  }
  ` : '')}
`;
