import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyInsideListWrapper, TermsAndPrivacyListItem } from '../styled';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const HowWorksPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={6}
      listTitle={t('terms.howWorks')}
      pointsNameForTrans="howWorksPoints"
    >
      <LandingNumberedLi>
        Steps to create a project:
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            The Member contacts the Client off Platform.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            The Member and Client agree off Platform on an accurate and
            complete description of the goods (being Output Data) the Member
            can provide to the Client (
            <span className="bold">Project</span>
            ), including the requirements (such as Your Data), fees for the
            goods including any GST payable (
            <span className="bold">Project Fees</span>
            ) and timing (
            <span className="bold">Project Terms</span>
            ). A Client and Member may enter into a written agreement in
            relation to a Project and if they do, such terms must include
            all terms and conditions relating to the relevant Project or
            must clearly state that there are additional terms and
            conditions. To the extent there is inconsistency between any
            Project terms and conditions and these Terms, these Terms will
            prevail. By entering into Project Terms, both the Member and the
            Client confirm that each are legally entitled to and capable of
            performing the obligations in the Project Terms.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            After the parties have agreed on the Project Terms, the Member
            starts and completes the Project.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            Once the Project is complete, the Member uses the Platform to
            flag to the Client that the Project is complete and waiting
            viewing and payment.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            The Client can review the Project and any Ideas (see clause 6.2)
            via the Platform and if appropriate based on the Project Terms,
            can request a revision to the Project Output Data from the
            Member.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            Once the Client is happy with the Output Data, the Client makes
            a payment of the Project Fees via the Platform which will enable
            it to download the Project Output Data.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            using data mining, robots, screen scraping or similar data
            gathering and extraction tools on the Platform;
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            facilitating or assisting a third party to do any of the above
            acts.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Ideas:
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            A Member may also use the Platform to post an accurate and
            complete description of additional goods they are willing to
            offer a Client through the Platform and the cost, including any
            GST payable (
            <span className="bold">Idea</span>
            ).
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            If a Client wishes to acquire the goods offered in an Idea, the
            Client can (as applicable) contact the Client off Platform to
            create a new Project based on the Idea or if the Idea is ready
            for purchase, can pay the Idea Project Fees via the Platform and
            download the Idea Output Data.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        The Platform contains a functionality that allows Clients to
        “favourite”, “comment”, and “share” for each specific Output Data
        item or Idea. Any personal information collected by us via this
        functionality will be dealt with by us in accordance with clause 21
        of these Terms.
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
