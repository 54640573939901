import React, {
  useEffect, useState,
} from 'react';
import { DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ButtonWithoutShadow, PrimaryWhiteButton } from '../../styles/Buttons';
import {
  ButtonsWrapper,
  CloseButton,
  CustomDialogActions,
  CustomDialogContentText,
  CustomTitleDialog,
  getAlertStyles,
} from './styled';
import { CloseIcon } from '../../icons';
import { AlertDialog } from './AlertDialog';
import { AlertTypes } from '../../types';

export type CustomAlert = {
  message: string;
  title: string;
  onClick?: any;
  isOpenWindow?: boolean;
  type?: AlertTypes;
  item?: any;
  buttonText?: string;
  noCancelBtn?: boolean;
  onClose?: () => void;
};

export type YesOrNoAlertProps = {
  message: string;
  title: string;
  onYes: () => void;
  onNo: () => void;
  isOpenWindow?: boolean;
  onClose?: () => void;
};

export const YesOrNoAlert: React.FC<YesOrNoAlertProps> = ({
  isOpenWindow,
  title,
  message,
  onClose,
  onNo,
  onYes,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setIsOpen(false);
  };

  useEffect(() => {
    setIsOpen(!!isOpenWindow);
  }, [isOpenWindow]);

  return (
    <div>
      <AlertDialog
        open={!!isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: getAlertStyles('confirmation') }}
      >
        <CloseButton type="button" onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <CustomTitleDialog confirmation id="alert-dialog-title">
          {title}
        </CustomTitleDialog>
        <DialogContent>
          <CustomDialogContentText id="alert-dialog-description">
            {message}
          </CustomDialogContentText>
        </DialogContent>
        <CustomDialogActions>
          <PrimaryWhiteButton
            type="button"
            onClick={onYes}
          >
            Yes
          </PrimaryWhiteButton>
          <ButtonsWrapper>
            <ButtonWithoutShadow onClick={onNo}>
              No
            </ButtonWithoutShadow>
          </ButtonsWrapper>
        </CustomDialogActions>
      </AlertDialog>
    </div>
  );
};

export const CustomAlert: React.FC<CustomAlert> = ({
  isOpenWindow,
  title,
  message,
  item,
  onClick,
  buttonText,
  noCancelBtn,
  onClose,
  type = 'confirmation',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('basicComponents');

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setIsOpen(false);
  };
  const handleClick = () => {
    if (onClick) {
      onClick(item);
    }
  };

  useEffect(() => {
    setIsOpen(!!isOpenWindow);
  }, [isOpenWindow]);

  return (
    <div>
      <AlertDialog
        open={!!isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: getAlertStyles(type) }}
      >
        <CloseButton type="button" onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <CustomTitleDialog confirmation={type !== 'warning'} id="alert-dialog-title">
          {title}
        </CustomTitleDialog>
        <DialogContent>
          <CustomDialogContentText id="alert-dialog-description">
            {message}
          </CustomDialogContentText>
        </DialogContent>
        <CustomDialogActions>
          {type !== 'confirmation' ? (
            <PrimaryWhiteButton onClick={handleClose} color="black" autoFocus>
              {t('buttons.okay')}
            </PrimaryWhiteButton>
          ) : (
            <ButtonsWrapper>
              <PrimaryWhiteButton
                type="button"
                onClick={handleClick}
              >
                {buttonText || t('buttons.sure')}
              </PrimaryWhiteButton>
              {!noCancelBtn && (
                <ButtonWithoutShadow onClick={handleClose}>
                  {t('buttons.cancel')}
                </ButtonWithoutShadow>
              )}
            </ButtonsWrapper>
          )}
        </CustomDialogActions>
      </AlertDialog>
    </div>
  );
};
