/* eslint-disable */
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModalWindow } from '../../../../../../../../shared/components/BaseModalWindow/BaseModalWindow';
import {
  ChangePhotoWindowContainer,
  ChangePhotoWindowWrapper,
  ZoomButtonsWrapper,
  ProfilePhotoUpload,
  ProfilePictureContainer,
} from './styled';
import { PrimaryWhiteButton, RoundButton, SimpleBlackText } from '../../../../../../../../shared/styles';
import { useSaveCroppedImage } from '../../../../../../../../shared/hooks/useSaveCroppedImage';
import { CustomFile, SetFieldValue } from '../../../../../../../../shared/types';
import { CloseModalWindowButton } from '../../../../../../../../shared/components/CloseModalWindowButton/CloseModalWindowButton';
import { BaseCropper } from '../../../../../../../../shared/components/BaseCropper/BaseCropper';
import { useUser } from '@clerk/clerk-react';
import { getURLByFile } from '../../../../../../../../shared/utils';
import { PinkLoader } from '../../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { ErrorMessage } from '../../../../../../../../shared/components/styled';

type ChangePhotoWindowProps = {
  image: CustomFile;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setFieldValue: SetFieldValue;
  initialImageObj: { file: CustomFile & { file: File | null } };
};

type ReturnObj = {
  isLoading: boolean;
  isSuccess: boolean;
  error: null | string;
  setProfileImage: (image: CustomFile) => void;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export const useSetProfileImage = (setFieldValue: SetFieldValue): ReturnObj => {
const [isLoading, setIsLoading] = useState(false);
const [error, setError] = useState('');
const [isSuccess, setIsSuccess] = useState(false);
const { user } = useUser();
const saveCroppedImage = useSaveCroppedImage(setFieldValue);

const setProfileImage = async (image: CustomFile) => {
  setError('');
  setIsSuccess(false);
  setIsLoading(true);
  try {
    const croppedProfileImg = await saveCroppedImage('croppedPhoto', image, 'profile');
    
    if(typeof croppedProfileImg !== 'undefined') {
      const fl = new DataTransfer();
      fl.items.add(croppedProfileImg);
      await user?.setProfileImage({ file: croppedProfileImg });
      setFieldValue('originalPhoto', { file: {
        name: 'profile',
                url: getURLByFile(croppedProfileImg),
                type: 'image',
                id: 'profile',
                file: croppedProfileImg,
      } })
    }
    setIsSuccess(true);
  } catch (e: any) {
    if (e?.errors?.length) {
      setError(e?.errors[0]?.message);
    } else {
      setError('Failed to set the profile image');
    }
  }
  setIsLoading(false);
};

return ({
  isLoading,
  isSuccess,
  error,
  setProfileImage,
  setIsSuccess,
});
};

export const ChangePhotoWindow: React.FC<ChangePhotoWindowProps> = ({
  image,
  isOpen,
  setIsOpen,
  setFieldValue,
  initialImageObj,
}) => {
  const { t } = useTranslation('basicComponents');
  const [zoom, setZoom] = useState(1);
  const { isLoading, error, isSuccess, setIsSuccess, setProfileImage } = useSetProfileImage(setFieldValue);

  const handleSave = async () => {
    await setProfileImage(image);
    setIsSuccess(false);
    setIsOpen(false);
  };

  const handleClose = () => {
    setFieldValue('originalPhoto', initialImageObj);
    setIsOpen(false);
  };

  const onPlus = () => {
    if (zoom < 5) {
      setZoom((prev) => +(prev + 0.1).toFixed(1));
    }
  }

  const onMinus = () => {
    if (zoom > 1) {
      setZoom((prev) => +(prev - 0.1).toFixed(1));
    }
  }

  return (
    <BaseModalWindow
      isOpen={isOpen}
      handleClose={handleClose}
      maxWidth="md"
      fullWidth
      transparentBackground
    >
      <ChangePhotoWindowContainer>
        <ChangePhotoWindowWrapper>
          <ProfilePictureContainer>
            <ProfilePhotoUpload>
              <BaseCropper
                videoOrImage={{ image: image.url }}
                width={280}
                height={280}
                size="profile"
                cropShape="round"
                zoom={zoom}
              />
            </ProfilePhotoUpload>
            <ZoomButtonsWrapper>
              <RoundButton onClick={onPlus} color="secondary" style={{ marginRight: '10px', fontSize: '26px' }}>
                +
              </RoundButton>
              <SimpleBlackText style={{ marginRight: '10px' }}>Zoom in/out</SimpleBlackText>
              <RoundButton onClick={onMinus} color="secondary" style={{ marginRight: '10px', fontSize: '26px' }}>
                -
              </RoundButton>
            </ZoomButtonsWrapper>
            <div style={{ marginTop: '10px' }}>
              {error && <ErrorMessage style={{ fontSize: '16px' }}>{error}</ErrorMessage>}
            </div>
          </ProfilePictureContainer>
          <PrimaryWhiteButton color="secondary" type="button" onClick={handleSave}>{isLoading ? <PinkLoader size="xs" /> : t('buttons.save')}</PrimaryWhiteButton>
        </ChangePhotoWindowWrapper>
      </ChangePhotoWindowContainer>
      <CloseModalWindowButton handleClick={handleClose} color="black" />
    </BaseModalWindow>
  );
};
