import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetToken } from '@clerk/types';
import { payment } from '../../../../api/payment/payment';
import { LeftSpots } from '../../types/payment';

export const fetchLeftSpots = createAsyncThunk(
  'subscription/fetchLeftSpots',
  async (getToken: GetToken, thunkApi) => {
    try {
      const token = (await getToken() || '');
      const response = await payment.getLeftSpots(token);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

type initialState = {
  spots: number;
  loading: boolean;
  error: string | null;
};

export const initialState: initialState = {
  spots: 0,
  loading: false,
  error: null,
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLeftSpots.fulfilled, (state, action: PayloadAction<LeftSpots>) => {
      state.spots = Number(action.payload.spots);
      state.error = null;
      state.loading = false;
    });
    builder.addCase(
      fetchLeftSpots.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchLeftSpots.pending, (state) => {
      state.loading = true;
    });
  },
});

export default subscriptionSlice.reducer;
