import React from 'react';
import { Provider } from 'react-redux';
import {
  RouterProvider, createBrowserRouter,
} from 'react-router-dom';
import { SignIn } from '@clerk/clerk-react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { ClientContainer } from './modules/Client/containers/ClientContainer';
import clientStore from './modules/Client/redux/configureStore';
import creatorStore from './modules/Creator/redux/configureStore';
import { CreatorAndLandingContainer } from './modules/Creator/containers/CreatorAndLandingContainer';
import { Cart } from './modules/Client/containers/Cart/Cart';
import { Favourites } from './modules/Client/containers/Favourites/Favorites';
import { GalleryClientViewContainer } from './modules/Client/containers/GalleryClientView/GalleryClientViewContainer';
import { ClientPaymentPageContainer } from './modules/Client/containers/Cart/CartSummary/ClientPayment/ClientPaymentPageContainer';
import { ClientPaymentSuccessful } from './modules/Client/containers/Cart/CartSummary/ClientPayment/ClientPaymentSuccessful';
import { ClientOnboardingSupportPage } from './modules/Client/containers/ClientWalkThroughCards/ClientOnboardingMobile/ClientOnboardingSupportPage';
import { TechnicalPage } from './shared/components/TechnicalPage/TechnicalPage';
import CookiesProvider from './shared/components/CookiesConsent/CookiesProvider';
import { CookiesConsent } from './shared/components/CookiesConsent/CookiesConsent';
import { GlobalContainer } from './shared/components/GlobalContainer/GlobalContainer';

if (process.env.REACT_APP_BUILD_TARGET === 'creator') {
  const creatorContainer = document.getElementById('root');
  const creatorRoot = createRoot(creatorContainer!); // createRoot(container!) if you use TypeScript
  creatorRoot.render(
    <Provider store={creatorStore}>
      <CookiesProvider>
        <CreatorAndLandingContainer />
        <GlobalContainer>
          <CookiesConsent />
        </GlobalContainer>
      </CookiesProvider>
    </Provider>,
  );
} else {
  const router = createBrowserRouter([
    {
      path: '',
      element: <ClientContainer />,
      children: [
        {
          path: '/',
          element: <GalleryClientViewContainer />,
        },
        {
          path: 'cart',
          element: <Cart />,
        },
        {
          path: 'favourites',
          element: <Favourites />,
        },
        {
          path: 'order',
          element: <ClientPaymentPageContainer />,
          children: [
            {
              path: 'success/:id',
              element: <ClientPaymentSuccessful />,
            },
          ],
        },
        {
          path: 'support',
          element: <ClientOnboardingSupportPage />,
        },
        {
          path: 'oops',
          element: <TechnicalPage />,
        },
        {
          path: 'signin',
          element: <SignIn />,
        },
      ],
    },
  ], {
    basename: `/${window.location?.href?.split('/')[3]}`,
  });

  const clientContainer = document.getElementById('root');
  const clientRoot = createRoot(clientContainer!); // createRoot(container!) if you use TypeScript
  clientRoot.render(
    <Provider store={clientStore}>
      <RouterProvider router={router} />
    </Provider>,
  );
}

reportWebVitals();
