import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ErrorType,
  GalleryFileInformation,
} from '../../../../../shared/types';

type GalleryFileSettings = {
  sectionId: string;
  selected: string[];
  settings: GalleryFileInformation;
};

type initialState = {
  loading: boolean;
  error: ErrorType;
  data: GalleryFileSettings[];
};

export const initialState: initialState = {
  loading: false,
  error: null,
  data: [],
};

const fileSettingsCreatorSlice = createSlice({
  name: 'fileSettingsCreator',
  initialState,
  reducers: {
    setValues(state, action: PayloadAction<GalleryFileSettings>) {
      const indexSection = state.data.findIndex(
        (item) => item.sectionId === action.payload.sectionId,
      );
      if (indexSection === -1) {
        state.data.push(action.payload);
      } else {
        state.data[indexSection].selected = action.payload.selected;
        state.data[indexSection].settings = action.payload.settings;
      }
    },
    setError(state, action) {
      state.error = action.payload;
    },
    unsetValues(state, action: PayloadAction<{ sectionId: string }>) {
      state.data = state.data.filter(
        (item) => item.sectionId !== action.payload.sectionId,
      );
    },
    unsetAllValues(state) {
      state.data = [];
    },
    unsetError(state) {
      state.error = null;
    },
  },
});

export const {
  setValues, setError, unsetError, unsetValues, unsetAllValues,
} = fileSettingsCreatorSlice.actions;

export default fileSettingsCreatorSlice.reducer;
