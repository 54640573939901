import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { EmailWasConfirmedCard } from './EmailWasConfirmedCard';
import { SignUpEmailConfirmation } from './SignUpEmailConfirmation';
import { SignUpSteps } from './SignUpSteps/SignUpSteps';
import { useCreatorSignUp } from './useCreatorSignUp';

export const SignUpContainer: React.FC = () => {
  const {
    isLoading, error, sendEmail, signUpSubmit,
  } = useCreatorSignUp();

  return (
    <Routes>
      <Route path="*" element={<SignUpEmailConfirmation isLoading={isLoading} sendEmail={sendEmail} />} />
      <Route path="thanks" element={<EmailWasConfirmedCard />} />
      <Route path="confirm" element={<SignUpSteps isLoading={isLoading} signUpSubmit={signUpSubmit} error={error} />} />
    </Routes>
  );
};
