import React, { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { useFormikContext } from 'formik';
import { ErrorMessage, TextLabelWrapper } from '../../../../../../../../../shared/components/styled';
import {
  SwiperArrowsWrapper,
  TitleAndErrorWrapper,
} from './styled';
import { GalleryData } from '../../../../../../../../../shared/types';
import { ArrowButtons } from '../../../../../../../../../shared/components/ArrowButtons/ArrowButtons';

type SwiperHeaderProps = {
  customSlider: MutableRefObject<Slider | null | undefined>;
};

export const SwiperHeader: React.FC<SwiperHeaderProps> = ({ customSlider }) => {
  const { t } = useTranslation('createNewGallery');
  const { errors, touched } = useFormikContext<GalleryData>();

  const error = errors.galleryDesign;
  const wasTouched = touched?.galleryDesign;

  return (
    <>
      <SwiperArrowsWrapper>
        <ArrowButtons
          handleLeftClick={() => customSlider.current?.slickPrev()}
          handleRightClick={() => customSlider.current?.slickNext()}
          buttonStyles={{ width: '33px', height: '33px' }}
        />
      </SwiperArrowsWrapper>
      <TitleAndErrorWrapper>
        <TextLabelWrapper wrapperDirection="row">{t('firstStep.chooseGalleryDesign')}</TextLabelWrapper>
        <ErrorMessage>{error && wasTouched ? error : null}</ErrorMessage>
      </TitleAndErrorWrapper>

    </>
  );
};
