import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import clientStore, { RootState as RootStateClient } from '../../modules/Client/redux/configureStore';
import creatorStore, { RootState as RootStateCreator } from '../../modules/Creator/redux/configureStore';
import galleryStore, { RootState as RootStateGallery } from '../../modules/GalleryView/redux/configureStore';

export const useTypedSelectorClient: TypedUseSelectorHook<RootStateClient> = useSelector;
export const useTypedSelectorCreator: TypedUseSelectorHook<RootStateCreator> = useSelector;
export const useTypedSelectorCommon: TypedUseSelectorHook<
  RootStateCreator | RootStateClient
> = useSelector;
export const useTypedSelectorGallery: TypedUseSelectorHook<RootStateGallery> = useSelector;

export type ClientAppDispatch = typeof clientStore.dispatch
export const useClientAppDispatch: () => ClientAppDispatch = useDispatch;

export type CreatorAppDispatch = typeof creatorStore.dispatch
export const useCreatorAppDispatch: () => CreatorAppDispatch = useDispatch;

export type GalleryAppDispatch = typeof galleryStore.dispatch
export const useGalleryAppDispatch: () => GalleryAppDispatch = useDispatch;
