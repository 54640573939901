import { createAsyncThunk } from '@reduxjs/toolkit';
import { creatorLogOut, creatorSignIn } from '../../../../../api/creator/creatorAuth';
import { SignIn } from '../../../../../shared/types';

export const fetchSignInCreator = createAsyncThunk(
  'signInCreator/fetchSignInCreator',
  async (data: SignIn, thunkApi) => {
    try {
      const response = await creatorSignIn.signIn(data);
      window.location.pathname = '/creator';
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  },
);

export const fetchLogOutCreator = createAsyncThunk(
  'signInCreator/fetchLogOutCreator',
  async (_, thunkApi) => {
    try {
      const response = await creatorLogOut.logout();
      window.location.pathname = '/creator';
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  },
);
