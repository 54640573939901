import { useEffect, useMemo } from 'react';
import { SetFieldError } from '../types';
import { ResponseErrors } from '../types/httpError';

type ReturnObj = {
  notFieldErrors: string | null;
};

export const useSetErrors = (
  errors: ResponseErrors | null,
  setFieldError: SetFieldError,
): ReturnObj => {
  useEffect(() => {
    if (errors && errors?.errorType === 'wrongData') {
      errors.errors?.forEach((item) => {
        setFieldError(item.field, item.message);
      });
    }
  }, [errors, setFieldError]);

  const notFieldErrors = useMemo(() => {
    if (errors?.errorType === 'other' && errors?.errors.length > 0) {
      return errors.errors[0].message;
    }
    return null;
  }, [errors]);

  return {
    notFieldErrors,
  };
};
