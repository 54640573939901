import React from 'react';
import { RowsWrapper, TabPanelColumnWrapper } from '../styled';
import { EmailNotificationsAndBranding } from './EmailNotificationsAndBranding';
import { PaymentCurrency } from './PaymentCurrency';
import { PinkLoader } from '../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';
import { TabSettingsWrapper } from '../ProfileTab/styled';
// import { Watermark } from './Watermark';

export const AdvancedSettings: React.FC = () => {
  const isLoadingUser = useTypedSelectorCreator(
    (state) => state.creator.loading,
  );

  // if (isLoadingUser) {
  //   return (
  //     <TabSettingsWrapper>
  //       <RowsWrapper>
  //         <PinkLoader />
  //       </RowsWrapper>
  //     </TabSettingsWrapper>
  //   );
  // }

  return (
    <TabPanelColumnWrapper>
      {/* hide for now */}
      {/* <Watermark /> */}
      <PaymentCurrency />
      <EmailNotificationsAndBranding />
    </TabPanelColumnWrapper>
  );
};
