import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { LandingContentContainer } from '../LandingContentContainer';
import { LandingTermsAndPrivacyWrapper, TermsAndPrivacyText } from './styled';
import { TermsAndPrivacyTopScreen } from './TermsAndPrivacyTopScreen';
import privacyPolicyImage from '../../../../assets/image/termsAndPrivacy/privacyPolicy.png';
import { TermsAndPrivacyContactInfo } from './TermsAndPrivacyContactInfo';
import { InfoWeCollectPoints } from './LandingPrivacyPoints/InfoWeCollectPoints';
import { HowCollectInfoPoints } from './LandingPrivacyPoints/HowCollectInfoPoints';
import { WhyCollectInfoPoints } from './LandingPrivacyPoints/WhyCollectInfoPoints';
import { OverseasDisclosurePoints } from './LandingPrivacyPoints/OverseasDisclosurePoints';
import { YourRightsPoints } from './LandingPrivacyPoints/YourRightsPoints';
import { StorageAndSecurityPoints } from './LandingPrivacyPoints/StorageAndSecurityPoints';
import { CookiesPoints } from './LandingPrivacyPoints/CookiesPoints';
import { LinksToOtherWebsitesPoints } from './LandingPrivacyPoints/LinksToOtherWebsitesPoints';
import { SocialMediaPoints } from './LandingPrivacyPoints/SocialMediaPoints';
import { AmendmentsPoints } from './LandingPrivacyPoints/AmendmentsPoints';

export const LandingPrivacy: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <LandingContentContainer>
      <LandingTermsAndPrivacyWrapper>
        <TermsAndPrivacyTopScreen
          title={t('privacy.titlePrivacy')}
          imageUrl={privacyPolicyImage}
          lastUpdateDate="28 March 2022"
        />
        <Trans t={t} i18nKey="privacy.privacyPolicyIntro">
          <TermsAndPrivacyText>
            Delivrable Pty Ltd ABN 84 d5d d03 100 (
            <span className="bold">we</span>
            ,
            {' '}
            <span className="bold">us</span>
            {' '}
            or
            {' '}
            <span className="bold">our</span>
            ), understands that protecting your personal information is
            important.
          </TermsAndPrivacyText>
          <TermsAndPrivacyText>
            This Privacy Policy sets out our commitment to protecting the
            privacy of personal information provided to us, or otherwise
            collected by us when providing our software and our website platform
            available at
            {' '}
            <NavLink to="/">deIivrabIe.io</NavLink>
            {' '}
            <span className="bold">(Services)</span>
            {' '}
            or when otherwise
            interacting with you.
          </TermsAndPrivacyText>
        </Trans>
        <InfoWeCollectPoints />
        <HowCollectInfoPoints />
        <WhyCollectInfoPoints />
        <OverseasDisclosurePoints />
        <YourRightsPoints />
        <StorageAndSecurityPoints />
        <CookiesPoints />
        <LinksToOtherWebsitesPoints />
        <SocialMediaPoints />
        <AmendmentsPoints />
        <TermsAndPrivacyContactInfo />
      </LandingTermsAndPrivacyWrapper>
    </LandingContentContainer>
  );
};
