export const rootLanding = '/';

export const demoGalleriesUrl = '/demo-galleries';
export const demoGalleryUrl = `${demoGalleriesUrl}/demo`;

export const pricingUrl = '/pricing';
export const landingPaymentUrl = `${pricingUrl}/payment`;
export const landingPaymentSuccess = `${pricingUrl}/payment/success`;

export const privacyUrl = 'privacy';
export const termsUrl = 'terms';

export const instagram = 'https://www.instagram.com/';
export const youtube = 'http://www.youtube.com/';
export const facebook = 'https://en-gb.facebook.com/';
