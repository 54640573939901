import React from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import * as urls from '../../../../constants/urls';
import { CustomBadge } from '../../../../shared/components/CustomBadge/CustomBadge';
import { useTypedSelectorClient } from '../../../../shared/hooks/useTypedSelector';
import { HeartIcon, ShareIcon, CartIcon } from '../../../../shared/icons';
import { RoundButton } from '../../../../shared/styles';
import { RoundButtonWithPopup } from './styled';
import { useShare } from '../../../GalleryView/hooks/buttons/useShare';
import { CartPopupWindow } from '../Cart/CartPopup/CartPopupWindow';

export const NavButtons: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { handleOpenClose, shareWindow } = useShare();

  const gallery = useTypedSelectorClient((state) => state.signIn.gallery);
  const totalNumberFiles = useTypedSelectorClient(
    (state) => state.cart.totalFiles,
  );
  const totalNumberFavourites = useTypedSelectorClient(
    (state) => state.favourites.count,
  );

  const handleClickToCart = () => {
    navigate(urls.clientCartUrl);
  };

  const handleClickToFavourites = () => {
    navigate(urls.clientFavouritesUrl);
  };

  return (
    <>
      <CustomBadge badgeContent={totalNumberFavourites} id="client-favourites-button-step">
        <Link to={urls.clientFavouritesUrl}>
          <RoundButton
            className={
              pathname.includes(urls.clientFavouritesUrl) ? ' active ' : ''
            }
            color="grey"
            onClick={handleClickToFavourites}
            size="medium"
          >
            <HeartIcon />
          </RoundButton>
        </Link>
      </CustomBadge>
      <CustomBadge badgeContent={totalNumberFiles}>
        <RoundButtonWithPopup
          className={pathname.includes(urls.clientCartUrl) ? ' active ' : ''}
          color="grey"
          onClick={handleClickToCart}
          id="client-download-cart-header-button-step"
          size="medium"
        >
          <CartIcon />
          <CartPopupWindow />
        </RoundButtonWithPopup>
      </CustomBadge>
      {gallery?.sharing && (
      <>
        <RoundButton
          onClick={handleOpenClose}
          color="grey"
          id="client-share-header-button-step"
          size="medium"
        >
          <ShareIcon />
        </RoundButton>
        {shareWindow}
      </>
      )}
    </>
  );
};
