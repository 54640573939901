import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { FormikHelpers, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  useNavigate, useLocation,
} from 'react-router-dom';
import { useSignIn, SignInButton } from '@clerk/clerk-react';
import * as urls from '../../../../constants/urls';
import { CommonInput } from '../../../../shared/components/FormElements/CommonInput/CommonInput';
import {
  SignInUpCard,
  TitleSimple,
  CustomGrid,
  ErrorWrapper,
  PrimaryWhiteButton,
  SignInUpCardCenter,
  TextWithIcon,
} from '../../../../shared/styles';
import { NewPassword } from '../../../../shared/types';
import { resetPassword } from '../../../../constants/initialValues';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../shared/hooks/useTypedSelector';
import { ErrorMessage } from '../../../../shared/components/styled';
import { ResetSuccessButton, SendResendText, SignInForm } from './styled';
import { fetchResetPasswordCreator } from '../../redux/userCreator/resetPassword/resetPasswordCreatorThunk';
import { NewPasswordSchema } from '../../../../shared/validation/signInUp/creator/signInUpSchemas';
import { useSetErrors } from '../../../../shared/hooks/useSetErrors';
import { TickIcon } from '../../../../shared/icons';
import { PinkLoader } from '../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { getDecodedToken } from '../../../../shared/utils/signUp/getDecodedToken';
import { setTimeToResendData } from '../../utils/helpers';

export const SetNewPasswordSuccess: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['signIn', 'basicComponents']);
  const onBack = () => {
    navigate(urls.creatorSignInUrl);
  };

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item lg={6} md={9} xs={11}>
        <SignInUpCardCenter theme="">
          <TitleSimple style={{ textAlign: 'center' }}>{t('resetPassword')}</TitleSimple>
          <TextWithIcon>
            <TickIcon />
            <span style={{ textAlign: 'center' }}>{t('resetSuccessfully')}</span>
          </TextWithIcon>
          <ResetSuccessButton type="button" width="213" onClick={onBack}>
            {t('basicComponents:signIn')}
          </ResetSuccessButton>
        </SignInUpCardCenter>
      </Grid>
    </Grid>
  );
};

export const SetNewPassword: React.FC = () => {
  const { t } = useTranslation(['signIn', 'basicComponents']);
  const { search } = useLocation();
  const navigate = useNavigate();
  const resetError = useTypedSelectorCreator((state) => state.resetPassword?.error);
  // const isLoading = useTypedSelectorCreator((state) => state.resetPassword?.loading);
  const [isLoading, setIsLoading] = useState(false);
  const passwordWasSet = useTypedSelectorCreator((state) => state.resetPassword?.passwordWasSet);
  const { isLoaded, signIn, setActive } = useSignIn();

  const onSubmit = async (data: NewPassword, bag: FormikHelpers<NewPassword>) => {
    bag.setSubmitting(false);
    setIsLoading(true);
    signIn
      ?.attemptFirstFactor({
        strategy: 'reset_password_email_code',
        code: data.code,
        password: data.newPassword,
      })
      .then((result) => {
        // Check if 2FA is required
        setIsLoading(false);
        if (result.status === 'needs_second_factor') {
          // setSecondFactor(true);
          // setError('');
        } else if (result.status === 'complete') {
          // navigate(urls.resetConfirmSuccessUrl);
          setActive({ session: result.createdSessionId });
          // setError('');
        } else {
          console.log(result);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error('error', { errors: err.errors });
        bag.setFieldError('code', err.errors[0].longMessage);
        // setError(err.errors[0].longMessage);
      });
    // const token = getDecodedToken(search);
    // dispatch(fetchResetPasswordCreator({ password: data.newPassword, token }));
  };

  const {
    values, handleSubmit, errors, handleChange, touched, handleBlur, setFieldError,
  } = useFormik({
    initialValues: resetPassword,
    validationSchema: NewPasswordSchema,
    onSubmit: (data: NewPassword, bag) => onSubmit(data, bag),
  });
  const { notFieldErrors } = useSetErrors(resetError, setFieldError);

  if (passwordWasSet) {
    return <SetNewPasswordSuccess />;
  }

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item lg={6} md={9} xs={11}>
        <SignInUpCard theme="reset">
          <TitleSimple>{t('signIn:resetPassword')}</TitleSimple>
          <SignInForm direction="column">
            <form onSubmit={handleSubmit} noValidate>
              <Grid container justifyContent="center">
                <CustomGrid item sm={9}>
                  <CommonInput
                    error={errors.code}
                    value={values.code}
                    name="code"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    type="text"
                    placeholder="Code"
                    touched={touched.code}
                  />
                </CustomGrid>
                <CustomGrid item sm={9}>
                  <CommonInput
                    error={errors.newPassword}
                    value={values.newPassword}
                    name="newPassword"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    type="password"
                    placeholder="Your new password"
                    touched={touched.newPassword}
                    eyeButton
                  />
                </CustomGrid>
                <CustomGrid item sm={9}>
                  <CommonInput
                    error={errors.confirmPassword}
                    value={values.confirmPassword}
                    name="confirmPassword"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    type="password"
                    placeholder="Confirm password"
                    touched={touched.confirmPassword}
                    eyeButton
                  />
                </CustomGrid>
              </Grid>
              {notFieldErrors
                && (
                <ErrorWrapper>
                  <ErrorMessage>{notFieldErrors}</ErrorMessage>
                </ErrorWrapper>
                )}
              <PrimaryWhiteButton type="submit" style={{ minWidth: '213px' }} disabled={isLoading}>
                {isLoading ? <PinkLoader size="xs" /> : t('basicComponents:send')}
              </PrimaryWhiteButton>
            </form>
          </SignInForm>
        </SignInUpCard>
      </Grid>
    </Grid>
  );
};
