import {
  ClientGallery,
  GalleryData,
  GalleryFile,
  GalleryIdea,
} from '../../../shared/types';
import { CartReq, FavouritesCartReq } from '../../../shared/types/client';

export const getSectionId = (
  gallery: ClientGallery | GalleryData | null,
  fileId: string,
): string => {
  if (!gallery) {
    return '';
  }
  const res = gallery.sections.find((section) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    [...section.files, ...section.ideas].find((file) => file.id === fileId));
  return res?.id || '';
};

export const getFile = (
  gallery: ClientGallery | GalleryData | null,
  sectionId: string,
  fileId: string,
): GalleryFile | GalleryIdea | null => {
  if (!gallery) {
    return null;
  }
  const section = gallery?.sections?.find((item) => item?.id === sectionId);
  const file = [...(section?.files || []), ...(section?.ideas || [])]?.find(
    (item) => item.id === fileId,
  );
  return file || null;
};

export const getFavouritesCartReqData = (
  fileIds: string[],
  gallery: ClientGallery | null,
): FavouritesCartReq => {
  if (!gallery) {
    return {
      galleryId: '',
      favouriteCartItems: [],
    };
  }
  const favouriteItems = fileIds.map((item) => ({
    fileId: item,
    sectionId: getSectionId(gallery, item),
  }));
  return {
    galleryId: gallery.id,
    favouriteCartItems: favouriteItems,
  };
};

export const getCartReqData = (
  fileIds: string[],
  gallery: ClientGallery | null,
  remainFreeFiles?: { id: string; remainFreeFiles: number }[],
): CartReq => {
  if (!gallery) {
    return {
      galleryId: '',
      cartItems: [],
    };
  }
  const cartItems = fileIds.map((item) => {
    const sectionId = getSectionId(gallery, item);

    const file = getFile(gallery, sectionId, item);
    let price = Number(file?.price || 0);
    let isFree = false;
    if (
      remainFreeFiles
      && !(file as GalleryFile)?.instantlyDownloadable
      && Number(file?.price) > 0
    ) {
      const remainFreeFilesItem = remainFreeFiles.find(
        (item2) => item2.id === sectionId,
      );
      if (remainFreeFilesItem) {
        remainFreeFilesItem.remainFreeFiles -= 1;
        if (remainFreeFilesItem.remainFreeFiles >= 0) {
          price = 0;
          isFree = true;
        }
      }
    }
    return {
      fileId: item,
      sectionId,
      price,
      isFree,
    };
  });
  return {
    galleryId: gallery.id,
    cartItems,
  };
};
