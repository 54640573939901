import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorType } from '../../../../shared/types';
import { Cart, CartSection } from '../../../../shared/types/client';
import {
  fetchAddToCart,
  fetchGetClientCart,
  fetchRemoveFromCart,
} from './cartClientThunk';

type initialState = {
  totalFiles: number;
  sections: CartSection[];
  subTotal: number;
  loadingGet: boolean;
  loadingPut: boolean;
  error: ErrorType;
};

export const initialState: initialState = {
  totalFiles: 0,
  sections: [],
  subTotal: 0,
  loadingGet: false,
  loadingPut: false,
  error: null,
};

const cartClientSlice = createSlice({
  name: 'cartClientSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetClientCart.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.totalFiles = action.payload.totalFiles;
        state.subTotal = action.payload.subTotal;
        state.error = null;
        state.loadingGet = false;
        state.sections = action.payload.sections;
      },
    );
    builder.addCase(
      fetchGetClientCart.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingGet = false;
      },
    );
    builder.addCase(fetchGetClientCart.pending, (state) => {
      state.loadingGet = true;
    });
    builder.addCase(
      fetchAddToCart.fulfilled,
      (state, action: PayloadAction<Cart>) => {
        state.totalFiles += action.payload.totalFiles;
        state.subTotal = action.payload.subTotal;
        state.error = null;
        state.loadingGet = false;
        if (state.sections.length === 0) {
          state.sections = action.payload.sections;
        } else {
          const sections = [...state.sections];
          sections.forEach((section) => {
            action.payload.sections.forEach((item) => {
              if (section.sectionId === item.sectionId) {
                section.freeFiles += item.freeFiles;
                section.files = [...section.files, ...item.files];
                section.filesNumber += item.filesNumber;
                section.totalSum += item.totalSum;
              }
            });
          });
        }
      },
    );
    builder.addCase(
      fetchAddToCart.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingGet = false;
      },
    );
    builder.addCase(fetchAddToCart.pending, (state) => {
      state.loadingGet = true;
    });
    builder.addCase(
      fetchRemoveFromCart.fulfilled,
      (state, action: PayloadAction<string[]>) => {
        state.totalFiles -= action.payload.length;
        state.error = null;
        state.loadingPut = false;
        state.sections.forEach((stateSection) => {
          let newTotalSum = 0;
          const filteredFiles = stateSection.files.filter((file) => {
            if (action.payload.includes(file.id)) {
              if ((file as any).isFree) {
                stateSection.freeFiles -= 1;
              }
              return false;
            }
            newTotalSum += Number(file.price);
            return true;
          });
          stateSection.files = filteredFiles;
          stateSection.filesNumber = filteredFiles.length;
          stateSection.totalSum = newTotalSum;
        });
      },
    );
    builder.addCase(
      fetchRemoveFromCart.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingPut = false;
      },
    );
    builder.addCase(fetchRemoveFromCart.pending, (state) => {
      state.loadingPut = true;
    });
  },
});

export default cartClientSlice.reducer;
