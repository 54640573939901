import React, { CSSProperties } from 'react';
import { ArrowSimpleIcon } from '../../icons';
import { RoundButton } from '../../styles';
import { ArrowWrapper } from '../styled';
import { ButtonsDirectionWrapper } from './styled';

type ArrowButtonsProps = {
  handleLeftClick: () => void;
  handleRightClick: () => void;
  buttonType?: 'submit' | 'button';
  large?: boolean;
  toRightNone?: boolean;
  buttonStyles?: CSSProperties;
};

export const ArrowButtons: React.FC<ArrowButtonsProps> = ({
  handleLeftClick,
  handleRightClick,
  buttonType = 'button',
  large,
  toRightNone,
  buttonStyles,
}) => (
  <ButtonsDirectionWrapper large={large}>
    <RoundButton
      size={large ? 'large' : 'small'}
      type={buttonType}
      onClick={handleLeftClick}
      style={buttonStyles}
    >
      <ArrowWrapper large={large} direction="left">
        <ArrowSimpleIcon direction="left" />
      </ArrowWrapper>
    </RoundButton>
    {!toRightNone
    && (
    <RoundButton
      size={large ? 'large' : 'small'}
      type={buttonType}
      onClick={handleRightClick}
      style={buttonStyles}
    >
      <ArrowWrapper large={large} direction="right">
        <ArrowSimpleIcon direction="right" />
      </ArrowWrapper>
    </RoundButton>
    )}
  </ButtonsDirectionWrapper>
);
