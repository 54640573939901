import React, { CSSProperties } from 'react';
import { NavLink } from 'react-router-dom';
import { MainLogoNoWhiteSpace } from '../../icons';
import { Header } from './styled';
import * as urls from '../../../constants/urls';

type HeaderWithLogoProps = {
  isCLient?: boolean;
  isCreator?: boolean;
  style?: CSSProperties;
  url?: string;
};

export const HeaderWithLogo: React.FC<HeaderWithLogoProps> = ({
  isCLient,
  isCreator,
  style,
  url,
}) => (
  <NavLink to={url || urls.rootUrl}>
    <Header style={style} isClient={!!isCLient} isCreator={!!isCreator}>
      <MainLogoNoWhiteSpace />
    </Header>
  </NavLink>
);
