import styled from '@emotion/styled';

export const FileLoaderWrapper = styled.div<{isLittle: boolean}>`
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: inherit;

  & svg {
    ${({ isLittle }) => (isLittle ? 'width: 33px; height: 32px;' : '')}
  }
`;
