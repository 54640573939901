import React from 'react';
import { RoundAvatar } from '../../../../shared/components/RoundAvatar/RoundAvatar';
import { CommentTextarea } from './CommentTextarea';
import { CommentItemContainerInput } from './styled';

type CommentItemWithTextareaProps = {
  urlUserAvatar: string;
  addComment: (comment: string) => void;
  currentUserName: string;
}

export const CommentItemWithTextarea: React.FC<CommentItemWithTextareaProps> = ({
  urlUserAvatar,
  addComment,
  currentUserName,
}) => (
  <CommentItemContainerInput>
    <RoundAvatar userName={currentUserName} urlPhoto={urlUserAvatar} size="medium" />
    <CommentTextarea addComment={addComment} />
  </CommentItemContainerInput>
);
