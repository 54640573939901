import React, { ReactElement } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SystemStyleObject } from '@mui/system';
import {
  Breakpoint, Dialog, styled, Theme,
} from '@mui/material';
import * as variables from '../../../constants/styles';

type BaseModalWindowStyleProps = {
  root?: SystemStyleObject<Theme>;
  paper?: SystemStyleObject<Theme>;
};

type BaseModalWindowProps = {
  isOpen: boolean;
  handleClose: () => void;
  fullScreen?: boolean;
  style?: BaseModalWindowStyleProps;
  fullWidth?: boolean;
  maxWidth?: false | Breakpoint;
  scroll?: 'body' | 'paper';
  children: (ReactElement | null)[] | null | ReactElement;
  transparentBackground?: boolean;
  fitContent?: boolean;
};

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    background: variables.colorGreyLight,
  },
  '& .MuiDialog-paper': {
    background: variables.colorGreyLight,
  },
}));

export const BaseModalWindow: React.FC<BaseModalWindowProps> = ({
  isOpen,
  handleClose,
  fullScreen,
  style,
  fullWidth,
  maxWidth,
  scroll,
  children,
  transparentBackground,
  fitContent,
}) => (
  <StyledDialog
    open={isOpen}
    onClose={handleClose}
    id="base-modal-window"
    aria-labelledby="base-modal-window"
    aria-describedby="alert-dialog-description"
    fullScreen={fullScreen}
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    scroll={scroll}
    sx={{
      '& .MuiPaper-root': {
        background: variables.colorGreyLight,
        ...style?.root,
      },
      '& .MuiDialog-paper': {
        background: transparentBackground ? 'transparent' : variables.colorGreyLight,
        width: fitContent ? 'fit-content' : '-',
        height: fitContent ? 'fit-content' : '-',
        ...style?.paper,
      },
    }}
  >
    {/* <div style={{ position: 'relative' }} id="base-modal-wrap"> */}
    {children}
    {/* </div> */}
  </StyledDialog>
);
