import { useUser } from '@clerk/clerk-react';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTimer } from '../../../../../../../../shared/hooks/useTimer';
import { setTimeToResendData } from '../../../../../../utils/helpers';

type ReturnObj = {
    isLoading: boolean;
    isSuccess: boolean;
    error: null | string;
    changeEmail: (email: string) => void;
    setIsSuccess: Dispatch<SetStateAction<boolean>>;
    msCounter: number;
  }

export const useChangeEmail = (): ReturnObj => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const { user } = useUser();
  const { msCounter, startNewTimer } = useTimer();

  const changeEmail = async (email: string) => {
    setError('');
    setIsSuccess(false);
    setIsLoading(true);
    try {
      const newEmail = user?.emailAddresses?.find((item) => item.emailAddress === email && item.verification.status !== 'verified');
      if (!newEmail) {
        const res = await user?.createEmailAddress({ email });
        await res?.prepareVerification({ strategy: 'email_link', redirectUrl: `${window.location.origin}/email-confirm` });
      } else {
        await newEmail?.prepareVerification({ strategy: 'email_link', redirectUrl: `${window.location.origin}/email-confirm` });
      }
      setIsSuccess(true);
    } catch (e: any) {
      if (e?.errors?.length) {
        setError(e?.errors[0]?.message);
      } else {
        setError('Something went wrong');
      }
    }
    setIsLoading(false);
    setTimeToResendData();
    startNewTimer();
  };

  return ({
    isLoading,
    isSuccess,
    error,
    changeEmail,
    setIsSuccess,
    msCounter,
  });
};
