import { useUser } from '@clerk/clerk-react';
import { Dispatch, SetStateAction, useState } from 'react';

type ChangePasswordReturn = {
    isLoading: boolean;
    isSuccess: boolean;
    error: string;
    changePasswordSubmit: (data: { password: string, currentPassword: string }) => void;
    setIsSuccess: Dispatch<SetStateAction<boolean>>;
  };

export const useChangePassword = (): ChangePasswordReturn => {
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const changePasswordSubmit = async (data: { password: string, currentPassword: string }) => {
    setError('');
    setIsLoading(true);
    setIsSuccess(false);
    user?.updatePassword({
      currentPassword: data.currentPassword, newPassword: data.password,
    })
      .then(() => { setIsSuccess(true); })
      .catch((e) => {
        if (e?.errors?.length) {
          setError(e?.errors[0]?.message);
        } else {
          setError('Something went wrong');
        }
        setIsSuccess(false);
      })
      .finally(() => { setIsLoading(false); });
  };

  return {
    isLoading,
    isSuccess,
    error,
    changePasswordSubmit,
    setIsSuccess,
  };
};
