import React from 'react';
import { Grid } from '@material-ui/core';
import {
  FifthMediumTextMessageTablet,
  FifthMediumTextTablet,
  FifthTitleTablet,
  GridWithBorderL,
  GridWithBorderR,
  ImageContainerFifthTablet,
} from './styled';
import { GalleryCover } from '../../../../../shared/types';
import { useGalleryContent } from '../../../hooks/useGalleryContent';

export const GalleryDemoFifthTablet: React.FC<GalleryCover> = (props) => {
  const { isPreview } = props;

  const data = useGalleryContent({
    ...props,
    size: 'tablet',
    galleryNumber: 'fifth',
  });

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ padding: '0px', height: '100%' }}
      spacing={0}
    >
      <Grid
        spacing={0}
        container
        item
        sm={12}
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid
          container
          item
          sm={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <GridWithBorderR item sm={3} container alignItems="center">
            <FifthMediumTextTablet isPreview={!!isPreview}>
              {data.date}
            </FifthMediumTextTablet>
          </GridWithBorderR>
          <Grid item sm={6} container alignItems="center">
            <FifthTitleTablet isPreview={!!isPreview}>
              {data.name}
            </FifthTitleTablet>
          </Grid>
          <GridWithBorderL
            container
            item
            sm={3}
            justifyContent="flex-end"
            alignItems="center"
          >
            <FifthMediumTextTablet isPreview={!!isPreview}>
              {data.userName}
            </FifthMediumTextTablet>
          </GridWithBorderL>
        </Grid>
        <Grid
          style={{ padding: '32px 0px 24px' }}
          container
          item
          sm={12}
          justifyContent="center"
          alignItems="flex-start"
        >
          <ImageContainerFifthTablet isPreview={!!isPreview}>
            {data.file}
          </ImageContainerFifthTablet>
        </Grid>
        <Grid item sm={8}>
          <FifthMediumTextMessageTablet isPreview={!!isPreview}>
            {data.message}
          </FifthMediumTextMessageTablet>
        </Grid>
      </Grid>
    </Grid>
  );
};
