import React, { ReactNode } from 'react';
import { isDocument, isImage, isVideo } from '..';
import { ImageContainer } from '../../components/Files/ImageContainer/ImageContainer';
import { VideoContainer } from '../../components/Files/VideoContainer/VideoContainer';
import { BasicDocumentCard } from '../../components/Files/BasicDocumentCard/BasicDocumentCard';
import { GalleryFile } from '../../types';
import { FileLoader } from '../../components/Loaders/FileLoader/FileLoader';
import { FileLoaderWrapper } from '../../components/Loaders/FileLoader/styled';
import { ProgressLoader } from '../../components/Loaders/ProgressLoader/ProgressLoader';

type ImageAndVideoProps = {
  autoPlay?: boolean;
  widthStretch?: boolean;
};

type FileInContainerProps = {
  file: GalleryFile | null;
  imageAndVideoProps?: ImageAndVideoProps;
  tryingVideoThumbnailUrl?: string;
  isLoading?: boolean;
  watermarked?: boolean;
  isLoadingWithProgress?: boolean;
  progress?: number;
  littlePlayButton?: boolean;
};

export const getFileInContainer = ({
  file,
  imageAndVideoProps,
  tryingVideoThumbnailUrl,
  isLoading,
  watermarked,
  isLoadingWithProgress,
  progress,
  littlePlayButton,
}: FileInContainerProps): ReactNode | null => {
  if (isLoadingWithProgress) {
    return (
      <FileLoaderWrapper isLittle={false}>
        <ProgressLoader value={progress || 0} />
      </FileLoaderWrapper>
    );
  }
  if (isLoading) {
    return <FileLoader isLittle isLoading />;
  }
  if (isDocument(file) && file) {
    return <BasicDocumentCard isSmall file={file} />;
  }
  if (isImage(file)) {
    return (
      <ImageContainer
        imageUrl={watermarked ? (file?.urlWatermarked || '') : (file?.url || '')}
        widthStretch={imageAndVideoProps?.widthStretch}
      />
    );
  }
  if (isVideo(file)) {
    return (
      <VideoContainer
        videoThumbnail={tryingVideoThumbnailUrl || file?.videoThumbnail?.url}
        videoUrl={file?.url || ''}
        widthStretch={imageAndVideoProps?.widthStretch}
        autoPlay={imageAndVideoProps?.autoPlay}
        littlePlayButton={littlePlayButton}
      />
    );
  }
  return null;
};
