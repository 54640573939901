import { FormikHelpers, useFormikContext } from 'formik';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  CustomFile,
  GalleryData,
  GalleryFile,
  GalleryFileInformation,
  GalleryIdea,
  GallerySection,
} from '../../../../../../../shared/types';
import { errorMessages, isVideo } from '../../../../../../../shared/utils';
import { useErrors } from '../../../../../hooks/useErrors';
import { getGalleryDataForRequest } from '../../../../../utils/getGalleryDataForRequest';
import { useUpdateGallery } from './useUpdateGallery';
import { setSuccessUpdateFalse } from '../../../../../redux/gallery/galleryCreatorSlice';
import { unsetValues } from '../../../../../redux/gallery/fileSettings/fileSettingsCreatorSlice';
import { useCreatorAppDispatch } from '../../../../../../../shared/hooks/useTypedSelector';

type ReturnObj = {
  formError: string;
  setFormError: Dispatch<SetStateAction<string>>;
  handleApplySettings: (
    data: GalleryFileInformation,
    bag: FormikHelpers<GalleryFileInformation>,
    currentSection: GallerySection
  ) => void;
  applySettings: (
    currentSection: GallerySection,
    settings: GalleryFileInformation,
  ) => { resFiles: GalleryFile[], resIdeas: GalleryIdea[] };
  settingsApplied: boolean;
  isLoading: boolean;
  errorAlert: ReactElement;
  disabled: boolean;
};

type ApplyFileSettingsProps = {
  isItemSelected: (id: string) => boolean;
};

export const useApplyFileSettings = ({
  isItemSelected,
}: ApplyFileSettingsProps): ReturnObj => {
  const [formError, setFormError] = useState<string>('');
  const [settingsApplied, setSettingsApplied] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [bagObj, setBagObj] = useState<FormikHelpers<GalleryFileInformation> | null>(null);
  const { values: gallery, setFieldValue } = useFormikContext<GalleryData>();
  const dataCopy: GalleryData = JSON.parse(JSON.stringify(gallery));
  const {
    updateGallery, isLoading, error, isSuccess,
  } = useUpdateGallery();
  const dispatch = useCreatorAppDispatch();
  const errorAlert = useErrors(error, bagObj?.setFieldError);

  const applySettings = (
    currentSection: GallerySection,
    settings: GalleryFileInformation,
  ): { resFiles: GalleryFile[], resIdeas: GalleryIdea[] } => {
    try {
      const indexSection = dataCopy.sections?.findIndex(
        (item) => item.id === currentSection.id,
      );
      const resFiles = currentSection?.files?.map((item) => {
        if (isItemSelected(item.id)) {
          if (isVideo(item)) {
            item = {
              ...item,
              ...settings,
              videoThumbnail: {
                ...settings.videoThumbnail,
                id: (settings.videoThumbnail as unknown as any)?.thumbnail || '',
              } as CustomFile,
            };
          } else {
            item = { ...item, ...settings };
          }
        }
        return item;
      });
      const resIdeas = currentSection?.ideas.map((item) => {
        if (isItemSelected(item.id)) {
          item = {
            ...item,
            // instantlyDownloadable: data.instantlyDownloadable,
            watermark: settings.watermark,
            allowCommenting: settings.allowCommenting,
          };
        }
        return item;
      });
      setFieldValue(`sections[${indexSection}].files`, resFiles);
      setFieldValue(`sections[${indexSection}].ideas`, resIdeas);

      return { resFiles, resIdeas };
    } catch (e) {
      throw new Error('Something went wrong');
    }
  };

  const handleApplySettings = async (
    data: GalleryFileInformation,
    bag: FormikHelpers<GalleryFileInformation>,
    currentSection: GallerySection,
  ) => {
    setBagObj(bag);
    setDisabled(true);
    try {
      const { resFiles, resIdeas } = applySettings(currentSection, data);

      const dataForRequest = getGalleryDataForRequest(dataCopy, 'draft');
      await updateGallery(dataCopy?.id, dataForRequest);

      setFormError('');
      dispatch(unsetValues({ sectionId: currentSection.id }));
    } catch (err: any) {
      setFormError(errorMessages.smthWentWrong);
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setSettingsApplied(true);
      setTimeout(() => {
        setDisabled(false);
        setSettingsApplied(false);
      }, 1000);
      dispatch(setSuccessUpdateFalse());
    }
  }, [isSuccess]);

  return {
    formError,
    setFormError,
    handleApplySettings,
    applySettings,
    settingsApplied,
    isLoading,
    errorAlert,
    disabled,
  };
};
