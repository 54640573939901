import React, { MutableRefObject, ReactNode, useContext } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { GradientCoverWithActions } from '../../../../../../../../shared/components/GradientCoverWithActions/GradientCoverWithActions';
import { InvisibleOpenFileButton } from './InvisibleOpenFileButton/InvisibleOpenFileButton';
import {
  FilesOrIdeas, GalleryComment, GalleryFile, GalleryIdea,
} from '../../../../../../../../shared/types';
import { getIdeaCoverOrFile } from '../../../../../../../../shared/utils';
import { ClientStateContext } from '../../../../../../../Client/context/ClientStateContext';
import { ExpandAndShrinkFileButton } from './ExpandAndShrinkFileButton';
import { InstantlyDownloadableOrLike } from './InstantlyDownloadableOrLike';
import { PositionedElementWrapper } from './styled';

type ElementsOverFileProps = {
  sectionId: string;
  id: string;
  fileType: FilesOrIdeas;
  fullWidth: boolean;
  children: ReactNode;
  instantlyDownloadable: boolean;
  allowCommenting: boolean;
  isFileLiked?: boolean;
  isFileInCart?: boolean;
  file: GalleryFile | GalleryIdea;
  comments: GalleryComment[];
  fileUrl: string;
  videoRef: MutableRefObject<HTMLVideoElement | null>;
};

export const ElementsOverFile: React.FC<ElementsOverFileProps> = ({
  sectionId,
  id,
  fileType,
  fullWidth,
  children: fileElement,
  instantlyDownloadable,
  allowCommenting,
  isFileLiked,
  isFileInCart,
  file,
  comments,
  videoRef,
  fileUrl,
}) => {
  const { isClientSignIn } = useContext(ClientStateContext);

  const handleMouseOver = () => {
    if (videoRef?.current) {
      const isPlaying = videoRef?.current?.currentTime > 0.001
        && !videoRef?.current?.paused
        && !videoRef?.current?.ended
        && videoRef?.current?.readyState > videoRef?.current?.HAVE_CURRENT_DATA;
      if (!isPlaying) {
        const img = document.getElementById('thumbnail-image');
        if (img) {
          img.style.visibility = 'hidden';
        }
        videoRef?.current?.play();
      }
    }
  };

  const handleMouseOut = () => {
    if (videoRef?.current?.pause) {
      const isPlaying = videoRef?.current?.currentTime > 0.001
      && !videoRef?.current?.paused
      && !videoRef?.current?.ended
      && videoRef?.current?.readyState > videoRef?.current?.HAVE_CURRENT_DATA;
      if (isPlaying) {
        const img = document.getElementById('thumbnail-image');
        if (img) {
          img.style.visibility = 'visible';
        }
        videoRef?.current?.pause();
        videoRef.current.currentTime = 0.001;
      }
    }
  };

  return (
    <>
      {isClientSignIn && (
        <>
          <InvisibleOpenFileButton
            file={file}
            handleMouseOver={handleMouseOver}
            handleMouseOut={handleMouseOut}
          />
          <GradientCoverWithActions
            comments={comments}
            file={getIdeaCoverOrFile(file)}
            sectionId={sectionId}
            id={id}
            isAllowCommenting={!!allowCommenting}
            isInstantlyDownloadable={!!instantlyDownloadable}
            isFileLiked={!!isFileLiked}
            isFileInCart={!!isFileInCart}
            disabledAll={!(isClientSignIn)}
          />
        </>
      )}
      {fileElement}
      <InstantlyDownloadableOrLike
        isFileLiked={!!isFileLiked}
        instantlyDownloadable={!!instantlyDownloadable}
        disabledAll={!isClientSignIn}
        fileId={id}
      />
      {!isClientSignIn && (
        <PositionedElementWrapper>
          <ExpandAndShrinkFileButton
            sectionId={sectionId}
            id={id}
            fileType={fileType}
            fullWidth={fullWidth}
          />
        </PositionedElementWrapper>
      )}
    </>
  );
};
